import * as React from 'react'
import {compose} from 'redux'

import css from './DiscountBoxDialog.css'
import {resolveCss, withTranslate, WithTranslateProps} from '$common/utils'
import GenericButton from '$common/components/buttons/GenericButton'

type Props = WithTranslateProps & {
  onConfirm: Function
  onCancel: Function
}

const ConfirmVoucherRedeem = (props: Props) => {
  const classes = resolveCss(css)

  return (
    <div {...classes('menuWrapper')}>
      <div {...classes('menu')}>
        <div {...classes('label')}>{props.t('voucher.confirmLabel')}</div>
        <GenericButton
          onClick={props.onConfirm}
          css={{container: css.menuButton}}
          style="bold"
        >
          {props.t('voucher.confirmButton')}
        </GenericButton>
        <GenericButton
          onClick={props.onCancel}
          css={{container: css.menuButton}}
          style="outline"
        >
          {props.t('voucher.cancelButton')}
        </GenericButton>
      </div>
    </div>
  )
}

export default compose(withTranslate())(ConfirmVoucherRedeem)
