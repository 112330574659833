import * as React from 'react'
import {compose} from 'redux'

import MediaQuery from 'react-responsive'
import css from './DesktopVerticalSplitLayout.css'
import {resolveCss} from '$common/utils'

type Props = {
  left: React.Node
  right: React.Node
  css?: any
}

const DesktopVerticalSplitLayout = (props: Props) => {
  const classes = resolveCss(css, props.css)

  return (
    <MediaQuery maxWidth={800}>
      {(isMobile) => {
        if (isMobile) {
          return props.right
        } else {
          return (
            <div {...classes('container', 'desktop')}>
              <div {...classes('left', 'desktop')}>{props.left}</div>
              <div {...classes('right', 'desktop')}>{props.right}</div>
            </div>
          )
        }
      }}
    </MediaQuery>
  )
}

export default compose((a) => a)(DesktopVerticalSplitLayout)
