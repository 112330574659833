import {take} from 'redux-saga/effects'
import * as Sentry from '@sentry/browser'

export default function* collectBreadCrumbs(): Generator<> {
  /*eslint-disable no-constant-condition*/
  while (true) {
    const action: {
      type: string
      payload: object
      meta?: object
    } = yield take('*')
    Sentry.addBreadcrumb({
      category: 'redux',
      message: action.type
    })
  }
}
