const CACHE_KEY = 'profilePhoto'

export function addToCache(src) {
  const stored = _fromLocalStorage()
  if (stored && stored.originalSrc === src) {
    return
  }
  const img = new Image()
  img.crossOrigin = 'anonymous'
  img.src = src
  img.onload = () => _toLocalStorage(img)
}

export function loadFromCache(originalSrc) {
  if (!originalSrc) return null

  const stored = _fromLocalStorage()
  if (!stored) {
    return originalSrc
  }

  if (originalSrc.split('?')[0] === stored.originalSrc.split('?')[0]) {
    return stored.src
  } else {
    // Found someone else's photo from local storage. This shouldn't happen but check also here just to be sure..
    clearCache()
    return originalSrc
  }
}

export function clearCache() {
  localStorage.removeItem(CACHE_KEY)
}

function _toLocalStorage(img) {
  const size = calculateImageDimension()
  const canvas = document.createElement('canvas')
  canvas.width = size
  canvas.height = size
  const context = canvas.getContext('2d')
  context.drawImage(img, 0, 0, size, size)
  const dataUrl = canvas.toDataURL()
  localStorage.setItem(
    CACHE_KEY,
    JSON.stringify({originalSrc: img.src, src: dataUrl})
  )
}

function _fromLocalStorage() {
  const raw = localStorage.getItem(CACHE_KEY)
  return raw ? JSON.parse(raw) : null
}

const calculateImageDimension = () => window.screen.width * 0.7
