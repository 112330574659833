import get from 'lodash/get'

/**
 * A simple helper for making API error messages. Also distinguishes JS errors from API errors for clearer error logging
 */
export default (
  error: Error | {status: number} | string,
  keyPrefix?: string
): Array<string> | string => {
  const errorStatus = get(error, 'status', '500')
  if (keyPrefix) {
    return [`${keyPrefix}.${errorStatus}`, `error.${errorStatus}`]
  } else {
    return `error.${errorStatus}`
  }
}
