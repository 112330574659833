import MediaQuery from 'react-responsive'
import * as React from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import TopMiddleBottomLayout from '$common/components/layouts/TopMiddleBottomLayout'
import align from '$common/grid/align.css'
import check from '$assets/icons/temporary/check.png'
import css from './AuthAfterSuccessPage.css'
import grid from '$common/grid/grid.css'
import {resolveCss, withRouter, withTranslate, withRunSaga} from '$common/utils'
import getSubscriptions from '$src/subscriptions/sagas/getSubscriptions'
import {isUserVerified} from '$src/profile/selectors'

class AuthAfterSuccessPage extends React.Component {
  async componentDidMount() {
    // Generate and fetch free digital card
    await this.props.runSaga(getSubscriptions)
  }

  render() {
    const classes = resolveCss(css, grid, align)
    let target
    // unverified users should not be allowed to access the add phone number page.
    if (!this.props.isUserVerified) {
      target = '/home'
    } else {
      target = window.cordova
        ? '/profile/verify/phone-number-before'
        : '/profile/verify/phone-number'
    }

    return (
      <TopMiddleBottomLayout
        adaptiveLayout={{
          stackMiddle: true
        }}
        css={{
          container: grid.container,
          bottom: css.bottom
        }}
        top={
          <MediaQuery minWidth={801}>
            <FrankLogoInSmallWhite />
          </MediaQuery>
        }
        middle={
          <div {...classes('columnLarge5', 'center')}>
            <img
              {...classes('check', 'column', 'center')}
              src={check}
              alt="nothing"
            />
            <H1 css={{container: align.largeCenterText}}>
              {this.props.t('profile.verification.verified.title')}
            </H1>
            <P
              style="small"
              css={{container: [css.message, align.largeCenterText]}}
            >
              {this.props.t('profile.verification.verified.body')}
            </P>
          </div>
        }
        bottom={
          <Link to={target} {...classes('column', 'center')}>
            <GenericButton
              style="bold"
              css={{container: css.button}}
              isExpanding={true}
            >
              {this.props.t('profile.verification.verified.cta')}
            </GenericButton>
          </Link>
        }
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isUserVerified: isUserVerified(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withRouter,
  withRunSaga(),
  withTranslate()
)(AuthAfterSuccessPage)
