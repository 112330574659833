import * as React from 'react'
import css from './SearchTermList.css'
import closeIcon from '$assets/icons/icon-close-solid-black.svg'
import SvgIcon from '$common/components/icons/SvgIcon'

const SearchTermList = (props) => {
  const {t} = props
  const clearStorage = () => {
    localStorage.removeItem('search_terms')
    props.setSearchTerms([])
  }

  const handleSearchTermClick = (term) => {
    props.setShowSearchTermList(false)
    props.initiateSearch(term)
    props.onSearchTermClick(term)
  }

  return (
    <div className={css.container}>
      <h3 className={css.title}>{t('offers.search.terms.title')}</h3>

      {!!props.searchTerms.length && (
        <ul>
          {props.searchTerms
            .sort((a, b) => b.amount - a.amount)
            .slice(0, 20)
            .map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={() => handleSearchTermClick(item.term)}
                >
                  {item.term}
                </li>
              )
            })}
          <div onClick={clearStorage} className={css.clear}>
            {t('offers.search.terms.clear')}
          </div>
        </ul>
      )}
      {!props.searchTerms.length && (
        <div className={css.noTerm}>{t('offers.search.terms.noTerms')}</div>
      )}
      <SvgIcon
        className={css.close}
        src={closeIcon}
        onClick={() => props.setShowSearchTermList(false)}
      />
    </div>
  )
}

export default SearchTermList
