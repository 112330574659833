const setBodyBackgroundColor = (backgroundColor) => {
  if (!backgroundColor) {
    if (window.StatusBar) window.StatusBar.styleBlackOpaque()
    document.body.style.removeProperty('background-color')
    return null
  }
  document.body.style.backgroundColor = backgroundColor
  if (backgroundColor === 'white' && window.StatusBar) {
    window.StatusBar.styleDefault()
  }
}

export default setBodyBackgroundColor
