import React from 'react'

import css from './SearchIcon.css'
import {resolveCss} from '$common/utils'

/**
 * This is only a temporary implementation of the search icon.
 * TODO: get an actual SVG file for a search icon,
 * use it via the SvgIcon-somponent and delete this one.
 */
const SearchIcon = ({isSearchVisible, css: propsCss}: {css?: object}) => {
  const classes = resolveCss(css, propsCss)
  return isSearchVisible ? (
    <div {...classes('container', 'active')} />
  ) : (
    <div {...classes('container')} />
  )
}

export default SearchIcon
