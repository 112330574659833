import MediaQuery from 'react-responsive'
import * as React from 'react'
import {compose} from 'redux'
import {Link} from 'react-router-dom'

import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import TopMiddleBottomLayout from '$common/components/layouts/TopMiddleBottomLayout'
import align from '$common/grid/align.css'
import css from './StartTrialPage.css'
import grid from '$common/grid/grid.css'
import {resolveCss, withTranslate} from '$common/utils'
import {connect} from 'react-redux'
import {getProfile} from '$src/profile/selectors'


class TrialStatusPage extends React.Component {
  openActivateMembershipPage = () => this.props.history.push('/profile/trial/activatemembership')

  render() {
    const classes = resolveCss(css, grid, align)

    return (
      <TopMiddleBottomLayout
        adaptiveLayout={{
          stackMiddle: true
        }}
        css={{
          container: grid.container,
          bottom: css.bottom
        }}
        top={
          <MediaQuery minWidth={801}>
            <FrankLogoInSmallWhite />
          </MediaQuery>
        }
        middle={
          <div {...classes('columnLarge5', 'center')}>
            <H1 css={{container: align.largeCenterText}}>
              {this.props.t('profile.trial.status.title')}
            </H1>
            <P
              style="small"
              css={{container: [css.message, align.largeCenterText]}}
            >
              {this.props.t('profile.trial.status.body')}
            </P>

            {!this.props.profile.trial.membershipActivated && (
              <P
                style="small"
                css={{container: [css.message, align.largeCenterText]}}
              >
                {this.props.t('profile.trial.status.daysleft')} {this.props.profile.trial.daysleft} {this.props.t('profile.trial.status.days')}.
              </P>
            )}


            {this.props.profile.trial.membershipActivated && (
              <P
              style="small"
              css={{container: [css.message, align.largeCenterText]}}
              >
                {this.props.t('profile.trial.status.membershipActivationInProgress')}
              </P>
            )}

            {!this.props.profile.trial.membershipActivated && (
              <GenericButton
                onClick={this.openActivateMembershipPage}
                style="outline"
                size="small"
              >
                {this.props.t('profile.trial.openActivateMembershipPage')}
              </GenericButton>
            )}


          </div>
        }
        bottom={
          <Link to="/profile/settings" {...classes('column', 'center')}>
            <GenericButton
              style="bold"
              css={{container: css.button}}
              isExpanding={true}
            >
              {this.props.t('profile.trial.status.button')}
            </GenericButton>
          </Link>
        }
      />
    )
  }
}

export default compose(
  connect((state) => ({
    profile: getProfile(state)  
  })),
  withTranslate())(TrialStatusPage)
