import {_multiReduceState} from './multiReduceState'
import {call} from 'redux-saga/effects'

import {StoreState} from '$src/types'

type Path = Array<string | number>

function* _reduceState(
  path: Path,
  reducer: (a: any) => any,
  providedState?: StoreState
) {
  return yield call(_multiReduceState, [{path, reducer}], providedState)
}

export default function reduceState(
  path: Path,
  reducer: (a: any) => any,
  providedState?: StoreState
) {
  return call(_reduceState, path, reducer, providedState)
}
