import * as React from 'react'
import {compose} from 'redux'
import css from './H1.css'
import {resolveCss} from '$common/utils'

type Props = {
  children?: React.Node
  style?: 'normal' | 'white' | 'black'
  css?: any
}

const H1 = (props: Props) => {
  const classes = resolveCss(css, props.css)

  return (
    <h1 {...classes('container', props.style || 'normal')}>{props.children}</h1>
  )
}

export default compose((a) => a)(H1)
