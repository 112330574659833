import * as React from 'react'
import {compose} from 'redux'
import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect'
import {authenticatedWrapper} from '$src/auth/components/userAuthenticated'
import {isUserVerified} from '$src/profile/selectors'

export const verifiedWrapper = connectedRouterRedirect({
  redirectPath: '/verify',
  allowRedirectBack: false,
  authenticatedSelector: isUserVerified,
  wrapperDisplayName: 'UserVerified'
})

export default (Component: React.ComponentType<any>) =>
  compose(authenticatedWrapper, verifiedWrapper)(Component)
