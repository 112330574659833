export const SAVE_Y_SCROLL = 'SAVE_Y_SCROLL'

interface SaveYScrollAction {
  type: typeof SAVE_Y_SCROLL
  payload: number
}

export const saveYScroll = (yScroll: number): SaveYScrollAction => {
  return {
    type: SAVE_Y_SCROLL,
    payload: yScroll
  }
}
