import {uniqueId} from 'lodash'
import {call, put as putAction} from 'redux-saga/effects'
import {createApiErrorMessage} from '$common/utils'

interface ActionObject {
  type: string
  payload: {
    status?: string
    omitKey?: string
    entityType?: string
    errMsg?: string | string[]
  }
}

export default function* requestAndSetFlags(
  asyncEffect: object,
  setFetchingFlags?: boolean,
  entityType = 'undefined'
) {
  function setFlag(...args: [ActionObject]) {
    return call(function*() {
      if (setFetchingFlags) {
        return yield putAction(...args)
      }
    })
  }

  const uId = uniqueId()

  try {
    yield setFlag({type: 'REQUEST/START', payload: {[uId]: {entityType}}})
    const result: {} = yield asyncEffect
    yield setFlag({type: 'REQUEST/SUCCESS', payload: {omitKey: uId}})
    return result
  } catch (e) {
    yield setFlag({
      type: 'REQUEST/ERROR',
      payload: {
        status: e.status || '500',
        omitKey: uId,
        errMsg: createApiErrorMessage(e, `error.${entityType}`),
        entityType
      }
    })
    throw e
  }
}
