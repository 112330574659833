import React, {Component} from 'react'
import {compose} from 'redux'
import isEmpty from 'lodash/isEmpty'
import MediaQuery from 'react-responsive'
import {Link} from 'react-router-dom'
import {SubmissionError, FormTypes} from 'redux-form'
import Auth from '@aws-amplify/auth'

import OnboardingHeader from '$common/components/OnboardingHeader'
import ErrorCandy from '$common/components/candyBars/ErrorCandy'
import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import Layout from '$common/components/layouts/TopMiddleBottomLayout'
import TextInput from '$common/components/forms/TextInput'
import LoadingSpinnerModal from '$common/components/LoadingSpinnerModal'
import align from '$common/grid/align.css'
import css from './index.css'
import grid from '$common/grid/grid.css'
import {
  asForm,
  resolveCss,
  withRouter,
  WithRouterProps,
  withRunSaga,
  WithRunSagaProps,
  withTranslate,
  WithTranslateProps
} from '$common/utils'
import {validateEmail} from '$common/validators'

type Props = WithRouterProps & WithRunSagaProps & WithTranslateProps & FormTypes

const validate = ({email}) => {
  return {
    email: validateEmail(email)
  }
}

class ForgotPasswordPage extends Component<Props> {
  onSubmit = async ({email}) => {
    try {
      await Auth.forgotPassword(email.toLowerCase())
      this.props.history.push('/reset-password')
    } catch (error) {
      const errorMessage =
        error.code === 'UserNotFoundException'
          ? this.props.t('error.resetPassword.enterEmail.400', {email})
          : this.props.t('error.500')
      throw new SubmissionError({
        _error: errorMessage,
        general: true
      })
    }
  }

  render() {
    if (this.props.submitting) {
      return <LoadingSpinnerModal />
    }

    const classes = resolveCss(css, grid, align)

    const ctaButton = (
      <GenericButton
        surroundMargin
        type="submit"
        style="bold"
        loading={this.props.submitting}
        disabled={this.props.submitting || this.props.invalid}
      >
        {this.props.t('resetPassword.enterEmail.sendResetCode.cta')}
      </GenericButton>
    )

    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Layout
          topStyle="autoHeight"
          adaptiveLayout={{stackMiddle: true}}
          top={<OnboardingHeader pathBack="/login" />}
          middle={
            <div {...classes('columnLarge6', 'center')}>
              <MediaQuery maxWidth={800}>
                <FrankLogoInSmallWhite />
              </MediaQuery>
              <H1 css={{container: css.h1}}>
                {this.props.t('resetPassword.title')}
              </H1>
              <P css={{container: css.paragraph}}>
                {this.props.t('resetPassword.enterEmail.content')}
              </P>
              <TextInput
                type="email"
                name="email"
                placeholder={this.props.t('common.emailPlaceholder')}
                label={this.props.t('common.emailAddress')}
              />
              <ErrorCandy
                show={
                  !isEmpty(this.props.submitErrors) &&
                  !isEmpty(this.props.error)
                }
                onHide={this.props.clearSubmitErrors}
              >
                {this.props.error}
              </ErrorCandy>
            </div>
          }
          bottom={
            <div {...classes('columnLarge6', 'center', 'right')}>
              <MediaQuery maxWidth={800}>
                {(isMobile) => {
                  if (isMobile) {
                    return ctaButton
                  } else {
                    return (
                      <div className={grid.row}>
                        {ctaButton}
                        <Link to="/login">
                          <GenericButton
                            surroundMargin
                            type="button"
                            style="outline"
                          >
                            {this.props.t('profile.profilePhotoPage.cancel')}
                          </GenericButton>
                        </Link>
                      </div>
                    )
                  }
                }}
              </MediaQuery>
            </div>
          }
        />
      </form>
    )
  }
}

export default compose(
  asForm('resetPassword', {
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
  }),
  withRouter,
  withRunSaga(),
  withTranslate()
)(ForgotPasswordPage)
