import * as React from 'react'
import {compose} from 'redux'
import isEmpty from 'lodash/isEmpty'
import MediaQuery from 'react-responsive'
import {SubmissionError, FormTypes} from 'redux-form'
import {Link} from 'react-router-dom'
import Auth from '@aws-amplify/auth'

import OnboardingHeader from '$common/components/OnboardingHeader'
import ErrorCandy from '$common/components/candyBars/ErrorCandy'
import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import Layout from '$common/components/layouts/TopMiddleBottomLayout'
import TextInput from '$common/components/forms/TextInput'
import PasswordInput from '$common/components/forms/PasswordInput'
import LoadingSpinnerModal from '$common/components/LoadingSpinnerModal'
import align from '$common/grid/align.css'
import css from './index.css'
import grid from '$common/grid/grid.css'
import {
  createApiErrorMessage,
  asForm,
  resolveCss,
  withRouter,
  WithRouterProps,
  withRunSaga,
  WithRunSagaProps,
  withTranslate,
  WithTranslateProps
} from '$common/utils'
import {
  validatePassword,
  validatePasswordAgain,
  validateEmail,
  validateCognitoVerifyCode
} from '$common/validators'

type Props = WithRouterProps & WithRunSagaProps & WithTranslateProps & FormTypes

const validate = ({email, verificationCode, newPassword, passwordAgain}) => {
  return {
    email: validateEmail(email),
    verificationCode: validateCognitoVerifyCode(verificationCode),
    newPassword: validatePassword(newPassword),
    passwordAgain: validatePasswordAgain(passwordAgain, newPassword)
  }
}

class ResetPasswordPage extends React.Component<Props> {
  submit = async ({email, verificationCode, newPassword}) => {
    try {
      await Auth.forgotPasswordSubmit(
        email.toLowerCase(),
        verificationCode,
        newPassword
      )
      this.props.history.push('/login', {passwordReset: true})
    } catch (e) {
      const errorMessage =
        e.code === 'CodeMismatchException'
          ? 'error.resetPassword.wrongCode'
          : createApiErrorMessage(e)
      throw new SubmissionError({
        _error: this.props.t(errorMessage),
        general: true
      })
    }
  }

  componentDidMount() {
    if (!this.props.fieldValues.email) {
      this.props.history.goBack()
    }
  }

  render() {
    if (this.props.submitting) {
      return <LoadingSpinnerModal />
    }
    const classes = resolveCss(css, grid, align)

    const ctaButton = (
      <GenericButton
        surroundMargin
        style="bold"
        type="submit"
        loading={this.props.submitting}
        disabled={this.props.submitting || this.props.invalid}
      >
        {this.props.t('resetPassword.enterPassword.savePassword.cta')}
      </GenericButton>
    )

    return (
      <form onSubmit={this.props.handleSubmit(this.submit)}>
        <Layout
          topStyle="autoHeight"
          adaptiveLayout={{stackMiddle: true}}
          top={<OnboardingHeader pathBack="/login" />}
          middle={
            <div {...classes('columnLarge6', 'center')}>
              <MediaQuery maxWidth={800}>
                <FrankLogoInSmallWhite />
              </MediaQuery>
              <H1 css={{container: css.h1}}>
                {this.props.t('resetPassword.title')}
              </H1>
              <P css={{container: css.paragraph}}>
                {this.props.t('resetPassword.enterPassword.content')}
              </P>
              <TextInput
                type="number"
                name="verificationCode"
                label={this.props.t(
                  'resetPassword.enterPassword.resetCode.label'
                )}
              />
              <PasswordInput
                name="newPassword"
                autocomplete="new-password"
                label={this.props.t(
                  'resetPassword.enterPassword.newPassword.label'
                )}
              />
              <PasswordInput
                name="passwordAgain"
                autocomplete="new-password"
                label={this.props.t(
                  'resetPassword.enterPassword.passwordAgain.label'
                )}
              />

              <ErrorCandy
                show={
                  !isEmpty(this.props.submitErrors) &&
                  !isEmpty(this.props.error)
                }
                onHide={this.props.clearSubmitErrors}
              >
                {this.props.error}
              </ErrorCandy>
            </div>
          }
          bottom={
            <div {...classes('columnLarge6', 'center', 'right')}>
              <MediaQuery maxWidth={800}>
                {(isMobile) => {
                  if (isMobile) {
                    return ctaButton
                  } else {
                    return (
                      <div className={grid.row}>
                        {ctaButton}
                        <Link to="/login">
                          <GenericButton
                            surroundMargin
                            type="button"
                            style="outline"
                          >
                            {this.props.t('profile.profilePhotoPage.cancel')}
                          </GenericButton>
                        </Link>
                      </div>
                    )
                  }
                }}
              </MediaQuery>
            </div>
          }
        />
      </form>
    )
  }
}

export default compose(
  asForm('resetPassword', {validate}),
  withRouter,
  withRunSaga(),
  withTranslate()
)(ResetPasswordPage)
