import * as React from 'react'
import {Link} from 'react-router-dom'
import amplitude from '$src/analytics/amplitude'

import GenericButton from '$common/components/buttons/GenericButton'
import SvgIcon from '$common/components/icons/SvgIcon'
import align from '$common/grid/align.css'
import checkIcon from '$assets/icons/checkSingleWhite.svg'
import css from './FeatureContent.css'
import grid from '$common/grid/grid.css'
import {resolveCss, WithTranslateProps} from '$common/utils'
import {Feature} from '$src/ordercard/types'

type Props = WithTranslateProps & {
  content: Feature
  handleFeatureCTA: (feature: Feature) => () => {}
  pricing: any
}

const FeatureContent = (props: Props) => {
  const classes = resolveCss(css, grid, align)
  const {content} = props

  return (
    <div {...classes('featuresContainer')}>
      <h1 {...classes('featureContentTitle')}>
        {props.t(content.title, props.pricing)}
      </h1>
      <p {...classes('featureContent')}>
        {props.t(content.body, props.pricing)}
      </p>
      <div {...classes('features')}>
        <div {...classes('features__details')}>
          <h1 {...classes('detailRow__header')}>
            {props.t(content.price, props.pricing)}
          </h1>

          {content.details.map((detail, index) => (
            <div {...classes('detailRow')} key={index}>
              <div {...classes('detailRow__icon')}>
                <SvgIcon
                  css={{container: css.featureSellingPointMarker}}
                  sizing="none"
                  src={checkIcon}
                />
              </div>
              <p {...classes('detailRow__text')}>
                {props.t(detail, props.pricing)}
              </p>
            </div>
          ))}
        </div>
        <div {...classes('featureCta', 'bottom')}>
          {content.external ? (
            <Link to={props.t(content.link)} target="_blank">
              <GenericButton
                css={{container: css.featureCtaButton}}
                style="subtle"
                isFullWidth={true}
              >
                {props.t(content.cta, props.pricing)}
              </GenericButton>
            </Link>
          ) : (
            <GenericButton
              onClick={props.handleFeatureCTA(content)}
              css={{container: css.featureCtaButton}}
              style="subtle"
              isFullWidth={true}
            >
              {props.t(content.cta, props.pricing)}
            </GenericButton>
          )}
        </div>
      </div>
    </div>
  )
}

export default FeatureContent
