import * as React from 'react'
import {compose} from 'redux'
import css from './ContinueButton.css'
import arrowRightIcon from '$assets/icons/arrowRight.svg'
import SvgIcon from '$common/components/icons/SvgIcon'
import {resolveCss} from '$common/utils'

type Props = {
  disabled?: boolean
  css?: any
  onClick?: Function
}

const ContinueButton = ({
  css: customCss,
  disabled,
  onClick,
  ...rest
}: Props) => {
  const classes = resolveCss(css, customCss)
  const onClickProp = disabled ? {} : {onClick}

  return (
    <div
      {...classes('container', disabled && 'disabled')}
      {...onClickProp}
      {...rest}
    >
      <SvgIcon src={arrowRightIcon} />
    </div>
  )
}

export default compose((a) => a)(ContinueButton)
