import {find} from 'lodash'
import {StoreState, Selector} from '$src/types'
import {AsyncErrorsNameSpaceState} from './types'

export const isFetching = (
  state: StoreState,
  searchTerm?: string,
  byInclusion?: true
): boolean => {
  return !searchTerm
    ? !!Object.keys(state.requests).length
    : !!find(state.requests, (request) =>
        !byInclusion
          ? request.entityType === searchTerm
          : new RegExp(searchTerm).test(request.entityType)
      )
}

export const selectAsyncErrors: Selector<AsyncErrorsNameSpaceState, string> = (
  state,
  entityType
) => {
  return entityType
    ? state.asyncErrors.filter((error) => error.entityType === entityType)
    : state.asyncErrors
}

export const getAsyncError: Selector<{
  message: string | Array<string>
  entityType: string
}> = (state, entityType?: string) => {
  return selectAsyncErrors(state, entityType)[0]
}

// seems stale
/*
export const hasAsyncErrors: Selector<boolean> = (
  state,
  entityType?: string
) => {
  return !!selectAsyncErrors(state, entityType).length
}
*/
