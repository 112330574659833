import * as React from 'react'
import {compose} from 'redux'

import SvgIcon from '$common/components/icons/SvgIcon'
import arrowLeftIcon from '$assets/icons/arrowLeft.svg'
import arrowBackSolidSrc from '$assets/icons/icon-arrow-back-solid.svg'
import css from './BackButton.css'
import {resolveCss, withRouter, WithRouterProps} from '$common/utils'

type Props = WithRouterProps & {
  css?: any
  onClick?: 'goBack' | Function
  style?: 'bold'
}

// @todo Let's see if we can actually ensure that we go back
// to a specific route, rather than just simulating the browser's back button
const BackButton = (props: Props) => {
  const classes = resolveCss(css, props.css)

  let back
  if (
    props.history.location.state &&
    props.history.location.state.from === '/profile/settings/photo'
  ) {
    back = () => props.history.push('/home')
  } else if (
    props.history.location.state &&
    props.history.location.state.from
  ) {
    back = () => props.history.push(props.history.location.state.from)
  } else {
    back = () => props.history.goBack()
  }

  const onClick =
    props.onClick === 'goBack'
      ? back
      : typeof props.onClick === 'function'
      ? props.onClick
      : null

  return (
    <SvgIcon
      {...classes('container', 'icon')}
      sizing="fill"
      src={props.style === 'bold' ? arrowBackSolidSrc : arrowLeftIcon}
      onClick={onClick}
    />
  )
}

export default compose(withRouter)(BackButton)
