import * as React from 'react'
import {compose} from 'redux'
import {Link} from 'react-router-dom'

import BackButton from '$common/components/buttons/BackButton'
import SvgIcon from '$common/components/icons/SvgIcon'
import closeIcon from '$assets/icons/close.svg'
import css from './ConversationLayout.css'
import {resolveCss, withRouter, connect} from '$common/utils'
import {StoreState} from '$src/types'
import {getProfile} from '$src/profile/selectors'

/*
[header]
[content]
[buttons]
*/

type Props = {
  content?: any
  buttons?: any
  dialogContainerStyle?: any
  hideBackButton?: boolean
  isSecondGradeStudent: boolean
}

const ConversationLayout = (props: Props) => {
  const classes = resolveCss(css)
  const {content, buttons, hideBackButton} = props

  return (
    <div {...classes('wrapper')}>
      <div className={css.container}>
        <div {...classes('dialogContainer', props.dialogContainerStyle)}>
          <header {...classes('item', 'header')}>
            {hideBackButton ? null : (
              <BackButton
                type="button"
                css={{container: [css.navigationIcon, css.back]}}
                onClick="goBack"
              />
            )}
            <div className={css.stretch} />
            {!props.isSecondGradeStudent && (
              <Link to="/ordercard">
                <SvgIcon
                  css={{container: [css.navigationIcon, css.close]}}
                  src={closeIcon}
                  sizing="none"
                />
              </Link>
            )}
          </header>

          <div {...classes('contentWrapper', window.cordova && 'cordova')}>
            <div {...classes('item', 'main', !buttons && 'stretch')}>
              {content}
            </div>
            {buttons && (
              <div {...classes('item', 'buttonsContainer')}>{buttons}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default compose(
  connect((state: StoreState) => {
    const profile = getProfile(state)
    const studentType = profile.organization && profile.organization.type
    const isSecondGradeStudent =
      studentType === 'vocational-studentType' || studentType === 'high-school'
    return {isSecondGradeStudent}
  }),
  withRouter
)(ConversationLayout)
