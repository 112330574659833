import {put, select} from 'redux-saga/effects'
import _ from 'lodash'

import {callEndpoint} from '$src/api/sagas/callEndpoint'
import getPricingEndpoint from '$src/api/endpoints/pricing/get'
import {getPreferredLang} from '$src/i18n/selectors'

import {Pricing} from '$src/pricing/types'

import {RECEIVE_PRICES, FETCH_PRICES} from '$src/pricing/reducer'

export default function* getPricing(requestKey = 'getPricing') {
  yield put({type: FETCH_PRICES})
  const pricing: Pricing = yield callEndpoint(requestKey)(getPricingEndpoint)
  const language: string = yield select(getPreferredLang)

  function parsePrice(priceInCents) {
    const decimalDelimiter = language === 'en' ? '.' : ','
    return (priceInCents / 100).toFixed(2).replace(/\./, decimalDelimiter)
  }

  function parseProducts(product) {
    const priceInCents = parseInt(product.price)
    const delivery = _.find(
      pricing.cardProducts,
      (product) => product.sku === 'card_delivery_cost'
    )

    return {
      [`${product.sku}-price`]: parsePrice(priceInCents),
      [`${product.sku}-price-withoutDelivery`]: parsePrice(
        priceInCents - parseInt(delivery.price)
      ),
      [`${product.sku}-lifetime`]: product.lifetime
    }
  }

  const cardProducts = Object.assign(
    {},
    ..._.map(pricing.cardProducts, parseProducts)
  )
  const isicProducts = Object.assign(
    {},
    ..._.map(pricing.isicProducts, parseProducts)
  )

  yield put({type: RECEIVE_PRICES, payload: {...cardProducts, ...isicProducts}})
}
