import {StoreState} from '$src/types'
import {
  PaginatedEntity,
  PaginationSelector,
  PaginatedNamespaceState,
  Pagination
} from '$src/pagination/types'
import {Lang} from '$src/i18n/types'

const getPaginationObject: PaginationSelector<Pagination | undefined> = (
  state,
  entityKey,
  params
) => {
  const paginatedState: PaginatedNamespaceState = state[entityKey]
  return (
    params.languageCode &&
    paginatedState[params.languageCode].pagination[JSON.stringify(params)]
  )
}

export const getEntitiesForPage: PaginationSelector<
  PaginatedEntity[] | undefined
> = (state, entityKey, params) => {
  const entitiesOfPage = getEntityIDsForPage(state, entityKey, params)
  return (
    entitiesOfPage &&
    entitiesOfPage.map((id) =>
      getEntity(state, entityKey, {languageCode: params.languageCode, id})
    )
  )
}

export const getEntityIDsForPage: PaginationSelector<string[] | undefined> = (
  state,
  entityKey,
  params
) => {
  const paginatedState: PaginatedNamespaceState = state[entityKey]
  return (
    params.languageCode &&
    paginatedState[params.languageCode].queries[JSON.stringify(params)]
  )
}

export const getTotalPageCount: PaginationSelector<number | undefined> = (
  state: StoreState,
  entityKey,
  params
) => {
  const paginationByParams = getPaginationObject(state, entityKey, params)
  return paginationByParams && paginationByParams.totalPages
}

export const getTotalEntityCount: PaginationSelector<number | undefined> = (
  state: StoreState,
  entityKey,
  params
) => {
  const paginationByParams = getPaginationObject(state, entityKey, params)
  return paginationByParams && paginationByParams.total
}

export const getOddEntitiesCount: PaginationSelector<number | undefined> = (
  state: StoreState,
  entityKey,
  params
) => {
  const paginationByParams = getPaginationObject(state, entityKey, params)
  return (
    paginationByParams &&
    paginationByParams.total % ((params && params.size) || 1)
  )
}

export const getEntity: PaginationSelector<
  PaginatedEntity,
  {languageCode: Lang; id: string}
> = (state: StoreState, entityKey, params) => {
  const entitiesByKey: {[key: string]: PaginatedEntity} =
    state[entityKey][params.languageCode].byKey

  return entitiesByKey && entitiesByKey[params.id]
}
