import * as React from 'react'

import {Article} from '$src/articles/types'
import {resolveCss} from '$common/utils'
import P from '$common/components/P'
import css from '$src/offers/components/Carousel/Carousel.css'
import {withRouter, WithRouterProps} from '$common/utils'
import SlideLoading from '$common/components/loadings/CarouselSlideLoading'
import {
  ANALYTICS_EVENT as evt,
  ANALYTICS_PROVIDER as provider,
  logEvent2 as log
} from '$common/utils/logUtils'

type Props = {
  item: Article | {placeholder: true}
  mobile?: true
} & WithRouterProps

const CarouselCard = ({item, mobile, history}: Props): React.Node => {
  const classes = resolveCss(css)
  return item.placeholder ? (
    <SlideLoading
      css={{
        container: [
          css.articleBox,
          !mobile ? css.cell : [css.mobileMode, css.mobilePlaceholder]
        ]
      }}
    />
  ) : (
    <div
      {...classes('articleBox', mobile ? 'mobileMode' : 'cell')}
      style={{
        backgroundImage: `linear-gradient(rgba(18,24,33,0.37), rgba(18,24,33,0.37)), url(${String(
          item.largeImage
        )})`
      }}
      onClick={() => {
        log(evt.OFFER_CAROUSEL_CLICKED, [provider.AMPLITUDE], {
          offer: item.key
        })
        history.push(`/offer/${item.slug}`)
      }}
    >
      <div {...classes('slideTextContainer')}>
        <P bolder={true} css={{container: css.slideHeading}}>
          {item.advertiser}
        </P>

        <P bolder={true} css={{container: css.slideSubtitle}}>
          {item.shortDescription}
        </P>
      </div>
    </div>
  )
}

export default withRouter(CarouselCard)
