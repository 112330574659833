import {compose} from 'redux'
import {connect} from 'react-redux'
import queryString from 'query-string'
import {default as fetchOffers} from '$src/offers/sagas/fetchOffers'
import {isFetching} from '$src/fetching/selectors'
import {getPreferredLang} from '$src/i18n/selectors'
import OfferItem from '$src/offers/components/ListPage/OfferItem'
import TheList from '$src/offers/components/ListPage/OfferList'
import {StoreState} from '$src/types'
import {withRunSaga, withRouter} from '$common/utils'
import {Offer, QueryParams, StateProps, OwnProps} from '$src/offers/types'
import {sort} from '$src/offers/data'

import {
  getTotalOfferCount,
  getOfferIDsByParams,
  getOffer,
  getTotalOfferPageCount
} from '$src/offers/selectors'
import {saveYScroll} from '$src/ui/actions'

export default compose(
  withRunSaga(),
  withRouter,
  connect(
    (state: StoreState, ownProps: OwnProps): StateProps<Offer> => {
      const parsed = queryString.parse(ownProps.location.search)

      if (!parsed.sort) {
        parsed.sort = sort[0].id
      }

      const queryParams: QueryParams = {
        q: parsed.search,
        size: ownProps.itemsPerPage,
        sort: parsed.sort,
        category: parsed.category,
        city: parsed.city,
        languageCode: getPreferredLang(state),
        includeVouchers: ownProps.includeVouchers,
        includeJobs: ownProps.includeJobs
      }

      return {
        hasRequest: (requestKey) => isFetching(state, `offers-${requestKey}`),
        getEntity: (id) => getOffer(state, id),
        getEntityIds: (params) => getOfferIDsByParams(state, params),
        total: getTotalOfferCount(state, queryParams),
        isFetching: isFetching(state, 'offers', true),
        scrollY: state.ui.scrollY,
        totalOfferPageCount: getTotalOfferPageCount(state, queryParams),

        // these next props do not depend on state it seems, so should not be defined here but in the component itself
        queryParams,
        fetchPage: fetchOffers,
        ItemComponent: OfferItem // this one even has it's own connector
      }
    },
    (dispatch) => ({
      saveYScroll: (scrollY) => dispatch(saveYScroll(scrollY))
    })
  )
)(TheList)