import {getStudentType} from '../profile/selectors'
import {
  GRADUATE_STUDENT_TYPE,
  POSTGRADUATE_STUDENT_TYPE
} from '../profile/types'
import {
  DANSKEBANK_GRADUATE_ISIC_CARD,
  DANSKEBANK_GRADUATE_NATIONAL_CARD,
  DANSKEBANK_POSTGRADUATE_ISIC_CARD,
  DIGITAL_ISIC_CARD,
  GRADUATE_DANSKEBANK_ISIC_CARD,
  GRADUATE_ISIC_CARD,
  GRADUATE_STUDENT_CARD,
  POSTGRADUATE_DANSKEBANK_ISIC_CARD,
  POSTGRADUATE_ISIC_CARD,
  POSTGRADUATE_STUDENT_CARD
} from '../subscriptions/types'

export const PLASTIC_CARD_ORDER = 'plastic'
export const DIGI_ISIC_ORDER = 'digi-isic'
export const DANSKE_PLASTIC_CARD_ORDER = 'danske-plastic'
export const DANSKE_ISIC_LICENSE_ORDER = 'danske-isic'

export function getOrderProduct(state, cardType, isIsic) {
  const studentType = getStudentType(state)

  switch (cardType) {
    case PLASTIC_CARD_ORDER:
      return getPlasticProductSku(studentType, isIsic)
    case DIGI_ISIC_ORDER:
      return DIGITAL_ISIC_CARD
    case DANSKE_ISIC_LICENSE_ORDER:
      return getDanskeIsicProductSku(studentType)
    case DANSKE_PLASTIC_CARD_ORDER:
      return getDanskePlasticCardProductSku(studentType, isIsic)
  }
  throw new Error(`Failed to get sku for card order with card type ${cardType}`)
}

function getPlasticProductSku(studentType, isIsic) {
  if (isIsic && studentType === GRADUATE_STUDENT_TYPE) {
    return GRADUATE_ISIC_CARD
  } else if (isIsic && studentType === POSTGRADUATE_STUDENT_TYPE) {
    return POSTGRADUATE_ISIC_CARD
  } else if (!isIsic && studentType === GRADUATE_STUDENT_TYPE) {
    return GRADUATE_STUDENT_CARD
  } else if (!isIsic && studentType === POSTGRADUATE_STUDENT_TYPE) {
    return POSTGRADUATE_STUDENT_CARD
  } else {
    throw new Error(
      `Failed to get plastic card sku for student type ${studentType}`
    )
  }
}

function getDanskeIsicProductSku(studentType) {
  if (studentType === GRADUATE_STUDENT_TYPE) {
    return GRADUATE_DANSKEBANK_ISIC_CARD
  } else if (studentType === POSTGRADUATE_STUDENT_TYPE) {
    return POSTGRADUATE_DANSKEBANK_ISIC_CARD
  }
  throw new Error(
    `Failed to get Danske ISIC sku for student type ${studentType}`
  )
}

function getDanskePlasticCardProductSku(studentType, isIsic) {
  if (isIsic && studentType === GRADUATE_STUDENT_TYPE) {
    return DANSKEBANK_GRADUATE_ISIC_CARD
  }
  if (isIsic && studentType === POSTGRADUATE_STUDENT_TYPE) {
    return DANSKEBANK_POSTGRADUATE_ISIC_CARD
  }
  if (!isIsic && studentType === GRADUATE_STUDENT_TYPE) {
    return DANSKEBANK_GRADUATE_NATIONAL_CARD
  } else {
    throw new Error(
      `Failed to get Danske card sku for student type ${studentType}`
    )
  }
}
