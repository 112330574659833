import React, {Component} from 'react'
import QRCode from 'qrcode.react'
import resolveCss from '$src/common/utils/resolveCss'
import css from '$src/offline-studentcard/components/QrCodeView/QrCodeView.css'
import {STUDENT_CARD_COLOR} from '$src/offline-studentcard/components/StudentCardColor'

import moment from 'moment'
import {Profile} from '$src/profile/types'
import {WithTranslateProps} from '$common/utils/index'
import fetchProfile from '$src/profile/sagas/getProfile'

const NavBarSpacer = () => <div style={{height: 50}} />

const QrCodeContainer = ({dimension, borderColor, children}) => {
  const classes = resolveCss(css)
  return (
    <div
      {...classes('qrCodeContainer', `${borderColor}BorderQrContainer`)}
      style={{width: dimension, height: dimension, minHeight: dimension}}
    >
      <div {...classes('innerContainer')}>{children}</div>
    </div>
  )
}

type Props = WithTranslateProps & {
  profile: Profile
  onClose: Function
  studentCardBackgroundColor: 'white' | 'green' | 'blue'
}

type State = {
  qrCodeWidth: number
}

export default class QrCodeView extends Component<Props, State> {
  state = {qrCodeWidth: 0}
  renderPeriod = (profile) => {
    const PRESENCE_CODE_SPRING = 1
    const PRESENCE_CODE_FALL = 2
    const PRESENCE_CODE_YEAR = 3

    const studentStatus = profile.studentStatus
    if (!studentStatus) return

    const presentUntilYear = moment(studentStatus.presentUntil)
      .subtract(1, 'months') // Subtract buffer provided by backend
      .year()

    switch (studentStatus.presenceCode) {
      case PRESENCE_CODE_SPRING:
        return this.props.t('studentId.validUntil.springTerm', {
          fromYear: presentUntilYear
        })
      case PRESENCE_CODE_FALL:
        return this.props.t('studentId.validUntil.fallTerm', {
          fromYear: presentUntilYear
        })
      case PRESENCE_CODE_YEAR:
        return `${presentUntilYear - 1} - ${presentUntilYear}`
    }
  }

  async componentWillMount() {
    // ensure a fresh qr code
    this.props.runSaga(fetchProfile)
  }

  async componentDidMount () {
    this.setQrCodeWidth()
  }

  componentDidUpdate() {
    this.setQrCodeWidth()
  }

  setQrCodeWidth() {
    const periodContainer = document.getElementById('studentQrCodePeriod')
    const untilContainer = document.getElementById('studentQrCodeValidUntil')

    if (periodContainer && untilContainer) {
      const width =
        periodContainer.clientWidth > untilContainer.clientWidth
          ? periodContainer.clientWidth
          : untilContainer.clientWidth

      if (width != this.state.qrCodeWidth) this.setState({qrCodeWidth: width})
    }
  }

  render() {

    if (!this.props.hasOwnProperty("profile")) return null

    const {profile} = this.props

    const classes = resolveCss(css)
    const signature = profile.signature
    const validToDate = moment(
      profile.studentStatus &&
        profile.studentStatus.presentUntil
    )

    return (
      <div {...classes('qrCodeView')} onClick={this.props.onClose}>
        <QrCodeContainer
          dimension={this.state.qrCodeWidth}
          borderColor={
            this.props.studentCardBackgroundColor === STUDENT_CARD_COLOR.WHITE
              ? 'black'
              : 'white'
          }
        >
          <QRCode
            id="qrCode"
            bgColor="#FFFFFF"
            fgColor="#000000"
            size={this.state.qrCodeWidth - 43}
            level="L"
            value={signature}
          />
        </QrCodeContainer>
        <div>
          <div {...classes('periodContainer')}>
            <h1 id="studentQrCodePeriod" {...classes('period')}>
              {this.renderPeriod(profile)}
            </h1>
          </div>
          <div {...classes('validUntilContainer')}>
            <h2 id="studentQrCodeValidUntil" {...classes('validUntil')}>
              {this.props.t('studentId.validUntil.date', {
                validToDate: validToDate.format('DD.MM.YYYY')
              })}
            </h2>
          </div>
        </div>
        <NavBarSpacer />
      </div>
    )
  }
}
