import {compose} from 'redux'
import {connect, ConnectedProps} from 'react-redux'
import get from 'lodash/get'

import {StoreState} from '$src/types'
import {getProfile} from '$src/profile/selectors'
import {
  withRouter,
  withRunSaga,
  withTranslate,
  asForm,
  WithTranslateProps
} from '$src/common/utils'
import VerifyEmailPage from './VerifyEmailPage'
import {validateCognitoVerifyCode} from '$common/validators'

const validate = ({newEmail, verifyCode}) => {
  return {
    newEmail: !newEmail,
    verifyCode: validateCognitoVerifyCode(verifyCode)
  }
}

const mapStateToProps = (state: StoreState) => {
  return {
    profile: getProfile(state),
    fieldErrors: get(state, 'form.verifyEmail.syncErrors', {})
  }
}

const connector = connect(mapStateToProps)

export default compose(
  asForm('verifyEmail', {validate}),
  connector,
  withRunSaga(),
  withRouter,
  withTranslate()
)(VerifyEmailPage)

type ReduxProps = ConnectedProps<typeof connector>

export type ContainerProps = ReduxProps & WithTranslateProps
