import {camelCase, upperFirst, snakeCase} from 'lodash'

import {getAppboy} from '$common/pgWebSeparatorUtils'
import amplitude from '$src/analytics/amplitude'
import {isDeviceOnline} from '$common/utils'

const SHOW_LOGS = false

// Analytics providers
export const ANALYTICS_PROVIDER = {
  AMPLITUDE: 'amplitude',
  BRANCH: 'branch',
  BRAZE: 'braze',
  FACEBOOK: 'facebook',
  FIREBASE: 'firebase'
}

// INTERNAL_EVENT_NAME : 'external event name'
export const ANALYTICS_EVENT = {
  // Signup
  SIGNUP_INITIATED: 'signup initiated',
  SIGNUP_SUCCESS: 'signup success',

  // STUDENT STATUS VERIFICATION
  OPEN_VERIFICATION_START_PAGE: 'open verification start page',
  // JOLLA
  JOLLA_VERIFICATION_PAGE_OPEN: 'jolla verification page open',
  JOLLA_VERIFICATION_INITIATED: 'jolla verification initiated',
  JOLLA_VERIFICATION_SUCCESS: 'jolla verification succeeded',
  JOLLA_VERIFICATION_FAIL: 'jolla verification failed',

  // Opintopolku
  OP_VERIFICATION_PAGE_OPEN: 'opintopolku verification page open',
  OP_VERIFICATION_INITIATED: 'opintopolku verification initiated',
  OP_VERIFICATION_SUCCESS: 'opintopolku verification succeeded',
  OP_VERIFICATION_FAIL: 'opintopolku verification failed',

  // FHS
  FHS_VERIFICATION_PAGE_OPEN: 'FHS verification page open',
  FHS_VERIFICATION_INITIATED: 'FHS verification initiated',
  FHS_VERIFICATION_SUCCESS: 'FHS verification succeeded',
  FHS_VERIFICATION_FAIL: 'FHS verification failed',

  // Page opens
  OPEN_HOMEPAGE: 'open homepage',
  OPEN_OFFERPAGE: 'open offerpage',

  // TRIAL
  START_TRIAL_PAGE_OPEN: 'start trial page opened',
  START_TRIAL_INITIATED: 'start trial initiated',
  START_TRIAL_SUCCESS: 'start trial success',
  START_TRIAL_FAIL: 'start trial fail',

  // Offer sharing
  MOBILE_SHARE_BUTTON_CLICKED: 'Cordova share button clicked',
  WEB_SHARE_BUTTON_CLICKED: 'Web share button clicked',

  // OFFER PAGE
  OFFER_OPEN: 'open offer',
  OFFER_OPEN_UNVERIFIED: 'offer open unverified user',
  SEARCH_INITIATED: 'offer search initiated',
  OFFER_PAGE_SCROLLING_DEPTH: 'offer page scrolling depth',

  // OFFERS-CAROUSEL
  OFFER_CAROUSEL_CLICKED: 'offer carousel clicked',
  OFFER_CAROUSEL_SWIPE: 'offer carousel swipe',

  // IN-APP RATING
  RATING_REQUEST_TRIGGERED: 'rating requested',
  RATING_REQUEST_FAILED: 'rating request failed',
  RATING_DIALOG_SHOWN_IOS: 'rating dialog shown on ios',
  RATING_DIALOG_DISMISSED_IOS: 'rating dialog dismissed ios',

  // FAVOURITE OFFERS
  OPENED_FAVOURITES_LIST: 'opened favourites list',
  ADD_FAVOURITE: 'add favourite',
  REMOVE_FAVOURITE: 'remove favourite',
  OPENED_FAVOURITE_OFFER: 'opened favourite offer',

  // NOTIFICATION FEED
  NOTIFICATION_FEED_OPENED: 'notification feed opened',
  NOTIFICATION_FEED_ITEM_CLICKED: 'notification feed item clicked',

  // BUTTONS
  VIEW_OFFERS_BUTTON_HOMEPAGE: 'view offers button pressed on homepage',
  NAVBAR_BUTTON_PRESSED_HOMEPAGE: 'navbar homepage button pressed',
  NAVBAR_BUTTON_PRESSED_STUDENTCARD: 'navbar studentcard button pressed',
  NAVBAR_BUTTON_PRESSED_OFFERPAGE: 'navbar offerpage button pressed',
  NAVBAR_BUTTON_PRESSED_FAVOURITES: 'navbar favourites button pressed',
  NAVBAR_BUTTON_PRESSED_MENU: 'navbar menu button pressed',
  LUCKY_BUTTON_PRESSED: 'lucky button pressed',

  //ISIC EVENTS
  ISIC_PURCHASED: 'isic card purchased',
  ISIC_ACTIVATED: 'isic card activated',
  // ACCOUNT DELETION
  ACCOUNT_DELETION_TRIGGERED_FROM_APP: 'account deletion triggered from app',
  // JOB PAGE
  OPEN_JOB: 'open job',
  OPEN_JOB_UNVERIFIED: 'open job unverified'
}
/**
 * Log click events to both Amplitude and Braze/Appboy
 *
 * @param {Object} e The link click event
 * @param {string} eventName The lower-case, space-separated event name
 */
export function logLinkClick(e, eventName) {
  if (!e.target) {
    return
  }
  const href = e.target.href
  logEvent(eventName, {href}, true)
}

/*
 logEvent2 extends the functionaity of logEvent with the added benefit of being able 
 to send only to the providers listed in the recpients argument. This allows more 
 fine graind control over what events to send to which providers.

 Note: if no recipients are specified, all providers will receive the event
*/

export const logEvent2 = (
  eventName: string, // A space separated String
  recipients: Array<string> = [], // list of known providers
  metaData = {}, // optional metadata to add to the event (only available for some of the providers)
  immediateFlush = false // only used by Braze
) => {
  // Helper function to determine if a candidate recipient is a known provider

  if (!isDeviceOnline()) return

  const isKnownProvider = (candidate, knownProviders) => {
    for (const key in knownProviders) {
      if (candidate === knownProviders[key]) {
        return true
      }
    }
    return false
  }

  // Send event to providers in recipients arguments
  recipients.forEach((candidate) => {
    if (isKnownProvider(candidate, ANALYTICS_PROVIDER)) {
      switch (candidate) {
        case ANALYTICS_PROVIDER.AMPLITUDE:
          logAmplitudeEvent(eventName, metaData)
          break

        case ANALYTICS_PROVIDER.BRANCH:
          logBranchEvent(eventName, metaData)
          break

        case ANALYTICS_PROVIDER.BRAZE:
          logBrazeEvent(eventName, metaData, immediateFlush)
          break

        case ANALYTICS_PROVIDER.FACEBOOK:
          logFacebookEvent(eventName)
          break

        case ANALYTICS_PROVIDER.FIREBASE:
          logFirebaseEvent(eventName)
          break

        default:
          break
      }
    } else {
      console.warn(`WARNING: ${candidate} is unknown analytics provider`)
    }
  })

  // if no recipients are listed, all providers receive the event
  if (!recipients.length) {
    logEvent(eventName, metaData, immediateFlush)
  }
}

/**
 * Log events to both Amplitude and Braze/Appboy (deprecated, use logEvent2 instead)
 *
 * @param {string} eventName The lower-case, space-separated event name
 * @param {Object} [data] The data to be sent
 * @param {boolean} [immediateFlush] Immediately flush Appboy/Braze events, defaults to false
 */
export function logEvent(eventName, data = {}, immediateFlush = false) {
  logBrazeEvent(eventName, data, immediateFlush)
  logAmplitudeEvent(eventName, data)
  logFacebookEvent(eventName)
  logFirebaseEvent(eventName)
  logBranchEvent(eventName, data)
}

// PROVIDER HANDLERS

/* 
  AMPLITUDE

  amplitude event names are camelCased
*/
function logAmplitudeEvent(eventName, data) {
  const amplitudeEventName = camelCase(eventName)

  SHOW_LOGS && console.log(`Amplitude: ${amplitudeEventName}`, data)

  amplitude.logEvent(amplitudeEventName, data)
}

/* 
  BRANCH

  - different SDKs for web and mobile
*/
function logBranchEvent(eventName, data) {
  if (SHOW_LOGS) {
    console.log(`Branch: ${eventName}`, data)
  }

  if (window.cordova) {
    Branch.sendBranchEvent(eventName, data)
  } else {
    branch.logEvent(eventName, data, (err) => {
      if (err && SHOW_LOGS) {
        console.log(err)
      }
    })
  }
}

/*
  BRAZE

  - sdk is still called Appboy
*/
function logBrazeEvent(eventName, data, immediateFlush) {
  const appboyEventName = upperFirst(eventName)

  if (SHOW_LOGS) {
    console.log(
      `Braze: ${appboyEventName}, immediateFlsuh: ${immediateFlush}`,
      data
    )
  }

  const appboy = getAppboy()

  appboy.logCustomEvent(appboyEventName, data)

  if (immediateFlush) {
    appboy.requestImmediateDataFlush()
  }
}

/* 
  FACEBOOK

  Because facebook doesn't let us specify our own event names, we need to use and map our events to those 
  provided by fFB as specified bt the FB_EVENT list
*/

const FB_EVENT = {
  [ANALYTICS_EVENT.JOLLA_VERIFICATION_SUCCESS]: {
    fbEvent: 'Complete Registration',
    meta: 'jolla_verification_succeeded'
  },
  [ANALYTICS_EVENT.OPEN_HOMEPAGE]: {
    fbEvent: 'Search',
    meta: 'openHomepage'
  },
  [ANALYTICS_EVENT.SIGNUP_INITIATED]: {
    fbEvent: 'Initiate Checkout',
    meta: 'signup'
  },
  [ANALYTICS_EVENT.SIGNUP_SUCCESS]: {
    fbEvent: 'Complete Registration',
    meta: 'opintopolku_verification_succeeded'
  }
}

function logFacebookEvent(eventName) {
  if (SHOW_LOGS) {
    console.log(`Facebook: ${FB_EVENT[eventName].meta}`)
  }

  window.addEventListener('fbSdkReady', () => {
    FB.AppEvents.logEvent(FB_EVENT[eventName].fbEvent, null, {
      value: FB_EVENT[eventName].meta
    })
  })
}

/*
  FIREBASE

  - mobile firebase sdk likes_snake_cased_event_names
  - different SDKs for mobile and web
  */

export function logFirebaseEvent(_eventName: string) {
  const eventName = snakeCase(_eventName)

  if (SHOW_LOGS) {
    console.log(`Firebase: ${eventName}`)
  }

  if (window.cordova) {
    window.cordova.plugins.firebase.analytics.logEvent(eventName)
  } else {
    window.firebase.analytics().logEvent(eventName)
  }
}
