import * as React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'
import withWindowDims from './withWindowDims'

export default function withResponsiveInfo() {
  return function connectTiWindowDims(component) {
    const finalComponent = withWindowDims()((props) => {
      const {windowDims, ...rest} = props

      const Comp = component
      return (
        <Comp
          {...rest}
          windowDims={windowDims}
          isDesktop={windowDims.width >= 801}
          isMobile={windowDims.width <= 800}
        />
      )
    })

    finalComponent.displayName = `withResponsiveInfo(${component.displayName ||
      component.name ||
      'Component'})`

    hoistNonReactStatics(finalComponent, component)

    return finalComponent
  }
}
