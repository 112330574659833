import {reduceState} from '$src/common/utils'

export function* unsetAsyncErrors(entityType: string): Generator<> {
  yield reduceState(['asyncErrors'], (errors) =>
    errors.filter((error, index) => {
      return entityType
        ? error.entityType !== entityType
        : index !== errors.length - 1
    })
  )
}
