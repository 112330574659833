import {compose} from 'redux'
import {connect} from 'react-redux'
import {get} from 'lodash'

import OpintoPolkuForm from './OpintopolkuFormPage'
import {asForm, withRunSaga, withTranslate, withRouter} from '$common/utils'
import {getProfile} from '$src/profile/selectors'

const OPINTOPOLKU_URL_REGEPX = /^https:\/\/(opintopolku|studyinfo|studieinfo)\.fi\/koski\/opinnot\/[0-9a-z]{32}$/

const validate = (values) => {
  const errors = {}
  if (
    !values.opintopolkuUrl ||
    !values.opintopolkuUrl.match(OPINTOPOLKU_URL_REGEPX)
  ) {
    errors.opintopolkuUrl = 'profile.verification.opintopolku.invalidLinkFormat'
  }
  return errors
}

export default compose(
  withTranslate(),
  withRunSaga(),
  asForm('auth', {validate}),
  withRouter,
  connect((state) => ({
    profile: getProfile(state),
    isValidOpintopolkuUrl: isValidOpintopolkuUrl(state)
  }))
)(OpintoPolkuForm)

function isValidOpintopolkuUrl(state) {
  const authForm = state.form.auth
  if (!get(authForm, 'fields.opintopolkuUrl.visited')) return false
  const validationErrors = validate(authForm.values)
  return !validationErrors.opintopolkuUrl
}
