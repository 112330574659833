import {call, put} from 'redux-saga/effects'
import Auth from '@aws-amplify/auth'

import {getAppboy} from '$common/pgWebSeparatorUtils'
import getProfile from '$src/profile/sagas/getProfile'
import {setLoggedInToCognito} from '$src/auth/actions'

export default function* login({
  email,
  password
}: {
  email: string
  password: string
}) {
  yield call(() => Auth.signIn(email.toLowerCase(), password))
  yield put(setLoggedInToCognito(true))

  const profile = yield call(getProfile)

  if (profile.externalId) {
    const appboy = getAppboy()
    appboy.changeUser(profile.externalId)
    if (!window.cordova) {
      appboy.openSession()
    }
  }
}
