export const favouritesReducer = (favourites = {}, action) => {
  const {advertiser, smallImage, shortDescription, url, key, slug} = action
  switch (action.type) {
    case 'NEW_FAVOURITE':
      const newOffer = {
        advertiser: advertiser,
        smallImage: smallImage,
        shortDescription: shortDescription,
        url: url,
        key: key,
        slug: slug,
        favourite: action.favourite // adding a favourite object containing only the isActive parameter
      }
      return {...favourites, [action.key]: newOffer}
    case 'UPDATE_FAVOURITE':
      const updatedOffer = {
        advertiser: advertiser,
        smallImage: smallImage,
        shortDescription: shortDescription,
        url: url,
        key: key,
        slug: slug,
        favourite: action.favourite // replace the whole favourite object
      }
      return {...favourites, [key]: updatedOffer}
    case 'TOGGLE_FAVOURITE':
      const toggledOffer = {
        advertiser: advertiser,
        smallImage: smallImage,
        shortDescription: shortDescription,
        url: url,
        key: key,
        slug: slug,
        favourite: {...action.favourite, isActive: !action.favourite.isActive} // only update the isActive parameter
      }
      return {...favourites, [key]: toggledOffer}
    default:
      return favourites
  }
}
