import {connect} from 'react-redux'
import {compose} from 'redux'
import {isLoggedIn} from '$src/auth/selectors'
import {getProfile} from '$src/profile/selectors'
import {withTranslate, withRouter, withRunSaga} from '$common/utils/index'
import QrCodeView from './QrCodeView'
import {isUserActive} from '$src/profile/selectors'

const mapStateToProps = (state) => {
  return {
    isLoggedIn: isLoggedIn(state),
    profile: getProfile(state),
    isUserActive: isUserActive(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withRouter,
  withTranslate(),
  withRunSaga()
)(QrCodeView)
