import {combineReducers, Reducer} from 'redux'
import {handleActions} from 'redux-actions'

import {setLoggedInToCognito} from './actions'
import {AuthNamespaceState} from './types'

const loggedIn = handleActions(
  {
    [setLoggedInToCognito.type]: (_, {payload}) => payload
  },
  false
)

/**
 * @deprecated
 *
 * tokensState is preserved in redux state only to allow the transfer from
 * old auth system to new amplify-based auth system. When clients <= 3.4.4
 * are not anymore in use, this should be removed along the auth transfer code
 * in file configureCore.ts.
 */
const tokensState = (state = {hasTokens: false}) => state

const reducer: Reducer<AuthNamespaceState, any> = combineReducers({
  tokensState,
  loggedIn
})

export default reducer
