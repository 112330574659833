/*

  This component is sued to display a singular offer. 

*/

import React, {useEffect, useState} from 'react'
import {Route} from 'react-router-dom'
import {compose} from 'redux'
import _ from 'lodash'
import {connect} from '$common/utils'
import LocationsModal from '$src/offers/components/LocationsModal'
import LoadingSpinnerModal from '$common/components/LoadingSpinnerModal'
import HeroLayout from '$common/components/layouts/HeroLayout'
import fetchOffer from '$src/offers/sagas/fetchOffer'
import {getOffer, getFavourites} from '$src/offers/selectors'
import {Offer} from '$src/offers/types'
import {isUserVerified, isUserActive, isUserAlumn} from '$src/profile/selectors'
import {getPreferredLang} from '$src/i18n/selectors'
import {isFetching} from '$src/fetching/selectors'
import {StoreState} from '$src/types'
import {
  resolveCss,
  WithRouterProps,
  WithTranslateProps,
  withRouter,
  withRunSaga,
  withTranslate,
  isDeviceOnline
} from '$common/utils'
import {
  ANALYTICS_EVENT as evt,
  ANALYTICS_PROVIDER as provider,
  logEvent2 as log
} from '$common/utils/logUtils'
import HomePageContainer from '$src/home/components/HomePage/HomePage-container'
import {getJob} from './sagas/getJob'
import css from '$src/common/components/layouts/HeroLayout.css'
import grid from '$common/grid/grid.css'
import align from '$common/grid/align.css'
import TopMiddleBottomLayout from '$common/components/layouts/TopMiddleBottomLayout'
import SvgIcon from '$common/components/icons/SvgIcon'
import backIcon from '$assets/icons/arrowLeftPink.svg'
import shareIcon from '$assets/icons/share.svg'
import checkIcon from '$assets/icons/checkSinglePink.svg'
import starUnfilled from '$assets/icons/starUnfilledOfferPage.svg'
import starFilled from '$assets/icons/starFilledOfferPage.svg'
import Header from '$common/components/Header/Header-container'
import Footer from '$common/components/Footer'
import moment from 'moment'

type Props = WithRouterProps &
  WithTranslateProps & {
    css?: any
    isFetching: boolean
    runSaga: RunSagaFn
    offer?: Offer
    offerId: string
    isUserVerified: boolean
    isUserActive: boolean
    isUserAlumn: boolean
    isUserLoggedIn: boolean
    languageCode: 'en' | 'sv' | 'fi'
  }

const JobPage = ({
  history,
  isUserVerified,
  isUserActive,
  isUserAlumn,
  isFetching,
  favourites,

  //redux
  runSaga,
  ...props
}) => {
  const [jobData, setJobData] = useState(null)

  useEffect(() => {
    if (jobData) return // we only want to run this once

    const slug = _.get(props, ['match', 'params', 'id'])

    const getIdFromSlug = (s) =>
      s
        .split('-')
        .slice(s.split('-').length - 5)
        .join('-')

    async function fetchAndSetJobData() {
      // use this to debug the data coming from backend
      const response = await runSaga(getJob, getIdFromSlug(slug))
      console.log(response)
      setJobData(response)

      // final version, above for debugging
      // setJobData(await runSaga(getJob, getIdFromSlug(slug)))
    }

    fetchAndSetJobData()
  }, [])

  //return <div>{jobData.title}</div>

  // once offer data is available, send the custom events and do other fun things that is dependent on the data
  /*
  useEffect(() => {
    if (!offer) return

    setDocumentTitle(offer)

    if (isUserVerified) {
      log(evt.OPEN_JOB, [provider.AMPLITUDE, provider.BRAZE], getParams(offer))
    } else {
      log(evt.OPEN_JOB_UNVERIFIED, [provider.AMPLITUDE], getParams(offer))
    }
  }, [offer])
  */

  return isFetching ? (
    <LoadingSpinnerModal />
  ) : (
    <div>
      <JobLayout
        item={jobData}
        isUserVerified={isUserVerified}
        isUserActive={isUserActive}
        isUserAlumn={isUserAlumn}
        parentRoute="offers"
        css={css}
        {...props}
        history={history}
      />
    </div>
  )
}

export const jobPageView = compose(
  connect((state: StoreState, props: WithRouterProps) => {
    return {
      languageCode: getPreferredLang(state),
      isUserVerified: isUserVerified(state),
      isUserActive: isUserActive(state),
      isUserAlumn: isUserAlumn(state),
      asyncErrors: state.asyncErrors
    }
  }),
  withRunSaga(),
  withRouter,
  withTranslate()
)(JobPage)

const setDocumentTitle = (job) => {
  if (job) {
    document.title = `Frank | ${job.advertiser} | ${job.title}`
  }
}

/*

    Laoyout definitions
    oh, boy!
*/

const JobLayout = ({...props}) => {
  if (!props.item) {
    return null
  }

  const {css, history, location} = props

  const [copiedSuccessfully, setCopiedSuccessfully] = useState(false)
  const {
    item,
    t,
    isUserVerified,
    isFavourite,
    favourites,
    isUserActive,
    isUserAlumn,
    isLoggedIn
  } = props
  const locations = item.locations || []

  const classes = resolveCss(css, props.css, grid, align)

  // not sure if this is needed
  const formatDescription = (description) =>
    description
      .split('\n\n')
      .map((part) => `<p>${part}</p>`)
      .join('')

  const BackButton = ({css, history, location}) => {
    return (
      <a
        className={css.back}
        onClick={() => {
          location.popToGoBack === true
            ? window.history.back()
            : history.push(`/${props.parentRoute}`)
        }}
      >
        <SvgIcon src={backIcon} sizing="em" />
      </a>
    )
  }

  const jobTypeToString = (type) => {
    const mapping = {
      FullTime: 'Kokoaika',
      PartTime: 'Osa-aika',
      UnpaidInternship: 'Palkaton harjoittelu',
      PaidInternship: 'Palkallinen harjoittelu'
    }

    return mapping[type]
  }

  const MainLayout = ({css, item, ...props}) => {
    return [
      <div key="hero" className={css.hero}>
        <div {...classes('columnLarge7')}>
          <div {...classes('heroPlaceholder')}>
            <img
              {...classes('heroPlaceholderImage')}
              src={item.imageUrl}
              alt={item.title}
            />
          </div>
          <div className={css.iconContainer}>
            <BackButton {...{css, history, location}} />
          </div>
        </div>
      </div>,

      <div
        key="body"
        {...classes('row', 'center', 'layoutContainer', 'columnLarge7')}
      >
        <div {...classes('descriptionContainer')}>
          <h2 {...classes('title')}>
            {item.company} | {item.title}
          </h2>
          <h2 {...classes('shortDescription')}>{item.caption}</h2>

          <div {...classes('description')}>Paikkakunta: {item.city}</div>
          <div {...classes('description')}>
            Työn tyyppi: {jobTypeToString(item.jobType)}
          </div>
          <div {...classes('description')}>
            Viimeinen hakupäivä: {moment(item.dueDate).format('D.M.YYYY')}
          </div>
          <div
            {...classes('description')}
            dangerouslySetInnerHTML={{
              __html: formatDescription(item.description)
            }}
          />
          <div {...classes('description')}>
            <p>
              Lisätietoja: {item.contactEmail && item.contactEmail}
              {item.contactPhone && ', '}
              {item.contactPhone && item.contactPhone}
            </p>
          </div>

          <div {...classes('description')}>
            <p>
              Työpaikan osoite: <br />
              {item.address}
              <br />
              {item.postalCode}, {item.city}
            </p>
          </div>
        </div>
      </div>
    ]
  }

  return (
    <TopMiddleBottomLayout
      adaptiveLayout={true}
      noPadding={true}
      css={{
        middle: css.middle
      }}
      topStyle="sticky"
      top={<Header />}
      middle={<MainLayout {...props} />}
      bottom={<Footer />}
    />
  )
}

/*
Viimeinen hakupäivä: [duedate]
Työn tyyppi: [jobtype]
Lisätietoja: [email], [puhelin] (<- ja nämähän ei sitten ole pakollisia kenttiä syöttää)
Työpaikan osoite: [address]
*/
