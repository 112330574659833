export default function putProfileEndpoint(
  {put},
  payload: {
    email?: string
    language?: string
    carrier?: string
    graduationYear?: boolean
  }
) {
  return put(`/profile`, payload, {
    numberOfRetries: 4
  })
}
