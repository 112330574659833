import MediaQuery from 'react-responsive'
import * as React from 'react'
import {compose} from 'redux'

import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import TopMiddleBottomLayout from '$common/components/layouts/TopMiddleBottomLayout'
import align from '$common/grid/align.css'
import css from './StartTrialPage.css'
import grid from '$common/grid/grid.css'
import {resolveCss, withTranslate} from '$common/utils'

class StartTrialFailPage extends React.Component {
  backButtonHandler = () => this.props.history.replace('/profile/settings')

  render() {
    const classes = resolveCss(css, grid, align)
    const {errorCode} = this.props.match.params
    let title, body
    if (errorCode === '403') {
      title = this.props.t('profile.trial.fail.repeatOffenderTitle')
      body = this.props.t('profile.trial.fail.repeatOffenderBody')
    } else {
      title = this.props.t('profile.trial.fail.title')
      body = this.props.t('profile.trial.fail.body')
    }

    return (
      <TopMiddleBottomLayout
        adaptiveLayout={{
          stackMiddle: true
        }}
        css={{
          container: grid.container,
          bottom: css.bottom
        }}
        top={
          <MediaQuery minWidth={801}>
            <FrankLogoInSmallWhite />
          </MediaQuery>
        }
        middle={
          <div {...classes('columnLarge5', 'center')}>
            <H1 css={{container: align.largeCenterText}}>{title}</H1>
            <P
              style="small"
              css={{container: [css.message, align.largeCenterText]}}
            >
              {body}
            </P>
          </div>
        }
        bottom={
          <GenericButton
            style="bold"
            css={{container: css.button}}
            isExpanding={true}
            onClick={this.backButtonHandler}
          >
            {this.props.t('profile.trial.fail.button')}
          </GenericButton>
        }
      />
    )
  }
}

export default compose(withTranslate())(StartTrialFailPage)
