import * as React from 'react'
import {compose} from 'redux'
import css from './index.css'
import {resolveCss} from '$common/utils'

type Props = {
  children?: React.Node
  css?: any
  onClick?: Function
  style?: 'normal' | 'subtle' | 'question' | 'small' | 'xSmall'
  bold?: boolean
  bolder?: boolean
  color?: 'white' | 'black'
  align?: 'centerText'
}

const P = ({color = 'white', ...props}: Props) => {
  const classes = resolveCss(css, props.css)

  return (
    <p
      onClick={props.onClick}
      {...classes(
        'container',
        props.style || 'normal',
        props.bold && 'bold',
        props.bolder && 'bolder',
        color,
        props.align
      )}
    >
      {props.children}
    </p>
  )
}

export default compose((a) => a)(P)
