/*
  job/{id}
*/

import {Job} from '$src/offers/types'

export type Params = {
  id: string
}

export default function getJob({get}, {slug}: Params): Promise<{data?: Job}> {
  return get(`/job/${slug}/`, {numberOfRetries: 4})
}
