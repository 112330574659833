import {NewsNamespaceState, NewsActions} from '$src/news/types'

const defaultState: NewsNamespaceState = {
  fi: [],
  sv: [],
  en: []
}

const newsReducer = (
  state = defaultState,
  action: NewsActions
): NewsNamespaceState => {
  switch (action.type) {
    case 'SET_NEWS': {
      return {
        ...state,
        [action.language]: action.news
      }
    }
    default: {
      return state
    }
  }
}

export default newsReducer
