import {
  default as findOffer,
  Params as FindEndpointParams
} from '$src/api/endpoints/offers/find'
import {callEndpoint} from '$src/api/sagas/callEndpoint'
import {reduceState} from '$common/utils'
import {select} from 'redux-saga/effects'
import {StoreState} from '$src/types'
import {Offer} from '$src/offers/types'
import {getPreferredLang} from '$src/i18n/selectors'

export type Params = FindEndpointParams & {dontOverrideCache?: boolean}

export default function* fetchOffer(key: string): Generator<> {
  const state: StoreState = yield select()
  const languageCode = getPreferredLang(state)
  const response: Offer = yield callEndpoint('singleOffer')(findOffer, {
    languageCode,
    key
  })

  yield reduceState(['offers', languageCode, 'byKey'], (state) => {
    return {...state, [response.key]: response, [response.slug]: response}
  })
}
