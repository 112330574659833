import {callEndpoint} from '$src/api/sagas/callEndpoint'
import getProfileEndpoint from '$src/api/endpoints/profile/get'
import {setUserProfile} from '$common/utils'
import {Profile, ProfileVerificationState} from '$src/profile/types'

export default function* syncProfile(requestKey? = 'syncProfile'): Generator<> {
  const r: Profile & {state: ProfileVerificationState} = yield callEndpoint(
    requestKey
  )(getProfileEndpoint, {forceSync: true})
  yield setUserProfile(r)
}
