import * as React from 'react'
import {compose} from 'redux'
import {StoreState} from '$src/types'
import LoadingItem from './LoadingItem'
import {onlyUpdateForKeysDeep, connect} from '$common/utils'

export type SelectorFn = (storeState: StoreState) => Type

type Props = {
  css?: any
  style: object
  selector: SelectorFn<T>
  data?: T
  render: (props: {data: T; style: object}) => React.Element<any>
  mode: 'row' | 'cell'
}

const LoadingOrRender = ({style, data, render, mode}: Props<T>) => {
  return data ? (
    render({data, style})
  ) : (
    <LoadingItem style={style} mode={mode} />
  )
}

export default compose(
  connect((storeState: StoreState, {selector}: any) => ({
    data: selector()
  })),
  onlyUpdateForKeysDeep(['data', 'style', 'css'])
)(LoadingOrRender)
