export const TOGGLE_CHANGING_PHOTO = 'TOGGLE_CHANGING_PHOTO'
export const toggleChangingPhoto = (isChanging: boolean) => {
  return {
    type: TOGGLE_CHANGING_PHOTO,
    payload: isChanging
  }
}

export const SET_TRIAL_PATH = 'SET_TRIAL_PATH'
export const setTrialPath = (isActive: boolean) => {
  return {
    type: SET_TRIAL_PATH,
    payload: isActive
  }
}

export const SET_HAS_OPENED_TRIAl_ENDED = 'SET_HAS_OPENED_TRIAl_ENDED'
export const setHasOpenedTrialEnded = (hasOpenedTrialEnded: boolean) => {
  return {
    type: SET_HAS_OPENED_TRIAl_ENDED,
    payload: hasOpenedTrialEnded
  }
}
