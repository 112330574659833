import putFavouriteEndpoint from '../../api2/endpoints/favourites/put'
import {callEndpoint} from '$src/api2/sagas/callEndpoint'
import {
  toggleFavourite,
  updateFavourite
} from '$src/offers/components/Favourites/actions'
import {put} from '@redux-saga/core/effects'
import {getParams} from '$src/offers/components/OfferPage'
import {
  ANALYTICS_EVENT as evt,
  ANALYTICS_PROVIDER as provider,
  logEvent2 as log
} from '$common/utils/logUtils'

export default function* putFavourite(offer, favourite) {
  try {
    // updating the favourite.isActive boolean optimistically to speed up the favourite button response time
    yield put(toggleFavourite(offer, favourite))
    // sending api call
    const res = yield callEndpoint('putFavourite')(
      putFavouriteEndpoint,
      favourite.uuid
    )
    // if the api call is successful, then we update the favourite object with response
    yield put(updateFavourite(offer, res))
    // send either add favourite or remove favourite event
    if (res.isActive) {
      log(evt.ADD_FAVOURITE, [provider.BRAZE], getParams(offer))
    } else {
      log(evt.REMOVE_FAVOURITE, [provider.BRAZE], getParams(offer))
    }
  } catch (e) {
    // if there wa en error then we reverse the optimistic UI change we did on row 11
    yield put(
      toggleFavourite(offer, {...favourite, isActive: !favourite.isActive})
    )
    throw new Error('PUT request to favourite endpoint failed')
  }
}
