import * as Sentry from '@sentry/browser'
import Auth from '@aws-amplify/auth'
import {select, call, race} from 'redux-saga/effects'

import {isLoggedInToCognito} from '$src/auth/selectors'
import logout from '$src/auth/sagas/logout'

type RequestOptions = {
  numberOfRetries: number
  retryTimeout: number // in ms
}

const defaultRequestOptions: RequestOptions = {
  numberOfRetries: 0,
  retryTimeout: 20000
}

/**
 * Sends a request to the server. This is not meant to be used directly. Use `callEndpoint()` instead.
 * The reason we don't call this directly is explained in Readme.md
 */
export default function* request(
  endpoint: string,
  originalOptions: RequestOptions
) {
  const authOptions = yield call(getAuthOptions)

  if (authOptions.networkError) {
    // Network error indictes that user tokens should be refreshed but it failed
    // due to network error.
    throw createApiError('Network error')
  } else if (authOptions.invalidSession) {
    // Invalid session indicates that for some reason user doesn't anymore have a valid
    // refresh token. Usually this is caused by doing global logout on another device / window.
    yield call(logout)
    throw createApiError('Invalid session', {status: 401} as Response)
  }

  const options = constructOptions(
    defaultRequestOptions,
    originalOptions,
    authOptions
  )

  if (options.numberOfRetries > 0) {
    const {response, timeout} = yield race({
      response: call(doFetch, options, endpoint),
      timeout: call(delay, options.retryTimeout, true)
    })

    if (timeout) {
      return yield call(request, endpoint, {
        ...options,
        numberOfRetries: options.numberOfRetries - 1
      })
    } else {
      return response
    }
  } else {
    return yield call(doFetch, options, endpoint)
  }
}

const constructOptions = (
  defaultRequestOptions,
  originalOptions,
  authOptions
) => {
  const options = {
    ...defaultRequestOptions,
    ...originalOptions
  }
  options.headers = {
    'Content-Type': 'application/json',
    ...options.headers
  }
  if (authOptions.loggedIn) {
    options.headers['Authorization'] = authOptions.idToken
  }
  return options
}

const doFetch = function*(options, endpoint) {
  const url = process.env.BACKEND_BASE_URL_2 + endpoint

  const fetchResponse: Response = yield call(fetch, url, options)
  const responseFn: any =
    fetchResponse.status === 204
      ? () => {} // eslint-disable-line @typescript-eslint/no-empty-function
      : [fetchResponse, fetchResponse.json]
  if (fetchResponse.ok) {
    return yield call(responseFn)
  } else {
    return yield call(rejectWithApiError, fetchResponse, url)
  }
}

function* getAuthOptions() {
  const options: {
    loggedIn: boolean
    idToken?: string
    networkError?: boolean
    invalidSession?: boolean
  } = {
    loggedIn: yield select(isLoggedInToCognito)
  }

  if (!options.loggedIn) {
    return options
  }

  try {
    // Auth.currentSession() gets fresh tokens from cognito if needed
    const session = yield call(() => Auth.currentSession())
    options.idToken = session.getIdToken().getJwtToken()
  } catch (err) {
    if (isNetworkError(err)) {
      options.networkError = true
    } else if (isInvalidAmplifySessionError(err)) {
      options.invalidSession = true
    } else {
      throw err
    }
  }

  return options
}

const isNetworkError = (error) => error instanceof TypeError
const isInvalidAmplifySessionError = (error) => error === 'No current user'

const delay = (dur, val) =>
  new Promise((resolve) => setTimeout(() => resolve(val), dur))

const rejectWithApiError = (fetchResponse: Response, url: string) => {
  const onSuccess = (text) => {
    let errorMessage = ''
    let responseContent: {
      message?: string
      errorCode?: number
    } = {}
    try {
      responseContent = JSON.parse(text)
      if (responseContent.message) {
        errorMessage = responseContent.message
      }
    } catch (e) {
      errorMessage = text
    }
    throw createApiError(errorMessage, fetchResponse, url, responseContent)
  }
  const onFailure = () => {
    throw createApiError('Unknown API error', fetchResponse, url)
  }
  return fetchResponse.text().then(onSuccess, onFailure)
}

class ApiError extends Error {
  public response?: Response
  public status?: Response['status']
  public errorCode?: number

  constructor(message, response, errorCode) {
    super(message)
    Object.setPrototypeOf(this, new.target.prototype)
    this.response = response
    this.status = response && response.status
    this.errorCode = errorCode
  }
}

const createApiError = (
  message: string,
  fetchResponse?: Response,
  url = '',
  responseContent: {errorCode?: number} = {}
) => {
  if (fetchResponse && fetchResponse.status >= 403) {
    Sentry.withScope((scope) => {
      scope.setExtra('apiUrl', url)
      scope.setExtra('response', fetchResponse)
      scope.setExtra('responseJson', responseContent)
      scope.setExtra('errorCode', responseContent.errorCode)
      Sentry.captureException(new Error(`API error: ${message}`))
    })
  }
  const apiError = new ApiError(
    message,
    fetchResponse,
    responseContent.errorCode
  )
  return apiError
}
