import {select} from 'redux-saga/effects'
import getUniqueCode, {Params} from '$src/api/endpoints/offers/getUniqueCode'
import {callEndpoint} from '$src/api/sagas/callEndpoint'
import {getPreferredLang} from '$src/i18n/selectors'
import {reduceState} from '$common/utils'

export default function* fetchUniqueCode({key, ...other}: Params): Generator<> {
  const languageCode: 'fi' | 'sv' | 'en' = yield select(getPreferredLang)
  const result = yield callEndpoint('offerUniqueCode')(getUniqueCode, {
    key,
    ...other
  })
  return yield reduceState(['offers', languageCode, 'byKey', key], () => result)
}
