import {compose} from 'redux'
import FinnishSSN from 'finnish-ssn'

import connect from '$src/common/utils/connect'
import asForm from '$src/common/utils/asForm'
import {withRouter, withRunSaga, withTranslate} from '$src/common/utils'
import JollaFormPage from '$src/profile/components/verification/JollaFormPage/JollaFormPage'
import {trimmedValues} from '$common/utils'
import {getSchools} from '$src/profile/selectors'

const mapStateToProps = (state) => {
  return {
    isKoskiEnabled: process.env.ENABLE_KOSKI,
    schools: getSchools(state)
  }
}

const validate = (values) => {
  const {ssn, jollaId, studentId, lastName} = trimmedValues(values)

  if (ssn) {
    const validSsn = FinnishSSN.validate(ssn.toUpperCase())
    return {
      ssn: !validSsn && 'profile.verification.jolla.invalidSsn'
    }
  }
  const validJollaId = Boolean(jollaId)
  const validStudentId = Boolean(studentId)
  const validLastName = Boolean(lastName)
  return {
    jollaId: !validJollaId && 'common.requiredField',
    studentId: !validStudentId && 'common.requiredField',
    lastName: !validLastName && 'common.requiredField'
  }
}

export default compose(
  // Use the same form on all verification flow pages.
  asForm('auth', {validate, destroyOnUnmount: false}),
  withRunSaga(),
  withRouter,
  withTranslate(),
  connect(mapStateToProps)
)(JollaFormPage)
