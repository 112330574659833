import * as React from 'react'
import {compose} from 'redux'

import BackButton from '$common/components/buttons/BackButton'
import css from './TopMiddleBottomLayout.css'
import {resolveCss, withRouter, WithRouterProps} from '$common/utils'

import {Portal} from 'react-portal'
import {navBarDisabledForRoute} from '$common/components/NavBar/NavBar'

/*
[topLeft | topCenter | topRight]
[middle]
[bottomLeft | bottomCenter | bottomRight]
*/
export type Props = WithRouterProps & {
  css?: any
  //   backButton?: 'none' | 'goBack' | Function, @todo
  containerStyle?: 'fitHeight'
  topStyle?: 'normal' | 'sticky' | 'autoHeight'
  noPadding?: boolean
  scroll?: boolean

  // if top is provided, then topLeft|topRight|topCenter will be ignored
  top?: any
  topLeft?: any
  topCenter?: any
  topRight?: any
  topRightStyle?: 'sideBySide'

  middle?: any

  bottom?: any
  bottomLeft?: any
  bottomCenterStyle?: 'normal' | 'flexRow'
  bottomCenter?: any
  bottomRight?: any

  containerProps?: object
  backButton?: 'goBack' | Function
  adaptiveLayout?: {
    stackMiddle?: boolean
    horizontallyCenterMiddle?: boolean
  } // @note is this correctly typed?
}

const TopMiddleBottomLayout = (props: Props) => {
  const classes = resolveCss(css, props.css)
  const backButton = props.backButton
  const topLeft =
    props.topLeft ||
    (backButton === 'goBack' ? (
      <BackButton onClick={() => props.history.goBack()} />
    ) : typeof backButton === 'function' ? (
      <BackButton
        onClick={() =>
          backButton({
            location: props.location,
            history: props.history,
            match: props.match
          })
        }
      />
    ) : (
      undefined
    ))
  const headerTop = (
    <div {...classes('top', props.topStyle, window.cordova ? 'cordova' : '')}>
      {props.top}
    </div>
  )
  const topContent = props.top ? (
    props.topStyle === 'sticky' ? (
      <Portal node={document && document.getElementById('#portal')}>
        {headerTop}
      </Portal>
    ) : (
      headerTop
    )
  ) : (
    <div {...classes('top', props.topStyle)}>
      <div {...classes('topLeft')}>{topLeft}</div>
      <div {...classes('topCenter')}>{props.topCenter}</div>
      <div {...classes('topRight', props.topRightStyle)}>{props.topRight}</div>
    </div>
  )

  let cordovaStyles = ''
  if (window.cordova) {
    cordovaStyles = navBarDisabledForRoute(props.location.pathname)
      ? 'cordova'
      : 'cordova navbar-push'
  }
  const middleContent = (
    <div
      {...classes(
        'middle',
        props.adaptiveLayout &&
          props.adaptiveLayout.horizontallyCenterMiddle &&
          'horizontallyCenterMiddle',
        cordovaStyles
      )}
    >
      {props.middle}
    </div>
  )

  const bottomContent = props.bottom ? (
    <div {...classes('bottom')}>{props.bottom}</div>
  ) : (
    <div {...classes('bottom')}>
      <div {...classes('bottomLeft')}>{props.bottomLeft}</div>
      <div {...classes('bottomCenter', props.bottomCenterStyle)}>
        {props.bottomCenter}
      </div>
      <div {...classes('bottomRight')}>{props.bottomRight}</div>
    </div>
  )

  return (
    <div {...classes(props.adaptiveLayout && 'wrapper')}>
      <div
        {...classes(
          'container',
          props.scroll && 'scroll',
          props.containerStyle,
          props.noPadding && 'noPadding',
          props.adaptiveLayout &&
            props.adaptiveLayout.stackMiddle &&
            'stackMiddle',
          props.adaptiveLayout &&
            props.adaptiveLayout.horizontallyCenterMiddle &&
            'horizontallyCenterMiddle'
        )}
        {...props.containerProps}
      >
        {props.top || props.topLeft || props.topCenter || props.topRight
          ? topContent
          : null}
        {middleContent}
        {bottomContent}
      </div>
    </div>
  )
}

export default compose(withRouter)(TopMiddleBottomLayout)
