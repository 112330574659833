import React, {useEffect} from 'react'
import {resolveCss, withTranslate} from '$common/utils'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {getProfile} from '$src/profile/selectors'

import {Link} from 'react-router-dom'
import align from '$common/grid/align.css'
import css from './EndingPage.css'
import grid from '$common/grid/grid.css'
import TopMiddleBottomLayout from '$common/components/layouts/TopMiddleBottomLayout'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import {setHasOpenedTrialEnded} from '../../../profile/actions'
import SvgIcon from '$common/components/icons/SvgIcon'
import closeIcon from '$assets/icons/close.svg'

const EndingPage = (props) => {
  const classes = resolveCss(css, grid, align)
  const {profile, t} = props

  useEffect(() => {
    props.setHasOpenedTrialEnded(true)
  }, [])

  let upperBody, lowerBody, link

  switch (profile.school?.type) {
    case 'high-school':
      upperBody = t('profile.trial.ending_page.upper_body_text_SLL')
      lowerBody = t('profile.trial.ending_page.lower_body_text_SLL')
      link = t('profile.trial.ending_page.upper_btn_link_SLL')
      break

    case 'vocational-school':
      upperBody = t('profile.trial.ending_page.upper_body_text_SAKKI')
      lowerBody = t('profile.trial.ending_page.lower_body_text_SAKKI')
      link = t('profile.trial.ending_page.upper_btn_link_SAKKI')
      break
    default:
      upperBody = t('profile.trial.ending_page.upper_body_text_SLL')
      lowerBody = t('profile.trial.ending_page.lower_body_text_SLL')
      link = t('profile.trial.ending_page.upper_btn_link_SLL')
      break
  }

  return (
    <TopMiddleBottomLayout
      adaptiveLayout={{
        stackMiddle: true
      }}
      css={{
        container: grid.container,
        bottom: css.bottom
      }}
      top={
        !window.cordova && (
          <SvgIcon
            onClick={() => props.history.replace('/home')}
            css={{container: css.uploadImageIcon}}
            src={closeIcon}
          />
        )
      }
      middle={
        <>
          <div
            {...classes(
              'columnLarge5',
              'marginTop2em',
              'marginBottom2em',
              'center'
            )}
          >
            <H1>{t('profile.trial.ending_page.upper_title')}</H1>
            <P style="small" css={{container: [css.message]}}>
              {upperBody}
            </P>
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              {...classes('column', 'center')}
            >
              <GenericButton
                style="bold"
                css={{container: css.button}}
                isExpanding={true}
                hasTopMargin={true}
              >
                {t('profile.trial.ending_page.upper_btn_text')}
              </GenericButton>
            </a>
          </div>
          <div {...classes('columnLarge5', 'marginTop1em', 'center')}>
            <H1>{t('profile.trial.ending_page.lower_title')}</H1>
            <P style="small" css={{container: [css.message]}}>
              {lowerBody}
            </P>
            <Link
              to="/profile/verify/jolla/form"
              {...classes('column', 'center')}
            >
              <GenericButton
                style="bold"
                css={{container: [css.button, align.centerText]}}
                isExpanding={true}
                hasTopMargin={true}
              >
                {t('profile.trial.ending_page.lower_btn_text')}
              </GenericButton>
            </Link>
          </div>
        </>
      }
    />
  )
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state)
  }
}

export default compose(
  connect(mapStateToProps, (dispatch) => ({
    setHasOpenedTrialEnded: (val) => dispatch(setHasOpenedTrialEnded(val))
  })),
  withTranslate()
)(EndingPage)
