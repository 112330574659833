
const deviceType = () => {
    const userAgent = navigator.userAgent;

    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
      const device = `Phone/${_getMobileOS()}`
      return device
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent)) {
      const device = `Phone/${_getMobileOS()}`
      return device
    }
    return "Desktop";
};

const _getMobileOS = () => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "unknown";
}

export default deviceType
