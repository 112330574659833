import {callEndpoint} from '$src/api2/sagas/callEndpoint'
import postFavouriteEndpoint from '$src/api2/endpoints/favourites/post'
import {
  newFavourite,
  updateFavourite
} from '$src/offers/components/Favourites/actions'
import {put} from '@redux-saga/core/effects'
import {getParams} from '$src/offers/components/OfferPage'
import {
  ANALYTICS_EVENT as evt,
  ANALYTICS_PROVIDER as provider,
  logEvent2 as log
} from '$common/utils/logUtils'

export default function* postFavourite(offer, payload) {
  try {
    // optimistically adding a favourite object with only an
    // favourite.isActive boolean to speed up the favourite button response time
    yield put(newFavourite(offer, {isActive: true}))
    // sending api call
    const res = yield callEndpoint('postFavourite')(
      postFavouriteEndpoint,
      payload
    )
    // if the api call is successful, then we update the favourite object with response
    yield put(updateFavourite(offer, res))
    // send add favourite event
    log(
      evt.ADD_FAVOURITE,
      [provider.AMPLITUDE, provider.BRAZE],
      getParams(offer)
    )
  } catch (e) {
    // if there wa en error then we reverse the optimistic UI change we did on row 11
    yield put(newFavourite(offer, {isActive: false}))
    throw new Error('POST request to favourite endpoint failed')
  }
}
