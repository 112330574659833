import * as React from 'react'
import {compose} from 'redux'
import css from './index.css'
import SvgIcon from '$common/components/icons/SvgIcon'
import frankLogoRedOutline from '$assets/icons/frankLogoRedOutline.svg'

// type Props = {}

const SplashScreen = () => {
  return (
    <div className={css.container}>
      <div className={css.logoContainer}>
        <SvgIcon src={frankLogoRedOutline} sizing="fill" />
      </div>
    </div>
  )
}

export default compose((a) => a)(SplashScreen)
