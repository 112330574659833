import {compose} from 'redux'
import {get} from 'lodash'
import {connect} from 'react-redux'

import {
  withResponsiveInfo,
  withRouter,
  withRunSaga,
  withTranslate
} from '$src/common/utils'
import {
  getProfile as profileSelector,
  isUserVerified
} from '$src/profile/selectors'
import {isFetching} from '$src/fetching/selectors'
import asForm from '$src/common/utils/asForm'
import ProfileSettingsPage from './ProfileSettingsPage'
import {getLatestActiveIsicSubscription} from '$src/subscriptions/selectors'

const mapStateToProps = (state, props) => {
  const profile: object = profileSelector(state)
  const subs: object = state.subscriptions

  const {studentStatus = {}, studentType, trial = {}} = profile
  const digiIsicValidityDate = get(
    getLatestActiveIsicSubscription(state),
    'validTo'
  )

  return {
    location: {pathname: props.location.pathname},
    isFetchingLanguage: isFetching(state, 'profileLang'),
    isFetchingProfile:
      isFetching(state, 'getProfile') || isFetching(state, 'syncProfile'),
    isFetchingSubscriptions: isFetching(state, 'subscriptions'),
    subscriptionsLoaded: subs.existing.length !== 0,
    isVerified: isUserVerified(state),
    isKoskiEnabled: process.env.ENABLE_KOSKI,
    shouldShowOpintopolkuLink: !!profile.opintopolkuUrl,
    initialValues: {
      ...profile,
      studentEligibilityValue: studentStatus.presentUntil
        ? props.t('profile.studentEligibility', {
            date: studentStatus.presentUntil
          })
        : '-',
      studentType: studentType
        ? props.t(`profile.studentType.${studentType}`)
        : '-',
      digiIsicValidityValue: digiIsicValidityDate
        ? props.t('profile.digiIsicValidity', {date: digiIsicValidityDate})
        : null,
      isicNumber: get(getLatestActiveIsicSubscription(state), 'isicNumber')
    },
    trial
  }
}

export default compose(
  withTranslate(),
  connect(mapStateToProps),
  asForm('profileForm', {enableReinitialize: true}),
  withRunSaga(),
  withResponsiveInfo(),
  withRouter
)(ProfileSettingsPage)
