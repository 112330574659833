import {shouldUpdate} from 'recompose'
import deepEqual from 'deep-equal'
import pick from 'lodash/pick'

type Ident = <T>(a: T) => T

const onlyUpdateForKeysDeep = (keys: Array<string>): Ident =>
  shouldUpdate(
    (prev: object, next: object) =>
      !deepEqual(pick(prev, keys), pick(next, keys))
  )

export default onlyUpdateForKeysDeep
