import * as React from 'react'
import {pick} from 'lodash'
import {compose} from 'redux'
import {withRouter, RouteComponentProps} from 'react-router-dom'
import {connect} from 'react-redux'

import TopMiddleBottomLayout from '$common/components/layouts/TopMiddleBottomLayout'
import LoadingSpinnerModal from '$common/components/LoadingSpinnerModal'
import Header from '$common/components/Header/Header-container'
import P from '$common/components/P'
import BackButton from '$common/components/buttons/BackButton'
import Footer from '$common/components/Footer'
import ArticleContainer from './ArticleContainer'
import {fetchAllArticles} from '$src/articles/sagas/fetchArticles'
import {getArticle, getLandscapeImage} from '$src/articles/selectors'
import withRunSaga, {WithRunSagaProps} from '$common/utils/withRunSaga'
import {resolveCss, isDeviceOnline} from '$common/utils'
import css from './SingleArticle.css'
import grid from '$common/grid/grid.css'
import align from '$common/grid/align.css'
import {StoreState} from '$src/types'
import {Lang} from '$src/i18n/types'
import {getPreferredLang} from '$src/i18n/selectors'
import {Article} from '$src/articles/types'
import {getAppboy} from '$common/pgWebSeparatorUtils'
import amplitude from '$src/analytics/amplitude'

interface MatchParams {
  id: string
}
type Props = RouteComponentProps<MatchParams> &
  WithRunSagaProps & {
    article?: Article
    languageCode: Lang
  }

const classes = resolveCss(css, grid, align)

class SingleArticle extends React.Component<Props> {
  componentDidMount() {
    if (!this.props.article) {
      this.loadArticles()
    } else {
      this.logCustomEvents()
    }
  }

  linkHandler = (e) => {
    const from = e.target
    if (from) {
      const params = pick(this.props.article, [
        'id',
        'slug',
        'publishedAt',
        'title'
      ])
      const linkParams = pick(from, ['href', 'innerText'])
      amplitude.logEvent('outboundLinkFromArticle', {...params, ...linkParams})
      getAppboy().logCustomEvent('Outbound link from article', {
        ...params,
        ...linkParams
      })
      getAppboy().requestImmediateDataFlush()
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.languageCode !== this.props.languageCode ||
      prevProps.article?.id !== this.props.article?.id
    ) {
      this.logCustomEvents()
    }
  }

  loadArticles = async () => {
    if (!isDeviceOnline()) {
      return
    }
    try {
      await this.props.runSaga(fetchAllArticles)
    } catch (error) {
      console.error(error) // eslint-disable-line no-console
      if (error.status === 404) {
        this.props.history.replace('/home')
      } else {
        throw error
      }
    }
  }

  logCustomEvents = (props: Props = this.props) => {
    if (!isDeviceOnline() || !props.article) {
      return
    }
    const params = pick(props.article, ['id', 'slug', 'publishedAt', 'title'])

    amplitude.logEvent('openArticle', params)
    getAppboy().logCustomEvent('Open article', params)
  }

  render() {
    const {article} = this.props
    if (!article) {
      return <LoadingSpinnerModal />
    }

    const leadHasChecklist =
      article.checklist &&
      article.checklist.position &&
      (article.checklist.position === 'left-of-lead' ||
        article.checklist.position === 'right-of-lead')

    return (
      <TopMiddleBottomLayout
        noPadding={true}
        css={{container: css.container, middle: css.middle}}
        topStyle="sticky"
        top={[<Header key="topHeader" />]}
        middle={
          <div>
            <div {...classes('hero')}>
              <div
                {...classes('imageContainer')}
                style={{
                  backgroundImage: `linear-gradient(
                  rgba(0, 0, 0, 0.25),
                  rgba(0, 0, 0, 0.25)
                ), url(${String(getLandscapeImage(article))})`
                }}
              >
                <div {...classes('textContainer')}>
                  <div>
                    <P bolder={true} css={{container: css.title}}>
                      {article.title}
                    </P>
                    <P bolder={true} css={{container: css.teaser}}>
                      {article.teaser}
                    </P>
                  </div>
                </div>
              </div>
              <BackButton css={{container: css.backButton}} onClick="goBack" />
            </div>
            <div {...classes('columnLarge8', 'center')}>
              <ArticleContainer
                title={article.leadTitle}
                body={article.lead}
                publishedAt={article.publishedAt}
                history={this.props.history}
                checklist={leadHasChecklist && article.checklist}
                onLinkClick={this.linkHandler}
              />

              {article.body && (
                <ArticleContainer
                  title={article.bodyTitle}
                  body={article.body}
                  history={this.props.history}
                  checklist={!leadHasChecklist && article.checklist}
                  onLinkClick={this.linkHandler}
                />
              )}
            </div>
          </div>
        }
        bottom={<Footer />}
      />
    )
  }
}

export default compose(
  withRouter,
  withRunSaga(),
  connect((state: StoreState, props: Props) => ({
    languageCode: getPreferredLang(state),
    article: getArticle(state, props.match.params.id)
  }))
)(SingleArticle)
