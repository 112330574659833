import React, {Component} from 'react'
import {compose} from 'redux'
import {Switch, Route} from 'react-router-dom'
import {connect} from 'react-redux'
import {destroy, FormProps} from 'redux-form'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'

import getSubscriptions from '$src/subscriptions/sagas/getSubscriptions'
import {hasActivePlainOrder} from '$src/subscriptions/selectors'
import {withRunSaga, WithRunSagaProps} from '$common/utils'

import ProfileSummary from '$src/subscriptions/components/ProfileSummary-container'
import Card from '$src/subscriptions/components/PlainCardOrderPage/Card'
import OrderCompleted from '$src/subscriptions/components/PlainCardOrderPage/OrderCompleted'
import OrderAlreadyInProgress from '$src/subscriptions/components/PlainCardOrderPage/OrderAlreadyInProgress'
import Payment from '$src/subscriptions/components/PlainCardOrderPage/Payment'
import ShippingAddress from '$src/subscriptions/components/PlainCardOrderPage/ShippingAddress'
import Start from '$src/subscriptions/components/PlainCardOrderPage/Start'
import AreYouSure from '$src/subscriptions/components/PlainCardOrderPage/AreYouSure'
import LoadingSpinnerModal from '$common/components/LoadingSpinnerModal'

type Props = FormProps & WithRunSagaProps & {path: string}

const basePath = '/plain-card/:nationality(national|international)'

const Summary = (routeProps) => (
  <ProfileSummary
    startLink="/plain-card/start"
    from="/plain-card/profile-summary"
    dialogContainerStyle="blue"
    {...routeProps}
  />
)
const Lander = connectedAuthWrapper({
  FailureComponent: AreYouSure,
  authenticatedSelector: (state) => !hasActivePlainOrder(state),
  AuthenticatingComponent: LoadingSpinnerModal
})(Summary)

class PlainCardOrderPage extends Component<Props> {
  componentDidUpdate() {
    window.scrollTo(0, 0)
  }

  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0)
    this.props.runSaga(getSubscriptions)
  }

  componentWillUnmount() {
    this.props.destroy('plainCardWizard')
  }

  render() {
    return (
      <Switch>
        <Route path="/plain-card" exact component={Lander} />
        <Route path="/plain-card/profile-summary" exact component={Summary} />
        <Route path="/plain-card/start" exact component={Start} />
        <Route path={basePath} exact component={Card} />
        <Route
          path={`${basePath}/shipping-address`}
          exact
          component={ShippingAddress}
        />
        <Route path={`${basePath}/payment`} component={Payment} />
        <Route
          path={`${basePath}/order-completed`}
          exact
          component={OrderCompleted}
        />
        <Route
          path={`${basePath}/order-already-in-progress`}
          exact
          component={OrderAlreadyInProgress}
        />
      </Switch>
    )
  }
}

export default compose(
  withRunSaga(),
  connect(null, {destroy})
)(PlainCardOrderPage)
