import * as React from 'react'
import Select from 'react-select'
import {compose} from 'redux'

import css from './SelectBoxField.css'
import {resolveCss, asField} from '$common/utils'
import withTranslate from '../../../i18n/utils/withTranslate'

const colors = {
  rose: '#FD1E6E',
  vulcan: '#121821',
  graphite: '#5E5E5E',
  gray: '#808080',
  sand: '#F6F6F6'
}

const customTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: colors.rose,
    primary75: colors.rose,
    primary50: colors.rose,
    primary25: colors.rose,
    neutral0: colors.vulcan,
    neutral5: colors.vulcan,
    neutral10: colors.vulcan,
    neutral20: colors.graphite,
    neutral30: colors.graphite,
    neutral40: colors.graphite,
    neutral50: colors.gray,
    neutral60: colors.gray,
    neutral70: colors.sand,
    neutral80: colors.sand,
    neutral90: colors.sand
  }
})

const getBackgroundColor = (selectState) => {
  if (selectState.isSelected) return colors.rose
  if (selectState.isFocused) return colors.graphite
  return colors.vulcan
}

const customStyles = {
  option: (provided, state) => {
    return {...provided, backgroundColor: getBackgroundColor(state)}
  },
  control: (provided) => {
    return {...provided, border: 'none', boxShadow: '0'}
  },
  indicatorSeparator: (provided) => {
    return {...provided, display: 'none'}
  },
  valueContainer: (provided) => {
    return {...provided, 'box-shadow': 'none', margin: '-12px'}
  }
}

export const SelectBoxField = (props) => {
  const classes = resolveCss(css, props.css)
  const placeholder = props.t('common.dropdown.select')
  return (
    <div
      {...classes(
        'container',
        props.meta.active && 'inFocus',
        props.style || 'normal',
        props.dark && 'dark'
      )}
    >
      <div {...classes('topRow')}>
        <div {...classes('label')}>{props.label}</div>
      </div>
      <Select
        {...props.config}
        onChange={({value}) => {
          props.input.onChange(value)
        }}
        options={props.options}
        theme={customTheme}
        styles={customStyles}
        placeholder={placeholder}
      />
    </div>
  )
}

export default compose(asField(), withTranslate())(SelectBoxField)
