import {select} from 'redux-saga/effects'

import {reduceState} from '$common/utils'
import {callEndpointAndSetFlags} from '$src/api/sagas/callEndpoint'
import putProfileEndpoint from '$src/api/endpoints/profile/put'
import {getProfile} from '$src/profile/selectors'
import {Profile} from '$src/profile/types'

export default function* putProfile(
  values: {email: string},
  requestKey?: string
): Generator<> {
  const profile: Profile = yield select(getProfile)

  const result: Promise<Profile> = yield callEndpointAndSetFlags(
    requestKey
  )(putProfileEndpoint, {...profile, ...values})

  yield reduceState(['profile'], (profileState) => ({
    ...profileState,
    ...result
  }))
  return result
}
