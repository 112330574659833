import {connect} from 'react-redux'
import {compose} from 'redux'

import {withTranslate, withRouter, withRunSaga} from '$common/utils'
import {getPreferredLang} from '$src/i18n/selectors'

import FeaturesMobile from './FeaturesMobile'

const mapStateToProps = (state) => {
  return {
    language: getPreferredLang(state),
    pricing: state.pricing
  }
}

export default compose(
  connect(mapStateToProps),
  withRouter,
  withTranslate(),
  withRunSaga()
)(FeaturesMobile)
