import * as React from 'react'
import {compose} from 'redux'
import {Link} from 'react-router-dom'

import ConversationLayout from '$common/components/layouts/ConversationLayout'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import css from '$src/subscriptions/components/styles.css'
import {NationalOrInternational} from '$src/subscriptions/types'
import {
  WithRouterProps,
  WithTranslateProps,
  withRouter,
  withTranslate
} from '$common/utils'
import HeaderTopFill from '$src/common/components/Header/HeaderTopFill'

type Props = WithRouterProps &
  WithTranslateProps & {
    match: {params: {nationality: NationalOrInternational}}
  }

const OrderAlreadyInProgress = (props: Props) => {
  let titleI18nKey,
    bodyI18nKey,
    ctaI18nKey,
    isicActivationReminder,
    isicActivationButton

  if (props.match.params.nationality === 'national') {
    titleI18nKey =
      'subscriptions.plainCardPurchase.finnish.orderAlreadyInProgress.title'
    bodyI18nKey =
      'subscriptions.plainCardPurchase.finnish.orderAlreadyInProgress.body'
    ctaI18nKey =
      'subscriptions.plainCardPurchase.finnish.orderAlreadyInProgress.cta'
  } else {
    titleI18nKey =
      'subscriptions.plainCardPurchase.international.orderAlreadyInProgress.title'
    bodyI18nKey =
      'subscriptions.plainCardPurchase.international.orderAlreadyInProgress.body'
    isicActivationReminder =
      'subscriptions.plainCardPurchase.international.orderAlreadyInProgress.isic_activation_reminder'
    isicActivationButton =
      'subscriptions.plainCardPurchase.international.orderAlreadyInProgress.isic_activation_button'
    ctaI18nKey =
      'subscriptions.plainCardPurchase.international.orderAlreadyInProgress.cta'
  }

  const conversationLayoutProps = {
    content: (
      <div className={css.content}>
        <H1 css={{container: css.heading}}>{props.t(titleI18nKey)}</H1>
        <P css={{container: css.paragraph}}>{props.t(bodyI18nKey)}</P>
        <P css={{container: css.paragraph}} bolder={true}>
          {props.t(isicActivationReminder)}
        </P>
      </div>
    ),
    buttons: (
      <div className={css.singleButton}>
        { (props.match.params.nationality !== 'national') &&  <Link to="/offer/offer_2585">
          <GenericButton
            css={{container: css.button}}
            style="bold"
            isExpanding={true}
          >
            {props.t(isicActivationButton)}
          </GenericButton>
        </Link>}
        <Link to="/">
          <GenericButton
            css={{container: css.button}}
            style="bold"
            isExpanding={true}
          >
            {props.t(ctaI18nKey)}
          </GenericButton>
        </Link>
      </div>
    ),
    hideBackButton: true,
    dialogContainerStyle: 'blue'
  }

  return (
    <div>
      <HeaderTopFill
        background={'linear-gradient(#036F89,#04A3CA)'}
        position={'fixed'}
      />
      <ConversationLayout {...conversationLayoutProps} />
    </div>
  )
}

export default compose(withRouter, withTranslate())(OrderAlreadyInProgress)
