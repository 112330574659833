import {compose} from 'redux'
import {connect} from 'react-redux'
import get from 'lodash/get'

import {getProfile, isUserVerified} from '$src/profile/selectors'
import {withRouter, withRunSaga, withTranslate, asForm} from '$src/common/utils'
import {EditPhoneNumberPage} from './EditPhoneNumberPage'

const PHONE_NUMBER_REGEX = /^([+]{1}|[0]{2})([0-9 ]+)$/
const VERIFICATION_CODE_REGEX = /^\d{6}$/
const validate = ({phoneNumber, verificationCode}) => {
  return {
    phoneNumber: PHONE_NUMBER_REGEX.test(phoneNumber) ? undefined : true,
    verificationCode: VERIFICATION_CODE_REGEX.test(verificationCode)
      ? undefined
      : true
  }
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state),
    fieldErrors: get(state, 'form.editPhoneNumberPage.syncErrors', {}),
    isUserVerified: isUserVerified(state)
  }
}

export default compose(
  asForm('editPhoneNumberPage', {validate}),
  connect(mapStateToProps),
  withRunSaga(),
  withRouter,
  withTranslate()
)(EditPhoneNumberPage)
