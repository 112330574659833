import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect'
import {getStudentType} from '$src/profile/selectors'
import {StudentTypes} from '$src/profile/types'
import {StoreState} from '$src/types'

export default (
  studentTypes: StudentTypes | Array<StudentTypes>,
  redirectUrl: string
) => {
  if (typeof studentTypes === 'string') {
    studentTypes = [studentTypes]
  }

  return connectedRouterRedirect({
    redirectPath: redirectUrl,
    allowRedirectBack: false,
    authenticatedSelector: (state: StoreState) => {
      const userStudentType = getStudentType(state)

      for (const studentType of studentTypes) {
        if (userStudentType === studentType) {
          return true
        }
      }
      return false
    },
    wrapperDisplayName: 'UserHasStudentType'
  })
}
