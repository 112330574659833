import React from 'react'
import {Link} from 'react-router-dom'
import {compose} from 'redux'

import P from '$common/components/P'

import css from './index.css'
import {resolveCss, WithTranslateProps, withTranslate} from '$common/utils'

type MultiLocationProps = WithTranslateProps & {
  itemName: string
  onlyLocation: object
  itemKey: string
}

export const MultiLocation = ({t, itemKey, itemName}: MultiLocationProps) => {
  const classes = resolveCss(css)
  return (
    <div {...classes('discountBox', 'companyBox')}>
      <h2 {...classes('discountBoxItem', 'aboutHeader')}>{itemName}</h2>
      <div {...classes('advertiserInfoContainer', 'discountBoxItem')}>
        <P bold>{t('offer.locations.title')}</P>
        <Link to={`/offer/${itemKey}/locations`}>
          <P style="xSmall">{t('offer.locations.show')}</P>
        </Link>
      </div>
    </div>
  )
}

type OneLocationProps = WithTranslateProps & {
  itemName: string
  onlyLocation: object
  url: string
}

const SingleLocation = ({onlyLocation, t, itemName}: OneLocationProps) => {
  const classes = resolveCss(css)

  return (
    <div {...classes('discountBox', 'companyBox')}>
      <h2 {...classes('discountBoxItem', 'aboutHeader')}>{itemName}</h2>

      {(onlyLocation.streetAddress ||
        onlyLocation.postalCode ||
        onlyLocation.cityName) && (
        <div {...classes('advertiserInfoContainer', 'discountBoxItem')}>
          <P bold>{t('offer.location.address')}</P>
          <P style="xSmall" css={{container: css.addressText}}>
            {`${onlyLocation.streetAddress || ''}${
              (onlyLocation.postalCode || onlyLocation.cityName) &&
              onlyLocation.streetAddress
                ? ', '
                : ''
            }${onlyLocation.postalCode || ''} ${onlyLocation.cityName || ''}`}
          </P>
        </div>
      )}

      {!!onlyLocation.openHours && (
        <div {...classes('advertiserInfoContainer', 'discountBoxItem')}>
          <P bold>{t('offer.openingHours')}</P>
          <div
            {...classes('innerHtmlContainer')}
            dangerouslySetInnerHTML={{__html: onlyLocation.openHours}}
          />
        </div>
      )}

      {!!onlyLocation.phone && (
        <div {...classes('advertiserInfoContainer', 'discountBoxItem')}>
          <P bold>{t('offer.phoneNumber')}</P>
          <P style="xSmall">{onlyLocation.phone}</P>
        </div>
      )}
    </div>
  )
}

export const SingleLocationCompanyBox = compose(withTranslate())(SingleLocation)
export const MultiLocationCompanyBox = compose(withTranslate())(MultiLocation)
