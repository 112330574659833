import {Offer, Category} from '$src/offers/types'

export type Params = {
  q?: string
  languageCode: 'en' | 'sv' | 'fi'
  page: number
  size: number
  sort?: 'alphabetical:asc' | 'alphabetical:desc' | 'date:asc' | 'date:desc'
  category?: Category
  city?: string
  coordiates?: string
  onlineOrPhysical?: 'online' | 'physical'
}

export default function getOffers(
  {get},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  {onlineOrPhysical, ...params}: Params
): Promise<{data: Array<Offer>}> {
  return get(`/offers`, params, {numberOfRetries: 4})
}