import {handleActions} from 'redux-actions'
import {omit} from 'lodash'
import {FetchingFlagNameSpaceState, AsyncErrorsNameSpaceState} from './types'

const startRequest = (
  state: FetchingFlagNameSpaceState,
  {payload}
): FetchingFlagNameSpaceState => {
  return {...state, ...payload}
}
const endRequest = (
  state: FetchingFlagNameSpaceState,
  {payload}: {payload: {omitKey: string}}
): FetchingFlagNameSpaceState => {
  return omit(state, [payload.omitKey])
}

const setAsyncError = (
  state: AsyncErrorsNameSpaceState,
  action: {payload: {errMsg: string; entityType: string; status: number}}
): AsyncErrorsNameSpaceState => {
  return [
    ...state,
    {
      status: action.payload.status,
      message: action.payload.errMsg,
      entityType: action.payload.entityType
    }
  ]
}

export const requests = handleActions(
  {
    'REQUEST/START': startRequest,
    'REQUEST/SUCCESS': endRequest,
    'REQUEST/ERROR': endRequest
  },
  {}
)

export const asyncErrors = handleActions(
  {
    'REQUEST/ERROR': setAsyncError
  },
  []
)
