import * as React from 'react'
import resolveCss from '$src/common/utils/resolveCss'
import css from '$src/offline-studentcard/components/Paywall.css'

type Props = {
  show: boolean
  text?: string
}
const Paywall = (props: Props) => {
  const {show, text} = props
  if (!show) {
    return null
  }
  const classes = resolveCss(css)
  return (
    <div {...classes('paywall')}>
      <div {...classes('paywallDialog')}>{text || ''}</div>
    </div>
  )
}

export default Paywall
