import React, {useEffect} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {withTranslate, isDeviceOnline, resolveCss} from '$src/common/utils'
import css from '../ListPage/ListPage.css'
import grid from '$common/grid/grid.css'
import TopMiddleBottomLayout from '$common/components/layouts/TopMiddleBottomLayout'
import OfflineModal from '$common/components/modals/OfflineModal'
import Header from '$common/components/Header/Header-container'
import P from '$common/components/P'
import OfferItem from '../ListPage/OfferItem'
import GenericButton from '$common/components/buttons/GenericButton'
import {getActiveFavourites} from '$src/offers/selectors'
import {
  ANALYTICS_EVENT as evt,
  ANALYTICS_PROVIDER as provider,
  logEvent2 as log
} from '$common/utils/logUtils'

const List = ({t, activeFavourites, auth}) => {
  const classes = resolveCss(css, grid)
  useEffect(() => {
    log(evt.OPENED_FAVOURITES_LIST, [provider.AMPLITUDE, provider.BRAZE])
  }, [])

  return (
    <TopMiddleBottomLayout
      adaptiveLayout={{
        horizontallyCenterMiddle: true
      }}
      noPadding={true}
      css={{container: css.container, middle: css.middle}}
      topStyle="sticky"
      top={<Header key="topHeader" />}
      middle={
        !isDeviceOnline() ? (
          <OfflineModal />
        ) : (
          window.cordova && (
            <div {...classes('theList', 'cordova')}>
              <div {...classes('mobileContent')}>
                <div {...classes('mobileDivider')}>
                  <P style="small" color="white" align="centerText">
                    {t('favourites.title')}
                  </P>
                </div>
                {activeFavourites.length ? (
                  activeFavourites.map((item) => (
                    <div style={{height: 200}} key={item.key}>
                      <OfferItem data={item} t={t} />
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <P style="small" color="white" align="centerText">
                      {auth.loggedIn
                        ? t('favourites.no_favourites_yet')
                        : t('favourites.guest_mode_info')}
                    </P>
                    <Link to="/offers" onClick={() => window.scrollTo(0, 0)}>
                      <GenericButton
                        css={{container: css.offersButton}}
                        style="outline"
                      >
                        {t('common.discounts')}
                      </GenericButton>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          )
        )
      }
    />
  )
}

const mapStateToProps = (state) => {
  return {
    activeFavourites: getActiveFavourites(state),
    auth: state.auth
  }
}

export default compose(connect(mapStateToProps), withTranslate())(List)
