import amplitude from 'amplitude-js'

const Amplitude = {
  init: function(apiKey, userId, config, cb) {
    amplitude.getInstance().init(apiKey, userId, config, cb)
  },

  amplitude: function(...args) {
    if (args.length > 0) {
      amplitude.apply(this, args)
      return
    }

    return amplitude
  },

  logEvent: function(eventType, eventProperties) {
    amplitude.getInstance().logEvent(eventType, eventProperties)
  },

  resetUserId: function() {
    amplitude.getInstance().setUserId(null)
    amplitude.getInstance().regenerateDeviceId()
  },

  setUserId: function(userId) {
    amplitude.getInstance().setUserId(userId)
  },

  addDeviceTypeToUser: function (deviceType: string) {
    amplitude.getInstance().setUserProperties({'deviceType': deviceType})
  }
}

export default Amplitude
