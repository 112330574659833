import * as React from 'react'
import {Link} from 'react-router-dom'
import Auth from '@aws-amplify/auth'
import {SubmissionError} from 'redux-form'
import {isEmpty} from 'lodash'

import ErrorCandy from '$common/components/candyBars/ErrorCandy'
import PasswordInput from '$common/components/forms/PasswordInput'
import LogoutHeader from '$common/components/Header/LogoutHeader-container'
import Layout from '$common/components/layouts/TopMiddleBottomLayout'
import GenericButton from '$src/common/components/buttons/GenericButton'
import LoadingSpinnerModal from '$common/components/LoadingSpinnerModal'
import {resolveCss, createApiErrorMessage} from '$common/utils'
import css from './ChangePasswordPage.css'
import layoutCss from '$common/grid/grid.css'
import alignCss from '$common/grid/align.css'

class ChangePasswordPage extends React.Component {
  onSubmit = async ({currentPassword, newPassword}) => {
    try {
      const cogitoUser = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(cogitoUser, currentPassword, newPassword)
      this.props.history.push('/profile/settings')
    } catch (err) {
      const errMsg = createApiErrorMessage(err)
      throw new SubmissionError({error: this.props.t(errMsg), general: true})
    }
  }

  render() {
    const classes = resolveCss(css, layoutCss, alignCss, this.props.css)

    return (
      <Layout
        noPadding={true}
        topStyle="sticky"
        top={<LogoutHeader />}
        middle={
          <div
            {...classes('changePasswordContainer', 'columnLarge9', 'center')}
          >
            {this.props.submitting && <LoadingSpinnerModal />}

            <h1>{this.props.t('profile.changePasswordPage.caption')}</h1>
            <form
              onSubmit={this.props.handleSubmit(this.onSubmit)}
              className={css.changePasswordForm}
            >
              <PasswordInput
                name="currentPassword"
                placeholder={this.props.t('common.passwordPlaceholder')}
                label={this.props.t(
                  'profile.changePasswordPage.currentPassword'
                )}
              />
              <PasswordInput
                name="newPassword"
                placeholder={this.props.t('common.passwordPlaceholder')}
                label={this.props.t('profile.changePasswordPage.newPassword')}
              />
              <PasswordInput
                name="newPasswordAgain"
                placeholder={this.props.t('common.passwordPlaceholder')}
                label={this.props.t(
                  'profile.changePasswordPage.newPasswordAgain'
                )}
              />
              <div {...classes('row')}>
                <div {...classes('column', 'right')}>
                  <Link to="/profile/settings">
                    <GenericButton style="outline">
                      {this.props.t('common.back')}
                    </GenericButton>
                  </Link>
                  &nbsp;
                  <GenericButton
                    style="bold"
                    type="submit"
                    disabled={this.props.invalid || this.props.submitting}
                  >
                    {this.props.t('profile.changePasswordPage.submit')}
                  </GenericButton>
                </div>
              </div>

              <ErrorCandy
                show={!isEmpty(this.props.submitErrors)}
                onHide={this.props.clearSubmitErrors}
              >
                {this.props.submitErrors && this.props.submitErrors.error}
              </ErrorCandy>
            </form>
          </div>
        }
      />
    )
  }
}

export default ChangePasswordPage
