import React, {Component} from 'react'
import FadeToDirection from './FadeToDirection/FadeToDirection'
import StudentCardView from './StudentCardView/StudentCardView-container'
import QrCodeView from './QrCodeView/QrCodeView-container'
import BarcodeView from './BarcodeView/BarcodeView-container'
import resolveCss from '$src/common/utils/resolveCss'
import css from '$src/offline-studentcard/components/StudentCard.css'
import getSubscriptions from '$src/subscriptions/sagas/getSubscriptions'
import fetchProfile from '$src/profile/sagas/getProfile'
import moment from 'moment'
import setBodyBackgroundColor from '$src/common/utils/setBodyBackgroundColor'
import queryString from 'query-string'
import GenericButton from '$src/common/components/buttons/GenericButton'
import LoadingSpinnerModal from '$src/common/components/LoadingSpinnerModal/index'
import Paywall from './Paywall'
import {
  setBodyBackgroundByStudentCardColor,
  getStudentCardColor
} from '$src/offline-studentcard/components/StudentCardColor'
import {logEvent} from '$src/common/utils/logUtils'
import {Profile} from '$src/profile/types'
import {SubscriptionsNamespaceState} from '$src/subscriptions/types'
import {
  isDeviceOnline,
  WithRunSagaProps,
  WithRouterProps,
  WithTranslateProps
} from '$common/utils/index'

type Props = WithRunSagaProps &
  WithRouterProps &
  WithTranslateProps & {
    profile: Profile
    subscriptions: SubscriptionsNamespaceState
    isUserActive: boolean
    shouldShowDigicard: boolean
    isFetchingProfile: boolean
    isFetchingSubscriptions: boolean
    isProfileLoaded: boolean
    isSubscriptionsLoaded: boolean
  }

type State = {
  renderStudentCard: boolean
  renderStudentCardQrCode: boolean
  renderStudentCardBarcode: boolean
  studentCardFadeDirection: 'left' | 'right' | ''
}

export default class StudentCard extends Component<Props, State> {
  state = {
    renderStudentCard: true,
    renderStudentCardQrCode: false,
    renderStudentCardBarcode: false,
    studentCardFadeDirection: ''
  }

  isOfflineAvailable() {
    if (!this.props.profile || this.props.profile.loaded === false) {
      return false
    }
    const loaded = moment(this.props.profile.loaded)
    return moment().diff(loaded, 'days') < 7
  }

  componentDidMount() {
    document.addEventListener('backbutton', this.backButtonHandler)
    if (isDeviceOnline()) {
      logEvent('Open student card')
    }
  }

  componentWillUnmount() {
    document.body &&
      document.body.classList &&
      document.body.classList.remove('no-padding')
    setBodyBackgroundColor(null)
    document.removeEventListener('backbutton', this.backButtonHandler)
  }

  selectDefaultPageByQueryString() {
    const {defaultPage} = queryString.parse(this.props.location.search)
    if (defaultPage === 'qr') {
      return this.showQrCode()
    } else if (defaultPage === 'barcode') {
      return this.showBarcode()
    }
  }

  fetchStudentData = async () => {
    if (!isDeviceOnline()) return

    if (!this.props.isFetchingProfile) await this.props.runSaga(fetchProfile)
    if (
      !this.props.isSubscriptionsLoaded &&
      !this.props.isFetchingSubscriptions
    ) {
      this.props.runSaga(getSubscriptions)
    }
  }

  UNSAFE_componentWillUpdate() {
    setBodyBackgroundByStudentCardColor(this.props.profile)
  }

  async UNSAFE_componentWillMount() {
    document.body &&
      document.body.classList &&
      document.body.classList.add('no-padding')
    await this.selectDefaultPageByQueryString()
    setBodyBackgroundByStudentCardColor(this.props.profile)
  }

  showQrCode = () => {
    this.setState({
      renderStudentCard: false,
      renderStudentCardQrCode: true,
      studentCardFadeDirection: 'left'
    })
    logEvent('Show QR code')
  }

  hideQrCode = () =>
    this.setState({
      renderStudentCard: true,
      renderStudentCardQrCode: false,
      studentCardFadeDirection: 'left'
    })

  showBarcode = () => {
    this.setState({
      renderStudentCard: false,
      renderStudentCardBarcode: true,
      studentCardFadeDirection: 'right'
    })
    logEvent('Show barcode')
  }
  hideBarcode = () =>
    this.setState({
      renderStudentCard: true,
      renderStudentCardBarcode: false,
      studentCardFadeDirection: 'right'
    })

  static hasSSN(profile: Profile) {
    return profile.identityCode !== 0
  }

  closeSideCard() {
    if (this.state.renderStudentCardQrCode) {
      this.hideQrCode()
    } else if (this.state.renderStudentCardBarcode) {
      this.hideBarcode()
    }
  }

  backButtonHandler = () => {
    if (
      this.state.renderStudentCardBarcode ||
      this.state.renderStudentCardQrCode
    ) {
      this.closeSideCard()
    } else {
      this.props.history.push('/home')
    }
  }

  render() {
    const {
      profile,
      isSubscriptionsLoaded,
      shouldShowDigicard,
      isUserFhsStudent,
      t
    } = this.props
    if (!profile || !isSubscriptionsLoaded) {
      return <LoadingSpinnerModal />
    }

    const classes = resolveCss(css)
    const studentCardBackgroundColor = getStudentCardColor(profile)

    const showPaywall = !shouldShowDigicard || isUserFhsStudent
    const showOfflineWall =
      !isDeviceOnline() && !this.isOfflineAvailable() && !showPaywall

    return (
      <div>
        <Paywall
          show={showOfflineWall}
          text={t('studentId.offline.text')}
          buttons={
            <GenericButton
              css={{container: css.button}}
              style="blackoutline"
              isExpanding={true}
              onClick={this.fetchStudentData}
            >
              {t('studentId.offline.button')}
            </GenericButton>
          }
        />
        <Paywall
          show={showPaywall}
          text={
            isUserFhsStudent
              ? t('studentId.paywall.fhsStudents')
              : t('studentId.paywall.notPresent')
          }
        />
        <div
          {...classes(
            studentCardBackgroundColor,
            showOfflineWall || showPaywall ? 'paywallBlur' : ''
          )}
          style={{position: 'relative', width: '100%', minHeight: '100vh'}}
        >
          <FadeToDirection
            direction={this.state.studentCardFadeDirection}
            delayTime={300}
            isMounted={this.state.renderStudentCard}
          >
            <StudentCardView
              onQrCodeClick={this.showQrCode}
              onBarcodeClick={this.showBarcode}
            />
          </FadeToDirection>
          <FadeToDirection
            direction="right"
            delayTime={300}
            isMounted={this.state.renderStudentCardQrCode}
          >
            <QrCodeView
              onClose={this.hideQrCode}
              studentCardBackgroundColor={studentCardBackgroundColor}
            />
          </FadeToDirection>
          <FadeToDirection
            direction="left"
            delayTime={300}
            isMounted={this.state.renderStudentCardBarcode}
          >
            <BarcodeView onClose={this.hideBarcode} />
          </FadeToDirection>
        </div>
      </div>
    )
  }
}
