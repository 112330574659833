import * as React from 'react'
import {compose} from 'redux'
import {Link} from 'react-router-dom'

import ConversationLayout from '$common/components/layouts/ConversationLayout'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import Line from '$common/components/Divider'
import P from '$common/components/P'
import css from '$src/subscriptions/components/styles.css'
import {
  WithRouterProps,
  WithTranslateProps,
  withRouter,
  withTranslate,
  connect
} from '$common/utils'
import HeaderTopFill from '$src/common/components/Header/HeaderTopFill'

type Props = WithRouterProps & WithTranslateProps & {pricing: any}

const Start = (props: Props) => {
  const buttonStyles = {container: [css.button, css.horizontalButton]}

  const conversationLayoutProps = {
    content: (
      <div className={css.content}>
        <H1 css={{container: css.heading}}>
          {props.t('subscriptions.plainCardPurchase.start.title')}
        </H1>
        <P css={{container: css.paragraph}}>
          {props.t('subscriptions.plainCardPurchase.start.body', props.pricing)}
        </P>
      </div>
    ),
    buttons: (
      <div className={css.horizontalButtons}>
        <div className={css.left}>
          <Link
            to="/plain-card/international"
            onClick={() => window.scrollTo(0, 0)}
          >
            <GenericButton style="bold" css={buttonStyles}>
              {props.t(
                'subscriptions.plainCardPurchase.start.internationalCta'
              )}
            </GenericButton>
          </Link>
          <div className={css.actionDescription}>
            {props.t(
              'subscriptions.plainCardPurchase.start.internationalDescription',
              props.pricing
            )}
          </div>
        </div>

        <Line vertical={true} css={{line: css.line}} />

        <div className={css.right}>
          <Link to="/plain-card/national" onClick={() => window.scrollTo(0, 0)}>
            <GenericButton style="bold" css={buttonStyles}>
              {props.t('subscriptions.plainCardPurchase.start.finnishCta')}
            </GenericButton>
          </Link>
          <div className={css.actionDescription}>
            {props.t(
              'subscriptions.plainCardPurchase.start.finnishDescription',
              props.pricing
            )}
          </div>
        </div>
      </div>
    ),
    hideBackButton: true,
    dialogContainerStyle: 'blue'
  }

  return (
    <div>
      <HeaderTopFill
        background={'linear-gradient(#036F89,#04A3CA)'}
        position={'fixed'}
      />
      <ConversationLayout {...conversationLayoutProps} />
    </div>
  )
}

export default compose(
  connect((state) => ({pricing: state.pricing})),
  withRouter,
  withTranslate()
)(Start)
