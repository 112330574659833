import {callEndpoint} from '$src/api2/sagas/callEndpoint'
import postAccountDeletionEndpoint from '$src/api2/endpoints/accountDeletion/post'
import {
  ANALYTICS_EVENT as evt,
  ANALYTICS_PROVIDER as provider,
  logEvent2 as log
} from '$common/utils/logUtils'

export default function* postAccountDeletion(payload) {
  try {
    // sending api call
    yield callEndpoint('postAccountDeletion')(
      postAccountDeletionEndpoint,
      payload
    )
    // send analytics event
    log(evt.ACCOUNT_DELETION_TRIGGERED_FROM_APP, [
      provider.AMPLITUDE,
      provider.BRAZE
    ])
  } catch (e) {
    throw new Error('POST request to account deletion endpoint failed')
  }
}
