import * as React from 'react'
import {compose} from 'redux'
import {Link} from 'react-router-dom'

import ConversationLayout from '$common/components/layouts/ConversationLayout'
import HeaderTopFill from '$common/components/Header/HeaderTopFill'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import Line from '$common/components/Divider'
import P from '$common/components/P'
import css from '$src/subscriptions/components/styles.css'
import {
  WithRouterProps,
  WithTranslateProps,
  withRouter,
  withTranslate,
  connect
} from '$common/utils'

type Props = WithRouterProps & WithTranslateProps & {pricing: any}

const AreYouSure = (props: Props) => {
  const buttonStyles = {container: [css.button, css.horizontalButton]}

  const conversationLayoutProps = {
    content: (
      <div className={css.content}>
        <H1 css={{container: css.heading}}>
          {props.t(
            'subscriptions.plainCardPurchase.orderExists.title',
            props.pricing
          )}
        </H1>
        <P css={{container: css.paragraph}}>
          {props.t(
            'subscriptions.plainCardPurchase.orderExists.body',
            props.pricing
          )}
        </P>
      </div>
    ),
    buttons: (
      <div className={css.horizontalButtons}>
        <div className={css.left}>
          <Link
            to="/plain-card/profile-summary"
            onClick={() => window.scrollTo(0, 0)}
          >
            <GenericButton style="bold" css={buttonStyles}>
              {props.t(
                'subscriptions.plainCardPurchase.orderExists.newOrder',
                props.pricing
              )}
            </GenericButton>
          </Link>
        </div>

        <Line vertical={true} css={{line: css.line}} />

        <div className={css.right}>
          <Link to="/home">
            <GenericButton style="outline" css={buttonStyles}>
              {props.t('subscriptions.plainCardPurchase.orderExists.goBack')}
            </GenericButton>
          </Link>
        </div>
      </div>
    ),
    hideBackButton: true,
    dialogContainerStyle: 'blue'
  }

  return (
    <div>
      <HeaderTopFill
        background={'linear-gradient(#036F89,#04A3CA)'}
        position={'fixed'}
      />
      <ConversationLayout {...conversationLayoutProps} />
    </div>
  )
}

export default compose(
  connect((state) => ({pricing: state.pricing})),
  withRouter,
  withTranslate()
)(AreYouSure)
