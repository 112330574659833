/**
 * @typedef {Object<string, string, string>} OrderAddress
 * @property {string} street
 * @property {string} postalCode
 * @property {string} city
 */
/**
 * @typedef {Object<string, string, OrderAddress>} OrderParams
 * @property {string} product The product sku // TODO ProductSkus
 * @property {string} paymentMethodId
 * @property {OrderAddress} address
 */

/**
 * @param post
 * @param {OrderParams} payload
 * @returns {*}
 */
export function startOrder({post}, payload) {
  return post('/order/start', payload, {
    numberOfRetries: 4
  })
}
