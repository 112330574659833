import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect'
import {isLoggedIn} from '$src/auth/selectors'

export const authenticatedWrapper = connectedRouterRedirect({
  redirectPath: '/suggest-login',
  allowRedirectBack: true,
  authenticatedSelector: isLoggedIn,
  wrapperDisplayName: 'UserAuthenticated'
})

export default authenticatedWrapper
