import * as React from 'react'
import { useEffect } from 'react'
import {compose} from 'redux'
import LoadingSpinnerModal from '$common/components/LoadingSpinnerModal'
import css from './index.css'
import HomePageContainer from '$src/home/components/HomePage/HomePage-container'
import {set404ErrorToStore} from '$src/error404/actions'
import {connect} from 'react-redux'

const NotFoundPage = (props) => {

  useEffect(() => {
      props.set404ErrorToStore({
        status: 404,
        message: "error.404",
        entityType: 'route'
      })
  }, []);

  return (
    window.cordova ? (
      <LoadingSpinnerModal />
    ) : (
      <HomePageContainer />
    )
  )
}
  
const mapDispatchToProps = {
  set404ErrorToStore
}

export default compose(connect(null, mapDispatchToProps),(a) => a)(NotFoundPage)
