import React, {useState, useRef} from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import SvgIcon from '$common/components/icons/SvgIcon'
import externalLinkIcon from '$assets/icons/externalLink_pink.svg'
import backIcon from '$assets/icons/arrowLeftPink.svg'
import shareIcon from '$assets/icons/share.svg'
import checkIcon from '$assets/icons/checkSinglePink.svg'
import starUnfilled from '$assets/icons/starUnfilledOfferPage.svg'
import starFilled from '$assets/icons/starFilledOfferPage.svg'
import Header from '$common/components/Header/Header-container'
import Footer from '$common/components/Footer'
import TopMiddleBottomLayout from '$common/components/layouts/TopMiddleBottomLayout'
import {Offer} from '$src/offers/types'
import DiscountBox from '$src/offers/components/DiscountBox'
import UnverifiedBox from '$src/offers/components/DiscountBox/UnverifiedBox'
import {
  SingleLocationCompanyBox,
  MultiLocationCompanyBox
} from '$src/offers/components/DiscountBox/CompanyInfoBox'
import align from '$common/grid/align.css'
import css from './HeroLayout.css'
import grid from '$common/grid/grid.css'
import {
  resolveCss,
  WithRouterProps,
  WithTranslateProps,
  withRouter,
  withTranslate,
  isDeviceOnline,
  withRunSaga
} from '$common/utils'
import putFavourite from '$src/offers/sagas/putFavourite'
import postFavourite from '$src/offers/sagas/postFavourite'
import {getIsFavourite, getFavourites} from '$src/offers/selectors'
import {isLoggedIn} from '$src/auth/selectors'
import {
  ANALYTICS_EVENT as evt,
  ANALYTICS_PROVIDER as provider,
  logEvent2 as log
} from '$common/utils/logUtils'
import _ from 'lodash'

type Props = WithRouterProps &
  WithTranslateProps & {
    css?: any
    current?: 'discounts' | 'profile' | 'ordercard'
    item?: Offer
    parentRoute: string
    isUserVerified?: boolean
    isUserActive?: boolean
    isLoggedIn: boolean
    extraIcons?: [
      {
        key: string
        src: any
        onClick?: Function
      }
    ]
  }

const HeroLayout = (props: Props) => {
  if (!props.item) {
    return null
  }
  const [copiedSuccessfully, setCopiedSuccessfully] = useState(false)
  const {
    item,
    t,
    isUserVerified,
    isFavourite,
    favourites,
    isUserActive,
    isUserAlumn,
    isLoggedIn
  } = props
  const locations = item.locations || []
  const discountBoxRef = useRef()

  const classes = resolveCss(css, props.css, grid, align)
  const formatDescription = (description) =>
    description
      .split('\n\n')
      .map((part) => `<p>${part}</p>`)
      .join('')

  const toggleFavourite = async (item, favourites, isLoggedIn: boolean) => {
    const {key} = item
    // if the user is not logged in they can't use the favourites feature
    // so we show them a tooltip explaining this
    if (!isLoggedIn) {
      showToolTip('favouriteBtnToolTipText')
      return
    }
    // if offer exists in favourites list then perform PUT request, otherwise do POST
    if (favourites[key]) {
      await props.runSaga(putFavourite, item, favourites[key].favourite)
    } else {
      await props.runSaga(postFavourite, item, {offerId: key})
    }
  }

  // this is the complete list of currently supported params you can pass to the plugin (all optional)
  const options = {
    message: t('offer.share_dialogue.message'), // not supported on some apps (Facebook, Instagram)
    subject: t('offer.share_dialogue.email_subject'), // fi. for email
    // files: ['', ''], // an array of filenames either locally or remotely
    url: item.branchLink,
    chooserTitle: t('offer.share_dialogue.chooser_title') // Android only, you can override the default share sheet title
    // appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
    // iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
  }

  // copied this with slight modifications from OfferPage
  const logCustomEvents = (
    isUserVerified: boolean,
    offer: any,
    event: string
  ) => {
    if (!isDeviceOnline()) return
    if (isUserVerified) {
      const params = _.pick(offer, ['key', 'advertiser', 'categories'])
      if (!_.isEmpty(params.categories)) {
        params.categories = params.categories.join(',')
      }
      log(event, [provider.AMPLITUDE, provider.BRAZE], params)
    }
  }

  const showShareDialog = () => {
    window.plugins.socialsharing.shareWithOptions(options)
    logCustomEvents(isUserVerified, item, evt.MOBILE_SHARE_BUTTON_CLICKED)
  }

  const copyShareableUrl = async () => {
    logCustomEvents(isUserVerified, item, evt.WEB_SHARE_BUTTON_CLICKED)
    try {
      navigator.clipboard.writeText(item.branchLink)
      setCopiedSuccessfully(true)
      showToolTip('copyBtnToolTipText')
      setCopiedSuccessfully(false)
    } catch (err) {
      alert('Oops, unable to copy. Please try again.')
    }
  }

  const showToolTip = (elementId: string) => {
    const span = document.getElementById(elementId)
    span.style.visibility = 'visible'
    setTimeout(() => {
      span.style.visibility = 'hidden'
    }, 2500)
  }

  return (
    <TopMiddleBottomLayout
      adaptiveLayout={true}
      noPadding={true}
      css={{
        middle: css.middle
      }}
      topStyle="sticky"
      top={<Header />}
      middle={[
        <div key="hero" className={css.hero}>
          <div {...classes('columnLarge7')}>
            <div {...classes('heroPlaceholder')}>
              <img
                {...classes('heroPlaceholderImage')}
                src={item.largeImage}
                alt={item.key}
              />
            </div>
            <div className={css.iconContainer}>
              <a
                role="menuitem"
                aria-label="Back"
                className={css.back}
                onClick={() => {
                  props.location.popToGoBack === true
                    ? window.history.back()
                    : props.history.push(`/${props.parentRoute}`)
                }}
              >
                <SvgIcon src={backIcon} sizing="em" />
              </a>
              {/* dont show share button on vouchers */
              !item.isVoucher && (
                <div className={css.tooltip}>
                  <span id={'copyBtnToolTipText'} className={css.tooltiptext}>
                    {t('offer.share_button_tooltip_text')}
                  </span>
                  <a
                    role="menuitem"
                    aria-label="Copy tooltip text"
                    className={css.share}
                    onClick={() =>
                      window.cordova ? showShareDialog() : copyShareableUrl()
                    }
                  >
                    <SvgIcon
                      src={copiedSuccessfully ? checkIcon : shareIcon}
                      sizing="em"
                    />
                  </a>
                </div>
              )}

              {!item.isVoucher && window.cordova && (
                <div className={css.tooltip}>
                  <span
                    id={'favouriteBtnToolTipText'}
                    className={css.tooltiptext}
                  >
                    {t(
                      'offer.favourite_star_tooltip_text_for_unregistered_users'
                    )}
                  </span>
                  <a
                    role="menuitem"
                    aria-label="Toggle Favourite"
                    className={css.star}
                    onClick={() =>
                      toggleFavourite(item, favourites, isLoggedIn)
                    }
                  >
                    <SvgIcon
                      src={isFavourite ? starFilled : starUnfilled}
                      sizing="em"
                      style={{width: '30px'}}
                    />
                  </a>
                </div>
              )}
              {((isUserVerified && isUserActive) || isUserAlumn) &&
                item.offerUrlDescription && (
                  <a
                    onClick={() => discountBoxRef.current()}
                    className={css.visitAdvertiser}
                  >
                    <span className={css.offerUrlDesc}>
                      {item.offerUrlDescription}
                    </span>
                    <SvgIcon
                      css={{container: css.externalLinkIcon}}
                      src={externalLinkIcon}
                      style={{width: '16px', height: '14px'}}
                    />
                  </a>
                )}
              {props.extraIcons &&
                props.extraIcons.map((icon, index) => (
                  <span key={icon.key} className={css.extraIcon}>
                    <SvgIcon
                      role="menuitem"
                      aria-label="Go"
                      src={icon.src}
                      onClick={icon.onClick}
                      style={{width: '30px'}}
                    />
                  </span>
                ))}
            </div>
          </div>
        </div>,

        <div
          key="body"
          {...classes('row', 'center', 'layoutContainer', 'columnLarge7')}
        >
          <div {...classes('descriptionContainer')}>
            <h2 {...classes('title')}>{item.advertiser}</h2>
            <h2 {...classes('shortDescription')}>{item.shortDescription}</h2>
            <div
              {...classes('description')}
              dangerouslySetInnerHTML={{
                __html: formatDescription(item.description)
              }}
            />
          </div>
          <div {...classes('boxContainer')}>
            <UnverifiedBox />
            {((isUserVerified && isUserActive) || isUserAlumn) && (
              <div>
                <DiscountBox
                  itemKey={item.key}
                  discountBoxRef={discountBoxRef}
                  userId={props.userId}
                />
                {//First ensure there actually are locations
                !!locations.length &&
                  !!Object.keys(locations[0]).length &&
                  (locations.length > 1 ? (
                    <MultiLocationCompanyBox
                      itemKey={item.key}
                      itemName={item.advertiser}
                    />
                  ) : (
                    <SingleLocationCompanyBox
                      itemName={item.advertiser}
                      onlyLocation={locations[0]}
                    />
                  ))}
              </div>
            )}
          </div>
        </div>
      ]}
      bottom={<Footer />}
    />
  )
}

const mapStateToProps = (state, offer) => {
  return {
    isFavourite: getIsFavourite(state, offer.item?.key),
    favourites: getFavourites(state),
    isLoggedIn: isLoggedIn(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withRouter,
  withTranslate(),
  withRunSaga()
)(HeroLayout)
