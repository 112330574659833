import * as React from 'react'
import {
  WithRunSagaProps,
  WithRouterProps,
  WithTranslateProps
} from '$common/utils'

import {resolveCss} from '$common/utils'
import css from './Main.css'
import {AsyncErrorsNameSpaceState} from '$src/fetching/types'
import {unsetAsyncErrors} from '$src/fetching/sagas'
import getProfile from '$src/profile/sagas/getProfile'
import getPricing from '$src/pricing/sagas/getPricing'
import Notification from '$src/notification/components/'
import ErrorCandy from '$common/components/candyBars/ErrorCandy'
import isDeviceOnline from '$common/utils/isDeviceOnline'
import getSubscriptions from '$src/subscriptions/sagas/getSubscriptions'
import updateUserPhoneAttributes from '$src/common/utils/updateUserPhoneAttributes'
import setBodyBackgroundColor from '$src/common/utils/setBodyBackgroundColor'
import getFavourites from '$src/offers/sagas/getFavourites'

type Props = WithRunSagaProps &
  WithTranslateProps &
  WithRouterProps & {
    loggedIn: boolean
    children: React.Element<any>
    asyncErrors: AsyncErrorsNameSpaceState
    isUserVerified: boolean
    isFetchingProfile: boolean
    isFetchingSubscriptions: boolean
    isFetchingPricing: boolean
    isFetchingFavourites: boolean
  }

export const isIOs = window.device && window.device.platform === 'iOS'

class Main extends React.Component<Props> {
  UNSAFE_componentWillMount() {
    document.addEventListener('resume', this.onResume, false)
    this.onResume()
  }

  componentWillUnmount() {
    document.removeEventListener('resume', this.onResume, false)
  }

  onResume = () => {
    setBodyBackgroundColor(null)
    this.getData()
  }

  getData = async ({loggingIn = false} = {}) => {
    if (!isDeviceOnline()) {
      return
    }

    if (!this.props.isFetchingPricing) this.props.runSaga(getPricing)

    if (this.props.loggedIn || loggingIn) {
      if (!this.props.isFetchingProfile) {
        await this.props.runSaga(getProfile)
        this.props.runSaga(updateUserPhoneAttributes)
      }

      if (!this.props.isFetchingSubscriptions && this.props.isUserVerified) {
        this.props.runSaga(getSubscriptions)
      }

      if (!this.props.isFetchingFavourites && this.props.isUserVerified) {
        this.props.runSaga(getFavourites)
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.asyncErrors.length && nextProps.asyncErrors.length === 0) {
      this.props.history.replace('/home')
    }

    if (!this.props.loggedIn && nextProps.loggedIn) {
      this.getData({loggingIn: true})
    }
  }

  removeAsyncError = (errorKey: string) => () =>
    this.props.runSaga(unsetAsyncErrors, errorKey)

  removeError404 = () => {
    this.props.remove404ErrorFromStore()
  }

  render() {
    const classes = resolveCss(css)
    const isOffers = this.props.location.pathname === '/offers'

    const {asyncErrors, t, children, error404} = this.props

    return (
      <div id="main" {...classes(isIOs && isOffers && 'ios-margin')}>
        <Notification />
        {asyncErrors.map((error, i) => (
          <ErrorCandy
            key={i}
            show={true}
            onHide={this.removeAsyncError(error.entityType)}
            autoHide={true}
          >
            {t(error.message)}
          </ErrorCandy>
        ))}

        {error404 && (
          <ErrorCandy show={true} onHide={this.removeError404} autoHide={true}>
            {t(error404.message)}
          </ErrorCandy>
        )}

        {children}
      </div>
    )
  }
}

export default Main
