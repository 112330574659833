import * as React from 'react'
import {compose} from 'redux'

import P from '$common/components/P'
import align from '$common/grid/align.css'
import css from './index.css'
import grid from '$common/grid/grid.css'
import {resolveCss, withTranslate, WithTranslateProps} from '$common/utils'

type Props = WithTranslateProps & {
  css?: any
}

const Footer = (props: Props) => {
  const classes = resolveCss(css, props.css, grid, align)
  const NavBarEnabled = window.cordova

  return (
    <footer {...classes('container')}>
      <div className={grid.row}>
        <div
          {...classes('column', 'center')}
          style={NavBarEnabled ? {height: 75} : {}}
        >
          <P style="subtle">{props.t('common.footer')}</P>
        </div>
      </div>
    </footer>
  )
}

export default compose(withTranslate())(Footer)
