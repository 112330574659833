/**
 * The payload sent to the confirm order endpoint
 * @typedef {Object<string, string>} ConfirmOrderPayload
 * @property {string} orderId
 * @property {string} paymentMethodId
 */

/**
 * The response from the confirm order endpoint. The response can also be an empty object.
 * @typedef {Object<boolean, string>} ConfirmOrderResponse
 * @property {boolean} requiresAction
 * @property {string} clientSecret
 */

/**
 * @param {any} post
 * @param {ConfirmOrderPayload} payload
 * @returns {Promise<ConfirmOrderResponse> | Promise<{}>}
 */
export function confirmOrder({post}, payload) {
  return post('/order/confirm', payload, {
    numberOfRetries: 4
  })
}
