import React from 'react'
import css from './StudentCardButton.css'
import resolveCss from '$src/common/utils/resolveCss'

type Props = {
  src: string
  pulse?: boolean
  onClick: Function
  style: object
  studentCardColor: 'white' | 'green' | 'blue'
}

const StudentCardButton = (props: Props) => {
  const classes = resolveCss(css)
  const cssClasses = classes(
    'studentCardButton',
    props.studentCardColor,
    props.pulse && 'pulseContainer'
  )
  return (
    <div {...cssClasses} style={{...props.style}} onClick={props.onClick}>
      {props.pulse ? (
        <div {...classes('pulse')}>
          <img src={props.src} />
        </div>
      ) : (
        <img src={props.src} />
      )}
    </div>
  )
}

export default StudentCardButton
