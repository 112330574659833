import {
  logEvent2,
  ANALYTICS_PROVIDER,
  ANALYTICS_EVENT
} from '$src/common/utils/logUtils'
import Auth from '@aws-amplify/auth'
import {call, put} from 'redux-saga/effects'
import callEndpoint from '$src/api/sagas/callEndpoint'
import signUpEndpoint from '$src/api/endpoints/auth/signUp'
import {getAppboy} from '$common/pgWebSeparatorUtils'
import {setUserProfile} from '$common/utils'
import {setLoggedInToCognito} from '$src/auth/actions'

export default function* verifyEmail({userId, password, language, verifyCode}) {
  yield call(() => Auth.confirmSignUp(userId, verifyCode))
  yield call(() => Auth.signIn(userId, password))
  yield put(setLoggedInToCognito(true))
  const profile = yield callEndpoint(signUpEndpoint, {language})

  if (profile) {
    const appboy = getAppboy()

    appboy.changeUser(profile.externalId)

    logEvent2(ANALYTICS_EVENT.SIGNUP_SUCCESS, [
      ANALYTICS_PROVIDER.AMPLITUDE,
      ANALYTICS_PROVIDER.BRANCH,
      ANALYTICS_PROVIDER.BRAZE,
      ANALYTICS_PROVIDER.FIREBASE
    ])
  }
  yield setUserProfile(profile)
}
