import React from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {Offer} from '$src/offers/types'
import {compose} from 'redux'
import {resolveCss, withRunSaga, withTranslate} from '$common/utils'
import css from './OfferItem.css'
import voucherTag from '$assets/images/diamond-sticker.png'
import {logEvent} from '$src/common/utils/logUtils'
import SvgIcon from '$src/common/components/icons/SvgIcon'
import starFilled from '$assets/icons/starFilled.svg'
import starUnfilled from '$assets/icons/starUnfilledList.svg'
import {saveYScroll} from '$src/ui/actions'
import {getFavourites, getIsFavourite} from '$src/offers/selectors'
import {isLoggedIn} from '$src/auth/selectors'
import putFavourite from '$src/offers/sagas/putFavourite'
import postFavourite from '$src/offers/sagas/postFavourite'

interface Props {
  css?: any
  style: object
  data: Offer
  mode: 'cell' | 'row'
  saveYScroll: (y: number) => void
  isFavourite: any
  favourites: any
}

const OfferItem: React.SFC<Props> = (props) => {
  const classes = resolveCss(css, props.css)
  const offer = props.data

  const showToolTip = (key: string) => {
    const span = document.getElementById(`tooltiptext_${key}`)
    span.style.visibility = 'visible'
    setTimeout(() => {
      span.style.visibility = 'hidden'
    }, 2500)
  }

  const toggleFavourite = async (offer, favourites, isLoggedIn) => {
    const {key} = offer
    // if the user is not logged in they can't use the favourites feature
    // so we show them a tooltip explaining this
    if (!isLoggedIn) {
      showToolTip(key)
      return
    }
    // if offer exists in favourites list then perform PUT request, otherwise do POST
    if (favourites[key]) {
      await props.runSaga(putFavourite, offer, favourites[key].favourite)
    } else {
      await props.runSaga(postFavourite, offer, {offerId: key})
    }
  }

  return (
    <OfferLinkContainer {...props} toggleFavourite={toggleFavourite}>
      <div
        {...classes('image')}
        style={{backgroundImage: `url("${offer.smallImage}")`}}
      />
      {offer.isVoucher ? (
        <img {...classes('voucherTag')} src={voucherTag} />
      ) : null}
      <div {...classes('info')}>
        <h4 {...classes('title')}>{offer.advertiser}</h4>
        <p {...classes('description')}>{offer.shortDescription}</p>
      </div>
    </OfferLinkContainer>
  )
}

const OfferLinkContainer: React.SFC<Props> = (props) => {
  const {data: offer, children, mode, saveYScroll: saveScroll} = props
  const classes = resolveCss(css, props.css)
  const {t, isFavourite, toggleFavourite, favourites, isLoggedIn} = props

  // based on the type of offer content the component is rendering, we want to modify the links
  // to route us to the correct content page
  const constructLinkBasedOnContentType = (offer) => {
    if (offer.isVoucher) {
      return `voucher/${offer.key}`
    }
    if (offer.isJob) {
      return `job/${offer.slug}`
    }
    if (offer.favourite) {
      return `offer/${offer.key}`
    }
    return `offer/${offer.slug}`
  }

  if (!offer.redirectUrl) {
    return (
      <div style={{position: 'relative'}}>
        {!offer.isVoucher && window.cordova && (
          <div className={css.tooltip}>
            <span id={`tooltiptext_${offer.key}`} className={css.tooltiptext}>
              {t('offer.favourite_star_tooltip_text_for_unregistered_users')}
            </span>
            <a
              className={css.star}
              onClick={() => toggleFavourite(offer, favourites, isLoggedIn)}
            >
              <SvgIcon
                src={isFavourite ? starFilled : starUnfilled}
                sizing="em"
                style={{width: '30px'}}
              />
            </a>
          </div>
        )}
        <Link
          to={{
            pathname: constructLinkBasedOnContentType(offer),
            popToGoBack: true
          }}
          onClick={() => {
            saveScroll(window.scrollY)
          }}
          {...classes('container', mode)}
        >
          {children}
        </Link>
      </div>
    )
  }

  const isExternalLink = offer.redirectUrl.startsWith('http')
  if (isExternalLink) {
    return (
      <a
        href={offer.redirectUrl}
        rel="noopener noreferrer"
        target="_blank"
        onClick={() =>
          logEvent(
            'Navigate out from voucher',
            {key: offer.key, url: offer.redirectUrl},
            true
          )
        }
      >
        {children}
      </a>
    )
  } else {
    return (
      <Link to={offer.redirectUrl} {...classes('container', mode)}>
        {children}
      </Link>
    )
  }
}

const mapStateToProps = (state, data) => {
  return {
    isFavourite: getIsFavourite(state, data.data.key),
    favourites: getFavourites(state),
    isLoggedIn: isLoggedIn(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveYScroll: (yScroll) => dispatch(saveYScroll(yScroll))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRunSaga(),
  withTranslate()
)(OfferItem)
