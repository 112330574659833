import {call} from 'redux-saga/effects'
import request from '../request'

export default function* patch(
  endpoint: string,
  payload: any,
  options?: object
): Generator<> {
  return yield call(request, endpoint, {
    ...options,
    method: 'PATCH',
    body: JSON.stringify(payload)
  })
}
