import * as React from 'react'
import onClickOutside from 'react-onclickoutside'
import {compose} from 'redux'
import {resolveCss, withTranslate, WithTranslateProps} from '$common/utils'
import css from './index.css'

import SvgIcon from '$common/components/icons/SvgIcon'
import checkboxChecked from '$assets/icons/checkboxChecked.svg'
import checkboxNotChecked from '$assets/icons/icon-check_default_gray.svg'
import checkboxNotCheckedCircle from '$assets/icons/checkboxCheckedCircle.svg'
import arrowUp from '$assets/icons/icon-arrow-up_bright-pink.svg'
import arrowDown from '$assets/icons/icon-arrow-down_bright-pink.svg'

type Props = WithTranslateProps & {
  isOpen: boolean
  closeDropdown: () => any
  toggle: () => void
  selectedItems: any
  selectItem: (string) => any
  array: any[]
  label: string
  itemsInColumn: number
  css?: object
  dropDownMargin: number
  isCircleCheckbox: boolean
  collapse?: number
}

type State = {
  elementsByRowInMobile: number
  collapseOpened: boolean
}

const PLACEHOLDER = 'placeholder-'

class DropDown extends React.Component<Props, State> {
  dropdownContainer: any
  constructor(props: Props) {
    super(props)
    this.state = {
      elementsByRowInMobile: 2,
      collapseOpened: false
    }
  }

  handleClickOutside = () => {
    this.props.closeDropdown()
  }

  getObjectsToRender() {
    let objectsToRender = []
    if (this.props.collapse && !this.state.collapseOpened) {
      objectsToRender = [...this.props.array.slice(0, this.props.collapse)]
    } else objectsToRender = [...this.props.array]
    while (
      this.state.elementsByRowInMobile !== 0 &&
      objectsToRender.length % this.state.elementsByRowInMobile !== 0
    ) {
      objectsToRender.push(`${PLACEHOLDER}${objectsToRender.length}`)
    }
    return objectsToRender
  }

  UNSAFE_componentWillReceiveProps() {
    const elementWidth = 136
    const {offsetWidth} = this.dropdownContainer
    this.setState({
      elementsByRowInMobile: Math.floor(offsetWidth / elementWidth)
    })
  }

  toggleCollapsing = () => {
    const currentState = this.state.collapseOpened
    this.setState({collapseOpened: !currentState})
  }
  render() {
    const classes = resolveCss(css, this.props.css)
    const {isOpen} = this.props
    const objectsToRender = this.getObjectsToRender()

    return (
      <div
        ref={(container) => {
          this.dropdownContainer = container
        }}
        {...classes('container', isOpen && 'selectedContainer')}
      >
        <div
          {...classes('label')}
          onClick={() => {
            this.props.toggle()
            this.setState({collapseOpened: false})
          }}
        >
          {this.props.t(this.props.label)}{' '}
          <SvgIcon {...classes('arrow')} src={!isOpen ? arrowDown : arrowUp} />
        </div>
        {isOpen && (
          <div
            {...classes('dropDown')}
            style={{marginTop: this.props.dropDownMargin}}
          >
            {objectsToRender.map((item) => {
              if (typeof item === 'string' && item.startsWith(PLACEHOLDER)) {
                return <div {...classes('dropDownItem')} key={item} />
              }

              const isSelected =
                this.props.selectedItems.split(',').indexOf(item.id) > -1
              return (
                <div
                  {...classes('dropDownItem')}
                  key={item.id}
                  onClick={() => this.props.selectItem(item.id)}
                >
                  <SvgIcon
                    {...classes('itemIcon')}
                    src={
                      isSelected
                        ? this.props.isCircleCheckbox
                          ? checkboxNotCheckedCircle
                          : checkboxChecked
                        : checkboxNotChecked
                    }
                  />
                  <div
                    {...classes('itemText', isSelected && 'selectedItemText')}
                  >
                    {this.props.t(item.name)}
                  </div>
                </div>
              )
            })}
            {!this.state.collapseOpened && this.props.collapse && (
              <div {...classes('collapse')} onClick={this.toggleCollapsing}>
                {this.props.t('offers.locations.showMore')}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default compose(withTranslate(), onClickOutside)(DropDown)
