import {select} from 'redux-saga/effects'
import {callEndpoint} from '$src/api/sagas/callEndpoint'
import paginateEntities from '$src/pagination/sagas'
import {getPreferredLang} from '$src/i18n/selectors'

import {getOfferIDsByParams} from '$src/offers/selectors'
import {isFetching} from '$src/fetching/selectors'
import {QueryParams} from '$src/offers/types'
import fetch from '$src/api/endpoints/offers/get'

type Params = QueryParams & {page: number}

export default function* fetchOffers(params: Params): Generator<> {
  const reqKey = `offers-${JSON.stringify(params)}`
  if (isFetching(yield select(), reqKey)) {
    return
  }

  const offers: Array<string> = yield select(getOfferIDsByParams, params)
  if (offers) {
    return offers
  }

  const languageCode: 'fi' | 'sv' | 'en' = yield select(getPreferredLang)
  const fetchParams: Params = {
    ...params,
    languageCode
  }

  const response = yield callEndpoint(reqKey)(fetch, fetchParams)
  return yield paginateEntities(
    response,
    {...params, languageCode},
    'offers',
    'key'
  )
}
