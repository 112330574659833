import {compose} from 'redux'
import {withResponsiveInfo, withRouter, withTranslate, withRunSaga} from '$src/common/utils'
import ListPage from './ListPage'

export default compose(
  withRouter,
  withResponsiveInfo(),
  withTranslate(),
  withRunSaga()
)(ListPage)
