import * as React from 'react'
import {compose} from 'redux'
import SvgIcon from '$common/components/icons/SvgIcon'

import closeSrc from '$assets/icons/icon-close-solid.svg'

import css from './LogoutMenu.css'
import {resolveCss, withTranslate, WithTranslateProps} from '$common/utils'
import GenericButton from '$common/components/buttons/GenericButton'

type Props = WithTranslateProps & {
  onClose: Function
  onLogout: () => void
}

const LogoutMenu = (props: Props) => {
  const classes = resolveCss(css)

  return (
    <div {...classes('menuWrapper')}>
      <div onClick={props.onClose} {...classes('closeIcon')}>
        <SvgIcon
          css={{container: css.logoutIcon}}
          sizing="none"
          src={closeSrc}
          style={{width: '22px', height: '29px'}}
        />
      </div>
      <div {...classes('menu')}>
        <div {...classes('label')}>{props.t('common.account')}</div>
        <GenericButton
          onClick={() => props.onLogout(false)}
          css={{container: css.menuButton}}
          style="bold"
        >
          {props.t('common.logout')}
        </GenericButton>
        <GenericButton
          onClick={() => props.onLogout(true)}
          css={{container: css.menuButton}}
          style="outline"
        >
          {props.t('common.logoutGlobally')}
        </GenericButton>
      </div>
    </div>
  )
}

export default compose(withTranslate())(LogoutMenu)
