import {compose} from 'redux'
import {connect} from 'react-redux'

import {
  withTranslate,
  withRunSaga,
  withRouter,
  withResponsiveInfo
} from '$common/utils'
import {
  getProfile,
  isUserVerified,
  getStudentType
} from '$src/profile/selectors'
import LogoutHeader from './LogoutHeader'

const mapStateToProps = (state) => {
  const profile = getProfile(state)
  const isVerified = isUserVerified(state)
  const isSecondGradeStudent = getStudentType(state) === 'upper_secondary'
  return {
    profile,
    isSecondGradeStudent,
    isVerified
  }
}

export default compose(
  connect(mapStateToProps),
  withRouter,
  withResponsiveInfo(),
  withRunSaga(),
  withTranslate()
)(LogoutHeader)
