import * as React from 'react'
import {reduxForm, getFormSubmitErrors, initialize} from 'redux-form'
import {connect} from 'react-redux'
import {compose} from 'redux'
import get from 'lodash/get'

import {StoreState} from '$src/types'

type Props = {
  fieldValues: {[key: string]: string}
  submitErrors: {}
  changeField: (field: string, value: any) => any
}

type Options = object

// @todo this makes the form element to re-render any time a value changes. let's see if we can avoid that
export default (formName: string, options: Options) => (
  WrappedComponent: any
) => {
  const ComposedComponent = ({fieldValues = {}, ...props}: Props) => (
    <WrappedComponent fieldValues={fieldValues} {...props} />
  )

  const mapStateToProps = (state: StoreState) => {
    const values = get(state.form[formName], 'values')
    return {
      fieldValues: values,
      submitErrors: getFormSubmitErrors(formName)(state)
    }
  }

  const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    initWithValues: () =>
      dispatchProps.initialize(formName, stateProps.fieldValues),
    ...stateProps,
    ...ownProps
  })

  return compose(
    connect(mapStateToProps, {initialize}, mergeProps),
    reduxForm({form: formName, ...options})
  )(ComposedComponent)
}
