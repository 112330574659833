/**
 * Validators for form fields. If value is valid, functions return undefined.
 * For errors functions return either true, or a error message translation key.
 */

export function validatePassword(password) {
  if (!password || password.length < process.env.PASSWORD_MIN_LENGTH) {
    return 'common.validation.password.invalidFormat'
  }
}

export function validatePasswordAgain(passwordAgain, password) {
  if (passwordAgain !== password) {
    return 'common.validation.password.mismatch'
  }
}

const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export function validateEmail(email) {
  if (!REGEX_EMAIL.test(email)) {
    return 'common.validation.email.invalidFormat'
  }
}

export function validateEmailAgain(emailAgain, email) {
  if (emailAgain !== email) {
    return 'common.validation.email.mismatch'
  }
}

const VERIFICATION_CODE_REGEX = /^\d{6}$/
export function validateCognitoVerifyCode(code) {
  if (!VERIFICATION_CODE_REGEX.test(code)) {
    return 'common.validation.cognitoVerifyCode'
  }
}
