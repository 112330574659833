import React from 'react'
import moment from 'moment'
import StudentCardPhoto from './StudentCardPhoto'
import resolveCss from '$src/common/utils/resolveCss'
import css from '$src/offline-studentcard/components/StudentCardView/StudentCardView.css'
import {getStudentCardColor} from '$src/offline-studentcard/components/StudentCardColor'
import {calculateImageMargin} from './StudentCardPhoto'
import Icons from './Icons'

// TODO: This most probably does not work. Try to test with real device.
import {isIOs} from '$src/bootstrap/components/Main'

import {ProfileNamespaceState} from '$src/profile/types'
import {
  WithRunSagaProps,
  WithRouterProps,
  WithTranslateProps
} from '$common/utils/index'

type Props = WithRunSagaProps &
  WithRouterProps &
  WithTranslateProps & {
    profile: ProfileNamespaceState
    onQrCodeClick: Function
    onBarcodeClick: Function
  }

function getStudentTypeText(profile: ProfileNamespaceState, t: Function) {
  if (!profile.loaded) {
    return ''
  }
  const organizationType = profile.organization && profile.organization.type
  const organizationId = profile.organization && profile.organization.id
  const studentType = profile.studentType
  if (
    organizationType === 'vocational-school' ||
    organizationType === 'high-school'
  ) {
    if (organizationId === 'FSS') {
      // NOTE: If there'll be more special cases, better to create new organization type like "upper-secondary"
      return t(`studentId.type.upper-secondary`)
    } else {
      return t(`studentId.type.${organizationType}`)
    }
  }
  return t(`studentId.type.${studentType}`)
}

function getDetails(profile, classes) {
  if (profile.organization) {
    return (
      <div
        {...classes(
          'studentCardDetailsContainer',
          'studentCardOrganizationDetails'
        )}
      >
        <div {...classes('detailsContainer')}>
          <p {...classes('details')}>
            {profile.organization ? profile.organization.name : ''}
          </p>
          <p {...classes('details')}>{profile.jollaMembershipExtraInfo}</p>
        </div>
        <Icons profile={profile} />
      </div>
    )
  }

  return (
    <div
      {...classes(
        'studentCardDetailsContainer',
        'studentCardOrganizationDetails'
      )}
    >
      <div {...classes('detailsContainer')}>
        <p {...classes('details')}>
          {profile.school ? profile.school.name : ''}
        </p>
      </div>
    </div>
  )
}

const StudentCardView = (props: Props) => {
  const {profile, t} = props
  if (!profile.loaded) {
    return null
  }
  const studentCardColor = getStudentCardColor(profile)
  const classes = resolveCss(css)
  return (
    <div {...classes('studentCard')}>
      <h1 {...classes('header')}>{t('studentId.studentCard')}</h1>
      <StudentCardPhoto
        studentCardColor={studentCardColor}
        src={profile.photo}
        onQrCodeClick={props.onQrCodeClick}
        onBarcodeClick={props.onBarcodeClick}
      />
      <div
        {...classes('studentInfoContainer', isIOs && 'studentInfoContainerIos')}
        style={{
          paddingLeft: calculateImageMargin() / 2,
          paddingRight: calculateImageMargin() / 4
        }}
      >
        <div {...classes('studentCardDetailsContainer')}>
          <p {...classes('name')}>{profile.firstName}</p>
          <p {...classes('name')}>{profile.lastName}</p>
          <p {...classes('details')}>
            {moment(profile.dob).format('DD.MM.YYYY')}
          </p>
        </div>
        {getDetails(profile, classes)}
        <div {...classes('studentCardDetailsContainer')}>
          <p {...classes('details')}>
            {getStudentTypeText(props.profile, props.t)}
          </p>
          <p {...classes('details')}>{profile.studentNumber}</p>
        </div>
      </div>
    </div>
  )
}

export default StudentCardView
