import {PaginatedResponseType} from '$src/pagination/types'
import {Lang} from '$src/i18n/types'
import {multiReduceState} from '$common/utils'

type ByKey = {
  byKey: {[key: string]: {id: string}}
  queries: {[key: string]: string[]}
}

type Params = {page: number; size: number; languageCode: Lang}

function* paginateEntities(
  response: PaginatedResponseType,
  {page, ...filters}: Params,
  entityKey: string,
  idKey = 'id'
) {
  const stringifiedParams = JSON.stringify({page, ...filters}) // TODO: This doesn't probably work always as the order of properties is not guaranteed. Need to give array of object keys for JSON.stringify to determine order
  const resultByKeys: ByKey = response.data.reduce(
    ({byKey, queries}: ByKey, entity) => {
      const existingKeysByQuery = queries[stringifiedParams]

      return {
        byKey: {
          ...byKey,
          [entity[idKey]]: entity
        },
        queries: {[stringifiedParams]: [...existingKeysByQuery, entity[idKey]]}
      }
    },
    {byKey: {}, queries: {[stringifiedParams]: []}}
  )

  yield multiReduceState([
    {
      path: [entityKey, filters.languageCode, 'pagination'],
      reducer: (state) => ({
        ...state,
        [JSON.stringify(filters)]: response.meta.pagination
      })
    },
    {
      path: [entityKey, filters.languageCode, 'queries'],
      reducer: (state) => ({...state, ...resultByKeys.queries})
    },
    {
      path: [entityKey, filters.languageCode, 'byKey'],
      reducer: (state) => ({...state, ...resultByKeys.byKey})
    }
  ])

  return resultByKeys
}

export default paginateEntities
