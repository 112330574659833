import React, {Component} from 'react'
import {compose} from 'redux'
import _ from 'lodash'
import {Trans} from 'react-i18next'

import {connect} from '$common/utils'
import amplitude from '$src/analytics/amplitude'
import Features from '$src/ordercard/components/Features/Features-container'
import FeaturesMobile from '$src/ordercard/components/FeaturesMobile/FeaturesMobile-container'
import Footer from '$common/components/Footer'
import GenericButton from '$common/components/buttons/GenericButton'
import GenericModal from '$common/components/modals/GenericModal'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import Header from '$common/components/Header/Header-container'
import Layout from '$common/components/layouts/TopMiddleBottomLayout'
import MediaQuery from 'react-responsive'
import align from '$common/grid/align.css'
import css from './index.css'
import config from '$src/config'
import grid from '$common/grid/grid.css'
import {StoreState} from '$src/types'
import {getProfile as profileSelector} from '$src/profile/selectors'
import getPricing from '$src/pricing/sagas/getPricing'

import {
  getStudentType,
  isUserActive,
  getVerificationState
} from '$src/profile/selectors'
import {
  resolveCss,
  isMobile,
  withRouter,
  withRunSaga,
  withTranslate,
  WithRunSagaProps,
  WithRouterProps,
  WithTranslateProps
} from '$common/utils'
import {Profile, StudentTypes} from '$src/profile/types'
import {getAppboy} from '$common/pgWebSeparatorUtils'
import {isFetching} from '$src/fetching/selectors'
import LoadingSpinnerModal from '$src/common/components/LoadingSpinnerModal/index'
import {ProfileVerificationState} from '$src/profile/types'

type Props = WithRouterProps &
  WithRunSagaProps &
  WithTranslateProps & {
    studentType: StudentTypes
    isUserActive: boolean
    verificationState: ProfileVerificationState
    profile: Profile
    isFetchingProfile: boolean
    subs: any
  }

type State = {
  blockedOnMobileModal: boolean
  schoolBlockedModal: boolean
  noSsnModal: boolean
  fakeSsnModal: boolean
  userInactiveModal: boolean
  notAMemberModal: boolean
}

const ORGANIZATIONS_WITHOUT_PHYSICAL_CARD_OPTION =
  config.organizationsWithoutPhysicalCardOption
const IDENTITY_CODES = {
  NO_SSN: 0,
  FAKE_SSN: 1,
  REAL_SSN: 2
}

const classes = resolveCss(css, grid, align)

class OrderCardPage extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      blockedOnMobileModal: false,
      schoolBlockedModal: false,
      noSsnModal: false,
      fakeSsnModal: false,
      userInactiveModal: false,
      notAMemberModal: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    if (!this.props.pricing.loaded && !this.props.isFetchingPricing) {
      this.props.runSaga(getPricing)
    }
  }

  toggleBlockedModal = () =>
    this.setState({blockedOnMobileModal: !this.state.blockedOnMobileModal})
  toggleNoSsnModal = () => this.setState({noSsnModal: !this.state.noSsnModal})
  toggleFakeSsnModal = () =>
    this.setState({fakeSsnModal: !this.state.fakeSsnModal})
  toggleUserInactiveModal = () => {
    this.setState({userInactiveModal: !this.state.userInactiveModal})
  }
  toggleSchoolBlockedModal = (value) =>
    this.setState({
      schoolBlockedModal:
        typeof value === 'boolean' ? value : !this.state.schoolBlockedModal
    })
  toggleNotAMemberModal = () =>
    this.setState({notAMemberModal: !this.state.notAMemberModal})

  isPhysicalCard = (link) => {
    return /(plain-card)|(plastic-student-card)|(mastercard)/.test(link)
  }

  handleFeatureCTA = ({link, blockOnMobile, name}) => () => {
    /*
     * Blocking conditions are in precedence order:
     *   0. Not logged in -> let order routes handle
     *   1. Unverified student -> let order routes handle
     *   2. Student not present -> show dialog
     *   3. Degree is upper secondary AND card order is other than digi -> let order routes handle
     *   4. Koski-verified student can't order physical card -> show dialog
     *   5. Organization doesn't allow physical card AND card order is other than digi -> show dialog
     *   6. Fake SSN AND card order is danske -> show dialog
     *   7. No SSN AND card order is danske -> show dialog
     *   8. No photo -> let order routes handle
     *   9. On mobile AND card order is danske -> show dialog
     */

    const {profile, isUserActive, verificationState} = this.props

    // 0. Not logged in
    // 1. Unverified student
    if (!profile.loaded || profile.verificationState === 'UNVERIFIED') {
      this.props.history.push(link) // Order routes handle these cases
    }

    // 2. Student not present
    if (!isUserActive) {
      return this.toggleUserInactiveModal()
    }

    // 3. Degree is upper secondary. This case is handled in card order routes.

    // 4. The user is not a member of a student organisation and thus can't buy a physical card.
    if (this.isPhysicalCard(link) && !verificationState.includes('JOLLA')) {
      return this.toggleNotAMemberModal()
    }

    // 5. Organization doesn't allow physical card
    const organizationId = _.get(
      profile,
      'organization.id',
      'just-a-null-school-not-in-the-excluded-school-list'
    )
    if (
      ORGANIZATIONS_WITHOUT_PHYSICAL_CARD_OPTION.includes(organizationId) &&
      this.isPhysicalCard(link)
    ) {
      return this.toggleSchoolBlockedModal(true)
    }

    // 6. Fake SSN and danske order
    if (
      /mastercard/.test(link) &&
      profile.identityCode === IDENTITY_CODES.FAKE_SSN
    ) {
      return this.toggleFakeSsnModal()
    }

    // 7. No SSN and danske order
    if (
      /mastercard/.test(link) &&
      profile.identityCode === IDENTITY_CODES.NO_SSN
    ) {
      return this.toggleNoSsnModal()
    }

    // 8. No photo. This case is handled in card order routes.

    // 9. On mobile and danske order (blockOnMobile implies danske order)
    if (blockOnMobile && isMobile()) {
      amplitude.logEvent('attemptFeatureOnMobile', {featureLink: link})
      getAppboy().logCustomEvent('Attempted feature on mobile', {
        featureLink: link
      })
      return this.toggleBlockedModal()
    }

    // OK. Let's order
    this.props.history.push(link)
  }

  onUpdateEmptySsnProfile = () => {
    this.toggleNoSsnModal()
    this.props.history.push('/profile/settings')
  }

  onUpdateInactiveProfile = () => {
    this.toggleUserInactiveModal()
    this.props.history.push('/profile/settings')
  }

  onDownloadApp = () => {
    this.toggleFakeSsnModal()
    window.open('https://frank.app.link/e1W1VlGiFy', '_blank')
  }

  SchoolBlockedModal = (
    <GenericModal
      onHide={this.toggleSchoolBlockedModal}
      middle={
        <div {...classes('modalContainer')}>
          <H1 css={{container: css.modalHeader}}>
            {this.props.t('subscriptions.schoolBlockedModal.title')}
          </H1>
          <P css={{container: css.modalText}}>
            {this.props.t('subscriptions.schoolBlockedModal.body')}
          </P>
          <GenericButton
            onClick={this.toggleSchoolBlockedModal}
            style="bold"
            isFullWidth
          >
            {this.props.t('common.close')}
          </GenericButton>
        </div>
      }
    />
  )

  BlockedOnMobileModal = (
    <GenericModal
      onHide={this.toggleBlockedModal}
      middle={
        <div {...classes('modalContainer')}>
          <H1 css={{container: css.modalHeader}}>
            {this.props.t('subscriptions.danskePurchase.blockedModal.title')}
          </H1>
          <P css={{container: css.modalText}}>
            {this.props.t('subscriptions.danskePurchase.blockedModal.body')}
          </P>
          <GenericButton
            onClick={this.toggleBlockedModal}
            style="bold"
            isFullWidth
          >
            {this.props.t('common.close')}
          </GenericButton>
        </div>
      }
    />
  )

  NoSsnModal = (
    <GenericModal
      onHide={this.toggleNoSsnModal}
      middle={
        <div {...classes('modalContainer')}>
          <H1 css={{container: css.modalHeader}}>
            {this.props.t('subscriptions.danskePurchase.start.title')}
          </H1>
          <P css={{container: css.modalText}}>
            {this.props.t('subscriptions.danskePurchase.noSSN.body')}
          </P>
          <P css={{container: css.modalButtonsContainer}}>
            <GenericButton onClick={this.toggleNoSsnModal} style="outline">
              {this.props.t('common.close')}
            </GenericButton>
            <GenericButton onClick={this.onUpdateEmptySsnProfile} style="bold">
              {this.props.t('subscriptions.danskePurchase.noSSN.update')}
            </GenericButton>
          </P>
        </div>
      }
    />
  )

  FakeSsnModal = (
    <GenericModal
      onHide={this.toggleFakeSsnModal}
      middle={
        <div {...classes('modalContainer')}>
          <H1 css={{container: css.modalHeader}}>
            {this.props.t('subscriptions.danskePurchase.start.title')}
          </H1>
          <P css={{container: css.modalText}}>
            {this.props.t('subscriptions.danskePurchase.fakeSSN.body')}
          </P>
          <P css={{container: css.modalButtonsContainer}}>
            <GenericButton onClick={this.toggleFakeSsnModal} style="outline">
              {this.props.t('common.close')}
            </GenericButton>
            <GenericButton
              onClick={this.onDownloadApp}
              style="bold"
              externalLink
            >
              {this.props.t('subscriptions.danskePurchase.fakeSSN.downloadApp')}
            </GenericButton>
          </P>
        </div>
      }
    />
  )

  UserInactiveModal = (
    <GenericModal
      onHide={this.toggleUserInactiveModal}
      middle={
        <div {...classes('modalContainer')}>
          <H1 css={{container: css.modalHeader}}>
            {this.props.t('subscriptions.cardPurchase.userInactiveModal.title')}
          </H1>
          <P css={{container: css.modalText}}>
            {this.props.t('subscriptions.cardPurchase.userInactiveModal.body')}
          </P>
          <P css={{container: css.modalButtonsContainer}}>
            <GenericButton
              onClick={this.toggleUserInactiveModal}
              style="outline"
            >
              {this.props.t('common.close')}
            </GenericButton>
            <GenericButton onClick={this.onUpdateInactiveProfile} style="bold">
              {this.props.t(
                'subscriptions.cardPurchase.userInactiveModal.update'
              )}
            </GenericButton>
          </P>
        </div>
      }
    />
  )

  NotAMemberModal = (
    <GenericModal
      onHide={this.toggleNotAMemberModal}
      middle={
        <div {...classes('modalContainer')}>
          <H1 css={{container: css.modalHeader}}>
            {this.props.t(
              'subscriptions.cardPurchase.userNotAMemberModal.title'
            )}
          </H1>
          <P css={{container: css.modalText}}>
            {this.props.t(
              'subscriptions.cardPurchase.userNotAMemberModal.body'
            )}
          </P>
          <P css={{container: css.modalButtonsContainer}}>
            <GenericButton onClick={this.toggleNotAMemberModal} style="outline">
              {this.props.t('common.close')}
            </GenericButton>
          </P>
        </div>
      }
    />
  )

  render() {
    if (this.props.isFetchingProfile || this.props.isFetchingPricing) {
      return <LoadingSpinnerModal />
    }

    return (
      <Layout
        noPadding={true}
        css={{
          middle: css.middle
        }}
        topStyle="sticky"
        top={<Header />}
        middle={
          <div {...classes('container')}>
            <MediaQuery maxWidth={800}>
              {(isMobile) =>
                isMobile ? (
                  <FeaturesMobile
                    handleFeatureCTA={this.handleFeatureCTA}
                    subs={this.props.subs}
                  />
                ) : (
                  <Features
                    handleFeatureCTA={this.handleFeatureCTA}
                    studentType={this.props.studentType}
                  />
                )
              }
            </MediaQuery>

            {this.state.blockedOnMobileModal && this.BlockedOnMobileModal}
            {this.state.schoolBlockedModal && this.SchoolBlockedModal}
            {this.state.noSsnModal && this.NoSsnModal}
            {this.state.fakeSsnModal && this.FakeSsnModal}
            {this.state.userInactiveModal && this.UserInactiveModal}
            {this.state.notAMemberModal && this.NotAMemberModal}
          </div>
        }
        bottom={<Footer />}
      />
    )
  }
}

export default compose(
  connect((state: StoreState) => ({
    profile: profileSelector(state),
    studentType: getStudentType(state),
    subs: state.subscriptions,
    pricing: state.pricing,
    isUserActive: isUserActive(state),
    isFetchingProfile: isFetching(state, 'getProfile'),
    isFetchingPricing: isFetching(state, 'getPricing'),
    verificationState: getVerificationState(state)
  })),
  withRouter,
  withTranslate(),
  withRunSaga()
)(OrderCardPage)
