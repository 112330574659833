import * as React from 'react'
import css from './GenericButton.css'
import {resolveCss} from '$common/utils'
import noop from 'lodash/noop'

import SvgIcon from '$common/components/icons/SvgIcon'
import externalLinkIcon from '$assets/icons/externalLink.svg'

type Props = {
  style: 'outline' | 'bold' | 'subtle' | 'blackoutline'
  size?: 'normal' | 'small'
  hasTopMargin?: boolean
  surroundMargin?: boolean
  loading?: boolean
  disabled?: boolean
  children?: React.Node
  icon?: React.Node
  css?: any
  onClick?: Function
  isExpanding?: boolean
  isFullWidth?: boolean
  externalLink?: boolean
}

// eslint-disable-next-line react/display-name
const GenericButton = React.forwardRef(
  (
    {
      style,
      icon,
      externalLink,
      children,
      css: customCss,
      disabled,
      loading,
      hasTopMargin,
      onClick,
      surroundMargin,
      isExpanding,
      isFullWidth,
      size,
      ...rest
    }: Props,
    ref
  ) => {
    const classes = resolveCss(css, customCss)

    return (
      <button
        ref={ref}
        disabled={disabled}
        {...classes(
          'container',
          style,
          !!hasTopMargin && 'hasTopMargin',
          loading === true && 'loading',
          disabled === true && 'disabled',
          surroundMargin === true && 'surroundMargin',
          isExpanding && 'expand',
          isFullWidth && 'full',
          externalLink && 'externalLink',
          size === 'small' && 'small'
        )}
        onClick={disabled === true ? noop : onClick}
        {...rest}
      >
        {children}
        {externalLink && (
          <SvgIcon
            css={{container: css.externalLinkIcon}}
            src={externalLinkIcon}
            style={{width: '16px', height: '14px'}}
          />
        )}
        {icon && <div className={css.iconWrapper}>{icon}</div>}
      </button>
    )
  }
)

export default GenericButton
