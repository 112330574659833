import React, {useEffect} from 'react'
import {Route} from 'react-router-dom'
import {compose} from 'redux'
import _ from 'lodash'
import {connect} from '$common/utils'

import LocationsModal from '$src/offers/components/LocationsModal'
import LoadingSpinnerModal from '$common/components/LoadingSpinnerModal'
import HeroLayout from '$common/components/layouts/HeroLayout'
import fetchOffer from '$src/offers/sagas/fetchOffer'
import {getOffer, getFavourites} from '$src/offers/selectors'
import {Offer} from '$src/offers/types'
import {isUserVerified, isUserActive, isUserAlumn, userId} from '$src/profile/selectors'
import {getPreferredLang} from '$src/i18n/selectors'
import {isFetching} from '$src/fetching/selectors'
import {StoreState} from '$src/types'
import {
  WithRouterProps,
  WithTranslateProps,
  withRouter,
  withRunSaga,
  withTranslate,
  isDeviceOnline
} from '$common/utils'
import {
  ANALYTICS_EVENT as evt,
  ANALYTICS_PROVIDER as provider,
  logEvent2 as log
} from '$common/utils/logUtils'
import HomePageContainer from '$src/home/components/HomePage/HomePage-container'

type Props = WithRouterProps &
  WithTranslateProps & {
    css?: any
    isFetching: boolean
    runSaga: RunSagaFn
    offer?: Offer
    offerId: string
    isUserVerified: boolean
    isUserActive: boolean
    isUserAlumn: boolean
    isUserLoggedIn: boolean
    languageCode: 'en' | 'sv' | 'fi'
  }

const OfferPage = ({
  offer,
  history,
  isUserVerified,
  isUserActive,
  isUserAlumn,
  userId,
  isFetching,
  offerId,
  favourites,
  ...props
}) => {
  // init the offer page data
  useEffect(() => {
    window.scrollTo(0, 0)
    fetchOfferOnInitialRender(props)
  }, [offerId])

  // once offer data is available, send the custom events and do other fun things that is dependent on the data
  useEffect(() => {
    if (!offer) return

    setDocumentTitle(offer)

    if (isUserVerified) {
      log(
        evt.OFFER_OPEN,
        [provider.AMPLITUDE, provider.BRAZE],
        getParams(offer)
      )
    } else {
      log(evt.OFFER_OPEN_UNVERIFIED, [provider.AMPLITUDE], getParams(offer))
    }
    if (favourites[offer.key]) {
      log(
        evt.OPENED_FAVOURITE_OFFER,
        [provider.AMPLITUDE, provider.BRAZE],
        getParams(offer)
      )
    }
  }, [offer])

  if (props.asyncErrors.length === 0) {
    return ( isFetching ? (
      <LoadingSpinnerModal />
    ) : (
      <div>
        <Route
          path={`${props.match.path}/locations`}
          component={() => (
            <LocationsModal
              hide={() => history.push(`/offer/${offerId}`)}
              item={offer}
            />
          )}
        />
        <HeroLayout
          item={offer}
          isUserVerified={isUserVerified}
          isUserActive={isUserActive}
          isUserAlumn={isUserAlumn}
          userId={userId}
          parentRoute="offers"
        />
      </div>
    ))
  } else return <HomePageContainer />
  
}

export const getParams = (offer) => {
  const params = _.pick(offer, ['key', 'advertiser'])

  if (!_.isEmpty(offer.categories)) {
    params.categories = offer.categories.join(',')
  }
  return params
}

const fetchOfferOnInitialRender = async (props: Props) => {
  if (!isDeviceOnline()) return
  try {
    const offerId = _.get(props, ['match', 'params', 'key'])
    await props.runSaga(fetchOffer, offerId)
  } catch (error) {
    /*
    if (error.status === 404) {
      props.history.replace('/offers')
    }
    */
  }
}

export default compose(
  connect((state: StoreState, props: WithRouterProps) => {
    const offerId = _.get(props, ['match', 'params', 'key']) || 'not_defined'
    return {
      offerId,
      isFetching: isFetching(state, 'singleOffer'),
      languageCode: getPreferredLang(state),
      isUserVerified: isUserVerified(state),
      isUserActive: isUserActive(state),
      isUserAlumn: isUserAlumn(state),
      userId: userId(state),
      offer: getOffer(state, offerId),
      favourites: getFavourites(state),
      asyncErrors: state.asyncErrors
    }
  }),
  withRunSaga(),
  withRouter,
  withTranslate()
)(OfferPage)

const setDocumentTitle = (offer) => {
  if (offer) {
    document.title = `Frank | ${offer.advertiser} | ${offer.shortDescription}`
  }
}
