export type NotificationNamespaceState = {
  message?: string
  messageKey?: string
}

const defaultState: NotificationNamespaceState = {
  message: null,
  messageKey: null
}

export const SET_NOTIFICATION = 'SET_NOTIFICATION'

export default (
  state: NotificationNamespaceState = defaultState,
  action: object
) => {
  if (action.type === SET_NOTIFICATION) {
    return {...state, ...action.payload}
  }
  return state
}
