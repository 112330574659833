import {LanguageOption} from '$src/i18n/types'

const languageOptions: Array<LanguageOption> = [
  {long: 'Finnish', short: 'fi'},
  {long: 'Swedish', short: 'sv'},
  {long: 'English', short: 'en'}
]

export default {
  languageOptions,
  organizationsWithoutPhysicalCardOption: [
    '02504', // Jyväskylän AMK
    '02536' // Centria AMK
    // '01901', // for dev: Helsingin Yliopisto
  ]
}
