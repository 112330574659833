import {
  ANALYTICS_EVENT as evt,
  ANALYTICS_PROVIDER as provider,
  logEvent2 as log
} from '$common/utils/logUtils'

//max time to wait for rating dialog to display on iOS
const MAX_DIALOG_WAIT_TIME_IOS = 5 * 1000
//max time to wait for rating dialog to display on Android
const MAX_DIALOG_WAIT_TIME_ANDROID = 60 * 1000

let ratingTimerId

export const launchRating = () => {
  if (cordova.platformId === 'android') {
    ratingTimerId = setTimeout(
      () =>
        log(evt.RATING_REQUEST_FAILED, [provider.AMPLITUDE, provider.BRAZE], {
          platform: 'android'
        }),
      MAX_DIALOG_WAIT_TIME_ANDROID
    )
  }

  LaunchReview.rating(
    (status) => {
      if (status === 'requested') {
        if (cordova.platformId === 'android') {
          log(
            evt.RATING_REQUEST_TRIGGERED,
            [provider.AMPLITUDE, provider.BRAZE],
            {platform: 'android'}
          )
          clearTimeout(ratingTimerId)
        } else if (cordova.platformId === 'ios') {
          log(
            evt.RATING_REQUEST_TRIGGERED,
            [provider.AMPLITUDE, provider.BRAZE],
            {platform: 'ios'}
          )
          ratingTimerId = setTimeout(
            () =>
              log(
                evt.RATING_REQUEST_FAILED,
                [provider.AMPLITUDE, provider.BRAZE],
                {platform: 'ios'}
              ),
            MAX_DIALOG_WAIT_TIME_IOS
          )
        }
      } else if (status === 'shown') {
        log(evt.RATING_DIALOG_SHOWN_IOS, [provider.AMPLITUDE, provider.BRAZE], {
          platform: 'ios'
        })
        clearTimeout(ratingTimerId)
      } else if (status === 'dismissed') {
        log(
          evt.RATING_DIALOG_DISMISSED_IOS,
          [provider.AMPLITUDE, provider.BRAZE],
          {
            platform: 'ios'
          }
        )
        clearTimeout(ratingTimerId)
      }
    },
    (err) => {
      clearTimeout(ratingTimerId)
    }
  )
}
