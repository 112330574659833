import {
  default as getRandomOffer,
  Params as FindEndpointParams
} from '$src/api/endpoints/offers/getRandomOffer'
import {callEndpoint} from '$src/api/sagas/callEndpoint'
import {reduceState} from '$common/utils'
import {select} from 'redux-saga/effects'
import {StoreState} from '$src/types'
import {Offer} from '$src/offers/types'
import {getPreferredLang} from '$src/i18n/selectors'

export type Params = FindEndpointParams & {dontOverrideCache?: boolean}

export default function* fetchRandomOffer(key: string): Generator<> {
  const state: StoreState = yield select()
  const languageCode = getPreferredLang(state)
  const response: Offer = yield callEndpoint('singleOffer')(getRandomOffer, {
    languageCode
  })
  yield reduceState(['randomOffer'], (state) => {
    return {...state, response}
  })
}
