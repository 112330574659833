import MediaQuery from 'react-responsive'
import * as React from 'react'
import isEmpty from 'lodash/isEmpty'
import {Interpolate} from 'react-i18next'
import {Link} from 'react-router-dom'
import {SubmissionError, FormProps} from 'redux-form'
import Auth from '@aws-amplify/auth'
import uuidv4 from 'uuid/v4'

import LoadingSpinnerModal from '$common/components/LoadingSpinnerModal'
import CheckBoxField from '$common/components/forms/CheckBoxField'
import ErrorCandy from '$common/components/candyBars/ErrorCandy'
import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import Layout from '$common/components/layouts/TopMiddleBottomLayout'
import Line from '$common/components/Divider'
import OnboardingHeader from '$common/components/OnboardingHeader'
import P from '$common/components/P'
import PasswordInput from '$common/components/forms/PasswordInput'
import TextInput from '$common/components/forms/TextInput'
import align from '$common/grid/align.css'
import css from './SignUpPage.css'
import grid from '$common/grid/grid.css'
import {isCurrentPage} from '$src/common/pgWebSeparatorUtils'
import {
  createApiErrorMessage,
  resolveCss,
  WithRouterProps,
  WithRunSagaProps,
  WithTranslateProps
} from '$common/utils'
import confirmSignup from '$src/auth/sagas/confirmSignup'
import setNotification from '$src/notification/sagas/setNotification'

type Props = WithRouterProps &
  WithRunSagaProps &
  WithTranslateProps &
  FormProps & {
    language: string
  }

const SIGN_UP_STEP = {
  BASIC_INFO: 1,
  VERIFY_EMAIL: 2
}
export class SignupPage extends React.Component<Props> {
  state = {
    step: SIGN_UP_STEP.BASIC_INFO,
    userId: uuidv4()
  }

  submit = async (values) => {
    try {
      if (this.state.step === SIGN_UP_STEP.BASIC_INFO) {
        await this.handleBasicInfoSubmit(values)
      } else {
        await this.handleVerifyEmailSubmit(values)
      }
    } catch (error) {
      const errorMessage = createApiErrorMessage(error, 'error.register')
      throw new SubmissionError({error: this.props.t(errorMessage)})
    }
  }

  handleBasicInfoSubmit = async ({password, email}) => {
    try {
      await Auth.signUp({
        username: this.state.userId,
        password,
        attributes: {
          email: email.toLowerCase()
        }
      })
      this.setState({step: SIGN_UP_STEP.VERIFY_EMAIL})
    } catch (error) {
      if (error.code === 'UserLambdaValidationException') {
        error.status = 409
      }
      throw error
    }
  }

  handleVerifyEmailSubmit = async (values) => {
    const {email, password, verifyCode} = values
    try {
      await this.props.runSaga(confirmSignup, {
        userId: this.state.userId,
        email: email.toLowerCase(),
        password,
        language: this.props.language,
        verifyCode
      })
    } catch (err) {
      if (
        ['CodeMismatchException', 'ExpiredCodeException'].includes(err.code)
      ) {
        return this.props.runSaga(setNotification, {
          message: this.props.t(`error.cognito.${err.code}`)
        })
      } else {
        throw err
      }
    }

    // if trialPath is true, we take the user to OP verification page, and if successfull, we start a trial
    if (this.props.trialPath) {
      this.props.history.replace('/profile/trial/start')
    } else {
      // set route to next step in the registration funnel: JOLLA / OP Account verification,
      this.props.history.replace('/profile/verify/start')
    }
  }

  isInputMismatch = (first: string, second: string) => {
    return (
      first &&
      first.length > 0 &&
      second &&
      second.length > 0 &&
      first !== second
    )
  }

  render() {
    if (!isCurrentPage(this.props)) {
      return null
    }
    const classes = resolveCss(css, grid, align)

    const ctaButton = (
      <GenericButton
        key="cta"
        style="bold"
        type="submit"
        loading={this.props.submitting}
        disabled={this.props.invalid || this.props.submitting}
      >
        {this.state.step === SIGN_UP_STEP.BASIC_INFO
          ? this.props.t('authentication.register.cta')
          : this.props.t('authentication.verifyEmail.cta')}
      </GenericButton>
    )

    const secondaryCtaButton =
      this.state.step === SIGN_UP_STEP.BASIC_INFO ? (
        <Link to="/">
          <GenericButton type="button" style="outline">
            {this.props.t('authentication.register.secondaryCta')}
          </GenericButton>
        </Link>
      ) : (
        <GenericButton
          type="button"
          style="outline"
          onClick={() =>
            this.setState({
              step: SIGN_UP_STEP.BASIC_INFO,
              userId: uuidv4()
            })
          }
        >
          {this.props.t('authentication.register.secondaryCta')}
        </GenericButton>
      )

    return (
      <form onSubmit={this.props.handleSubmit(this.submit)}>
        {this.props.submitting && (
          <LoadingSpinnerModal
            message={this.props.t('authentication.register.registering')}
          />
        )}
        <Layout
          adaptiveLayout={{
            stackMiddle: true
          }}
          css={{
            container: grid.container,
            bottom: css.bottom
          }}
          topStyle="autoHeight"
          top={<OnboardingHeader />}
          middle={
            <div {...classes('columnLarge6', 'center')}>
              <MediaQuery maxWidth={800}>
                <FrankLogoInSmallWhite />
              </MediaQuery>
              <SignUpFormBasicInfoPart
                t={this.props.t}
                visible={this.state.step === SIGN_UP_STEP.BASIC_INFO}
              />
              <SignUpFormVerifyEmailPart
                t={this.props.t}
                visible={this.state.step === SIGN_UP_STEP.VERIFY_EMAIL}
              />
              <ErrorCandy
                show={!isEmpty(this.props.submitErrors)}
                onHide={this.props.initWithValues}
              >
                {this.props.submitErrors && this.props.submitErrors.error}
              </ErrorCandy>
              <MediaQuery minWidth={801}>
                <Line />
              </MediaQuery>
            </div>
          }
          bottom={
            <div {...classes('columnLarge6', 'center')}>
              <MediaQuery maxWidth={800}>
                {(isMobile) => {
                  if (isMobile) {
                    return ctaButton
                  } else {
                    return (
                      <div className={grid.row}>
                        <div {...classes('column', 'right')}>
                          {ctaButton}
                          &nbsp;
                          {secondaryCtaButton}
                        </div>
                      </div>
                    )
                  }
                }}
              </MediaQuery>
            </div>
          }
        />
      </form>
    )
  }
}

const SignUpFormBasicInfoPart = ({visible, t}) => {
  const terms = (
    <a
      href={t('authentication.register.personalDataFileDescriptionLink')}
      target="_blank"
      rel="noopener noreferrer"
    >
      {t('authentication.register.terms')}
    </a>
  )
  return (
    <div className={visible ? null : css.hidden}>
      <H1 css={{container: css.h1}}>{t('authentication.register.title')}</H1>
      <P style="subtle" css={{container: css.p}}>
        {t('authentication.register.body')}
      </P>
      <TextInput
        name="email"
        type="email"
        autocomplete="username"
        placeholder={t('common.emailPlaceholder')}
        label={t('common.emailAddress')}
      />
      <PasswordInput
        name="password"
        autocomplete="new-password"
        placeholder={t('common.passwordPlaceholder')}
        label={t('authentication.password')}
      />
      <P style="subtle" css={{container: css.p}}>
        {t('authentication.signUp.gdpr')}
      </P>
      <P style="subtle" css={{container: css.p}}>
        <a
          href={t('authentication.signUp.gdprLink')}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('authentication.signUp.gdprLinkLabel')}
        </a>
      </P>
      <CheckBoxField
        css={{container: css.checkboxContainer}}
        name="hasAccepted"
        label={
          <P css={{container: css.checkboxLabel}}>
            <Interpolate
              i18nKey="authentication.register.termsOfService"
              terms={terms}
            />
          </P>
        }
        disableToggleFromLabel={true}
      />
      <P style="subtle" css={{container: css.p}}>
        <a
          href={t('authentication.register.personalDataFileDescriptionLink')}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('authentication.register.personalDataFileDescription')}
        </a>
      </P>
    </div>
  )
}

const SignUpFormVerifyEmailPart = ({visible, t}) => {
  return (
    <div className={visible ? null : css.hidden}>
      <H1 css={{container: css.h1}}>{t('authentication.verifyEmail.title')}</H1>
      <P style="subtle" css={{container: css.p}}>
        {t('authentication.verifyEmail.body')}
      </P>
      <TextInput
        name="verifyCode"
        placeholder={t('authentication.signUp.verifyEmailPlaceholder')}
        label={t('authentication.signUp.verifyEmailLabel')}
      />
    </div>
  )
}
