import MediaQuery from 'react-responsive'
import * as React from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {Link} from 'react-router-dom'
import {getProfile} from '$src/profile/selectors'

import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import TopMiddleBottomLayout from '$common/components/layouts/TopMiddleBottomLayout'
import align from '$common/grid/align.css'
import css from './StartTrialPage.css'
import grid from '$common/grid/grid.css'
import {resolveCss, withTranslate} from '$common/utils'

class StartTrialSuccessPage extends React.Component {
  render() {
    const classes = resolveCss(css, grid, align)
    const {profile, t} = this.props

    let body, link

    switch (profile.school?.type){
      case 'high-school':
        body = t('profile.trial.success.SLL_body')
        link = (
          <>
            <a href={t('profile.trial.success.SLL_link')}>
              {t('profile.trial.success.SLL_link_text')}
            </a>
          </>
        )
        break
      case 'vocational-school':
        body = t('profile.trial.success.SAKKI_body')
        link = (
          <>
            <a href={t('profile.trial.success.SAKKI_link')}>
              {t('profile.trial.success.SAKKI_link_text')}
            </a>
          </>
        )
        break
      default:
        body = t('profile.trial.success.SLL_and_SAKKI_body')
        link = (
          <>
            <a href={t('profile.trial.success.SLL_link')}>
              {t('profile.trial.success.SLL_link_text')}
            </a>
            <br />
            <a href={t('profile.trial.success.SAKKI_link')}>
              {t('profile.trial.success.SAKKI_link_text')}
            </a>
          </>
        )
        break
    }

    return (
      <TopMiddleBottomLayout
        adaptiveLayout={{
          stackMiddle: true
        }}
        css={{
          container: grid.container,
          bottom: css.bottom
        }}
        top={
          <MediaQuery minWidth={801}>
            <FrankLogoInSmallWhite />
          </MediaQuery>
        }
        middle={
          <div {...classes('columnLarge5', 'center')}>
            <H1 css={{container: align.largeCenterText}}>
              {t('profile.trial.success.title')}
            </H1>
            <P
              style="small"
              css={{container: [css.message, align.largeCenterText]}}
            >
              {body}
            </P>
            <br />
            <P
              style="small"
              css={{container: [css.message, align.largeCenterText]}}
            >
              {link}
            </P>
            <br />
          </div>
        }
        bottom={
          <Link to="/" {...classes('column', 'center')}>
            <GenericButton
              style="bold"
              css={{container: css.button}}
              isExpanding={true}
            >
              {t('profile.trial.success.button')}
            </GenericButton>
          </Link>
        }
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state)
  }
}

export default compose(
  connect(mapStateToProps, null),
  withTranslate()
)(StartTrialSuccessPage)
