import React from 'react'

import rightArrow from '$assets/icons/arrowRight.svg'
import leftArrow from '$assets/icons/arrowLeft.svg'

import SvgIcon from '$common/components/icons/SvgIcon'
import {resolveCss} from '$common/utils'

import css from './Carousel.css'

type Props = {
  prevSlide?: Function
  nextSlide?: Function
  exists?: boolean
  tiny?: boolean
}

const Arrow = ({nextSlide, prevSlide, exists, tiny}: Props) => {
  const classes = resolveCss(css)
  return exists ? (
    <div
      onClick={nextSlide || prevSlide}
      {...classes(
        'arrowButton',
        nextSlide ? 'next' : 'prev',
        tiny ? 'tiny' : ''
      )}
    >
      {nextSlide ? <SvgIcon src={rightArrow} /> : <SvgIcon src={leftArrow} />}
    </div>
  ) : null
}

export default Arrow
