import * as React from 'react'
import {compose} from 'redux'
import {Link, WithRouterProps} from 'react-router-dom'

import GenericButton from '$common/components/buttons/GenericButton'
import GenericModal from './GenericModal'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import SvgIcon from '$common/components/icons/SvgIcon'
import align from '$common/grid/align.css'
import css from './ProfilePhotoRequiredPromptModal.css'
import frankLogoRedOutline from '$assets/icons/frankLogoRedOutline.svg'
import grid from '$common/grid/grid.css'
import {
  resolveCss,
  withRouter,
  withTranslate,
  WithTranslateProps
} from '$common/utils'
import {getHomePage} from '$src/common/pgWebSeparatorUtils'

type Props = WithRouterProps &
  WithTranslateProps & {
    onHide?: Function
  }

const ProfilePhotoRequiredPromptModal = (props: Props) => {
  const classes = resolveCss(css, grid, align)
  const addPhotoLink = props.match.params.photoPageLink
    ? decodeURIComponent(props.match.params.photoPageLink)
    : '/profile/photo'

  return (
    <GenericModal
      onHide={() => props.history.replace(getHomePage())}
      middle={
        <div {...classes('columnLarge5', 'center')}>
          <div {...classes('column', 'center')}>
            <SvgIcon
              key="logo"
              css={{container: css.icon}}
              src={frankLogoRedOutline}
              sizing="none"
              style={{padding: '20px 0 0 0', width: '143px', height: '120px'}}
            />
          </div>

          <H1 key="title">
            {props.t('profile.verification.promptAddPicture.title')}
          </H1>
          <P key="message" css={{container: css.message}}>
            {props.t('profile.verification.promptAddPicture.body')}
          </P>
          <div {...classes('column', 'center')}>
            <Link to={addPhotoLink}>
              <GenericButton
                key="action"
                css={{container: css.button}}
                style="bold"
              >
                {props.t('profile.verification.promptAddPicture.cta')}
              </GenericButton>
            </Link>
          </div>
        </div>
      }
    />
  )
}

export default compose(
  withRouter,
  withTranslate()
)(ProfilePhotoRequiredPromptModal)
