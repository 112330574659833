import {fork} from 'redux-saga/effects'

import callEndpoint from '$src/api/sagas/callEndpoint'
import getProfile from '$src/profile/sagas/getProfile'
import postPhotoEndpoint from '$src/api/endpoints/profile/postPhoto'
import {clearCache} from '$src/offline-studentcard/components/StudentCardView/PhotoCache'

export default function* postProfilePhoto(photo: string): Generator<> {
  yield callEndpoint(postPhotoEndpoint, {photo})
  yield fork(getProfile)
  clearCache()
}
