import moment from 'moment'

import {StudentTypes} from '$src/profile/types'
import {getPreferredLang} from '$src/i18n/selectors'
import {GRADUATE_STUDENT_CARD, POSTGRADUATE_STUDENT_CARD} from './types'

/**
 *  Different subscription-related selectors.
 *
 *   FIXME: A subscription in the state 'active' doesn't necessarily mean that the related card is valid and
 *   usable. It mostly corresponds to the state 'completed' in the database. E.g. a digital card which is too old to
 *   be valid is still 'active'. Naming in back-end should be clarified but it since it would break older mobile app
 *   versions, the change should be carefully timed.
 */

const ISIC_CARD_TYPE = 'isic_card'

const STUDENT_CARD_TYPE = 'student_card'

// A digicard that the student purchased. Can't be created at the moment but can still exist
const DIGITAL_STUDENT_CARD_TYPE = 'digital_student_card'

// Currently all users get a free digital card, unless a paid subscription still exists
const FREE_DIGICARD_TYPE = 'free'

const THREE_MONTHS = 7889238000

const isValidDate = (endIsoDate) => {
  const startOfToday = moment()
    .startOf('day')
    .toDate()
  const endDate = new Date(endIsoDate)
  return startOfToday <= endDate
}

export const isDanskeCustomer = (state) => {
  return (
    state.subscriptions.danskeDebitCard &&
    state.subscriptions.danskeDebitCard.existingCustomer
  )
}

export const getPreparedOrder = (state) => {
  const {existingCustomer, nationalOrInternational} =
    state.subscriptions.danskeDebitCard || {}
  const {studentType} = state.profile
  const preparedOrder = {
    existingCustomer,
    nationalOrInternational,
    studentType,
    language: getPreferredLang(state)
  }
  return {...preparedOrder}
}

const sortByCreatedAt = (a, b) => {
  if (moment(a.createdAt).isAfter(moment(b.createdAt))) {
    return -1
  }
  if (moment(a.createdAt).isBefore(moment(b.createdAt))) {
    return 1
  }
  return 0
}

const sortByValidTo = (a, b) => {
  if (moment(a.validTo).isAfter(moment(b.validTo))) {
    return -1
  }
  if (moment(a.validTo).isBefore(moment(b.validTo))) {
    return 1
  }
  return 0
}

export const getPendingDanskeBankSubscription = (state) => {
  return state.subscriptions.existing
    .sort(sortByCreatedAt)
    .find((f) => f.type === 'danskebank_mastercard' && f.status === 'pending')
}

export const getActiveNationalStudentCardSubscription = (
  state,
  studentType: StudentTypes
) => {
  return state.subscriptions.existing
    .sort(sortByCreatedAt)
    .find((subscription) => {
      let found = false
      if (
        subscription.status === 'active' &&
        subscription.type === STUDENT_CARD_TYPE
      ) {
        if (
          studentType === 'graduate' &&
          subscription.name === GRADUATE_STUDENT_CARD
        ) {
          found = true
        } else if (
          studentType === 'postgraduate' &&
          subscription.name === POSTGRADUATE_STUDENT_CARD
        ) {
          found = true
        }
      }
      return found
    })
}

export const hasActivePlainOrder = (state) => {
  return !!state.subscriptions.existing.find(
    ({status, type, createdAt, name}) => {
      if (
        type === ISIC_CARD_TYPE ||
        (type === STUDENT_CARD_TYPE &&
          !name.includes('danskebank') &&
          status === 'active')
      ) {
        if (createdAt) {
          return (
            THREE_MONTHS >= new Date().getTime() - new Date(createdAt).getTime()
          )
        } else {
          return true
        }
      }
    }
  )
}

const hasValidCardOfType = (state, cardType) => {
  return !!state.subscriptions.existing.find(({status, type, validTo}) => {
    return type === cardType && status === 'active' && isValidDate(validTo)
  })
}

export const hasActiveDigitalSubscription = (state) => {
  return (
    hasValidCardOfType(state, DIGITAL_STUDENT_CARD_TYPE) ||
    hasValidCardOfType(state, FREE_DIGICARD_TYPE)
  )
}

// get the isic subscription that was purchased most recently, and is active, has a valid date in the future.
export const getLatestActiveIsicSubscription = (state) => {
  return state.subscriptions.existing
    .sort(sortByValidTo)
    .find(({status, type, validTo}) => {
      return (
        status === 'active' && isValidDate(validTo) && type === ISIC_CARD_TYPE
      )
    })
}

export const hasActiveDanskeSubscription = (state) => {
  return state.subscriptions.existing.some(
    ({status, type}) => type === 'danskebank_mastercard' && status === 'active'
  )
}
