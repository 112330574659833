import React from 'react'

import {Offer} from '$src/offers/types'

import css from './index.css'
import grid from '$common/grid/grid.css'

import GenericModal from '$common/components/modals/GenericModal'
import GenericButton from '$common/components/buttons/GenericButton'
import {resolveCss, withTranslate, WithTranslateProps} from '$common/utils'
import LoadingSpinnerModal from '$common/components/LoadingSpinnerModal'

type Props = WithTranslateProps & {item: Offer; hide: Function}

const LocationsModal = ({item, hide, t}: Props) => {
  const classes = resolveCss(grid, css)

  if (!item) return <LoadingSpinnerModal />

  return (
    <GenericModal
      onHide={hide}
      closeCss={css.closeIcon}
      css={{container: css.modalContainer, middle: css.modalLayoutMiddle}}
      middle={
        <div {...classes('columnLarge7', 'contentContainer')}>
          <div {...classes('locationsHeading')}>
            <h2>{item.advertiser}</h2>
            <p>{t('offer.locations.title')}</p>
          </div>
          <ul {...classes('locationsList')}>
            {item.locations.map((location, index) => (
              <li key={index} {...classes('locationListItem')}>
                <div {...classes('advertiserInfoContainer')}>
                  <h3>{location.cityName}</h3>
                  <p>{`${location.streetAddress}, ${location.postalCode} ${location.cityName}`}</p>
                  <p>{location.phone}</p>
                </div>
                {location.latitude && location.longitude && (
                  <a
                    href={`http://www.google.com/maps/place/${location.latitude},${location.longitude}`}
                  >
                    <GenericButton
                      isFullWidth
                      externalLink
                      css={{container: css.button}}
                      style="bold"
                    >
                      {t('offer.location.showOnMap')}
                    </GenericButton>
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>
      }
    />
  )
}

export default withTranslate()(LocationsModal)
