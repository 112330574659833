import {ProfileNamespaceState} from './types'
import {
  TOGGLE_CHANGING_PHOTO,
  SET_TRIAL_PATH,
  SET_HAS_OPENED_TRIAl_ENDED
} from './actions'

const defaultState: ProfileNamespaceState = {
  loaded: false
}

export default (
  state: ProfileNamespaceState = defaultState,
  action: object
) => {
  if (action.type === TOGGLE_CHANGING_PHOTO) {
    return {...state, isPhotoChanging: action.payload}
  }
  _clearOldEducation(state)
  return state
}

const _clearOldEducation = (state: ProfileNamespaceState) => {
  if (state.verificationState === 'KOSKI') {
    delete state.organization
  }
  if (state.verificationState === 'JOLLA') {
    delete state.school
  }
}

// List of schools and Jolla organisations, used in verification flow drop-downs.
export const schoolsReducer = (state = null) => state

export const setTrialPath = (state = null, action: object) => {
  if (action.type === SET_TRIAL_PATH) {
    return action.payload
  }
  return state
}

export const setHasOpenedTrialEnded = (state = null, action) => {
  if (action.type === SET_HAS_OPENED_TRIAl_ENDED) {
    return action.payload
  }
  return state
}
