import React from 'react'
import {Article} from '$src/articles/types'
import {resolveCss} from '$common/utils'
import P from '$common/components/P'
import {withRouter, WithRouterProps} from '$common/utils'
import css from '$src/articles/components/Carousel/Carousel.css'
import {getPortraitImage} from '$src/articles/selectors'

import SlideLoading from '$common/components/loadings/CarouselSlideLoading'

type Props = {article: Article | {placeholder: true}} & WithRouterProps

const PortraitSlide = ({article, history}: Props) => {
  const classes = resolveCss(css)
  return article.placeholder ? (
    <SlideLoading
      overlayColor="red"
      css={{container: css.portraitSlide, animatedBarContainer: css.cell}}
    />
  ) : (
    <div
      {...classes('portraitSlide')}
      onClick={() => history.push(`/article/${article.id}`)}
    >
      <div {...classes('tintOverlay')} />
      <img src={getPortraitImage(article)} />
      <div {...classes('portraitOverlay', 'cell')}>
        <P css={{container: css.slideHeading}}>{article.title}</P>
      </div>
    </div>
  )
}

export default withRouter(PortraitSlide)
