import {Selector} from '$src/types'

export const getIsBootstrapped: Selector<boolean> = (state) =>
  state.common.temp.bootstrapped

export const selectArticleCarouselPage: Selector<number> = (state) =>
  state.common.ui.articleCarouselPage

export const selectMobileArticleCarouselPage: Selector<number> = (state) =>
  state.common.ui.mobileArticleCarouselPage

export const selectNewsCarouselPage: Selector<number> = (state) =>
  state.common.ui.newsCarouselPage
