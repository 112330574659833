import {select, put} from 'redux-saga/effects'
import {call} from 'redux-saga/effects'
import update from 'lodash/fp/update'
import {StoreState} from '$src/types'

type Path = Array<string | number>

type Pair = {
  path: Path
  reducer: (value: any) => any
}

export default function(pairs: Array<Pair>, providedState?: StoreState) {
  return call(_multiReduceState, pairs, providedState)
}

export function* _multiReduceState(
  pairs: Array<Pair>,
  providedState?: StoreState
) {
  const appState = yield select()
  const state: StoreState = providedState || appState

  const newState = pairs.reduce(
    (acc: StoreState, pair: Pair) => update(pair.path, pair.reducer, acc),
    state
  )

  yield put({type: '@@root/SET_STATE', payload: newState})
}
