import {connect} from '$common/utils/index'
import {compose} from 'redux'
import {getProfile} from '$src/profile/selectors'
import {withTranslate, withRouter, withRunSaga} from '$common/utils/index'
import StudentCardView from './StudentCardView'
import {StoreState} from '$src/types'

export default compose(
  connect((state: StoreState) => {
    return {
      profile: getProfile(state)
    }
  }),
  withRouter,
  withTranslate(),
  withRunSaga()
)(StudentCardView)
