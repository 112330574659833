import {put} from 'redux-saga/effects'

import {SET_NOTIFICATION} from '$src/notification/reducer'
import {NOTIFICATION_TYPE} from '$src/notification/components'

export default function* setNotification(notification: any): Generator<> {
  if (notification.type) {
    yield put({type: SET_NOTIFICATION, payload: notification})
  } else {
    yield put({
      type: SET_NOTIFICATION,
      payload: {...notification, type: NOTIFICATION_TYPE.ERROR}
    })
  }
}
