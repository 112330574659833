import * as React from 'react'
import {Link} from 'react-router-dom'

import P from '$common/components/P'
import SvgIcon from '$common/components/icons/SvgIcon'
import align from '$common/grid/align.css'
import ownCss from './LogoutHeader.css'
import defaultCss from './index.css'
import grid from '$common/grid/grid.css'
import logoSrc from '$assets/icons/frankLogoSolidWhite.svg'
import logoutSrc from '$assets/icons/icon-logout.svg'
import BurgerSvg from '$assets/icons/menu-burger-header.svg'
import HeaderTopFill from './HeaderTopFill'

import {
  resolveCss,
  WithRouterProps,
  WithTranslateProps,
  WithRunSagaProps,
  WithResponsiveInfoProps
} from '$common/utils'

import {ProfileNamespaceState} from '$src/profile/types'

import BackButton from '$common/components/buttons/BackButton'
import LogoutMenu from './LogoutMenu'

type Props = WithTranslateProps &
  WithRunSagaProps &
  WithResponsiveInfoProps &
  WithRouterProps & {
    css?: any
    profile: ProfileNamespaceState
    isSecondGradeStudent: boolean
    isVerified: boolean
  }

type State = {
  showLogoutMenu: boolean
}

class LogoutHeader extends React.Component<Props, State> {
  state = {showLogoutMenu: false}

  logout = (globalLogout = false) => {
    this.props.history.replace(`/logout?global=${globalLogout}`)
  }

  showMenuModal = () => this.setState({showLogoutMenu: true})
  hideMenuModal = () => this.setState({showLogoutMenu: false})

  render() {
    const classes = resolveCss(ownCss, defaultCss, grid, align)

    const backButton = <BackButton onClick="goBack" style="bold" />
    const burgerMenuIcon = window.cordova ? null : (
      <div {...classes('left')}>
        <Link to="/menu">
          <SvgIcon
            css={{container: defaultCss.iconMenu}}
            sizing="none"
            src={BurgerSvg}
          />
        </Link>
      </div>
    )

    const showBackButton =
      this.props.history.location.state &&
      this.props.history.location.state.from
    let topLeft
    if (this.props.isDesktop) {
      topLeft = (
        <Link to="/home">
          <SvgIcon
            css={{container: ownCss.iconLogo}}
            sizing="none"
            src={logoSrc}
          />
        </Link>
      )
    } else if (showBackButton) {
      topLeft = backButton
    } else if (window.cordova) {
      topLeft = backButton
    } else {
      topLeft = burgerMenuIcon
    }

    return (
      <header {...classes('wrapper')}>
        <HeaderTopFill />
        <div {...classes('container')}>
          <div {...classes('left')}>
            {topLeft}

            {this.props.isDesktop && (
              <div {...classes('linkWrapper')}>
                <Link to="/offers">
                  <div {...classes('linkContent')}>
                    {this.props.t('common.discounts')}
                  </div>
                </Link>
              </div>
            )}
            {this.props.isDesktop &&
              this.props.isVerified &&
              !this.props.isSecondGradeStudent && (
                <div {...classes('linkWrapper')}>
                  <Link to="/ordercard">
                    <div {...classes('linkContent')}>
                      {this.props.t('common.ordercard')}
                    </div>
                  </Link>
                </div>
              )}
          </div>

          <div {...classes('middle')}>
            {!this.props.isDesktop &&
              this.props.profile.loaded &&
              this.props.profile.firstName &&
              this.props.profile.lastName && (
                <P style="small">
                  {this.props.profile.firstName} {this.props.profile.lastName}
                </P>
              )}
          </div>

          <div {...classes('right')}>
            <div className={ownCss.iconWrapper}>
              <SvgIcon
                onClick={this.showMenuModal}
                css={{container: ownCss.logoutIcon}}
                sizing="none"
                src={logoutSrc}
              />
            </div>
            {this.state.showLogoutMenu && (
              <LogoutMenu onLogout={this.logout} onClose={this.hideMenuModal} />
            )}
          </div>
        </div>
      </header>
    )
  }
}

export default LogoutHeader
