import React, {Component} from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import Slider from 'react-slick'

import '!style-loader!css-loader!./slick-mobile-fix.nomod.css'
import withRunSaga, {WithRunSagaProps} from '$common/utils/withRunSaga'
import {fetchAllArticles} from '$src/articles/sagas/fetchArticles'
import {Article} from '$src/articles/types'
import ArticleCard from '$src/articles/components/Carousel/ArticleCard'
import {selectMobileArticleCarouselPage} from '$common/selectors'
import {getMobileArticles} from '$src/articles/selectors'
import {setMobileArticleCarouselPage} from '$common/sagas'

type Props = WithRunSagaProps & {
  articles: Article[]
  mobileArticleCarouselPage: number
}

class MobileCarousel extends Component<Props> {
  componentDidMount() {
    if (this.props.articles.length === 0) {
      this.props.runSaga(fetchAllArticles)
    }
  }

  renderMobileSlides() {
    let articles: Array<Article | {placeholder: boolean; id: string}> = this
      .props.articles

    if (articles.length === 0) {
      articles = [{placeholder: true, id: 'placeholder'}]
    }

    const slides = articles.map((article, i) => (
      <ArticleCard
        key={article.id}
        item={article}
        mobile={true}
        firstSlide={i === 0}
      />
    ))

    return slides
  }

  handleAfterChange = (page: number) => {
    this.props.runSaga(setMobileArticleCarouselPage, page)
  }

  render() {
    const {mobileArticleCarouselPage} = this.props

    return (
      <Slider
        arrows={false}
        adaptiveHeight={false}
        infinite={true}
        speed={200}
        slidesToShow={1}
        slidesToScroll={1}
        initialSlide={mobileArticleCarouselPage}
        afterChange={this.handleAfterChange}
        dots={true}
      >
        {this.renderMobileSlides()}
      </Slider>
    )
  }
}

export default compose(
  connect((state) => {
    return {
      articles: getMobileArticles(state),
      mobileArticleCarouselPage: selectMobileArticleCarouselPage(state)
    }
  }),
  withRunSaga()
)(MobileCarousel)
