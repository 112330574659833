import {SubscriptionsNamespaceState} from './types'

const defaultState: SubscriptionsNamespaceState = {
  existing: [],
  danskeDebitCard: {}
}

export default (
  state: SubscriptionsNamespaceState = defaultState
): SubscriptionsNamespaceState => state
