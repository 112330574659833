/*
 * Opens a new window (using in-app browser in cordova) to ask koski permission.
 *
 * Returns: a promise - resolved when window is either closed or is navigated back to frank
 */

import i18n from 'i18next'

export default async function koskiAuthorization() {
  if (window.cordova) {
    return _openCordovaKoskiAuthorization()
  } else {
    return _openWebKoskiAuthorization()
  }
}

function _openCordovaKoskiAuthorization() {
  const CALLBACK_URL = process.env.BASE_URL // Cannot redirect back to cordova app because file:/// protocol is not supported in redirects
  const KOSKI_AUTHORIZATION_URL = `${process.env.KOSKI_URL}?callback=${CALLBACK_URL}`
  const closeButtonText = i18n.t('profile.verification.koski.backToFrank')
  return new Promise((resolve) => {
    const koskiWindow = window.cordova.InAppBrowser.open(
      KOSKI_AUTHORIZATION_URL,
      '_blank',
      `closebuttoncaption=${closeButtonText},footer=yes,location=no,clearcache=yes,clearsessioncache=yes,toolbar=yes,disallowoverscroll=yes,enableViewportScale=yes,hidenavigationbuttons=no,hideurlbar=no,zoom=no,keyboardDisplayRequiresUserAction=yes`
    )
    koskiWindow.addEventListener('exit', resolve)
    koskiWindow.addEventListener('loadstart', (evt) => {
      if (evt.url.startsWith(CALLBACK_URL)) {
        koskiWindow && koskiWindow.close()
      }
    })
  })
}

function _openWebKoskiAuthorization() {
  const CALLBACK_URL = window.location.origin
  const KOSKI_AUTHORIZATION_URL = `${process.env.KOSKI_URL}?callback=${CALLBACK_URL}`
  return new Promise((resolve) => {
    const koskiWindow = window.open(
      KOSKI_AUTHORIZATION_URL,
      '_blank',
      'width=600,height=800'
    )
    const poller = setInterval(() => {
      if (!koskiWindow || koskiWindow.closed) {
        clearInterval(poller)
        resolve()
      }
      try {
        if (koskiWindow.location.href.startsWith(CALLBACK_URL)) {
          clearInterval(poller)
          koskiWindow && koskiWindow.close()
          resolve()
        }
      } catch (err) {
        /* Expected error when trying to get location.href from another origin */
      }
    }, 50)
  })
}
