import {compose} from 'redux'
import {reduxForm} from 'redux-form'
import {connect, withRunSaga, withRouter, withTranslate} from '$common/utils'
import pgProfilePhotoPageHOC from './PGProfilePhotoPage'
import webProfilePhotoPageHOC from './WebProfilePhotoPage'
import ProfilePhotoPage from './ProfilePhotoPage'
import {getProfile as profileSelector} from '$src/profile/selectors'
import {toggleChangingPhoto} from '$src/profile/actions'
import {StoreState} from '$src/types'

const withHOC = window.cordova ? pgProfilePhotoPageHOC : webProfilePhotoPageHOC

const mapStateToProps = (state: StoreState) => {
  const profile = profileSelector(state)
  return {
    photo: profile.loaded && profile.photo,
    isPhotoChanging: profile.loaded && profile.isPhotoChanging
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleChangingPhoto: (isChanging) =>
      dispatch(toggleChangingPhoto(isChanging))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRunSaga(),
  withRouter,
  reduxForm({form: 'profilePhotoForm'}),
  withTranslate(),
  withHOC
)(ProfilePhotoPage)
