import React from 'react'
import css from './LogoContainer.css'
import resolveCss from '$src/common/utils/resolveCss'

const padding = 20
const logoMargin = 5

function calculateLogoStyle(src, logoCount) {
  const pagePadding = padding * 2
  const elementWidth = (window.screen.width - pagePadding) / logoCount
  const elementWidthWithoutMargin = elementWidth - logoMargin * 2
  return {
    marginLeft: logoMargin,
    marginRight: logoMargin,
    width: elementWidthWithoutMargin,
    backgroundImage: `url("${src}")`
  }
}

type Props = {
  logos: string[]
}

const LogoContainer = (props: Props) => {
  const {logos} = props
  const classes = resolveCss(css)
  return (
    <div {...classes('logoContainer')}>
      {logos.map((src) => (
        <div
          key={src}
          {...classes('logo')}
          style={{...calculateLogoStyle(src, logos.length)}}
        />
      ))}
    </div>
  )
}

export default LogoContainer
