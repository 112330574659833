export const validateShippingAddress = (values) => {
  const errors = {
    streetAddress: !values.streetAddress || !values.streetAddress.length,
    postalCode: !values.postalCode || !values.postalCode.length,
    city: !values.city || !values.city.length
  }
  return errors
}

export const validatePayment = (values) => {
  return {
    cardHolderName: !values.cardHolderName || !values.cardHolderName.length,
    cardNumber:
      !values.cardNumber ||
      !values.cardNumber.complete ||
      (values.cardNumber.error && values.cardNumber.error.message)
  }
}
