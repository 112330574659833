/*
 Delay creates a promise that is resolved after n milliseconds.
 It is highly advice to avoid use the function.
 For the time being we are using the code to display info before redirect the user to another page
*/
const delay = (
 milliseconds: number // Millisecond to be waited until resolve the promise
) => {
  let timeout
  const promise: Promise<any> = new Promise((resolve) => timeout = setTimeout(resolve, milliseconds))
  return {
    promise: promise,
    cancel: () => clearTimeout(timeout)
  }
}

export default delay
