import * as React from 'react'
import P from '$common/components/P'
import {Trans} from 'react-i18next'
import GenericButton from '$common/components/buttons/GenericButton'

export const TrialButton = ({t, history, ...props}) => {
  return (
    <GenericButton
      style="bold"
      loading={false}
      disabled={false}
      isFullWidth={true}
      onClick={(event) => {
        event.preventDefault() //stop the default form "submit" action
        history.push('/profile/verify/opintopolku/form')
      }}
    >
      {t('profile.verification.startForm.trialButton')}
    </GenericButton>
  )
}
