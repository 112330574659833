import {compose} from 'redux'
import {connect} from 'react-redux'
import {get} from 'lodash'

import StartTrial from './StartTrialPage'
import {asForm, withRunSaga, withTranslate, withRouter} from '$common/utils'
import {getProfile, getTrialPath} from '$src/profile/selectors'

export default compose(
  withTranslate(),
  withRunSaga(),
  asForm('auth', {}),
  withRouter,
  connect((state) => ({
    profile: getProfile(state),
    trialPath: getTrialPath(state)
  }))
)(StartTrial)
