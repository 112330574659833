import * as React from 'react'
import '!style-loader!css-loader!slick-carousel/slick/slick.css'
import Slider from 'react-slick'

import {isDeviceOnline} from '$common/utils'
import NewsCard, {NewsCardItem} from '$src/news/components/NewsCard/NewsCard'
import {NewsItem} from '$src/news/types'

interface Props {
  fetchNews: () => void
  setCurrentPage: (page: number) => void
  news: NewsItem[]
  handleClose: () => void
  page: number
}

interface SliderNode {
  slickPrev: () => any
  slickNext: () => any
}

export default class NewsCarousel extends React.Component<Props> {
  private slider?: SliderNode = undefined

  componentDidMount() {
    if (!isDeviceOnline()) {
      return
    }

    if (this.props.news.length === 0) {
      this.props.fetchNews()
    }
  }

  prevSlide = () => this.slider?.slickPrev()
  nextSlide = () => this.slider?.slickNext()

  handleAfterChange = (page: number) => {
    this.props.setCurrentPage(page)
  }

  renderNewsSlides() {
    let news: NewsCardItem[]
    if (this.props.news.length === 0) {
      news = [{placeholder: true}]
    } else {
      news = this.props.news
    }

    return news.map((newsItem, i) => {
      const isFirstItem = i === 0
      const isLastItem = i === news.length - 1
      return (
        <NewsCard
          key={i}
          item={newsItem}
          handleClose={this.props.handleClose}
          prevSlide={isFirstItem ? undefined : this.prevSlide}
          nextSlide={isLastItem ? undefined : this.nextSlide}
        />
      )
    })
  }

  render() {
    const {page, ...sliderProps} = this.props

    return (
      <Slider
        ref={(slider) => {
          this.slider = slider as SliderNode
        }}
        afterChange={this.handleAfterChange}
        initialSlide={page}
        slidesToShow={1}
        slidesToScroll={1}
        arrows={false}
        adaptiveHeight={false}
        vertical={false}
        infinite={false}
        {...sliderProps}
      >
        {this.renderNewsSlides()}
      </Slider>
    )
  }
}
