import * as React from 'react'

import css from './RandomOfferDialog.css'
import {resolveCss, withTranslate} from '$common/utils'

const RandomOfferDialog = (props) => {
  const classes = resolveCss(css)
  return (
    <div {...classes('wrapper')}>
      <div>{props.t('home.randomOffer.title')}</div>
      <div>
        <h2>{props.offer?.advertiser}</h2>
      </div>
      <div>{props.offer?.shortDescription}</div>
      {props.offer?.hasDiscountCode && (
        <div>{props.t('home.randomOffer.additionalText')}</div>
      )}
    </div>
  )
}

export default withTranslate()(RandomOfferDialog)
