import {AvailableEducation} from '$src/profile/types'

export default function changeEducationEndpoint(
  {post},
  education: AvailableEducation
) {
  return post(`/profile/change-education`, education, {
    retryOn401: true,
    numberOfRetries: 4
  })
}
