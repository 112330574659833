import React from 'react'
import {Interpolate} from 'react-i18next'

import P from '$common/components/P'

import css from './index.css'

const NoContent = () => (
  <div className={css.container}>
    <P css={{container: css.body}}>
      <Interpolate i18nKey="offers.noResults" />
    </P>
  </div>
)

export default NoContent
