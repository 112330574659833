import MediaQuery from 'react-responsive'
import * as React from 'react'
import {compose} from 'redux'
import {Link} from 'react-router-dom'

import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import TopMiddleBottomLayout from '$common/components/layouts/TopMiddleBottomLayout'
import align from '$common/grid/align.css'
import css from './FhsAfterFailurePage.css'
import grid from '$common/grid/grid.css'
import {resolveCss, withTranslate} from '$common/utils'

class FhsAfterFailurePage extends React.Component {
  render() {
    const classes = resolveCss(css, grid, align)

    return (
      <TopMiddleBottomLayout
        adaptiveLayout={{
          stackMiddle: true
        }}
        css={{
          container: grid.container,
          bottom: css.bottom
        }}
        top={
          <MediaQuery minWidth={801}>
            <FrankLogoInSmallWhite />
          </MediaQuery>
        }
        middle={
          <div {...classes('columnLarge5', 'center')}>
            <H1 css={{container: align.largeCenterText}}>
              {this.props.t('profile.fhs_verification.not_verified.title')}
            </H1>
            <P
              style="small"
              css={{container: [css.message, align.largeCenterText]}}
            >
              {this.props.t('profile.fhs_verification.not_verified.body')}
            </P>
          </div>
        }
        bottom={
          <Link to="/" {...classes('column', 'center')}>
            <GenericButton
              style="bold"
              css={{container: css.button}}
              isExpanding={true}
            >
              {this.props.t('profile.fhs_verification.not_verified.cta')}
            </GenericButton>
          </Link>
        }
      />
    )
  }
}

export default compose(withTranslate())(FhsAfterFailurePage)
