export const NEW_FAVOURITE = 'NEW_FAVOURITE'
export const newFavourite = (offer, favourite) => {
  return {
    type: NEW_FAVOURITE,
    advertiser: offer.advertiser,
    smallImage: offer.smallImage,
    shortDescription: offer.shortDescription,
    url: offer.url,
    key: offer.key,
    slug: offer.slug,
    favourite: favourite
  }
}

export const UPDATE_FAVOURITE = 'UPDATE_FAVOURITE'
export const updateFavourite = (offer, favourite) => {
  return {
    type: UPDATE_FAVOURITE,
    advertiser: offer.advertiser,
    smallImage: offer.smallImage,
    shortDescription: offer.shortDescription,
    url: offer.url,
    key: offer.key,
    slug: offer.slug,
    favourite: favourite
  }
}

export const TOGGLE_FAVOURITE = 'TOGGLE_FAVOURITE'
export const toggleFavourite = (offer, favourite) => {
  return {
    type: TOGGLE_FAVOURITE,
    advertiser: offer.advertiser,
    smallImage: offer.smallImage,
    shortDescription: offer.shortDescription,
    url: offer.url,
    key: offer.key,
    slug: offer.slug,
    favourite: favourite
  }
}
