import {MODE} from './ChangeGraduationYearComponent'

export function getTranslation(location, t) {
  const queryParams = new URLSearchParams(location.search)
  const copyParam = queryParams.get('mode')

  switch (copyParam) {
    case MODE.REGISTRATION:
      return {
        copyParam,
        header: t('profile.setGraduationYearPage.registration.header'),
        fieldLabel: t('profile.setGraduationYearPage.registration.fieldLabel'),
        saveButton: t('common.next'),
        backButton: t('common.back'),
        laterButton: t('common.later')
      }
    case MODE.LANDER:
      return {
        copyParam,
        header: t('profile.setGraduationYearPage.lander.header'),
        fieldLabel: t('profile.setGraduationYearPage.lander.fieldLabel'),
        saveButton: t('common.save'),
        backButton: t('common.back')
      }
    default:
      return {
        copyParam,
        header: t('profile.setGraduationYearPage.default.header'),
        fieldLabel: t('profile.setGraduationYearPage.default.fieldLabel'),
        saveButton: t('common.save'),
        backButton: t('common.back')
      }
  }
}
