import * as React from 'react'

import {Article} from '$src/articles/types'
import {resolveCss} from '$common/utils'
import P from '$common/components/P'
import css from '$src/articles/components/Carousel/Carousel.css'
import {getLandscapeImage} from '$src/articles/selectors'
import {withRouter, WithRouterProps} from '$common/utils'
import SlideLoading from '$common/components/loadings/CarouselSlideLoading'

type Props = {
  item: Article | {placeholder: true}
  mobile?: true
} & WithRouterProps

const ArticleCard = ({item, mobile, history}: Props): React.Node => {
  const classes = resolveCss(css)
  return item.placeholder ? (
    <SlideLoading
      css={{
        container: [
          css.articleBox,
          !mobile ? css.cell : [css.mobileMode, css.mobilePlaceholder]
        ]
      }}
    />
  ) : (
    <div
      {...classes('articleBox', mobile ? 'mobileMode' : 'cell')}
      style={{
        backgroundImage: `linear-gradient(rgba(18,24,33,0.37), rgba(18,24,33,0.37)), url(${String(
          getLandscapeImage(item)
        )})`
      }}
      onClick={() => history.push(`/article/${item.id}`)}
    >
      <div {...classes('slideTextContainer')}>
        <P bolder={true} css={{container: css.slideHeading}}>
          {item.title}
        </P>
        {mobile && (
          <P bolder={true} css={{container: css.slideSubtitle}}>
            {item.teaser}
          </P>
        )}
      </div>
    </div>
  )
}

export default withRouter(ArticleCard)
