import find from 'lodash/find'

import {Selector} from '$src/types'
import {Article, ArticleImageType} from './types'
import {getPreferredLang} from '$src/i18n/selectors'

type ImageSelector = (
  article: Article,
  imageType: ArticleImageType
) => string | undefined

export const getArticle: Selector<Article | undefined, string> = (
  state,
  id
) => {
  return getAllArticles(state).find((article) => article.id === id)
}

/**
 * Get hero article for desktop view.
 *
 * Articles are fetched so that hero articles are always first. Also, hero articles
 * are randomized in fronted side when fetched so the hero article will change time-by-time.
 */
export const getHeroArticle: Selector<Article | undefined> = (state) => {
  return getAllArticles(state)[0]
}

export const getArticleImage: ImageSelector = (article, imageType) => {
  const image = find(article.images, (im) => im.type === imageType)
  return image?.url
}

export const getLandscapeImage = (article: Article) => {
  return getArticleImage(article, 'landscape')
}

export const getPortraitImage = (article: Article) => {
  return getArticleImage(article, 'portrait') || getLandscapeImage(article)
}

export const getAllArticles: Selector<Article[]> = (state) => {
  const language = getPreferredLang(state)
  return state.articles[language]
}

export const getMobileArticles: Selector<Article[]> = (state) => {
  return getAllArticles(state).filter((article) => article.showOnMobile)
}

/**
 * Get articles for desktop carousel.
 *
 * First article is always shown in hero banner, exclude it from the carousel list
 */
export const getDesktopArticles: Selector<Article[]> = (state) => {
  return getAllArticles(state).slice(1)
}
