import {
  default as redeemVoucher,
  Params as FindEndpointParams
} from '$src/api/endpoints/vouchers/redeem'
import {callEndpoint} from '$src/api/sagas/callEndpoint'
import {reduceState} from '$common/utils'
import {select} from 'redux-saga/effects'
import {StoreState} from '$src/types'
import {getPreferredLang} from '$src/i18n/selectors'

export type Params = FindEndpointParams

export default function* redeemVoucherByKey({key}: Params): Generator<> {
  const state: StoreState = yield select()
  const languageCode = getPreferredLang(state)
  yield callEndpoint('redeemVoucher')(redeemVoucher, {key})

  const voucher = {...state.offers[languageCode].byKey[key]}
  voucher.redeemedAt = new Date().toISOString()

  yield reduceState(['offers', languageCode, 'byKey'], (state) => {
    return {...state, [key]: voucher}
  })
}
