export const error404Reducer = (state = {}, action) => {
  const {type, payload} = action

  switch (type) {
    case 'SET_404_ERROR_TO_STORE':{
      return {...state, error404: payload}
    }
    case 'REMOVE_404_ERROR_FROM_STORE':{
      return {...state, error404: null}
    }
    default: return state
  }
}