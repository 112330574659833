import {Lang} from '$src/i18n/types'

export const GRADUATE_STUDENT_TYPE = 'graduate'
export const POSTGRADUATE_STUDENT_TYPE = 'postgraduate'
export const UPPER_SECONDARY_STUDENT_TYPE = 'upper_secondary'

export type StudentTypes =
  | 'upper_secondary'
  | 'graduate'
  | 'postgraduate'
  | 'not_a_student'

export type ProfileVerificationState =
  | 'UNVERIFIED'
  | 'JOLLA'
  | 'KOSKI'
  | 'KOSKI|JOLLA'

export interface InternalEducation {
  id: string
  name: string
  type: string
}
export interface AvailableEducation {
  organizationId: string
  organizationName: string
  schoolId: string
  schoolName: string
  type: string
}
export interface Profile {
  id: string
  isPhotoChanging: boolean
  photoLastModified?: string
  photoIsModifiableDate?: string
  photoLastModifiedDisabled?: string
  jollaMembershipExtraInfo?: string
  photo?: string
  email?: string
  identityCode: number
  firstName: string
  lastName: string
  signature: string
  phoneNumber: string
  language: Lang
  studentType: StudentTypes
  verificationState: ProfileVerificationState
  studentStatus?: {
    presentUntil: string // Date string
    isPresent: boolean
    presenceCode: number
  }
  organization?: InternalEducation
  school?: InternalEducation
  studentNumber?: string
  dob?: string
  carrier?: string
  availableEducations?: AvailableEducation[]
  trial?: {
    active: boolean
    startedAt: string
    membershipActivated: boolean
    membershipActivatedAt?: string
    membershipActivationStage?: string // not sure if this is needed in the end but it might be useful to know what stage of activation a userprofile currently is in
    membershipTransactionReference?: string
  }
  alumn?: boolean
}

export type ProfileNamespaceState =
  | ({loaded: string} & Profile)
  | {loaded: false}
