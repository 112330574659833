import css from '../../../profile/components/verification/JollaFormPage/JollaFormPage.css'
import * as React from 'react'
import P from '$common/components/P'
import {compose} from 'redux'
import {withTranslate} from '$src/common/utils'

const FaqLink = ({t, href, text, onClick}) => (
  <P css={{container: css.problems}} style="subtle">
    <a
      href={t(href)}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
    >
      {t(text)}
    </a>
  </P>
)

export default compose(withTranslate())(FaqLink)
