import React from 'react'
import css from './HeaderTopFill.css'
import {resolveCss} from '$src/common/utils'
import NavBar from '$common/components/NavBar/NavBar'

type Props = {
  background?: string
  backgroundColor?: string
  position?: string
}

const HeaderTopFill = ({
  background = '',
  backgroundColor = '#121821',
  position = 'absolute'
}: Props) => {
  if (!window.device || window.device.platform !== 'iOS') {
    return null
  }
  const classes = resolveCss(css)
  return (
    <div
      {...classes('headerTopFill', NavBar.isSafeAreaDevice() && 'safeAreaTop')}
      style={{background, backgroundColor, position}}
    />
  )
}

export default HeaderTopFill
