import {compose} from 'redux'
import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect'

import userAuthenticated from '$src/auth/components/userAuthenticated'
import {isUserNotKoskiVerified} from '$src/profile/selectors'
import {getHomePage} from '$src/common/pgWebSeparatorUtils'

export const userNotKoskiVerifiedWrapper = connectedRouterRedirect({
  redirectPath: getHomePage(),
  allowRedirectBack: false,
  authenticatedSelector: isUserNotKoskiVerified,
  wrapperDisplayName: 'UserNotKoskiVerified'
})

export default (Component) =>
  compose(userAuthenticated, userNotKoskiVerifiedWrapper)(Component)
