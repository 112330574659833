import React, {Component} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {translate} from 'react-i18next'

import css from './HeroArticle.css'
import grid from '$common/grid/grid.css'
import {
  resolveCss,
  withRunSaga,
  withRouter,
  WithTranslateProps
} from '$common/utils'
import {WithRunSagaProps} from '$common/utils/withRunSaga'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import {Article} from '$src/articles/types'
import {getHeroArticle, getLandscapeImage} from '$src/articles/selectors'

type Props = WithRunSagaProps &
  WithTranslateProps & {
    article?: Article
  }

class HeroArticle extends Component<Props> {
  render() {
    const classes = resolveCss(css, grid)
    const {article} = this.props

    if (article) {
      const image = getLandscapeImage(article) || ''
      return (
        <div {...classes('imageContainer')}>
          <div
            key="0"
            {...classes('imageContainer', 'fadeIn')}
            style={{
              backgroundImage: `linear-gradient(
              rgba(0, 0, 0, 0.25),
              rgba(0, 0, 0, 0.25)
            ), url(${image})`
            }}
          />
          <div key="1" {...classes('container', 'textContainer', 'fadeIn')}>
            <div>
              <P bolder={true} css={{container: css.title}}>
                {article.title}
              </P>
              <P bolder={true} css={{container: css.teaser}}>
                {article.teaser}
              </P>
              <Link to={`/article/${article.id}`} {...classes('link')}>
                {this.props.t('home.heroArticle.readMore')}
              </Link>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default compose(
  connect((state) => {
    return {
      article: getHeroArticle(state) // Hero article is fetched along all ohter articles
    }
  }),
  withRouter,
  withRunSaga(),
  translate()
)(HeroArticle)
