import * as React from 'react'
import {translate} from 'react-i18next'
import {compose} from 'redux'
import * as Sentry from '@sentry/browser'
import {Link} from 'react-router-dom'
import H1 from '$common/components/headers/H1'
import GenericButton from '$common/components/buttons/GenericButton'

import css from './ErrorBoundary.css'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {hasError: false}
  }

  componentDidCatch(error, errorInfo) {
    console.error('ErrorBoundary DidCatch:', error, errorInfo) // eslint-disable-line no-console
    this.setState({hasError: true})

    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  render() {
    if (this.state.hasError) {
      return this.errorMessageComponent()
    } else {
      return <this.props.wrappedComponent {...this.props} />
    }
  }

  clearError = () => {
    this.setState({hasError: false})
  }

  errorMessageComponent = () => {
    const headerAndButtonBox = (
      <div className={css.headerAndButtonBox}>
        <H1>{this.props.t('utils.errorBoundary.header')}</H1>
        <Link to={'/'} onClick={this.clearError}>
          <GenericButton style={'bold'}>
            {this.props.t('utils.errorBoundary.returnToHomePageLink')}
          </GenericButton>
        </Link>
      </div>
    )

    const mailtoAddress = process.env.SUPPORT_EMAIL
    const emailLink = <a href={`mailto:${mailtoAddress}`}>{mailtoAddress}</a>
    const messageBox = (
      <div className={css.messageBox}>
        <p>
          {this.props.t('utils.errorBoundary.contactUs')} {emailLink}.
        </p>
        <p>{this.props.t('utils.errorBoundary.addToMessage.title')}</p>
        <ul className={css.listPaddingFix}>
          <li>{this.props.t('utils.errorBoundary.addToMessage.username')}</li>
          <li>
            {this.props.t('utils.errorBoundary.addToMessage.description')}
          </li>
        </ul>
      </div>
    )

    return (
      <div className={css.container}>
        {headerAndButtonBox}
        {messageBox}
      </div>
    )
  }
}

// For some reason withTranslate does not work together with withErrorBoundary
export default compose(translate())(ErrorBoundary)
