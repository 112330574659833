import wn from 'when'
import {call} from 'redux-saga/effects'
import get from './methods/get'
import put from './methods/put'
import post from './methods/post'
import patch from './methods/patch'
import requestAndSetFlags from '$src/api/sagas/requestAndSetFlags'

const methods = {get, post, put, patch}

/**
 * Takes an api function (anything found in $src/api/endpoints/*) and its arguments,
 * and runs that api function, returning either the success value or rejecting with the error.
 */

export const callEndpoint = (entityType?: string) => (
  fn: Function,
  ...args: unknown[]
): unknown => {
  return call(function*(): Generator<> {
    const callDeferred = wn.defer()
    const proxies = {}
    let requestMethod
    for (const method of ['get', 'post', 'put', 'patch']) {
      proxies[method] = (...args) => {
        requestMethod = method
        callDeferred.resolve(call(methods[method], ...args))
      }
    }

    yield call(fn, proxies, ...args)
    const effect = yield callDeferred.promise
    const setFlags: boolean = requestMethod === 'get'

    return yield call(requestAndSetFlags, effect, setFlags, entityType)
  })
}

/**
 * Performs a request, and sets global fetching state.
 * NOTE: This saga is ONLY used for requests that don't normally affect global fetching state,
 * which are PUT/POST requests not fired from inside a redux-form implementation.
 * Example use case: changing user language from outside a redux-form.
 *
 */
export const callEndpointAndSetFlags = (entityType?: string) =>
  function*(fn: Function, ...args: Array<any>): Generator<> {
    return yield call(
      requestAndSetFlags,
      callEndpoint()(fn, ...args),
      true,
      entityType
    )
  }

export default callEndpoint()
