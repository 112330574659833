import * as React from 'react'
import {isEqual} from 'lodash'
import {
  default as InfiniteLoadingResponsiveCollection,
  RenderRowProps,
  RenderCellProps
} from '$common/components/lists/InfiniteLoadingResponsiveCollection'
import {isDeviceOnline, WithRunSagaProps} from '$common/utils'
import LoadingOrRender from './LoadingOrRender'
import {OwnProps, StateProps, Offer} from '$src/offers/types'
import {
  logEvent2,
  ANALYTICS_EVENT,
  ANALYTICS_PROVIDER
} from '$src/common/utils/logUtils'

type Props = WithRunSagaProps &
  OwnProps &
  StateProps<Offer> & {
    scrollY: number
    saveYScroll: (y: number) => void
  }

export default class TheList extends React.Component<Props> {
  static defaultProps = {total: 0}

  componentDidMount() {
    if (this.props.isDesktop) {
      setTimeout(() => {
        window.scrollTo(0, this.props.scrollY)
        this.props.saveYScroll(0)
      }, 300)
    } else {
      window.scrollTo(0, this.props.scrollY)
      this.props.saveYScroll(0)
    }
  }

  UNSAFE_componentWillReceiveProps({
    queryParams: {size, ...nextQParams}
  }: Props) {
    const {size: disregard, ...prevQParams} = this.props.queryParams
    if (!isEqual(prevQParams, nextQParams) && nextQParams.q != undefined) {
      logEvent2(
        ANALYTICS_EVENT.SEARCH_INITIATED,
        [ANALYTICS_PROVIDER.AMPLITUDE, ANALYTICS_PROVIDER.BRAZE],
        nextQParams // metadata will not be tracked in Braze unless turned on for an event
      )
    }
  }

  getItemCount = (): number => {
    const itemCount = Math.min(
      this.props.isFetching && !this.props.total ? 40 : this.props.total,
      this.props.rowCountLimit || 99999999
    )
    return itemCount
  }

  fetchPages = (pageNumbers: number[], props: Props = this.props) => {
    if (!isDeviceOnline()) {
      return
    }

    const fetchPagePromises = pageNumbers.map((page) => {
      return props.runSaga(this.props.fetchPage, {
        page,
        ...this.props.queryParams
      })
    })

    return Promise.all(fetchPagePromises)
  }

  renderRow = (params: RenderRowProps) => {
    return this.renderItem('row', params)
  }

  renderCell = (params: RenderCellProps) => {
    return params.index < this.getItemCount()
      ? this.renderItem('cell', params)
      : null
  }

  getIdForItem(params: RenderRowProps | RenderCellProps): string {
    const ids = this.props.getEntityIds({
      page: params.pageNumber,
      ...this.props.queryParams
    })

    const id = ids && ids[params.offsetInPage]
    return id
  }

  renderItem = (
    mode: 'row' | 'cell',
    params: RenderRowProps | RenderCellProps
  ) => {
    // NOTE: tried getting rid of this; passing the entity ID to each LoadingOrRender,
    // and selecting entities by IDs there... only to find weird render throttling bugs.
    const selector = () => {
      const id = this.getIdForItem(params) || 'undefined'
      const entity = this.props.getEntity(id)
      return entity
    }

    const key = `${mode}-${params.index}`
    return (
      <LoadingOrRender
        selector={selector}
        key={key}
        mode={mode}
        render={(props) => React.createElement(this.props.ItemComponent, props)}
      />
    )
  }

  isItemLoaded = (params): boolean => {
    return this.getIdForItem(params) ? true : false
  }

  render() {
    return (
      <InfiniteLoadingResponsiveCollection
        fetchPages={this.fetchPages}
        itemsPerPage={this.props.itemsPerPage}
        itemCount={this.getItemCount()}
        uniqueParams={this.props.queryParams}
        renderRow={this.renderRow}
        renderCell={this.renderCell}
        isItemLoaded={this.isItemLoaded}
        rowHeight={200}
        cellHeight={244}
        minCellWidth={366}
        cellMargin={24}
      />
    )
  }
}
