import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import mapKeys from 'lodash/mapKeys'

/**
 * @note Atm, the language definition files are so small that I think it's
 * better to preload all of them at once rather than load each one on demand,
 * but this may not be true a year from now.
 */

const localesContext = require.context('./locales', false, /\.json$/)
const listOfLocalePaths = localesContext.keys()
const requireLocaleByPath = localesContext
const localesByPath = mapValues(
  keyBy(listOfLocalePaths, (s) => s),
  (localePath) => ({translation: requireLocaleByPath(localePath)})
)

const localesByName = mapKeys(localesByPath, (_, localePath) => {
  return localePath.replace(/^\.\//, '').replace(/\.json$/, '')
})

export default localesByName
