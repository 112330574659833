import {PricingNamespaceState} from './types'

const defaultState: PricingNamespaceState = {
  loaded: false
}

export const RECEIVE_PRICES = 'RECEIVE_PRICES'
export const FETCH_PRICES = 'FETCH_PRICES'

export default (
  state: PricingNamespaceState = defaultState,
  action: object
) => {
  if (action.type === FETCH_PRICES) {
    return {loaded: false}
  }
  if (action.type === RECEIVE_PRICES) {
    return {loaded: true, ...action.payload}
  }
  return state
}
