import * as React from 'react'
import {compose} from 'redux'

import TextInput from './TextInput'
import css from './PasswordInput.css'
import {resolveCss, withTranslate, WithTranslateProps} from '$common/utils'

type Props = WithTranslateProps & {
  input: {
    value: any
    onChange: Function
  }
  meta: {
    active: boolean
  }
  css?: any
  label: string
  placeholder?: string
  autocomplete?: string
}

type State = {
  showing: boolean
}

class PasswordInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {showing: false}
  }

  toggleShowingState() {
    this.setState(({showing}) => ({showing: !showing}))
  }

  render() {
    const {showing} = this.state
    const classes = resolveCss(css, this.props.css)

    const toggleVisibilityButton = (
      <a
        {...classes('showOrHide', showing && 'showing')}
        onClick={() => this.toggleShowingState()}
      >
        {showing
          ? this.props.t('authentication.hide_password')
          : this.props.t('authentication.show_password')}
      </a>
    )

    return (
      <TextInput
        {...this.props}
        type={showing ? 'text' : 'password'}
        topRowExtraContent={toggleVisibilityButton}
      />
    )
  }
}

export default compose(withTranslate())(PasswordInput)
