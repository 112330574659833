import * as React from 'react'
import {compose} from 'redux'
import SvgIcon from '$common/components/icons/SvgIcon'
import P from '$common/components/P'
import frankSpinner from '$assets/icons/frankSpinner.svg'
import GenericModal from '$common/components/modals/GenericModal'
import css from './index.css'

// style: 'dark' |'light'
const LoadingSpinnerModal = ({message, style = 'dark'}) => {
  const paragraphStyle = style === 'light' ? 'black' : 'normal'

  return (
    <div>
      <GenericModal
        noTop={true}
        css={{middle: css.spinnerContainer}}
        middle={[
          <SvgIcon key="0" src={frankSpinner} />,
          message && (
            <P style={paragraphStyle} key="1">
              {message}
            </P>
          )
        ]}
        style={style}
      />
    </div>
  )
}

export default compose((a) => a)(LoadingSpinnerModal)
