import * as React from 'react'
import {compose} from 'redux'
import {Link} from 'react-router-dom'
import {Interpolate} from 'react-i18next'

import ConversationLayout from '$common/components/layouts/ConversationLayout'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import css from '$src/subscriptions/components/styles.css'
import {NationalOrInternational} from '$src/subscriptions/types'
import {
  WithRouterProps,
  WithTranslateProps,
  withRouter,
  withTranslate,
  connect
} from '$common/utils'
import HeaderTopFill from '$src/common/components/Header/HeaderTopFill'

type Props = WithRouterProps &
  WithTranslateProps & {
    match: {params: {nationality: NationalOrInternational}}
    pricing: any
  }

const Card = (props: Props) => {
  let titleI18nKey, bodyI18nKey, ctaI18nKey, ctaPath
  if (props.match.params.nationality === 'national') {
    titleI18nKey = 'subscriptions.plainCardPurchase.finnish.title'
    bodyI18nKey = 'subscriptions.plainCardPurchase.finnish.body'
    ctaI18nKey = 'subscriptions.plainCardPurchase.finnish.cta'
    ctaPath = '/plain-card/national/shipping-address'
  } else {
    titleI18nKey = 'subscriptions.plainCardPurchase.international.title'
    bodyI18nKey = 'subscriptions.plainCardPurchase.international.body'
    ctaI18nKey = 'subscriptions.plainCardPurchase.international.cta'
    ctaPath = '/plain-card/international/shipping-address'
  }

  const buttonStyles = {container: [css.button, css.horizontalButton]}

  const isicTermsLink = (
    <a
      href={props.t('isicTerms.link')}
      target="_blank"
      rel="noopener noreferrer"
      style={{textDecoration: 'underline'}}
    >
      {props.t(
        'subscriptions.plainCardPurchase.international.isicTermsLinkText'
      )}
    </a>
  )

  const conversationLayoutProps = {
    content: (
      <div className={css.content}>
        <H1 css={{container: css.heading}}>
          {props.t(titleI18nKey, props.pricing)}
        </H1>
        <P css={{container: css.paragraph}}>
          <Interpolate
            i18nKey={bodyI18nKey}
            isicTermsLink={isicTermsLink}
            {...props.pricing}
          />
        </P>
      </div>
    ),
    buttons: (
      <div className={css.horizontalButtons}>
        <div className={css.left}>
          <Link to={ctaPath} onClick={() => window.scrollTo(0, 0)}>
            <GenericButton style="bold" css={buttonStyles}>
              {props.t(ctaI18nKey, props.pricing)}
            </GenericButton>
          </Link>
        </div>

        <div className={css.right} onClick={() => window.scrollTo(0, 0)}>
          <GenericButton
            style="outline"
            css={buttonStyles}
            onClick={props.history.goBack}
          >
            {props.t('common.back')}
          </GenericButton>
        </div>
      </div>
    ),
    dialogContainerStyle: 'blue'
  }

  return (
    <div>
      <HeaderTopFill
        background={'linear-gradient(#036F89,#04A3CA)'}
        position={'fixed'}
      />
      <ConversationLayout {...conversationLayoutProps} />
    </div>
  )
}

export default compose(
  connect((state) => ({pricing: state.pricing})),
  withRouter,
  withTranslate()
)(Card)
