import {compose} from 'redux'
import {getProfile} from '$src/profile/selectors'
import {
  withRouter,
  withTranslate,
  withRunSaga,
  connect
} from '$common/utils/index'
import BarcodeView from './BarcodeView'
import {StoreState} from '$src/types'
import {getLatestActiveIsicSubscription} from '$src/subscriptions/selectors'
import {get} from 'lodash'

export default compose(
  connect((state: StoreState) => {
    return {
      subscriptions: state.subscriptions,
      profile: getProfile(state),
      isicPrice: state.pricing['digital_isic_card-price'],
      isicNumber: get(getLatestActiveIsicSubscription(state), 'isicNumber')
    }
  }),
  withRouter,
  withTranslate(),
  withRunSaga()
)(BarcodeView)
