import {connect} from 'react-redux'
import {compose} from 'redux'
import withSizes from 'react-sizes'

import {
  getProfile as selectProfile,
  hasOpenedTrialEndedSelector
} from '$src/profile/selectors'
import {withRouter, withRunSaga, withTranslate} from '$src/common/utils'
import {isUserVerified} from '$src/profile/selectors'
import HomePage from './HomePage'

// The number of items to show in the list of offers and vouchrs on the Home Page
const HOME_PAGE_CAMPAIGN_LIST_LENGTH = 20

const mapStateToProps = (state) => {
  return {
    profile: selectProfile(state),
    itemsCount: HOME_PAGE_CAMPAIGN_LIST_LENGTH,
    trialEnded: hasTrialEnded(selectProfile(state)),
    hasOpenedTrialEnded: hasOpenedTrialEndedSelector(state),
    randomOffer: state.randomOffer.response,
    isUserVerified: isUserVerified(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withRouter,
  withRunSaga(),
  withTranslate()
)(HomePage)

const hasTrialEnded = (userProfile) => {
  return userProfile.trial?.daysleft <= 0
}
