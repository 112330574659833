import {Voucher} from '$src/offers/types'

export type Params = {
  key: string
}

export default function redeemVoucher(
  {post}: any,
  {key}: Params
): Promise<{data?: Voucher}> {
  return post(`/vouchers/${key}/redeem`, null, {
    numberOfRetries: 4
  })
}
