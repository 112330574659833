import React from 'react'
import {connect, resolveCss, withTranslate} from '$common/utils'
import {compose} from 'redux'
import {Link} from 'react-router-dom'
import align from '$common/grid/align.css'
import css from './LandingPage.css'
import grid from '$common/grid/grid.css'
import TopMiddleBottomLayout from '$common/components/layouts/TopMiddleBottomLayout'
import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import {setTrialPath} from '$src/profile/actions'
import {getTrialPath} from '$src/profile/selectors'

const mapStateToProps = (state: StoreState) => {
  const trialPath = getTrialPath(state)
  return trialPath.isActive
}

const mapDispatchToProps = (dispatch) => {
  return {
    trialPath: (isActive: boolean) => {
      dispatch(setTrialPath(isActive))
    }
  }
}

const LandingPage = (props) => {
  const classes = resolveCss(css, grid, align)

  return (
    <TopMiddleBottomLayout
      adaptiveLayout={{
        stackMiddle: true
      }}
      css={{
        container: grid.container,
        bottom: css.bottom
      }}
      top={<FrankLogoInSmallWhite />}
      middle={
        <>
          <div {...classes('columnLarge5', 'marginTop2em', 'marginBottom2em')}>
            <H1>{props.t('profile.trial.landing_page.upper_title')}</H1>
            <P style="small" css={{container: [css.message]}}>
              {props.t('profile.trial.landing_page.upper_body_text')}
            </P>
            <Link to="/signup" {...classes('column', 'center')}>
              <GenericButton
                style="bold"
                css={{container: css.button}}
                isExpanding={true}
                hasTopMargin={true}
                onClick={() => {
                  return props.trialPath(true)
                }}
              >
                {props.t('profile.trial.landing_page.upper_btn_text')}
              </GenericButton>
            </Link>
          </div>
          <div {...classes('columnLarge5', 'marginTop1em')}>
            <H1>{props.t('profile.trial.landing_page.lower_title')}</H1>
            <P style="small" css={{container: [css.message]}}>
              {props.t('profile.trial.landing_page.lower_body_text')}
            </P>
            <Link to="/signup" {...classes('column', 'center')}>
              <GenericButton
                style="bold"
                css={{container: [css.button, align.centerText]}}
                isExpanding={true}
                hasTopMargin={true}
              >
                {props.t('profile.trial.landing_page.lower_btn_text')}
              </GenericButton>
            </Link>
          </div>
        </>
      }
    />
  )
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslate()
)(LandingPage)
