import * as React from 'react'
import {compose} from 'redux'
import queryString from 'query-string'

import {connect, withRunSaga, withRouter, withTranslate} from '$common/utils'
import logoutSaga from '$src/auth/sagas/logout'
import {isLoggedIn} from '$src/auth/selectors'
import LoadingSpinnerModal from '$common/components/LoadingSpinnerModal'

class Logout extends React.Component {
  async componentDidMount() {
    if (this.props.loggedIn) {
      const signOutGlobally =
        queryString.parse(this.props.location.search).global === 'true'
      await this.props.runSaga(logoutSaga, signOutGlobally)
    }
    this.props.history.replace('/')
  }

  render() {
    return <LoadingSpinnerModal message={this.props.t('common.loggingOut')} />
  }
}

export default compose(
  connect((state) => {
    return {
      loggedIn: isLoggedIn(state)
    }
  }),
  withRouter,
  withTranslate(),
  withRunSaga()
)(Logout)
