import {UINamespaceState} from './types'
import {SAVE_Y_SCROLL} from '$src/ui/actions'

const defaultState: UINamespaceState = {
  scrollY: 0
}

export default (
  state: UINamespaceState = defaultState,
  action: object
): UINamespaceState => {
  if (action.type === SAVE_Y_SCROLL) {
    return {...state, scrollY: action.payload}
  }
  return state
}
