import {Field as ReduxFormField} from 'redux-form'
import * as React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'

const asField = () => (component) => {
  const FinalComponent = ({name, forwardedRef, ...rest}) => (
    <ReduxFormField
      name={name}
      component={component}
      ref={forwardedRef}
      {...rest}
    />
  )

  hoistNonReactStatics(FinalComponent, component)

  FinalComponent.displayName = `asField(${component.displayName ||
    component.name ||
    'Component'})`

  return React.forwardRef((props, ref) => {
    return <FinalComponent {...props} forwardedRef={ref} />
  })
}

export default asField
