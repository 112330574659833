import {trim} from 'lodash'

/**
 * Creates a shallow copy of parameter object, trimming all string values.
 *
 * @param {values} Object
 * @returns Object A trimmed-text copy of values
 */
export function trimmedValues(values) {
  const trimmed = {}
  for (const [key, value] of Object.entries(values)) {
    trimmed[key] = typeof value === 'string' ? trim(value) : value
  }
  return trimmed
}
