import * as React from 'react'
import {compose} from 'redux'
import {Link} from 'react-router-dom'
import {Interpolate} from 'react-i18next'

import ConversationLayout from '$common/components/layouts/ConversationLayout'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import css from '$src/subscriptions/components/styles.css'
import {
  WithTranslateProps,
  withRouter,
  withTranslate,
  connect
} from '$common/utils'
import HeaderTopFill from '$src/common/components/Header/HeaderTopFill'
import {HeaderTopFillStyles} from '$src/subscriptions/components/ProfileSummary'

const DigiIsicTermsAndConditions = (props: WithTranslateProps) => {
  const isicTermsLink = (
    <a
      href={props.t('isicTerms.link')}
      target="_blank"
      rel="noopener noreferrer"
      style={{textDecoration: 'underline'}}
    >
      {props.t('subscriptions.digiIsicPurchase.terms.isicTermsLinkText')}
    </a>
  )

  const conversationLayoutProps = {
    content: (
      <div className={css.content}>
        <H1 css={{container: css.heading}}>
          {props.t('subscriptions.digiIsicPurchase.terms.title')}
        </H1>
        <Interpolate
          i18nKey="subscriptions.digiIsicPurchase.terms.body"
          isicTermsLink={isicTermsLink}
          {...props.pricing}
        />
      </div>
    ),
    buttons: (
      <div className={css.singleButton}>
        <Link to="/digi-isic/payment" onClick={() => window.scrollTo(0, 0)}>
          <GenericButton
            css={{container: css.button}}
            style="bold"
            isExpanding={true}
          >
            {props.t('common.accept')}
          </GenericButton>
        </Link>
      </div>
    ),
    hideBackButton: true,
    dialogContainerStyle: 'black'
  }

  return (
    <div>
      <HeaderTopFill
        background={HeaderTopFillStyles['black']}
        position={'fixed'}
        headerStyle=""
      />
      <ConversationLayout {...conversationLayoutProps} />
    </div>
  )
}

export default compose(
  connect((state) => ({pricing: state.pricing})),
  withRouter,
  withTranslate()
)(DigiIsicTermsAndConditions)
