// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/fonts/Nexa_Free_Bold-webfont.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/fonts/Nexa_Free_Bold-webfont.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "/**\n * Global CSS rules should be placed here.\n */\nbody {\n  margin: 0;\n  padding: 0;\n\n  background: #121821;\n  font-family: 'Montserrat', sans-serif;\n\n  color: #fff;\n  font-size: 18px;\n  font-weight: 300;\n  line-height: 24px;\n  padding-top: calc(-15px + constant(safe-area-inset-top)); /* iOS 11.0 */\n  padding-top: calc(-15px + env(safe-area-inset-top)); /* iOS 11.2 */\n  overflow-x: hidden;\n  width: 100vw;\n  height: 100vh;\n  position: relative;\n}\n\n.no-padding {\n  padding-top: 0 !important;\n}\n\n.scroll-lock {\n  overflow-y: hidden;\n}\n\nbutton {\n  font-family: 'Nexa Bold', sans-serif;\n}\n\n#main {\n  width: 100vw;\n  min-height: 100vh;\n  overflow-x: hidden;\n  position: relative;\n}\n\n#root,\n#main-wrap {\n  overflow-x: hidden;\n  width: 100vw;\n  position: relative;\n}\n\n.fade-enter {\n  opacity: 0;\n  z-index: 1;\n}\n\n.fade-enter.fade-enter-active {\n  opacity: 1;\n  transition: opacity 250ms ease-in;\n}\n\n* {\n  color: inherit;\n  text-decoration: none;\n  margin: 0;\n  padding: 0;\n  outline: none;\n  border: 0;\n  background: none;\n  box-sizing: border-box;\n}\n\n@font-face {\n  font-family: 'Nexa Bold';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n}\n", ""]);
// Exports
module.exports = exports;
