import React, {Component} from 'react'
import MediaQuery from 'react-responsive'
import DesktopCarousel from '$src/articles/components/Carousel/DesktopCarousel'
import MobileCarousel from '$src/articles/components/Carousel/MobileCarousel'
import HeroArticle from '$src/articles/components/HeroArticle/HeroArticle'
import Footer from '$common/components/Footer'
import GenericButton from '$common/components/buttons/GenericButton'
import P from '$common/components/P'
import H1 from '$common/components/headers/H1'
import Header from '$common/components/Header/Header-container'
import Layout from '$common/components/layouts/TopMiddleBottomLayout'
import OfflineModal from '$common/components/modals/OfflineModal'
import OfferList from '$src/offers/components/ListPage/OfferList-container'
import NewsCarousel from '$src/news/components/NewsCarousel-container'
import align from '$common/grid/align.css'
import css from './HomePage.css'
import grid from '$common/grid/grid.css'
import NewsTeaser from '$src/news/components/NewsTeaser/NewsTeaser'
import {
  ANALYTICS_EVENT as evt,
  ANALYTICS_PROVIDER as provider,
  logEvent2 as log
} from '$src/common/utils/logUtils'
import {
  resolveCss,
  isDeviceOnline,
  WithRunSagaProps,
  WithRouterProps,
  WithTranslateProps
} from '$common/utils'
import {Profile} from '$src/profile/types'
import {isUserVerified} from '$src/profile/selectors'
import NotificationFeed from '$src/notificationFeed/notificationFeed'
import {getAppboy} from '$common/pgWebSeparatorUtils'
import fetchRandomOffer from '$src/offers/sagas/fetchRandomOffer'
import RandomOfferDialog from './RandomOfferDialog'
import delay from '$src/common/utils/delay'
import {Offer} from '$src/offers/types'
import {Portal} from 'react-portal'

type State = {
  isCarouselOpen: boolean
  shouldShowIsicLinkDialog: boolean
}
type PropTypes = {
  profile: Profile
  itemsCount: number
  randomOffer: Offer
}

type Props = WithRouterProps & WithRunSagaProps & WithTranslateProps & PropTypes

export default class HomePage extends Component<Props, State> {
  delayTimer = null

  static path = '/home'
  state = {
    isCarouselOpen: false,
    notificationFeedIsVisible: null,
    unreadNotifications: [],
    notifications: [],
    shouldShowRandomOfferDialog: false
  }

  backButtonHandler = () => {
    if (this.state.isCarouselOpen) {
      this.newsTeaserCarouselToggle()
    } else if (this.props.location.pathname === HomePage.path) {
      window.navigator.app.exitApp()
    } else {
      window.navigator.app.backHistory()
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    document.addEventListener('backbutton', this.backButtonHandler)

    // Display the "Trial Ended" screen when appropriate
    handleTrialEnded(this.props)

    // Display tracking permission modal on IOS
    this.getIdfaTrackingPermission()

    if (window.cordova) {
      AppboyPlugin.getContentCardsFromServer((cards) => {
        const unreadNotifications = cards.filter(
          (card) => card.viewed === false
        )
        const notifications = cards.sort((x, y) => {
          return y.created - x.created
        })
        this.setState((prevState) => ({
          ...prevState,
          unreadNotifications: unreadNotifications,
          notifications: notifications
        }))
      })
    }

    log(evt.OPEN_HOMEPAGE, [
      provider.AMPLITUDE,
      provider.BRAZE,
      provider.FACEBOOK,
      provider.FIREBASE
    ])
  }

  componentWillUnmount() {
    document.removeEventListener('backbutton', this.backButtonHandler)
  }

  newsTeaserCarouselToggle() {
    this.setState({isCarouselOpen: !this.state.isCarouselOpen})
  }

  getIdfaTrackingPermission() {
    if (window.cordova) {
      const idfaPlugin = window.cordova.plugins.idfa

      idfaPlugin
        .getInfo()
        .then((info) => {
          if (!info.trackingLimited) {
            return info.idfa || info.aaid
          } else if (
            info.trackingPermission ===
            idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED
          ) {
            return idfaPlugin.requestPermission().then((result) => {
              if (result === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED) {
                return idfaPlugin.getInfo().then((info) => {
                  return info.idfa || info.aaid
                })
              }
            })
          }
        })
        .then((idfaOrAaid) => {
          if (idfaOrAaid) {
            console.log(idfaOrAaid)
          }
        })
    }
  }

  toggleNotificationFeed = (action: string) => {
    if (action === 'open') {
      this.state.unreadNotifications.forEach((notification) =>
        AppboyPlugin.logContentCardImpression(notification.id)
      )
      this.setState((prevState) => ({
        ...prevState,
        unreadNotifications: [],
        notificationFeedIsVisible: true
      }))
      log(evt.NOTIFICATION_FEED_OPENED, [provider.AMPLITUDE])
      document.body.style.overflow = 'hidden'
    }
    if (action === 'close') {
      this.setState((prevState) => ({
        ...prevState,
        notificationFeedIsVisible: false
      }))
      document.body.style.overflow = 'visible'
    }
  }

  showRandomOfferDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      shouldShowRandomOfferDialog: true
    }))
    document.body.classList.add('scroll-lock')
  }

  hideRandomOfferDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      shouldShowRandomOfferDialog: false
    }))
    document.body.classList.remove('scroll-lock')
    if (this.delayTimer) this.delayTimer.cancel()
  }

  onClickRandomOffer = async () => {
    try {
      await this.props.runSaga(fetchRandomOffer)
      if (this.props.randomOffer.hasDiscountCode) {
        this.copyRandomOfferDiscountCode(this.props.randomOffer.discountCode)
      }
      this.showRandomOfferDialog()
      this.delayTimer = delay(3000)
      await this.delayTimer.promise
      log(evt.LUCKY_BUTTON_PRESSED, [provider.BRAZE, provider.AMPLITUDE], {
        key: this.props.randomOffer?.key
      })
      getAppboy().requestImmediateDataFlush() // Flush before navigating outside the application
      if (window.cordova) {
        window.open(this.props.randomOffer?.offerUrl, '_system')
      } else {
        window.open(
          this.props.randomOffer?.offerUrl,
          '_blank',
          'noopener,noreferrer'
        )
      }
      setTimeout(this.hideRandomOfferDialog, 1000)
    } catch (error) {
      console.log(error)
    }
  }

  copyRandomOfferDiscountCode = async (discountCode) => {
    if (window.cordova) {
      try {
        window.cordova.plugins.clipboard.copy(discountCode)
      } catch (error) {
        // Display an error message to the user
        alert('Oops, unable to copy. Please try again.')
      }
    } else {
      try {
        await navigator.clipboard.writeText(discountCode)
      } catch (error) {
        console.error('Async: Could not copy text: ', error)
      }
    }
  }

  render() {
    const classes = resolveCss(css, grid, align)

    const mobileContent = (
      <div {...classes('mobileContent')}>
        <NotificationFeed
          notificationFeedIsVisible={this.state.notificationFeedIsVisible}
          notifications={this.state.notifications}
          toggleNotificationFeed={this.toggleNotificationFeed}
        />
        <NewsTeaser
          toggleCarousel={this.newsTeaserCarouselToggle.bind(this)}
          isCarouselOpen={this.state.isCarouselOpen}
        />
        <div {...classes('mobileArticleCarouselContainer')}>
          <MobileCarousel />
        </div>
        <div {...classes('mobileDivider')}>
          <P style="small">{this.props.t('home.discounts.title')}</P>
        </div>
      </div>
    )

    const desktopContent = (
      <div {...classes('contentContainer')}>
        <div {...classes('columnLarge3', 'newsCarouselContainer')}>
          <NewsCarousel />
        </div>
        <div {...classes('columnLarge9', 'articleCarouselContainer')}>
          <DesktopCarousel />
        </div>
      </div>
    )

    const renderContent = () => {
      if (!isDeviceOnline()) {
        return <OfflineModal />
      }
      return (
        <div {...classes('middleContainer')}>
          <MediaQuery minWidth={1025}>
            <HeroArticle />
          </MediaQuery>
          <div {...classes('container')}>
            <MediaQuery maxWidth={1024}>
              {(isMobile) => (isMobile ? mobileContent : desktopContent)}
            </MediaQuery>
            <div>
              <MediaQuery minWidth={1025}>
                <div {...classes('divider')}>
                  <H1 style="black" css={{container: css.dividerHeading}}>
                    {this.props.t('home.discounts.title')}
                  </H1>
                  <P color="black" css={{container: css.dividerText}}>
                    {this.props.t('home.discounts.subTitle')}
                  </P>
                </div>
              </MediaQuery>
              <OfferList
                includeVouchers={true}
                onlineOrPhysical="physical"
                sortBy="date:desc"
                itemsPerPage={this.props.itemsCount}
                rowCountLimit={this.props.itemsCount}
              />
              <div {...classes('twoButtons')}>
                <div {...classes('buttonWrapper', 'center', 'buttonPadding')}>
                  <GenericButton
                    style="bold"
                    css={{container: css.button}}
                    isFullWidth
                    onClick={() => {
                      log(evt.VIEW_OFFERS_BUTTON_HOMEPAGE, [
                        provider.AMPLITUDE,
                        provider.BRAZE
                      ])
                      this.props.history.push('/offers')
                    }}
                  >
                    {this.props.t('home.discounts.viewAll')}
                  </GenericButton>
                </div>
                {this.props.isUserVerified && (
                  <div
                    {...classes(
                      'buttonWrapper',
                      'center',
                      'buttonPadding',
                      'secondButton'
                    )}
                  >
                    <GenericButton
                      style="bold"
                      css={{container: css.button}}
                      isFullWidth
                      onClick={this.onClickRandomOffer}
                    >
                      {this.props.t('home.discounts.viewRandomOffer')}
                    </GenericButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <>
        <Layout
          noPadding={true}
          css={{middle: css.middle}}
          topStyle="sticky"
          top={
            <Header
              withNotifications={true}
              notificationFeedIsVisible={this.state.notificationFeedIsVisible}
              toggleNotificationFeed={this.toggleNotificationFeed}
              unreadNotificationsCount={this.state.unreadNotifications.length}
            />
          }
          middle={renderContent()}
          bottom={<Footer />}
        ></Layout>
        {this.state.shouldShowRandomOfferDialog && (
          <Portal node={document && document.getElementById('#portal')}>
            <RandomOfferDialog offer={this.props.randomOffer} />
          </Portal>
        )}
      </>
    )
  }
}

const handleTrialEnded = (state) => {
  const {hasOpenedTrialEnded, trialEnded, history} = state

  if (trialEnded && !hasOpenedTrialEnded && !isUserVerified(state)) {
    history.push('/profile/trial/ending')
  }
}
