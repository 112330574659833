import React, {Component} from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import Slider from 'react-slick'
import '!style-loader!css-loader!./slick-mobile-fix.nomod.css'
import withRunSaga, {WithRunSagaProps} from '$common/utils/withRunSaga'
import fetchOffers from '$src/offers/sagas/fetchOffers'
import {Offer} from '$src/offers/types'
import CarouselCard from '$src/offers/components/Carousel/CarouselCard-Offer'
import {setMobileArticleCarouselPage} from '$common/sagas'
import {getCarouselOffers} from '$src/offers/selectors'
import {
  ANALYTICS_EVENT as evt,
  ANALYTICS_PROVIDER as provider,
  logEvent2 as log
} from '$common/utils/logUtils'

type Props = WithRunSagaProps & {
  offers: Offer[]
  mobileArticleCarouselPage: number
}

class OfferPageCarousel extends Component<Props> {
  componentDidMount() {
    // this code fetches all carousel-offers
    // it puts them into redux

    this.props.runSaga(fetchOffers, {
      // url query params
      isCarousel: true,
      includeVouchers: false // has to be explictly set, until legacy client support is deprecated
    })
  }

  renderMobileSlides() {
    let offers: Array<Offer | {placeholder: boolean; key: string}> = this.props
      .offers

    if (offers.length === 0) {
      offers = [{placeholder: true, key: 'placeholder'}]
    }

    const slides = offers
      .sort((a, b) =>
        a.displayInCarouselOrder > b.displayInCarouselOrder
          ? 1
          : b.displayInCarouselOrder > a.displayInCarouselOrder
          ? -1
          : 0
      )
      .map((offer, i) => {
        return <CarouselCard key={offer.key} item={offer} mobile={true} />
      })
    return slides
  }

  handleAfterChange = (page: number) => {
    this.props.runSaga(setMobileArticleCarouselPage, page)
  }

  render() {
    return (
      <Slider
        arrows={false}
        adaptiveHeight={false}
        infinite={true}
        speed={200}
        slidesToShow={1}
        slidesToScroll={1}
        initialSlide={0}
        afterChange={this.handleAfterChange}
        dots={true}
        autoplay={true}
        autoplaySpeed={5000}
        onSwipe={() => {
          log(evt.OFFER_CAROUSEL_SWIPE, [provider.AMPLITUDE])
        }}
      >
        {this.renderMobileSlides()}
      </Slider>
    )
  }
}

export default compose(
  connect((state) => {
    return {
      offers: getCarouselOffers(state)
    }
  }),
  withRunSaga()
)(OfferPageCarousel)
