import {startOpintopolkuTrial as startOpintopolkuTrialAPI} from '../../api2/endpoints/opintopolku'
import {setUserProfile} from '../../common/utils'
import callEndpoint from '$src/api2/sagas/callEndpoint'

export function* startOpintopolkuTrial({url}) {
  const splitUrl = url.trim().split('/')
  const secret = splitUrl[splitUrl.length - 1]
  const response = yield callEndpoint(startOpintopolkuTrialAPI, {secret})
  yield setUserProfile(response)
}
