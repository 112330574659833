import {connect} from 'react-redux'
import {compose} from 'redux'

import {withTranslate} from '$common/utils'
import {getPreferredLang} from '$src/i18n/selectors'

import FeatureContent from './FeatureContent'

const mapStateToProps = (state) => {
  return {
    language: getPreferredLang(state),
    pricing: state.pricing
  }
}

export default compose(
  connect(mapStateToProps),
  withTranslate()
)(FeatureContent)
