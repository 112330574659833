import React, {Component} from 'react'
import Barcode from 'react-barcode'
import resolveCss from '$src/common/utils/resolveCss'
import css from '$src/offline-studentcard/components/BarcodeView/BarcodeView.css'
import kilroyIcon from '$assets/logos/kilroy.png'
import kelaIcon from '$assets/logos/kelaBlackCard.png'
import matkahuoltoIcon from '$assets/logos/matkahuolto.png'
import vrIcon from '$assets/logos/vr.png'
import isicLogo from '$assets/logos/isiclogo.png'
import LoadingSpinnerModal from '$src/common/components/LoadingSpinnerModal/index'
import {
  getStudentCardColor,
  STUDENT_CARD_COLOR
} from '$src/offline-studentcard/components/StudentCardColor'
import LogoContainer from './LogoContainer'

import {ProfileNamespaceState} from '$src/profile/types'
import {SubscriptionsNamespaceState} from '$src/subscriptions/types'
import {
  WithRunSagaProps,
  WithRouterProps,
  WithTranslateProps
} from '$common/utils/index'
import {logEvent} from '$src/common/utils/logUtils'

const Isic = (props) => {
  const {t, profile, onClick, isicNumber, isicPrice} = props
  const classes = resolveCss(css)
  return (
    <div
      {...classes(
        'isicContainer',
        `background-${getStudentCardColor(profile)}`,
        isicNumber ? 'isic' : 'noIsic'
      )}
      onClick={onClick}
    >
      <img {...classes('isicLogo')} src={isicLogo} />
      <div {...classes('isicTextContainer')}>
        {isicNumber ? (
          <div>
            <p {...classes('isicText')}>
              {t('studentId.internationalIsicCard')}
            </p>
            <p {...classes('isicNumber')}>{isicNumber}</p>
          </div>
        ) : (
          <p {...classes('noIsicText')}>
            {t('studentId.internationalIsicCardCTA', {
              'digital_isic_card-price': isicPrice
            })}
          </p>
        )}
      </div>
    </div>
  )
}

type Props = WithRunSagaProps &
  WithRouterProps &
  WithTranslateProps & {
    subscriptions: SubscriptionsNamespaceState
    profile: ProfileNamespaceState
    onClose: Function
    isicPrice: string
  }

type State = {
  loading: boolean
}

export default class BarcodeView extends Component<Props, State> {
  state = {
    loading: false
  }

  handleIsicClick = () => {
    if (!this.props.isicNumber) {
      this.props.history.push('/digi-isic')
    }
  }

  render() {
    const {profile} = this.props
    if (!profile.loaded) {
      return null
    } else if (this.state.loading) {
      return <LoadingSpinnerModal />
    }

    const classes = resolveCss(css)
    return (
      <div {...classes('barcodeContainer')} onClick={this.props.onClose}>
        <Isic
          t={this.props.t}
          profile={profile}
          isicPrice={this.props.isicPrice}
          isicNumber={this.props.isicNumber}
          onClick={this.handleIsicClick}
        />

        <div
          {...classes(
            'membership',
            `background-${getStudentCardColor(profile)}`
          )}
        >
          {this.props.profile.organization
            ? this.props.t('studentId.studentUnionMembershipIsValid')
            : ''}
        </div>
        <div {...classes('barcodeSvgContainer')}>
          <Barcode value={profile.email} width={4} height={150} fontSize={0} />
        </div>
        <LogoContainer logos={[kilroyIcon]} />
        {getStudentCardColor(profile) === STUDENT_CARD_COLOR.WHITE && (
          <LogoContainer logos={[matkahuoltoIcon, vrIcon]} />
        )}
        {getStudentCardColor(profile) === STUDENT_CARD_COLOR.BLUE && (
          <LogoContainer logos={[kelaIcon, matkahuoltoIcon, vrIcon]} />
        )}
        {getStudentCardColor(profile) === STUDENT_CARD_COLOR.GREEN && (
          <LogoContainer logos={[vrIcon]} />
        )}
      </div>
    )
  }
}
