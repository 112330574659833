import {formValueSelector} from 'redux-form'
import {compose} from 'redux'
import {getPreferredLang} from '$src/i18n/selectors'
import {getTrialPath} from '$src/profile/selectors'
import {
  connect,
  asForm,
  withRouter,
  withRunSaga,
  withTranslate
} from '$common/utils'
import {SignupPage} from './SignUpPage'
import {validatePassword, validateEmail} from '$common/validators'

const formName = 'onboarding/signup'
const formSelector = formValueSelector(formName)

const mapStateToProps = (state) => {
  return {
    language: getPreferredLang(state),
    emailValue: formSelector(state, 'email'),
    passwordValue: formSelector(state, 'password'),
    verifyCodeValue: formSelector(state, 'verifyCode'),
    trialPath: getTrialPath(state)
  }
}

const validate = ({email, hasAccepted, password}) => {
  return {
    email: validateEmail(email),
    hasAccepted: !hasAccepted,
    password: validatePassword(password)
  }
}

export default compose(
  asForm(formName, {validate}),
  connect(mapStateToProps),
  withRouter,
  withRunSaga(),
  withTranslate()
)(SignupPage)
