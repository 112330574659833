import {setUserProfile} from '../../common/utils'
import callEndpoint from '$src/api/sagas/callEndpoint'
import postProfileSynced from '../../api/endpoints/profile/postProfileSynced'

export default function* updateOpintopolkuSecret(url) {
  const splitUrl = url.trim().split('/')
  const secret = splitUrl[splitUrl.length - 1]
  const response = yield callEndpoint(postProfileSynced, {secret})
  yield setUserProfile(response)
}
