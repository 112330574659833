import {compose} from 'redux'
import {connect} from 'react-redux'

import NewsCarousel from '$src/news/components/NewsCarousel'
import {StoreState} from '$src/types'
import {getNewsItems} from '$src/news/selectors'
import fetchNews from '$src/news/sagas/fetchNews'
import {setNewsCarouselPage} from '$common/sagas'
import {selectNewsCarouselPage} from '$common/selectors'
import {withRunSaga} from '$common/utils'

const NewsCarouselContainer = compose(
  withRunSaga(),
  connect((state: StoreState, ownProps) => {
    return {
      fetchNews: () => ownProps.runSaga(fetchNews),
      news: getNewsItems(state),
      page: selectNewsCarouselPage(state),
      setCurrentPage: (page) => ownProps.runSaga(setNewsCarouselPage, page),
      handleClose: ownProps.handleClose
    }
  })
)(NewsCarousel)

export default NewsCarouselContainer
