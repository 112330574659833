import callEndpoint from '$src/api/sagas/callEndpoint'

function sendVerificationCodeEndpoint(
  {post}: any,
  payload: {phoneNumber: string}
): Promise<any> {
  return post(`/profile/verify-phone/start`, payload, {
    numberOfRetries: 4
  })
}

export function* sendVerificationCode(phoneNumber: string): Generator<> {
  return yield callEndpoint(sendVerificationCodeEndpoint, {phoneNumber})
}

function verifyPhoneNumberEndpoint(
  {post}: any,
  payload: {phoneNumber: string; verificationCode: string}
): Promise<any> {
  return post(`/profile/verify-phone/check`, payload, {
    numberOfRetries: 4
  })
}

export function* verifyPhoneNumber(
  phoneNumber: string,
  verificationCode: string
): Generator<> {
  return yield callEndpoint(verifyPhoneNumberEndpoint, {
    phoneNumber,
    verificationCode
  })
}
