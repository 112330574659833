import * as React from 'react'
import {compose} from 'redux'
import {Link} from 'react-router-dom'
import {get} from 'lodash'

import {connect} from '$common/utils'
import {withTranslate, withRouter} from '$common/utils'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import css from '$src/subscriptions/components/styles.css'
import HeaderTopFill from '$src/common/components/Header/HeaderTopFill'
import ConversationLayout from '$common/components/layouts/ConversationLayout'
import {getLatestActiveIsicSubscription} from '../../selectors'

const ExistingOrder = (props) => {
  const buttonStyles = {container: [css.button, css.horizontalButton]}

  const goBack = () => {
    props.history.goBack()
  }

  const conversationLayoutProps = {
    content: (
      <div className={css.content}>
        <H1 css={{container: css.heading}}>
          {props.t('subscriptions.digiIsicPurchase.existing.title')}
        </H1>
        <P css={{container: css.paragraph}}>
          {props.t('subscriptions.digiIsicPurchase.existing.body', {
            validTo: props.validTo
          })}
        </P>
      </div>
    ),
    buttons: (
      <div className={css.horizontalButtons}>
        <div className={css.left}>
          <GenericButton style="bold" css={buttonStyles} onClick={goBack}>
            {props.t('subscriptions.digiIsicPurchase.existing.goBack')}
          </GenericButton>
        </div>

        <div className={css.right}>
          <Link to="/digi-isic/summary">
            <GenericButton style="bold" css={buttonStyles}>
              {props.t('subscriptions.digiIsicPurchase.existing.continue')}
            </GenericButton>
          </Link>
        </div>
      </div>
    ),
    hideBackButton: true,
    dialogContainerStyle: 'black'
  }

  return (
    <div>
      <HeaderTopFill
        background={'linear-gradient(#036F89,#000000)'}
        position={'fixed'}
      />
      <ConversationLayout {...conversationLayoutProps} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    validTo: get(getLatestActiveIsicSubscription(state), 'validTo')
  }
}

export default compose(
  connect(mapStateToProps),
  withTranslate(),
  withRouter
)(ExistingOrder)
