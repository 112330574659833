import MobileDetect from 'mobile-detect'

const isMobileApp = () => Boolean(window.cordova)

const isMobileUserAgent = () => {
  const md = new MobileDetect(window.navigator.userAgent)
  return Boolean(md.mobile())
}

export default () => {
  return isMobileApp() || isMobileUserAgent()
}
