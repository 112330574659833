import * as Sentry from '@sentry/browser'
import {call} from 'redux-saga/effects'
import Auth from '@aws-amplify/auth'

import amplitude from '$src/analytics/amplitude'
import {getAppboy} from '$common/pgWebSeparatorUtils'
import {clearCache} from '$src/offline-studentcard/components/StudentCardView/PhotoCache'
import {resetState} from '$common/sagas'

export default function* logout(logOutGlobally = false) {
  try {
    yield call(() => Auth.signOut({global: logOutGlobally}))
  } catch (err) {
    console.error(err) // eslint-disable-line no-console
  } finally {
    yield call(resetLocalState)
  }
}

export function* resetLocalState() {
  const appboy = getAppboy()
  if (window.cordova && window.device.platform !== 'iOS') {
    appboy.wipeData()
  } else if (!window.cordova) {
    appboy.destroy()
    delete window.appboy
  }
  clearCache()
  amplitude.resetUserId()

  try {
    yield call(Sentry.flush.bind(Sentry), 2000)
  } catch (err) {
    /* No-op. We want to continue logout even if the Sentry.flush() times out. */
  }
  Sentry.configureScope((scope) => {
    scope.clear()
  })

  yield call(resetState)
}
