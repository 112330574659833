import React, {Component} from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import Slider from 'react-slick'

import withRunSaga, {WithRunSagaProps} from '$common/utils/withRunSaga'
import {fetchAllArticles} from '$src/articles/sagas/fetchArticles'
import {Article} from '$src/articles/types'
import PortraitSlide from '$src/articles/components/Carousel/PortraitSlide'
import BoxStackSlide from '$src/articles/components/Carousel/BoxStackSlide'
import Arrow from '$src/articles/components/Carousel/ArrowButton'
import {selectArticleCarouselPage} from '$common/selectors'
import {getDesktopArticles} from '$src/articles/selectors'
import {setArticleCarouselPage} from '$common/sagas'

type Props = WithRunSagaProps & {
  articles: Article[]
  mobileArticleCarouselPage: number
  desktopArticleCarouselPage: number
  mobile?: boolean
}

interface SlickCarousel {
  slickPrev: () => void
  slickNext: () => void
}

class DesktopCarousel extends Component<Props> {
  slider?: SlickCarousel

  componentDidMount() {
    if (this.props.articles.length === 0) {
      this.props.runSaga(fetchAllArticles)
    }
  }

  renderDesktopSlides() {
    let articles: Array<Article | {placeholder: true}> = this.props.articles

    // Fallback content
    if (articles.length === 0) {
      articles = Array(5).fill({placeholder: true})
    }

    const slides = articles.reduce((aggregate: JSX.Element[], article, i) => {
      const pageSize = 5
      const previousArticle = articles[i - 1]
      const isLastItem = i === articles.length - 1

      switch (i % pageSize) {
        // case for every second and fifth article in the grid: populate BoxStackSlide with two articles
        case 1:
        case 4:
          aggregate.push(
            <BoxStackSlide key={i} items={[previousArticle, article]} />
          )
          break
        // every third article in the grid is a PortraitSlide
        case 2:
          aggregate.push(<PortraitSlide key={i} article={article} />)
          break
        // case for every first and fourth article in the grid:
        // if last, create BoxStackSlide containing only that article.
        default:
          if (isLastItem) {
            aggregate.push(<BoxStackSlide key={i} items={[article]} />)
          }
          break
      }

      return aggregate
    }, [])

    return slides
  }

  handleAfterChange = (page: number) => {
    this.props.runSaga(setArticleCarouselPage, page)
  }

  renderPreviousArrow() {
    const {desktopArticleCarouselPage} = this.props
    const onFirstPage = desktopArticleCarouselPage === 0
    return <Arrow exists={!onFirstPage} prevSlide={this.prevSlide} />
  }

  renderNextArrow() {
    return <Arrow exists={true} nextSlide={this.nextSlide} />
  }

  prevSlide = () => this.slider?.slickPrev()
  nextSlide = () => this.slider?.slickNext()

  render() {
    const {desktopArticleCarouselPage} = this.props

    return (
      <Slider
        ref={(slider) => {
          this.slider = slider
        }}
        adaptiveHeight={false}
        infinite={false}
        speed={200}
        slidesToShow={3}
        slidesToScroll={3}
        initialSlide={desktopArticleCarouselPage}
        afterChange={this.handleAfterChange}
        swipe={false}
        nextArrow={this.renderNextArrow()}
        prevArrow={this.renderPreviousArrow()}
      >
        {this.renderDesktopSlides()}
      </Slider>
    )
  }
}

export default compose(
  connect((state) => {
    return {
      articles: getDesktopArticles(state),
      desktopArticleCarouselPage: selectArticleCarouselPage(state)
    }
  }),
  withRunSaga()
)(DesktopCarousel)
