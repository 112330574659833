import callEndpoint from '$src/api/sagas/callEndpoint'
import koski from '$src/api/endpoints/auth/koski'
import {setUserProfile} from '$common/utils'
import {getAppboy} from '$common/pgWebSeparatorUtils'
import {Profile, ProfileVerificationState} from '$src/profile/types'

export default function* verifyKoski(values) {
  const response: Profile & {
    state: ProfileVerificationState
  } = yield callEndpoint(koski, values)

  if (response.id) {
    const appboy = getAppboy()
    appboy.changeUser(response.id)
    if (!window.cordova) {
      appboy.openSession()
    }
    appboy.logCustomEvent('Registered')
  }

  yield setUserProfile(response)
}
