import * as React from 'react'

import css from './IsicLinkDialog.css'
import {resolveCss, withTranslate} from '$common/utils'

const IsicLinkDialog = (props) => {
  const classes = resolveCss(css)

  return (
    <div {...classes('menuWrapper')}>
      <div {...classes('menu')}>{props.t('subscriptions.digiIsicPurchase.isicLinkDialog.body')}</div>
    </div>
  )
}

export default withTranslate()(IsicLinkDialog)
