import React, {useEffect} from 'react'
import css from './notificationFeed.css'
import {resolveCss, withTranslate, connect, withRouter} from '$common/utils'
import P from '$common/components/P'
import moment from 'moment'
import {compose} from 'redux'
import {
  ANALYTICS_EVENT as evt,
  ANALYTICS_PROVIDER as provider,
  logEvent2 as log
} from '$common/utils/logUtils'
import {getPreferredLang} from '$src/i18n/selectors'
import {StoreState} from '$src/types'
import _ from 'lodash'

const NotificationFeed = ({
  t,
  notifications,
  toggleNotificationFeed,
  history,
  preferredLang,
  notificationFeedIsVisible
}) => {
  if (notificationFeedIsVisible === null) {
    return null
  }
  const classes = resolveCss(css)
  moment.locale(preferredLang)

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [])

  const handleClick = (notification) => {
    let extras
    if (!_.isEmpty(notification.extras) && notification.extras !== '{}') {
      /* the extras object nested inside the notification 
      object needs to be parsed on ios */
      if (notification.extras.unbounce) {
        extras = notification.extras
      } else {
        extras = JSON.parse(notification.extras)
      }
      // if extras.unbouce has some content, then we'll open the
      // unbounce url in the in app browser
      // in app browser doesn't work with urls that are without http(s)
      if (
        extras.unbounce &&
        (extras.unbounce.includes('https') || extras.unbounce.includes('http'))
      ) {
        const url = extras.unbounce
        const target = '_blank'
        const options = 'location=no,toolbar=yes,presentationstyle=fullscreen,'
        const inAppBrowser = window.open(url, target, options)
        inAppBrowser.addEventListener('message', function(e: any) {
          inAppBrowser.close()
          history.push(e.data.message)
        })
      }
    }
    AppboyPlugin.logContentCardClicked(notification.id)
    log(evt.NOTIFICATION_FEED_ITEM_CLICKED, [provider.AMPLITUDE], {
      url: notification.url ? notification.url : extras.unbounce
    })
    if (notification.url) {
      history.push(notification.url)
    }
  }

  return (
    <div
      {...classes(
        notificationFeedIsVisible ? 'containerOpen' : 'containerClose'
      )}
      onClick={() => toggleNotificationFeed('close')}
    >
      <div
        {...classes(
          notificationFeedIsVisible
            ? 'notificationDrawerOpen'
            : 'notificationDrawerClose'
        )}
      >
        {notifications.length ? (
          notifications.map((notification) => (
            <div
              key={notification.id}
              {...classes('notificationContainer')}
              onClick={() => handleClick(notification)}
            >
              <P style={'normal'} bolder={true} css={{container: css.time}}>
                {moment.unix(notification.created).fromNow()}
              </P>
              <P style={'normal'} bolder={true} color={'black'}>
                {notification.cardDescription}
              </P>
            </div>
          ))
        ) : (
          <div style={{margin: 10}}>
            <P
              style={'normal'}
              bolder={true}
              color={'white'}
              css={{container: css.noMessages}}
            >
              {t('notificationFeed.empty')}
            </P>
          </div>
        )}
      </div>
    </div>
  )
}

export default compose(
  connect((state: StoreState) => ({preferredLang: getPreferredLang(state)})),
  withRouter,
  withTranslate()
)(NotificationFeed)
