import {put, select} from 'redux-saga/effects'

import {SET_NOTIFICATION} from '$src/notification/reducer'

export default function* clearNotification(): Generator<> {
  const {notification} = yield select()

  const emptyNotification = Object.keys(notification).reduce(
    (emptyNotification, key) => {
      emptyNotification[key] = undefined
      return emptyNotification
    },
    {}
  )

  yield put({type: SET_NOTIFICATION, payload: emptyNotification})
}
