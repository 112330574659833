import * as React from 'react'
import {compose} from 'redux'
import MediaQuery from 'react-responsive'
import {Link} from 'react-router-dom'
import {SubmissionError, FormProps} from 'redux-form'

import BackButton from '$common/components/buttons/BackButton'
import SvgIcon from '$common/components/icons/SvgIcon'
import messageIcon from '$assets/icons/message.svg'
import ErrorCandy from '$common/components/candyBars/ErrorCandy'
import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import LanguageSelector from '$src/i18n/components/LanguageSelector'
import Layout from '$common/components/layouts/TopMiddleBottomLayout'
import OnboardingLayout from '$src/onboarding/components/OnboardingLayout'
import PasswordInput from '$common/components/forms/PasswordInput'
import TextInput from '$common/components/forms/TextInput'
import align from '$common/grid/align.css'
import css from './index.css'
import grid from '$common/grid/grid.css'
import login from '$src/auth/sagas/login'
import {
  createApiErrorMessage,
  asForm,
  resolveCss,
  withRouter,
  WithRouterProps,
  withRunSaga,
  WithRunSagaProps,
  withTranslate,
  WithTranslateProps
} from '$common/utils'
import {isCurrentPage} from '$src/common/pgWebSeparatorUtils'

interface FieldValues {
  email: string
  password: string
}

type Props = WithRouterProps &
  WithRunSagaProps &
  WithTranslateProps &
  FormProps & {
    fieldValues: FieldValues
  }

class LoginPage extends React.Component<Props> {
  onSubmit = async ({email, password}: FieldValues) => {
    try {
      await this.props.runSaga(login, {email, password})
    } catch (err) {
      if (
        ['NotAuthorizedException', 'UserNotFoundException'].includes(err.code)
      ) {
        throw new SubmissionError({
          _error: this.props.t('error.400'),
          general: true
        })
      }

      const errMsg = createApiErrorMessage(err)
      throw new SubmissionError({_error: this.props.t(errMsg), general: true})
    }
  }

  hideError() {
    this.setState(() => ({showingError: false}))
  }

  closePWResetToast = () => this.props.history.push('/login')

  render() {
    if (!isCurrentPage(this.props)) {
      return null
    }
    const classes = resolveCss(css, grid, align)
    const userHasResetPassword =
      this.props.location.state && this.props.location.state.passwordReset

    const ctaButton = (
      <GenericButton
        type="submit"
        style="bold"
        loading={this.props.submitting}
        disabled={this.props.submitting || this.props.invalid}
      >
        {this.props.t('authentication.logIn.cta')}
      </GenericButton>
    )

    const secondaryCtaButton = (
      <Link to="/">
        <GenericButton type="button" style="outline">
          {this.props.t('authentication.logIn.secondaryCta')}
        </GenericButton>
      </Link>
    )

    const forgotPassword = (
      <Link to="/forgot-password">
        <GenericButton type="button" style="subtle">
          {this.props.t('authentication.logIn.forgotPassword')}
        </GenericButton>
      </Link>
    )

    return (
      <OnboardingLayout>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Layout
            adaptiveLayout={{
              stackMiddle: true
            }}
            top={
              <div {...classes('columnLarge11')}>
                <MediaQuery maxWidth={800}>
                  {(isMobile) => {
                    if (isMobile) {
                      return (
                        <div className={grid.row}>
                          <div {...classes('column', 'onboardingHeader')}>
                            <BackButton
                              type="button"
                              onClick={() => this.props.history.push('/')}
                            />
                          </div>
                        </div>
                      )
                    } else {
                      return (
                        <div {...classes('right', 'onboardingHeader')}>
                          <div
                            key="language-selector"
                            className={css.selectorContainer}
                          >
                            <LanguageSelector />
                          </div>
                        </div>
                      )
                    }
                  }}
                </MediaQuery>
              </div>
            }
            middle={
              <div {...classes('columnLarge8', 'offsetLarge2')}>
                <MediaQuery maxWidth={800}>
                  <FrankLogoInSmallWhite />
                </MediaQuery>
                <H1 css={{container: css.h1}}>
                  {this.props.t('authentication.logIn.title')}
                </H1>
                <TextInput
                  type="email"
                  name="email"
                  placeholder={this.props.t('common.emailPlaceholder')}
                  label={this.props.t('common.emailAddress')}
                  autocomplete="username"
                />
                <PasswordInput
                  name="password"
                  placeholder={this.props.t('common.passwordPlaceholder')}
                  label={this.props.t('authentication.password')}
                  autocomplete="current-password"
                />

                <ErrorCandy
                  autoHide
                  top
                  white
                  centerContent
                  show={userHasResetPassword}
                  onHide={this.closePWResetToast}
                >
                  <div {...classes('pwResetToastText')}>
                    <SvgIcon
                      css={{container: css.messageBubble}}
                      src={messageIcon}
                    />
                    &nbsp;
                    <p>{this.props.t('authentication.passwordHasBeenReset')}</p>
                  </div>
                </ErrorCandy>

                <ErrorCandy
                  show={this.props.submitFailed && !!this.props.error}
                  onHide={this.props.initWithValues}
                >
                  {this.props.error}
                </ErrorCandy>
              </div>
            }
            bottom={
              <div {...classes('columnLarge8', 'offsetLarge2')}>
                <MediaQuery maxWidth={800}>
                  {(isMobile) => {
                    if (isMobile) {
                      return ctaButton
                    } else {
                      return (
                        <div className={grid.row}>
                          <div {...classes('column')}>
                            {ctaButton}
                            &nbsp;
                            {secondaryCtaButton}
                          </div>
                        </div>
                      )
                    }
                  }}
                </MediaQuery>
                <div style={{marginTop: 25}}>{forgotPassword}</div>
              </div>
            }
          />
        </form>
      </OnboardingLayout>
    )
  }
}

export default compose(
  asForm('onboarding/login', {
    destroyOnUnmount: process.env.NODE_ENV !== 'development',
    validate: (values: FormValues) => {
      return {
        email: !values.email || !values.email.length,
        password: !values.password || !values.password.length
      }
    }
  }),
  withRouter,
  withRunSaga(),
  withTranslate()
)(LoginPage)
