import React, {Component} from 'react'
import {compose} from 'redux'
import css from './ErrorCandy.css'
import {Portal} from 'react-overlays'
import {resolveCss} from '$common/utils'
import SvgIcon from '$common/components/icons/SvgIcon'
import closeIcon from '$assets/icons/close.svg'
import {navBarDisabledForRoute} from '$common/components/NavBar/NavBar'
import {WithRouterProps, withRouter} from '$common/utils'

type Props = WithRouterProps & {
  show: boolean
  onHide: Function
  children?: any
  css?: any
  autoHide?: boolean
  hideTimeout?: number
  top?: boolean
  centerContent?: boolean
  white?: boolean
}

class ErrorCandy extends Component<Props> {
  static defaultProps = {hideTimeout: 10000}
  timeOutFunc = undefined
  UNSAFE_componentWillMount() {
    if (this.props.autoHide && this.props.show) {
      this.timeOutFunc = setTimeout(this.hideIfShown, this.props.hideTimeout)
    }
  }

  componentWillUnmount() {
    this.timeOutFunc && clearTimeout(this.timeOutFunc)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.autoHide && nextProps.show && !this.props.show) {
      this.timeOutFunc = setTimeout(this.hideIfShown, this.props.hideTimeout)
    }
  }

  hideIfShown = () => this.props.show && this.props.onHide()

  render() {
    const {
      show,
      onHide,
      children,
      top,
      centerContent,
      white,
      css: customCss
    } = this.props
    const classes = resolveCss(css, customCss)

    const push =
      window.cordova && !navBarDisabledForRoute(this.props.location.pathname)
    return (
      <Portal container={document.body} show={true}>
        <div
          {...classes(
            'container',
            top && 'top',
            centerContent && 'centerContent',
            white && 'white',
            show && 'show',
            push && 'push'
          )}
        >
          <a onClick={onHide} {...classes('closeButtonContainer')}>
            <SvgIcon src={closeIcon} sizing="em" />
          </a>
          <div {...classes('content')}>{children}</div>
        </div>
      </Portal>
    )
  }
}

export default compose(withRouter, (a) => a)(ErrorCandy)
