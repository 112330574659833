import callEndpoint from '$src/api/sagas/callEndpoint'
import {confirmOrder as confirmOrderEndpoint} from '$src/api/endpoints/subscriptions/confirmOrder'

/**
 *
 * @param {ConfirmOrderPayload} order
 * @returns {IterableIterator<ConfirmOrderResponse>}
 */
export function* confirmOrder(order) {
  return yield callEndpoint(confirmOrderEndpoint, order)
}
