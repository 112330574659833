import * as React from 'react'
import {compose} from 'redux'

import SvgIcon from '$common/components/icons/SvgIcon'
import checkboxChecked from '$assets/icons/checkboxChecked.svg'
import checkboxCheckedWhite from '$assets/icons/checkboxCheckedWhite.svg'
import checkboxNotChecked from '$assets/icons/checkboxNotChecked.svg'
import checkboxNotCheckedWhite from '$assets/icons/checkboxNotCheckedWhite.svg'
import css from './CheckBoxField.css'
import {resolveCss, asField} from '$common/utils'

type Props = {
  input: {
    value: any
    onChange: Function
  }
  css?: any
  label: string
  color?: string
  disableToggleFromLabel?: boolean
}

const CheckBoxField = (props: Props) => {
  const checked = props.input.value === true
  const classes = resolveCss(css, props.css)

  const checkbox =
    props.color && props.color === 'white' ? (
      <SvgIcon
        src={checked ? checkboxCheckedWhite : checkboxNotCheckedWhite}
        sizing="none"
        css={{container: css.icon}}
      />
    ) : (
      <SvgIcon
        src={checked ? checkboxChecked : checkboxNotChecked}
        sizing="none"
        css={{container: css.icon}}
      />
    )

  const label = (
    <div {...classes('right')}>
      <div {...classes('label')}>{props.label}</div>
    </div>
  )

  return props.disableToggleFromLabel ? (
    <div {...classes('container', checked && 'checked')}>
      <div {...classes('left')} onClick={() => props.input.onChange(!checked)}>
        {checkbox}
      </div>
      {label}
    </div>
  ) : (
    <div
      {...classes('container', checked && 'checked')}
      onClick={() => props.input.onChange(!checked)}
    >
      <div {...classes('left')}>{checkbox}</div>
      {label}
    </div>
  )
}

export default compose(asField())(CheckBoxField)
