import moment from 'moment'
import {get} from 'lodash'

import {
  ProfileNamespaceState,
  InternalEducation,
  AvailableEducation,
  ProfileVerificationState,
  StudentTypes
} from '$src/profile/types'
import {StoreState} from '$src/types'
import {Lang} from '$src/i18n/types'

interface LocalizedEducation {
  name: string
  schoolId?: string
  jollaId?: string
  internalEducationId: string
}
interface Localizable {
  fi: string
  en: string
  sv: string
}
interface EducationDto {
  name: string
  localizedName: Localizable
  internalEducationId: string
  schoolId?: string
  jollaId?: string
}
export interface Schools {
  polytechnic: EducationDto[]
  university: EducationDto[]
  second_degree: EducationDto[]
}

const getUnlocalizedEducation = (
  unlocalized: InternalEducation | undefined,
  educations: LocalizedEducation[]
): InternalEducation | undefined => {
  if (!unlocalized) {
    return undefined
  }
  const education = educations.find(
    (ed) => ed.internalEducationId === unlocalized.id
  )
  return {
    ...unlocalized,
    name: education ? education.name : unlocalized.name
  }
}

const mapAvailableEducationToLocalized = (educations: LocalizedEducation[]) => (
  ae: AvailableEducation
): AvailableEducation => {
  const organization = educations.find(
    (education) => education.internalEducationId === ae.organizationId
  )
  const organizationName = organization
    ? organization.name
    : ae.organizationName
  const school = educations.find(
    (education) => education.internalEducationId === ae.schoolId
  )
  const schoolName = school ? school.name : ae.schoolName
  return {
    ...ae,
    organizationName,
    schoolName
  }
}

export function getProfile(state: StoreState): ProfileNamespaceState {
  const profile: ProfileNamespaceState = state.profile
  if (!profile.loaded) {
    return {loaded: false}
  }

  const schools = getSchools(state)
  if (!schools) {
    // While schools are loading profile will contain untranslated school names
    return profile
  }

  const educations = schools.polytechnic.concat(
    schools.university,
    schools.second_degree
  )

  const organization = getUnlocalizedEducation(profile.organization, educations)
  const school = getUnlocalizedEducation(profile.school, educations)
  const availableEducations = profile.availableEducations
    ? profile.availableEducations.map(
        mapAvailableEducationToLocalized(educations)
      )
    : undefined

  return {...profile, organization, school, availableEducations}
}

export const getVerificationState = (
  state: StoreState
): ProfileVerificationState => {
  const profileState = getProfile(state)
  if (profileState.loaded) {
    return profileState.verificationState
  }
  return 'UNVERIFIED'
}

export const getProfilePhoto = (state: StoreState) => {
  const profileState = getProfile(state)
  if (profileState.loaded) {
    return profileState.photo
  }
}

// FIXME: Since Koski, being a student and being verified are no longer equal (a person can now be a verified non-student)
// It would be clearer if there was an explicit 'isStudent' selector.
export const isUserVerified = (state: StoreState) => {
  const verificationState = getVerificationState(state)
  const studentType = getStudentType(state)

  return (
    (verificationState === 'JOLLA' ||
      verificationState === 'KOSKI|JOLLA' ||
      verificationState === 'KOSKI') &&
    studentType !== 'not_a_student'
  )
}

export const isUserUnverified = (state: StoreState) => {
  return !isUserVerified(state)
}

export const isUserNotKoskiVerified = (state: StoreState) => {
  return !getVerificationState(state).includes('KOSKI')
}

export const isUserActive = (state: StoreState) => {
  const profileState = getProfile(state)
  if (!profileState || !profileState.loaded) {
    return false
  }

  const studentStatus = profileState.studentStatus

  if (!studentStatus || studentStatus.presenceCode === 0) {
    return false
  }
  // If there is no presentUntil date, this will return today, that's why we need to check presenceCode separately.
  const presentUntil = moment(studentStatus.presentUntil).endOf('day')
  return moment().isBefore(presentUntil)
}

export function getStudentType(state: StoreState): StudentTypes {
  const profileState = getProfile(state)
  if (profileState.loaded) {
    return profileState.studentType
  }
  return 'not_a_student'
}

export const shouldShowEducationSelect = (state: StoreState) => {
  const profileState = getProfile(state)
  if (
    !profileState ||
    !profileState.loaded ||
    !isUserVerified(state) ||
    !profileState.availableEducations ||
    profileState.availableEducations.length < 1
  ) {
    return false
  }

  if (profileState.availableEducations.length > 1) {
    return true
  }

  const organizationId = get(profileState, 'organization.id', false)
  const schoolId = get(profileState, 'school.id', false)

  const isCurrentAvailable = profileState.availableEducations.some(
    (education) => {
      return (
        education.schoolId === schoolId ||
        education.organizationId === organizationId
      )
    }
  )

  return !isCurrentAvailable
}

const mapEducationToLocalizedEducation = (language: Lang) => (
  education: EducationDto
): LocalizedEducation => ({
  name: education.localizedName[language],
  schoolId: education.schoolId,
  jollaId: education.jollaId,
  internalEducationId: education.internalEducationId
})

/**
 * Get list of schools and Jolla organisations, for verification drop-downs.
 * Here because the corresponding saga is under the profile directory (where all verification code lives).
 */
export function getSchools(state: StoreState) {
  const profile = state.profile
  if (profile.loaded === false) {
    return undefined
  }

  const language: Lang = profile?.language ?? 'fi'

  const schools: Schools = state.schools

  // Fix for international school name migration to 3.5.0 version, so old local storage data is not used to try to translate
  // Remove this after all clients have updated to at least version 3.5.0
  if (!schools || !schools.university[0].localizedName) {
    return undefined
  }

  return {
    polytechnic: schools.polytechnic.map(
      mapEducationToLocalizedEducation(language)
    ),
    university: schools.university.map(
      mapEducationToLocalizedEducation(language)
    ),
    second_degree: schools.second_degree.map(
      mapEducationToLocalizedEducation(language)
    )
  }
}

export const getTrialPath = (state: StoreState) => {
  if (state.trialPath) {
    return true
  }
  return false
}

export const shouldDisplayTrialStartButton = (state: StoreState) => {
  // if profile.trial.daysLeft exists, then we don't want to show the activate trial
  // button anymore,  does not matter if the trial is active or not
  return true
}

export const hasOpenedTrialEndedSelector = (state) => {
  if (state.common.hasOpenedTrialEnded) {
    return true
  }
  return false
}

export const userId = (state: StoreState) => state.profile?.userId

export const isUserAlumn = (state: StoreState) => state.profile.alumn?.isAlumn

export const isUserFhsStudent = (state: StoreState) =>
  state.profile.studentType === 'folk_high_school' ? true : false
