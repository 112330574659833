import {actionCreator} from '$common/utils'

/**
 * This is so far the only namespace where we have actual action creators, rather
 * than just using `reduceState()` directly.
 */

export const setLoggedInToCognito = actionCreator(
  'auth/loggedInToCognito',
  (loggedIn) => loggedIn
)
