import {select, call} from 'redux-saga/effects'
import {isEmpty} from 'lodash'

import {getProfile} from '$src/profile/selectors'
import {reduceState} from '$common/utils'
import putProfileEndpoint from '$src/api/endpoints/profile/put'
import {callEndpoint} from '$src/api/sagas/callEndpoint'

// https://www.viestintavirasto.fi/internetpuhelin/puhelinverkonnumerointi/muutteleverkonkooditjatunnukset/mnc-tunnukset.html
// Downloaded Oct 8, 2018
const CARRIERS = {
  '03': 'DNA Oyj',
  '04': 'DNA Oyj',
  '05': 'Elisa Oyj',
  '06': 'Elisa Oyj',
  '07': 'Nokia Solutions and Networks Oy',
  '08': 'Nokia Solutions and Networks Oy',
  '09': 'Nokia Solutions and Networks Oy',
  '10': 'Viestintävirasto',
  '11': 'Viestintävirasto',
  '12': 'DNA Oyj',
  '13': 'DNA Oyj',
  '14': 'Ålands Telekommunikation Ab',
  '20': 'Telia Finland Oyj',
  '21': 'Elisa Oyj',
  '26': 'Compatel Limited',
  '27': 'Teknologian tutkimuskeskus VTT Oy',
  '28': 'Teknologian tutkimuskeskus VTT Oy',
  '32': 'Voxbone S.A.',
  '33': 'Suomen Virveverkko Oy',
  '34': 'Bittium Wireless Oy',
  '35': 'Ukkoverkot Oy',
  '36': 'Telia Finland Oyj',
  '37': 'Tismi BV',
  '38': 'Nokia Solutions and Networks Oy',
  '39': 'Nokia Solutions and Networks Oy',
  '40': 'Nokia Solutions and Networks Oy',
  '41': 'Nokia Solutions and Networks Oy',
  '42': 'SMS Provider Corp.',
  '43': 'Telavox AB / Telavox OY',
  '44': 'Turun ammattikorkeakoulu Oy',
  '50': 'Aalto-korkeakoulusäätiö sr',
  '51': 'Aalto-korkeakoulusäätiö sr',
  '52': 'Aalto-korkeakoulusäätiö sr',
  '53': 'Aalto-korkeakoulusäätiö sr',
  '54': 'Aalto-korkeakoulusäätiö sr',
  '55': 'Aalto-korkeakoulusäätiö sr',
  '56': 'Aalto-korkeakoulusäätiö sr',
  '57': 'Aalto-korkeakoulusäätiö sr',
  '58': 'Aalto-korkeakoulusäätiö sr',
  '59': 'Aalto-korkeakoulusäätiö sr',
  '91': 'Telia Finland Oyj'
}

const MCC_TEXT = 'fi'
const MCC_NUMBER = '244'

function readCarrierInfo() {
  return new Promise((resolve) => {
    window.plugins.sim.getSimInfo(
      (data) => {
        if (data.carrierName) {
          resolve(data.carrierName)
        } else {
          resolve(carrierFromMnc(data.mnc, data.mcc))
        }
      },
      (err) => {
        // eslint-disable-next-line
        console.log('Error reading mobile carrier name: ', err.message)
        resolve()
      }
    )
  })
}

function carrierFromMnc(mnc, mcc) {
  if (mcc !== MCC_TEXT && mcc !== MCC_NUMBER) {
    return 'unknown_non_finnish'
  } else if (!CARRIERS[mnc]) {
    // If this happens, the carrier list above might be out of date.
    return mnc
  } else {
    return CARRIERS[mnc]
  }
}

export default function* updateUserPhoneAttributes() {
  if (!window.cordova) return

  const profile = yield select(getProfile)
  const carrier = yield call(readCarrierInfo)

  const updates = {}

  if (carrier !== profile.carrier) {
    updates.carrier = carrier
  }

  if (!isEmpty(updates)) {
    const result = yield callEndpoint()(putProfileEndpoint, updates)
    yield reduceState(['profile'], (profileState) => ({
      ...profileState,
      ...result
    }))
  }
}
