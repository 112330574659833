import {combineReducers} from 'redux'
import {handleActions} from 'redux-actions'

import {bootstrapAction} from './actions'

import {setHasOpenedTrialEnded} from '../profile/reducer'

export default combineReducers({
  ui: combineReducers({
    articleCarouselPage: (state = 0) => state,
    mobileArticleCarouselPage: (state = 0) => state,
    newsCarouselPage: (state = 0) => state
  }),
  temp: combineReducers({
    bootstrapped: handleActions(
      {
        [bootstrapAction.type]: () => true
      },
      false
    )
  }),
  hasOpenedTrialEnded: setHasOpenedTrialEnded
})
