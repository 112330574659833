import {connect} from 'react-redux'
import {
  WithRouterProps,
  WithTranslateProps,
  WithResponsiveInfoProps,
  launchRating,
  resolveCss,
  isDeviceOnline
} from '$common/utils'

import {
  logEvent2,
  ANALYTICS_EVENT,
  ANALYTICS_PROVIDER
} from '$src/common/utils/logUtils'
import TopMiddleBottomLayout from '$common/components/layouts/TopMiddleBottomLayout'
import Header from '$common/components/Header/Header-container'
import OfferList from '$src/offers/components/ListPage/OfferList-container'
import css from './ListPage.css'
import grid from '$common/grid/grid.css'
import queryString from 'query-string'
import OfflineModal from '$common/components/modals/OfflineModal'
import OfferPageCarousel from '$src/offers/components/Carousel/OfferPageCarousel'
import SearchInput from '$src/offers/components/ListPage/SearchInput'
import P from '$common/components/P'
import H1 from '$common/components/headers/H1'
import SearchTermList from './SearchTermList'

import omit from 'lodash/omit'
import React, {useState, useEffect} from 'react'
import MediaQuery from 'react-responsive'

import DropDown from '$common/components/DropDown'
import {categories, sort} from '$src/offers/data'

import 'style-loader!css-loader!react-virtualized/styles.css'
import {getAppboy} from '$common/pgWebSeparatorUtils'
import getLocations from '$src/offers/sagas/getLocations'

type Props = WithRouterProps &
  WithTranslateProps &
  WithResponsiveInfoProps & {
    location: {path: string}
    isDesktop: boolean
  }

const ListPage = (props: Props) => {
  const termClick = React.useRef(null)
  const {isDesktop, t} = props
  const itemsPerPage = 20
  const path = '/offers'
  const classes = resolveCss(css, grid)

  const [showCategories, setShowCategories] = useState(isDesktop ? false : true)
  const [showSort, setShowSort] = useState(false)
  const [showLocations, setShowLocations] = useState(false)
  const [showFilterDropDown, setShowFilterDropDown] = useState(false)
  const [search, setSearch] = useState('')
  const [ratingRequested, setRatingRequested] = useState(false)
  const [showSearchTermList, setShowSearchTermList] = useState(false)
  const [searchTerms, setSearchTerms] = useState(null)
  const backButtonHandler = () => {
    if (showFilterDropDown) {
      setShowFilterDropDown(false)
    } else {
      props.history.push('/home')
    }
  }

  const fetchLocations = async () => {
    await props.runSaga(getLocations)
  }

  const setSearchTermToStorage = (term) => {
    const list = searchTerms.slice(0)
    const index = list.findIndex((item) => item.term === term)
    if (index >= 0) {
      list[index].amount++
      list[index].date = new Date().toISOString()
    } else {
      list.push({
        term: term,
        amount: 1,
        date: new Date().toISOString()
      })
    }

    localStorage.setItem('search_terms', JSON.stringify(list))
    setSearchTerms(list)
  }

  const getSearchTerms = () => {
    const string = localStorage.getItem('search_terms')
    if (string) {
      const list = JSON.parse(string)
      if (Array.isArray(list) && list.length) {
        return list
      }
    }
    return []
  }

  useEffect(() => {
    logEvent2(ANALYTICS_EVENT.OPEN_OFFERPAGE, [
      ANALYTICS_PROVIDER.AMPLITUDE,
      ANALYTICS_PROVIDER.BRAZE
    ])
    setSearchTerms(getSearchTerms())

    gotoSort(sort[0].id)
    document.addEventListener('mousedown', handleClickOutsideList, false)
    document.addEventListener('backbutton', backButtonHandler)

    const parsedURL = queryString.parse(props.location.search)
    if (parsedURL.search) {
      setSearch(parsedURL.search)
    }

    fetchLocations()
    // returned function will be called on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideList, false)
      document.removeEventListener('backbutton', backButtonHandler)
    }
  }, [])

  const handleClickOutsideList = (e: Event) => {
    if (document.querySelector('body') === e.target) {
      setShowFilterDropDown(false)
    }
  }

  const parsedURL = queryString.parse(props.location.search)

  const gotoCategory = (id: any) => {
    if (id === '0') {
      delete parsedURL.category
      return props.history.push({
        path: path,
        search: '?' + queryString.stringify(omit(parsedURL, 'category'))
      })
    }

    const arrayOfPickedCategories = parsedURL.category
      ? parsedURL.category.split(',') //Split of empty string will not give us empty array
      : []
    const catIndex = arrayOfPickedCategories.indexOf(id)

    if (catIndex > -1) {
      arrayOfPickedCategories.splice(catIndex, 1)
      //If there is no more picked categories => go back to All categories
      if (arrayOfPickedCategories.length === 0) {
        return gotoCategory('0')
      }
    } else {
      logCategoryToAppboy(id)
      arrayOfPickedCategories.push(id)
    }
    //Sorting to cash more properly
    parsedURL.category = arrayOfPickedCategories.sort().join()
    props.history.push({
      path: path,
      search: '?' + queryString.stringify(parsedURL)
    })
  }

  const gotoSort = (id: any) => {
    parsedURL.sort = id
    props.history.push({
      path: path,
      search: '?' + queryString.stringify(parsedURL)
    })
  }

  const gotoLocation = (city: any) => {
    const arrayOfPickedCities = parsedURL.city
      ? parsedURL.city.split(',') //Split of empty string will not give us empty array
      : []
    const cityIndex = arrayOfPickedCities.indexOf(city)
    if (cityIndex > -1) {
      arrayOfPickedCities.splice(cityIndex, 1)
      //If there is no more picked cities => go back to All categories
      if (arrayOfPickedCities.length === 0) {
        delete parsedURL.city
        return props.history.push({
          path: path,
          search: '?' + queryString.stringify(omit(parsedURL, 'city'))
        })
      }
    } else {
      arrayOfPickedCities.push(city)
    }
    //Sorting to cash more properly
    parsedURL.city = arrayOfPickedCities.sort().join()
    const searchString = '?' + queryString.stringify(parsedURL)

    props.history.push({
      path: path,
      search: searchString
    })
  }

  const closeSortDropDown = () => isDesktop && setShowSort(false)

  const closeCategoriesDropDown = () => isDesktop && setShowCategories(false)

  const closeLocationsDropDown = () => isDesktop && setShowLocations(false)

  const onFilterClick = () => {
    setShowFilterDropDown(!showFilterDropDown)
    setShowSearchTermList(false)
  }

  const logCategoryToAppboy = (id: any) => {
    const category = categories.find((category) => category.id === id)
    if (category && parseInt(id) > 0) {
      getAppboy().logCustomEvent('Change category', {category: id})
      getAppboy().logCustomEvent(`Change to category ${id}`)
    }
  }

  const initiateSearch = (value?: string) => {
    const parsedURL = queryString.parse(props.location.search)
    if (value === '') {
      props.history.push({
        path: '/offers',
        search: '?' + queryString.stringify(omit(parsedURL, 'search'))
      })
    } else {
      parsedURL.search = value
      props.history.push({
        path: '/offers',
        search: '?' + queryString.stringify(parsedURL)
      })
    }
    setSearch(value)
  }

  const onSearchTermClick = (term) => {
    termClick.current(term)
  }

  // are these necessary??
  const shouldRenderCategories =
    (showFilterDropDown || isDesktop) && isDeviceOnline()

  const mobileContent =
    !queryString.parse(props.location.search).search &&
    !queryString.parse(props.location.search).category ? (
      <div {...classes('mobileContent')}>
        <div {...classes('mobileDivider')}>
          <P style="small" color="white" align="centerText">
            {t('offers.carusellTitle')}
          </P>
        </div>
        <div {...classes('mobileArticleCarouselContainer')}>
          <OfferPageCarousel />
        </div>
        <div {...classes('mobileDivider')}>
          <P style="small" color="white" align="centerText">
            {t('home.discounts.title')}
          </P>
        </div>
      </div>
    ) : (
      <div {...classes('mobileContent', search && 'middle')}>
        <div {...classes('mobileDivider')}>
          <P style="small" color="white" align="centerText">
            {t('home.discounts.title')}
          </P>
        </div>
      </div>
    )

  const desktopContent = (
    <div {...classes('contentContainer')}>
      <div {...classes('carusellTitle')}>
        <P color="black" align="centerText" css={{container: css.dividerText}}>
          {t('offers.carusellTitle')}
        </P>
      </div>
      <div {...classes('pageThreeArticleCarouselContainer')}>
        <OfferPageCarousel />
      </div>
    </div>
  )

  // this code is use for triggering the rating request
  // via Braze
  if (
    window.cordova &&
    props.location.search.includes('rating') &&
    !ratingRequested
  ) {
    setTimeout(launchRating, 500)
    setRatingRequested(true)
  }

  return (
    <TopMiddleBottomLayout
      adaptiveLayout={{
        horizontallyCenterMiddle: true
      }}
      noPadding={true}
      css={{container: css.container, middle: css.middle}}
      topStyle="sticky"
      top={[
        <Header
          key="topHeader"
          isFilter={!isDesktop}
          onFilterClick={onFilterClick}
          isFilterOpen={showFilterDropDown}
          withSearch={isDesktop ? false : true}
          search={search}
          initiateSearch={initiateSearch}
          updateSearchToParentState={setSearch}
          setShowSearchTermList={setShowSearchTermList}
          setSearchTermToStorage={setSearchTermToStorage}
          setShowFilterDropDown={setShowFilterDropDown}
          showSearchTermList={showSearchTermList}
          searchTerms={searchTerms}
          setSearchTerms={setSearchTerms}
          termClick={termClick}
          onSearchTermClick={onSearchTermClick}
        />,

        shouldRenderCategories && props.locations && (
          <div {...classes('categoriesContainer')} key="dropDown">
            <div {...classes('categoriesDropdowns')}>
              <DropDown
                isOpen={showCategories}
                toggle={() => {
                  setShowCategories(!showCategories)
                  setShowSearchTermList(false)
                  isDesktop && setShowSort(false) && setShowLocations(false)
                }}
                closeDropdown={closeCategoriesDropDown}
                selectItem={gotoCategory}
                selectedItems={
                  queryString.parse(props.location.search).category || '0'
                }
                array={categories}
                label="offers.categories.selectCategory"
                itemsInColumn={isDesktop ? 4 : 5}
                dropDownMargin={isDesktop ? 265 : 0}
                isCircleCheckbox={false}
              />
              <DropDown
                isOpen={showLocations}
                toggle={() => {
                  setShowLocations(!showLocations)
                  setShowSearchTermList(false)
                  isDesktop && setShowSort(false) && setShowCategories(false)
                }}
                closeDropdown={closeLocationsDropDown}
                selectItem={gotoLocation}
                selectedItems={
                  queryString.parse(props.location.search).city || '0'
                }
                array={props.locations}
                label="offers.locations.label"
                itemsInColumn={isDesktop ? 4 : 5}
                dropDownMargin={isDesktop ? 265 : 0}
                isCircleCheckbox={false}
                collapse={14}
              />
              <DropDown
                isOpen={showSort}
                toggle={() => {
                  setShowSort(!showSort)
                  setShowSearchTermList(false)
                  isDesktop &&
                    setShowCategories(false) &&
                    setShowLocations(false)
                }}
                closeDropdown={closeSortDropDown}
                selectItem={gotoSort}
                selectedItems={
                  queryString.parse(props.location.search).sort || 'date:asc'
                }
                array={sort}
                label="offers.sort.label"
                itemsInColumn={2}
                dropDownMargin={isDesktop ? 100 : 0}
                isCircleCheckbox={true}
                css={{dropDown: css.sortDropDown}}
              />
            </div>
            {
              <MediaQuery minWidth={801} key="searchDropDown">
                <div {...classes('searchContainer', 'searchBarWidthDesktop')}>
                  <SearchInput
                    initialValue={search}
                    initiateSearch={initiateSearch}
                    updateSearchToParentState={setSearch}
                    setShowSearchTermList={setShowSearchTermList}
                    setShowFilterDropDown={setShowFilterDropDown}
                    setSearchTermToStorage={setSearchTermToStorage}
                    termClick={termClick}
                  />
                  {showSearchTermList && (
                    <SearchTermList
                      setShowSearchTermList={setShowSearchTermList}
                      searchTerms={searchTerms}
                      initiateSearch={initiateSearch}
                      setSearchTerms={setSearchTerms}
                      onSearchTermClick={onSearchTermClick}
                      t={t}
                    />
                  )}
                </div>
              </MediaQuery>
            }
          </div>
        )
      ]}
      middle={
        !isDeviceOnline() ? (
          <OfflineModal />
        ) : (
          <div
            onClick={() => setShowFilterDropDown(false)}
            key="theList"
            {...classes(
              'theList',
              window.cordova ? 'cordova' : null,
              queryString.parse(props.location.search).search ||
                queryString.parse(props.location.search).category
                ? 'whenSearchingOrSorting'
                : null
            )}
          >
            <div {...classes(showFilterDropDown && !isDesktop && 'blur')}>
              <MediaQuery maxWidth={800}>
                {(isMobile) => (isMobile ? mobileContent : desktopContent)}
              </MediaQuery>
              <MediaQuery minWidth={801}>
                <div {...classes('divider')}>
                  <H1 style="black" css={{container: css.dividerHeading}}>
                    {t('offers.discounts.title')}
                  </H1>
                  <P color="black" css={{container: css.dividerText}}>
                    {t('offers.discounts.subTitle')}
                  </P>
                </div>
              </MediaQuery>
              <OfferList
                includeVouchers={false}
                includeJobs={true}
                itemsPerPage={itemsPerPage}
                isDesktop={isDesktop}
              />
            </div>
          </div>
        )
      }
    />
  )
}

function mapStateToProps(state) {
  return {locations: state.locations}
}

export default connect(mapStateToProps)(ListPage)
