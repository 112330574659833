import React from 'react'
import {compose} from 'redux'
import {withResponsiveInfo, WithResponsiveInfoProps} from '$common/utils'
import ChecklistBox, {ChecklistProps} from './ChecklistBox'
import H1 from '$common/components/headers/H1'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import {Link} from 'react-router-dom'

import css from './ArticleContainer.css'

type Props = WithResponsiveInfoProps & {
  title: string
  body: string
  history: object
  publishedAt?: string
  checklist?: ChecklistProps
}

function renderLink({props, history, onLinkClick}) {
  const isExternalLink =
    props.href.startsWith('http://') || props.href.startsWith('https://')
  if (window.cordova && isExternalLink) {
    return (
      <a
        href={props.href}
        rel="noopener noreferrer"
        target="_blank"
        onClick={onLinkClick}
      >
        {props.children}
      </a>
    )
  } else if (window.cordova && props.href.startsWith('/offer/')) {
    return (
      <Link
        to={{pathname: props.href, popToGoBack: true}}
        onClick={onLinkClick}
      >
        {props.children}
      </Link>
    )
  } else if (window.cordova) {
    return (
      <a
        onClick={(e) => {
          window.scrollTo(0, 0)
          history.push(props.href)
          onLinkClick(e)
        }}
        rel="noopener noreferrer"
        target="_blank"
      >
        {props.children}
      </a>
    )
  }
  return (
    <a
      href={props.href}
      rel="noopener noreferrer"
      target="_blank"
      onClick={onLinkClick}
    >
      {props.children}
    </a>
  )
}

const ArticleContainer = ({
  title,
  history,
  body,
  publishedAt,
  checklist,
  isDesktop,
  onLinkClick
}: Props) => {
  return (
    <div>
      <div className={css.article}>
        {publishedAt && (
          <div className={css.date}>
            {moment(publishedAt).format('DD.MM.YYYY')}
          </div>
        )}
        <H1 style="black" css={{container: css.articleTitle}}>
          {title}
        </H1>
        {checklist && isDesktop && <ChecklistBox {...checklist} />}
        <ReactMarkdown
          className={css.body}
          source={body}
          renderers={{
            link: (props) => renderLink({props, history, onLinkClick})
          }}
        />
      </div>
      {checklist && !isDesktop && <ChecklistBox {...checklist} />}
    </div>
  )
}

export default compose(withResponsiveInfo())(ArticleContainer)
