import callEndpoint from '$src/api/sagas/callEndpoint'
import changeEducationEndpoint from '$src/api/endpoints/profile/changeEducation'
import {setUserProfile} from '$common/utils'
import getSubscriptions from '$src/subscriptions/sagas/getSubscriptions'
import {AvailableEducation, Profile} from '$src/profile/types'

export default function* changeEducation(
  education: AvailableEducation
): Generator {
  const profile: Profile = (yield callEndpoint(
    changeEducationEndpoint,
    education
  )) as Profile

  yield setUserProfile(profile)
  yield getSubscriptions()
}
