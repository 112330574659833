import {call} from 'redux-saga/effects'
import queryString from 'query-string'
import request from '../request'

export default function* get(
  endpoint: string,
  params?: object,
  options?: object
): Generator<> {
  return yield call(request, `${endpoint}?${queryString.stringify(params)}`, {
    ...options,
    method: 'GET'
  })
}
