import {select, put} from 'redux-saga/effects'
import {shuffle} from 'lodash'

import {callEndpoint} from '$src/api/sagas/callEndpoint'
import {PaginatedResponseType} from '$src/pagination/types'
import {getPreferredLang} from '$src/i18n/selectors'
import {
  Article,
  FetchArticlesParams,
  SetArticlesAction
} from '$src/articles/types'
import {Lang} from '$src/i18n/types'
import fetch from '$src/api/endpoints/articles/fetch'

export function* fetchAllArticles() {
  const languageCode: Lang = yield select(getPreferredLang)
  const fetchParams: FetchArticlesParams = {
    languageCode,
    sort: 'frontpageHero:desc::displayOrder:asc::publishedAt:desc',
    type: 'article'
  }
  const response: PaginatedResponseType<Article> = yield callEndpoint(
    'articles'
  )(fetch, fetchParams)

  const articles = response.data
  const randomizedHeroArticles = shuffle(
    articles.filter((article) => article.frontpageHero)
  )
  const nonHeroArticles = articles.filter((article) => !article.frontpageHero)
  const articlesWithRandomizedHeros = [
    ...randomizedHeroArticles,
    ...nonHeroArticles
  ]
  const action: SetArticlesAction = {
    type: 'SET_ARTICLES',
    articles: articlesWithRandomizedHeros,
    language: languageCode
  }
  yield put(action)
}
