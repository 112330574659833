import * as React from 'react'
import {compose} from 'redux'
import {Field, SubmissionError} from 'redux-form'
import {injectStripe, CardElement} from 'react-stripe-elements'
import filter from 'lodash/filter'

import amex from '$assets/images/card/icon-card-amex@2x.png'
import electron from '$assets/images/card/icon-card-electron@2x.png'
import mastercard from '$assets/images/card/icon-card-mastercard@2x.png'
import visa from '$assets/images/card/icon-card-visa@2x.png'

import css from './Form.css'
import {withTranslate, WithTranslateProps, resolveCss} from '$common/utils'
import ErrorCandy from '$src/common/components/candyBars/ErrorCandy'
import {startOrder} from '$src/subscriptions/sagas/startOrder'
import {FormValues} from '../../../subscriptions/components/PlainCardOrderPage/types'
import {confirmOrder} from '../../../subscriptions/sagas/confirmOrder'
import getSubscriptions from '../../../subscriptions/sagas/getSubscriptions'
import createApiErrorMessage from '../../../api/utils/createApiErrorMessage'

type Props = InjectStripeProps &
  WithTranslateProps &
  FormProps & {
    submitButton?: React.Node
    productSku: string
    css?: any
    acceptedCards?: Array<string>
    acceptedCardsLabel: string
    onSuccessRedirectUrl: string
  }

const availableCards = [
  {name: 'amex', src: amex},
  {name: 'electron', src: electron},
  {name: 'mastercard', src: mastercard},
  {name: 'visa', src: visa}
]

const CardElementField = ({input: {value, ...handlers}, ...rest}) => {
  return <CardElement {...handlers} {...rest} />
}

class Form extends React.Component<Props> {
  state = {
    showError: false,
    errorMessage: undefined
  }

  submit = async (values: FormValues) => {
    const result = await this.props.stripe.createPaymentMethod('card', {
      billing_details: {name: values.cardHolderName}
    })

    const paymentMethodId = result.paymentMethod.id

    /**
     * @type {OrderParams}
     */
    const orderParams = {
      productSku: this.props.productSku,
      paymentMethodId: paymentMethodId
    }

    // Backend expects either a valid address or no address at all
    if (values.streetAddress) {
      orderParams.address = {
        street: values.streetAddress,
        postalCode: values.postalCode,
        city: values.city
      }
    }

    try {
      const initPaymentResult = await this.props.runSaga(
        startOrder,
        orderParams
      )
      await this.handleServerResponse(initPaymentResult)
    } catch (err) {
      const errMsg = createApiErrorMessage(
        err,
        'error.subscriptions.plainCardPurchase'
      )
      throw new SubmissionError({error: this.props.t(errMsg), general: true})
    }
  }

  /**
   * @typedef {Object<boolean, string, string>} InitPaymentResult
   * @property {boolean} requiresAction
   * @property {string} clientSecret
   * @property {string} orderId
   */

  /**
   * @param {InitPaymentResult} initPaymentResult
   */
  handleServerResponse = async (initPaymentResult) => {
    if (initPaymentResult.requiresAction) {
      const secret = initPaymentResult.clientSecret
      const orderId = initPaymentResult.orderId
      await this.handleAdditionalStripeAction(secret, orderId)
    }

    // If we end up in here, the payment was successful

    await this.props.runSaga(getSubscriptions)
    this.props.history.push(this.props.onSuccessRedirectUrl)
    window.scrollTo(0, 0)
  }

  handleAdditionalStripeAction = async (clientSecret, orderId) => {
    const {
      errorAction,
      paymentIntent
    } = await this.props.stripe.handleCardAction(clientSecret)

    if (errorAction) {
      throw new SubmissionError({error: errorAction.message, general: true})
    }

    /**
     * @type {ConfirmOrderPayload}
     */
    const confirmOrderPayload = {
      orderId: orderId,
      paymentIntentId: paymentIntent.id
    }
    const confirmOrderResponse = await this.props.runSaga(
      confirmOrder,
      confirmOrderPayload
    )
    await this.handleServerResponse(confirmOrderResponse)
  }

  closeErrorCandy = () => {
    this.setState({showError: false, errorMessage: undefined})
  }

  render() {
    const classes = resolveCss(css, this.props.css)
    const {acceptedCards, acceptedCardsLabel} = this.props

    return (
      <div>
        <form
          {...classes('container')}
          onSubmit={this.props.handleSubmit(this.submit)}
        >
          <fieldset>
            <div className={css.card}>
              <Field
                name="cardNumber"
                onBlur={(e) => e.preventDefault()}
                style={{base: {fontSize: '16px', lineHeight: '60px'}}}
                component={CardElementField}
              />
            </div>
            <div>
              <div className={css.acceptedCardsWrapper}>
                <span>{acceptedCardsLabel}</span>
                <span>
                  {acceptedCards
                    ? filter(availableCards, (card) =>
                        acceptedCards.includes(card.name)
                      ).map((imageSrc, i) => (
                        <img
                          className={css.cardThumbnail}
                          key={i}
                          src={imageSrc.src}
                        />
                      ))
                    : availableCards.map((imageSrc, i) => (
                        <img
                          className={css.cardThumbnail}
                          key={i}
                          src={imageSrc.src}
                        />
                      ))}
                </span>
              </div>
            </div>
            {this.props.submitButton}
          </fieldset>
        </form>
        <ErrorCandy
          show={this.state.showError}
          onHide={this.closeErrorCandy}
          key="Candy"
        >
          {this.props.t(this.state.errorMessage)}
        </ErrorCandy>
      </div>
    )
  }
}

export default compose(withTranslate(), injectStripe)(Form)
