import {ArticlesNamespaceState, ArticleActions} from '$src/articles/types'

const defaultState: ArticlesNamespaceState = {
  fi: [],
  sv: [],
  en: []
}

const articleReducer = (
  state = defaultState,
  action: ArticleActions
): ArticlesNamespaceState => {
  switch (action.type) {
    case 'SET_ARTICLES': {
      return {
        ...state,
        [action.language]: action.articles
      }
    }
    default: {
      return state
    }
  }
}

export default articleReducer
