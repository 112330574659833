import React, {Component} from 'react'
import {flow, values, min, max} from 'lodash/fp'
import dataUriToBuffer from 'data-uri-to-buffer'
import resizeImg from 'resize-img'
import {WithRouterProps} from 'react-router-dom'

import postProfilePhoto from '$src/profile/sagas/postProfilePhoto'
import amplitude from '$src/analytics/amplitude'
import setNotification from '$src/notification/sagas/setNotification'
import {WithRunSagaProps, WithTranslateProps} from '$common/utils'

type Props = WithRouterProps &
  WithRunSagaProps &
  WithTranslateProps & {
    photo?: string
  }

const cropPhoto = (fileUri) =>
  window.plugins.crop
    .promise(fileUri)
    .then(function success(newPath) {
      return newPath
    })
    .catch((err) => {
      throw err
    })

//https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-camera/#take-a-picture-and-get-a-fileentry-object
function createNewFileEntry(imgUri) {
  return new Promise((resolve, reject) => {
    window.resolveLocalFileSystemURL(
      window.cordova.file.cacheDirectory,
      function success(dirEntry) {
        dirEntry.getFile(
          'tempFile.jpeg',
          {create: true, exclusive: false},
          function(fileEntry) {
            fileEntry.createWriter(function(fileWriter) {
              fileWriter.onwriteend = function() {
                resolve(fileEntry)
              }

              fileWriter.onerror = function(err) {
                reject(err)
              }

              fileWriter.write(new Blob(imgUri, {type: 'image/jpeg'}))
            })
          },
          (err) => {
            reject(err)
          }
        )
      },
      (err) => {
        reject(err)
      }
    )
  })
}

const fileUriToFileEntry = (fileUri) =>
  new Promise((resolve) => {
    window.resolveLocalFileSystemURL(
      fileUri,
      function success(fileEntry) {
        resolve(fileEntry)
      },
      function() {
        // If don't get the FileEntry (which may happen when testing
        // on some emulators), copy to a new FileEntry.
        resolve(createNewFileEntry(fileUri))
      }
    )
  })

const fileEntryToDataUrl = (fileEntry) =>
  new Promise((resolve) => {
    fileEntry.file(function(file) {
      const reader = new FileReader()
      reader.onloadend = function() {
        resolve(this.result)
      }
      reader.readAsDataURL(file)
    })
  })

function getDimensions(image) {
  return new Promise((resolve) => {
    const i = new Image()
    i.onload = () => resolve({width: i.width, height: i.height})
    i.src = image
  })
}

const maxDimension = 1024
async function squareImage(encodedImage, dimensions = maxDimension) {
  const resizedImage = await resizeImg(dataUriToBuffer(encodedImage), {
    width: dimensions,
    height: dimensions
  })
  return `data:image/jpeg;base64,${Buffer.from(resizedImage).toString(
    'base64'
  )}`
}

/**
 * Ensure that image is square (512x512, 1024x1024 etc.) because crop plugin does sometimes 1024x2025
 */
async function ensureSquareImage(encodedImage) {
  const cropDimensions = await getDimensions(encodedImage)
  const smallerDimension = flow(
    values,
    min
  )([cropDimensions.height, cropDimensions.width])
  const largerDimension = flow(
    values,
    max
  )([cropDimensions.height, cropDimensions.width])
  if (smallerDimension === largerDimension) {
    return smallerDimension <= maxDimension
      ? encodedImage
      : squareImage(encodedImage)
  } else if (largerDimension - smallerDimension === 1) {
    return smallerDimension <= maxDimension
      ? squareImage(encodedImage, smallerDimension)
      : squareImage(encodedImage)
  }
  throw new Error('Image is not square')
}

export default function pgProfilePhotoPageHOC(WrappedComponent) {
  return class PGProfilePhotoPage extends Component<Props> {
    state = {loadedPhoto: undefined, croppedPhoto: undefined}

    onFileUploadLinkClick = (event, usePhotoLibrary) => {
      const onSuccess = async (fileUri) => {
        this.props.toggleChangingPhoto(true)
        try {
          const croppedPhoto = await cropPhoto(fileUri)
          const photoFileEntry = await fileUriToFileEntry(croppedPhoto)
          const encodedImage = await fileEntryToDataUrl(photoFileEntry)
          await this.props.runSaga(
            postProfilePhoto,
            await ensureSquareImage(encodedImage)
          )
          const isFirst = !this.props.photo
          amplitude.logEvent('uploadProfilePhoto', {isFirst})
          this.props.history.push('/profile/settings')
        } catch (e) {
          if (e.status === 412) {
            this.props.runSaga(setNotification, {
              message: this.props.t('error.profile.profilePhotoPage.412')
            })
          } else if (e.status === 415) {
            this.props.runSaga(setNotification, {
              message: this.props.t('error.profile.profilePhotoPage.415')
            })
          } else {
            this.props.runSaga(setNotification, {
              message: this.props.t('profile.photo.genericPhotoError')
            })
          }
        } finally {
          this.props.toggleChangingPhoto(false)
        }
      }

      const onFail = (message) => {
        console.log('Failed because: ' + message) // eslint-disable-line
      }

      const opts = {
        cameraDirection: window.Camera.Direction.FRONT,
        correctOrientation: true,
        destinationType: window.Camera.DestinationType.FILE_URI,
        mediaType: window.Camera.MediaType.PICTURE,
        encodingType: window.Camera.EncodingType.JPEG,
        targetHeight: 1280,
        targetWidth: 1280,
        quality: 80,
        sourceType: usePhotoLibrary
          ? window.Camera.PictureSourceType.PHOTOLIBRARY
          : window.Camera.PictureSourceType.CAMERA
      }

      window.navigator.camera.getPicture(onSuccess, onFail, opts)
    }

    updatePhotos = (loadedPhoto) => {
      this.setState({loadedPhoto})
    }

    render() {
      const props = {
        ...this.props,
        loadedPhoto: this.state.loadedPhoto,
        croppedPhoto: this.state.croppedPhoto,
        onFileUploadLinkClick: this.onFileUploadLinkClick,
        updatePhotos: this.updatePhotos
      }

      return <WrappedComponent {...props} />
    }
  }
}
