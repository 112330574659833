import * as React from 'react'
import {Modal} from 'react-overlays'

import SvgIcon from '$common/components/icons/SvgIcon'
import TopMiddleBottomLayout from '$common/components/layouts/TopMiddleBottomLayout' // eslint-disable-line
import closeIcon from '$assets/icons/close.svg'
import css from './GenericModal.css'
import grid from '$common/grid/grid.css'

// Using flow's comment types because the type spread operator cannot be parsed by babel atm
/*:: type Props = {
  ...LayoutProps,
  onHide?: Function,
  show?: boolean,
  noTop?: boolean,
  closeCss?: Array<string> | string,
  style: 'dark' | 'light'
}*/

const GenericModal = ({
  show = true,
  noTop,
  onHide,
  css: layoutCss,
  closeCss,
  style = 'dark',
  ...layoutProps
}) => {
  const backdropClassName =
    style === 'light' ? 'modalBackdropLight' : 'modalBackdropDark'

  return (
    <Modal
      backdropClassName={css[backdropClassName]}
      container={document.body}
      show={show}
      onHide={onHide}
    >
      <TopMiddleBottomLayout
        containerProps={{tabIndex: -1}}
        scroll={true}
        css={[
          {
            container: [css.container, grid.container]
          },
          layoutCss
        ]}
        topRight={
          !noTop && (
            <a onClick={onHide}>
              <SvgIcon
                css={{container: closeCss}}
                src={closeIcon}
                sizing="em"
              />
            </a>
          )
        }
        {...layoutProps}
      />
    </Modal>
  )
}

export default GenericModal
