import appboy from '@braze/web-sdk'

type AppBoy = {
  openSession: Function
  destroy: Function
  wipeData: Function
  initialize: Function
  changeUser: Function
  logCustomEvent: Function
  requestImmediateDataFlush: Function
}

export function getAppboy(): AppBoy {
  if (window.appboy) {
    return window.appboy
  }
  if (window.cordova && window.device.platform !== 'browser') {
    return window.AppboyPlugin
  } else {
    appboy.initialize(process.env.BRAZE_API_KEY, {
      enableLogging: false,
      baseUrl: 'https://sdk.fra-01.braze.eu/api/v3'
    })
    appboy.wipeData()
  }
  window.appboy = appboy
  return appboy
}

export function getHomePage(): string {
  return '/home'
}

/**
 * There is transition effect that will cause old page to flash at the bottom of the cordova app.
 *
 * With this function you can decide in render method that should component be rendered.
 *
 * @param props
 * @returns {boolean}
 */
export function isCurrentPage(props: any) {
  return props.history.location.pathname === props.match.path
}
