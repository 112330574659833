/* eslint-disable react/jsx-key */

import * as React from 'react'
import {Route} from 'react-router-dom'
import AuthAfterSuccessPage from '$src/profile/components/verification/AuthAfterSuccessPage/AuthAfterSuccessPage'
import AuthAfterFailurePage from '$src/profile/components/verification/AuthAfterFailurePage/AuthAfterFailurePage'
import JollaFormPage from '$src/profile/components/verification/JollaFormPage/JollaFormPage-container'
import KoskiFormPage from '$src/profile/components/verification/KoskiFormPage/KoskiFormPage'
import ProfileSettingsPage from '$src/profile/components/ProfileSettingsPage/ProfileSettingsPage-container'
import ProfilePhotoPage from '$src/profile/components/ProfilePhotoPage/ProfilePhotoPage-container'
import userUnverified from '$src/auth/components/userUnverified'
import userNotKoskiVerified from '$src/auth/components/userNotKoskiVerified'
import userHasNoPhoto from '$src/auth/components/userHasNoPhoto'
import userAuthenticated from '$src/auth/components/userAuthenticated'
import VerificationStartPage from '$src/profile/components/verification/StartPage'
import EditPhoneNumberPage from '$src/profile/components/EditPhoneNumberPage/EditPhoneNumberPage-container'
import EditEmailPage from '$src/profile/components/VerifyEmailPage/VerifyEmailPage-container'
import ChangePasswordPage from '$src/profile/components/ChangePasswordPage/ChangePasswordPage-container'
import PhoneNumberBefore from '$src/profile/components/verification/PhoneNumber'
import StartFormPage from './components/verification/StartFormPage/StartFormPage-container'
import OpintopolkuFormPage from './components/verification/OpintopolkuFormPage/OpintoPolkuFormPage-container'
import FhsFormPage from './components/verification/FhsFormPage/FhsFormPage-container'
import FhsAfterFailurePage from './components/verification/FhsFormPage/FhsAfterFailurePage'
import FhsAfterSuccessPage from './components/verification/FhsFormPage/FhsAfterSuccessPage'
import {TrialPages} from './components/Trial'
import ChangeGraduationYearComponent from './components/ChangeGraduationYear/ChangeGraduationYearComponent'
import InitiateAccountDeletion from './components/AccountDeletion/InitiateAccountDeletion'

export default [
  <Route
    path={`/profile/verify/start`}
    component={userUnverified(VerificationStartPage)}
  />,
  <Route
    path={`/profile/verify/start-form`}
    component={userAuthenticated(StartFormPage)}
  />,
  <Route
    path={`/profile/verify/after-success`}
    component={AuthAfterSuccessPage}
  />,
  <Route
    path={`/profile/verify/after-failure`}
    component={userAuthenticated(AuthAfterFailurePage)}
  />,
  <Route path={`/profile/verify/jolla/form`} component={JollaFormPage} />,
  <Route
    path={`/profile/verify/opintopolku/form`}
    component={userAuthenticated(OpintopolkuFormPage)}
  />,
  <Route
    path={`/profile/verify/fhs/form`}
    component={userAuthenticated(FhsFormPage)}
  />,
  <Route
    path={`/profile/verify/fhs/after-failure`}
    component={userAuthenticated(FhsAfterFailurePage)}
  />,
  <Route
    path={`/profile/verify/fhs/after-success`}
    component={FhsAfterSuccessPage}
  />,
  <Route
    path={`/profile/verify/phone-number`}
    component={userAuthenticated(EditPhoneNumberPage)}
  />,
  <Route
    path={`/profile/verify/phone-number-before`}
    component={userAuthenticated(PhoneNumberBefore)}
  />,
  <Route
    path={`/profile/settings/email`}
    component={userAuthenticated(EditEmailPage)}
  />,
  <Route
    path={`/profile/settings/password`}
    component={userAuthenticated(ChangePasswordPage)}
  />,
  <Route
    path={`/profile/verify/photo`}
    component={userHasNoPhoto(ProfilePhotoPage)}
  />,
  <Route
    path={`/profile/verify/koski/form`}
    component={userNotKoskiVerified(KoskiFormPage)}
  />,

  <Route
    path={`/profile/settings`}
    exact
    component={userAuthenticated(ProfileSettingsPage)}
  />,
  <Route
    path={`/profile/delete-account-permanently`}
    exact
    component={userAuthenticated(InitiateAccountDeletion)}
  />,
  <Route
    path={`/profile/settings/phone-number`}
    component={userAuthenticated(EditPhoneNumberPage)}
  />,

  <Route
    path={`/studentcard/photo`}
    component={userAuthenticated(ProfilePhotoPage)}
  />,
  <Route
    path={`/profile/settings/photo`}
    component={userAuthenticated(ProfilePhotoPage)}
  />,
  <Route
    path={`/profile/settings/graduation-year`}
    component={userAuthenticated(ChangeGraduationYearComponent)}
  />,
  <Route
    path={`/plain-card/photo`}
    component={userAuthenticated(ProfilePhotoPage)}
  />,
  <Route
    path={`/digital-card/photo`}
    component={userAuthenticated(ProfilePhotoPage)}
  />,
  <Route
    path={`/digi-isic/photo`}
    component={userAuthenticated(ProfilePhotoPage)}
  />,
  <Route
    path={`/profile/trial/start`}
    component={userAuthenticated(TrialPages.start)}
  />,
  <Route
    path={`/profile/trial/success`}
    component={userAuthenticated(TrialPages.success)}
  />,
  <Route
    path={`/profile/trial/fail/:errorCode`}
    component={userAuthenticated(TrialPages.fail)}
  />,
  <Route path={`/profile/trial/landing`} component={TrialPages.landing} />,
  <Route
    path={`/profile/trial/ending`}
    component={userAuthenticated(TrialPages.ending)}
  />
]
