import React, {Component} from 'react'
import layoutCss from '$common/grid/grid.css'
import alignCss from '$common/grid/align.css'
import {WithRouterProps, Link} from 'react-router-dom'
import {FormProps} from 'redux-form'

import {resolveCss, WithTranslateProps} from '$common/utils'
import TextInput from '$common/components/forms/TextInput'
import GenericButton from '$src/common/components/buttons/GenericButton'
import css from './EditPhoneNumberPage.css'
import LogoutHeader from '$common/components/Header/LogoutHeader-container'
import Layout from '$common/components/layouts/TopMiddleBottomLayout'
import {
  sendVerificationCode,
  verifyPhoneNumber
} from '$src/profile/sagas/verifyPhoneNumber'
import getProfile from '$src/profile/sagas/getProfile'
import setNotification from '$src/notification/sagas/setNotification'
import clearNotification from '$src/notification/sagas/clearNotification'

type Props = WithRouterProps &
  WithTranslateProps &
  FormProps & {
    fieldErrors: object
  }

export class EditPhoneNumberPage extends Component<Props> {
  // For controlling input field focus
  verificationCodeField?: any
  phoneNumberField?: any

  state = {
    submitting: false
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    this.props.runSaga(clearNotification)
    setTimeout(
      () => this.phoneNumberField && this.phoneNumberField.focus(),
      100
    )
  }

  sendVerifyCode = async (evt) => {
    evt.preventDefault()

    const {phoneNumber} = this.props.fieldValues
    this.setState({submitting: true})
    try {
      const verification = await this.props.runSaga(
        sendVerificationCode,
        phoneNumber
      )
      if (!verification.status === 'pending') {
        return this.props.runSaga(setNotification, {
          message: this.props.t(
            'validate.phoneNumber.cannotSendVerificationMessage'
          )
        })
      }
      setTimeout(
        () => this.verificationCodeField && this.verificationCodeField.focus(),
        100
      )
    } catch (err) {
      this.props.runSaga(setNotification, {
        message: this.props.t(
          'validate.phoneNumber.cannotSendVerificationMessage'
        )
      })
    } finally {
      this.setState({submitting: false})
    }
  }

  verifyNumber = async (evt) => {
    evt.preventDefault()

    const {phoneNumber, verificationCode} = this.props.fieldValues
    this.setState({submitting: true})
    try {
      const verification = await this.props.runSaga(
        verifyPhoneNumber,
        phoneNumber,
        verificationCode
      )
      if (!verification.valid) {
        this.props.runSaga(setNotification, {
          message: this.props.t('validate.phoneNumber.cannotSavePhoneNumber')
        })
        return
      }
      this.props.runSaga(setNotification, {
        message: this.props.t('validate.phoneNumber.phoneNumberSaved')
      })
      await this.props.runSaga(getProfile)

      if (this.isInitialVerification()) {
        this.props.history.push('/profile/verify/photo')
      } else {
        this.props.history.push('/profile/settings')
      }
    } catch (err) {
      this.props.runSaga(setNotification, {
        message: this.props.t('validate.phoneNumber.cannotSavePhoneNumber')
      })
      throw err
    } finally {
      this.setState({submitting: false})
    }
  }

  isInitialVerification() {
    return (
      this.props.history.location.pathname === '/profile/verify/phone-number'
    )
  }

  getHeader(css) {
    if (this.isInitialVerification()) {
      return null
    } else {
      return <LogoutHeader css={{wrapper: css.fixedHeader}} />
    }
  }

  render() {
    const classes = resolveCss(css, layoutCss, alignCss, this.props.css)
    // unverified users should not be allowed to access the edit phone number page.
    if (!this.props.isUserVerified) {
      this.props.history.push('/profile/settings')
    }
    return (
      <Layout
        noPadding={true}
        topStyle="sticky"
        css={{top: css.top}}
        top={this.getHeader(css)}
        middle={
          <div
            {...classes('editPhoneNumberContainer', 'columnLarge6', 'center')}
          >
            <h1>{this.props.t('validate.phoneNumber.editPhoneNumber')}</h1>
            <form
              {...classes('editPhoneNumberForm')}
              onSubmit={this.sendVerifyCode}
            >
              <TextInput
                placeholder={'+358123456789'}
                inputRef={(node) => (this.phoneNumberField = node)}
                name="phoneNumber"
                type="tel"
                disabled={this.state.submitting}
                label={this.props.t(
                  'validate.phoneNumber.sendConfirmationMessageLabel'
                )}
              />
              <GenericButton
                id="send-confirmation-message-button"
                isFullWidth
                css={{container: css.button}}
                disabled={
                  this.state.submitting || this.props.fieldErrors.phoneNumber
                }
                style="bold"
              >
                {this.props.t('validate.phoneNumber.sendConfirmationMessage')}
              </GenericButton>
            </form>
            <form
              {...classes('editPhoneNumberForm')}
              onSubmit={this.verifyNumber}
            >
              <TextInput
                placeholder={'123456'}
                inputRef={(node) => (this.verificationCodeField = node)}
                name="verificationCode"
                disabled={this.state.submitting}
                label={this.props.t('validate.phoneNumber.verifyCodeLabel')}
              />
              <GenericButton
                isFullWidth
                css={{container: css.button}}
                style="bold"
                disabled={
                  this.state.submitting ||
                  this.props.fieldErrors.phoneNumber ||
                  this.props.fieldErrors.verificationCode
                }
              >
                {this.props.t('validate.phoneNumber.verifyCode')}
              </GenericButton>
            </form>

            {this.isInitialVerification() ? (
              <div {...classes('did-not-get-verification-message')}>
                <p {...classes('disclaimerElement')}>
                  {this.props.t('validate.phoneNumber.didNotGetMessageTitle')}
                </p>
                <p {...classes('disclaimerElement')}>
                  {this.props.t('validate.phoneNumber.didNotGetMessage')}
                </p>
                <Link to="/profile/verify/photo">
                  <GenericButton
                    isFullWidth
                    surroundMargin={true}
                    css={{container: css.button}}
                    style="outline"
                  >
                    {this.props.t(
                      'validate.phoneNumber.didNotGetMessageButton'
                    )}
                  </GenericButton>
                </Link>
              </div>
            ) : (
              <Link to="/profile/settings">
                <GenericButton
                  isFullWidth
                  surroundMargin={true}
                  css={{container: css.button}}
                  style="outline"
                >
                  {this.props.t('common.back')}
                </GenericButton>
              </Link>
            )}
          </div>
        }
      />
    )
  }
}
