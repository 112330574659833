import {Selector} from '$src/types'
import {getPreferredLang} from '$src/i18n/selectors'
import {NewsItem} from './types'

export const getNewsItem: Selector<NewsItem | undefined, string> = (
  state,
  id
) => {
  return getNewsItems(state).find((newsItem) => newsItem.id === id)
}

export const getNewsItems: Selector<NewsItem[]> = (state) => {
  const language = getPreferredLang(state)
  return state.news[language]
}
