import Dropzone from 'react-dropzone'
import React, {Component} from 'react'
import {WithRouterProps} from 'react-router-dom'
import {Field} from 'redux-form'
import '$common/components/FrankCropper/index.css'
import MediaQuery from 'react-responsive'

import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import LoadingSpinnerModal from '$common/components/LoadingSpinnerModal'
import ErrorCandy from '$common/components/candyBars/ErrorCandy'
import {
  ProfileFlowButton,
  CardFlowButton,
  RegisterFlowButton
} from './BottomButtons'
import SvgIcon from '$common/components/icons/SvgIcon'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import GenericButton from '$common/components/buttons/GenericButton'
import GenericModal from '$common/components/modals/GenericModal'
import css from './ProfilePhotoPage.css'
import grid from '$common/grid/grid.css'
import align from '$common/grid/align.css'
import Layout from '$common/components/layouts/TopMiddleBottomLayout'

import {resolveCss, WithRunSagaProps, WithTranslateProps} from '$common/utils'
import checkIcon from '$assets/icons/checkSingleWhite.svg'
import pictureIcon from '$assets/icons/picture.svg'
import rotateIcon from '$assets/icons/rotate.svg'
import closeIcon from '$assets/icons/close.svg'

type Props = WithRouterProps &
  WithRunSagaProps &
  WithTranslateProps & {
    photo?: string
    loadedPhoto?: string
    croppedPhoto?: string
    onFileUploadLinkClick?: Function
    uploadCroppedImage: Function
    onImageDrop?: Function
  }

type PhotoUploadTextProps = {
  photoExists?: boolean
  onFileUploadLinkClick: Function
  css: any
  t: Function
}

const PhotoUploadText = ({
  t,
  css,
  onFileUploadLinkClick,
  photoExists
}: PhotoUploadTextProps) => {
  return window.cordova ? (
    <div className={css.uploadImageTxtCordova}>
      <GenericButton size="small" style="bold" onClick={onFileUploadLinkClick}>
        {t('profile.newProfilePicture.takePhoto')}
      </GenericButton>
      <GenericButton
        size="small"
        style="bold"
        onClick={(event) => onFileUploadLinkClick(event, true)}
      >
        {t('profile.newProfilePicture.selectFromLibrary')}
      </GenericButton>
    </div>
  ) : (
    <div className={css.uploadImageTxt}>
      {photoExists ? (
        <SvgIcon src={rotateIcon} />
      ) : (
        <SvgIcon css={{container: css.uploadImageIcon}} src={closeIcon} />
      )}
      <P onClick={onFileUploadLinkClick} css={{container: null}}>
        {t('profile.newProfilePicture.upload')}
      </P>
    </div>
  )
}

const WebappPhotoUpload = ({t, css, onFileUploadLinkClick, onImageDrop}) => {
  return (
    <div>
      <Dropzone
        className={css.dropZone}
        multiple={false}
        accept=".jpg, .jpeg"
        onDrop={onImageDrop}
      >
        <SvgIcon css={{container: css.pictureIcon}} src={pictureIcon} />
        <P css={{container: css.dropZoneText}}>
          {t('profile.profilePhotoPage.drop')}
        </P>
      </Dropzone>
      <div>
        <P>{t('profile.profilePhotoPage.or')}</P>
        <PhotoUploadText
          t={t}
          css={css}
          onFileUploadLinkClick={onFileUploadLinkClick}
        />
      </div>
    </div>
  )
}

class ProfilePhotoPage extends Component<Props> {
  props: Props

  fileInput = undefined
  photoRef = undefined

  rotateCropperImage = () =>
    this.props.cropperRef && this.props.cropperRef.rotate(90)

  handleSelectedFiles = () =>
    this.fileInput && this.props.onImageDrop(this.fileInput.files)

  onFileUploadLinkClick = () => {
    if (this.fileInput) {
      // nullify file input field's value for its
      // onChange to fire on selecting the same file as the previous time
      this.fileInput.value = ''
      this.fileInput.click()
    }
  }

  render() {
    const {
      props: {
        error,
        photo,
        isPhotoChanging,
        submitting,
        submitFailed,
        handleSubmit,
        reset: resetForm,
        loadedPhoto,
        croppedPhoto
      }
    } = this
    const classes = resolveCss(css, grid, align)

    const TextContent = (
      <div {...classes('columnLarge7', 'textContentWrapper')}>
        <H1>{this.props.t('profile.profilePhotoPage.profilePicture')}</H1>
        <P css={{container: css.instructionsText}}>
          {this.props.t('profile.profilePhotoPage.uploadProfilePicture')}
        </P>
        <div>
          <H1>{this.props.t('profile.profilePhotoPage.requirements')}</H1>
          <ul {...classes('requirementsList')}>
            <li>
              <SvgIcon
                style={{width: '11px', height: '28px'}}
                css={{container: css.listItemCheck}}
                src={checkIcon}
              />
              <p>{this.props.t('profile.profilePhotoPage.itsaPhotoOfYou')}</p>
            </li>
            <li>
              <SvgIcon
                style={{width: '11px', height: '28px'}}
                css={{container: css.listItemCheck}}
                src={checkIcon}
              />
              <p>{this.props.t('profile.profilePhotoPage.recognizable')}</p>
            </li>
            <li>
              <SvgIcon
                style={{width: '11px', height: '28px'}}
                css={{container: css.listItemCheck}}
                src={checkIcon}
              />
              <p>{this.props.t('profile.profilePhotoPage.noSunglasses')}</p>
            </li>
          </ul>
        </div>
      </div>
    )

    const onFileUploadLinkClick =
      this.props.onFileUploadLinkClick || this.onFileUploadLinkClick

    if (isPhotoChanging) {
      return <LoadingSpinnerModal />
    }

    const ImageContent = (
      <div
        {...classes(
          'columnLarge5',
          'right',
          window.cordova ? 'justify-center' : ''
        )}
      >
        {!photo ? (
          <div
            ref={(ref) => {
              this.photoRef = ref
            }}
            {...classes(
              'imageContentWrapper',
              window.cordova ? 'width-100' : ''
            )}
          >
            {window.cordova ? (
              <PhotoUploadText
                t={this.props.t}
                css={css}
                onFileUploadLinkClick={onFileUploadLinkClick}
              />
            ) : (
              <WebappPhotoUpload
                t={this.props.t}
                css={css}
                onFileUploadLinkClick={onFileUploadLinkClick}
                onImageDrop={this.props.onImageDrop}
              />
            )}
          </div>
        ) : (
          <div
            {...classes(
              'imageContentWrapper',
              window.cordova ? 'width-100' : ''
            )}
          >
            <img
              ref={(ref) => {
                this.photoRef = ref
              }}
              style={{width: '150px', height: '150px', alignSelf: 'center'}}
              src={croppedPhoto || this.props.photo}
            />
            <PhotoUploadText
              t={this.props.t}
              css={css}
              onFileUploadLinkClick={onFileUploadLinkClick}
              photoExists={!!photo}
            />
          </div>
        )}
      </div>
    )

    return (
      <div>
        {loadedPhoto && this.photoRef && (
          <GenericModal
            onHide={() => this.props.updatePhotos(undefined, undefined)}
            css={{
              container: css.modalContainer,
              middle: css.modalLayoutMiddle
            }}
            middle={
              <form onSubmit={handleSubmit(this.props.uploadCroppedImage)}>
                {this.props.cropper}
                <div {...classes('imageWrapperButtonRow')}>
                  {this.props.cropper ? (
                    <div
                      {...classes('rotateImageLink')}
                      onClick={this.rotateCropperImage}
                    >
                      <SvgIcon
                        css={{container: css.uploadNewImageIcon}}
                        src={rotateIcon}
                      />
                      <P>
                        {this.props.t('profile.profilePhotoPage.rotateImage')}
                      </P>
                    </div>
                  ) : null}
                  <Field
                    component={GenericButton}
                    type="submit"
                    name="upload"
                    css={{container: css.bottomButtons}}
                    surroundMargin={true}
                    disabled={submitting}
                    style="bold"
                  >
                    {this.props.t(
                      'profile.profilePhotoPage.cropEtSave'
                    ) /*Crop & Save*/}
                  </Field>
                </div>
              </form>
            }
          />
        )}
        <Layout
          topStyle="autoHeight"
          css={{container: grid.container}}
          top={
            <MediaQuery minWidth={801}>
              <FrankLogoInSmallWhite />
            </MediaQuery>
          }
          middle={
            <div {...classes('columnLarge6', 'center', 'middleRow')}>
              <input
                accept=".jpg, .jpeg"
                ref={(ref) => {
                  this.fileInput = ref
                }}
                onChange={this.handleSelectedFiles}
                type="file"
              />

              <MediaQuery maxWidth={800}>
                {(isMobile) =>
                  isMobile ? (
                    <div {...classes('row')}>
                      <div {...classes('imageContentMobile')}>
                        {ImageContent}
                      </div>
                      <div>{TextContent}</div>
                    </div>
                  ) : (
                    <div {...classes('row')}>
                      {TextContent}
                      {ImageContent}
                    </div>
                  )
                }
              </MediaQuery>
            </div>
          }
          bottom={
            <div {...classes('container', 'profilePhotoBottom')}>
              <div {...classes('right', 'columnLarge6', 'center', 'bottomRow')}>
                <ProfileFlowButton
                  from={
                    this.props.history.location.state &&
                    this.props.history.location.state.from
                  }
                />
                <CardFlowButton
                  matchPath="/plain-card/photo"
                  linkTo="/plain-card/profile-summary"
                  canContinue={!!photo}
                />
                <CardFlowButton
                  matchPath="/digital-card/photo"
                  linkTo="/digital-card/profile-summary"
                  canContinue={!!photo}
                />
                <CardFlowButton
                  matchPath="/digi-isic/photo"
                  linkTo="/digi-isic/profile-summary"
                  canContinue={!!photo}
                />
                <CardFlowButton
                  matchPath="/studentcard/photo"
                  linkTo="/studentcard"
                  canContinue={!!photo}
                />
                <RegisterFlowButton canContinue={!!photo} />
                <ErrorCandy show={submitFailed} onHide={resetForm}>
                  {error}
                </ErrorCandy>
              </div>
            </div>
          }
        />
      </div>
    )
  }
}

export default ProfilePhotoPage
