import MediaQuery from 'react-responsive'
import * as React from 'react'
import {compose} from 'redux'
import {Link} from 'react-router-dom'

import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import TopMiddleBottomLayout from '$common/components/layouts/TopMiddleBottomLayout'
import align from '$common/grid/align.css'
import check from '$assets/icons/temporary/check.png'
import css from './FhsAfterSuccessPage.css'
import grid from '$common/grid/grid.css'
import {resolveCss, withRouter, withTranslate, withRunSaga} from '$common/utils'
import getSubscriptions from '$src/subscriptions/sagas/getSubscriptions'

class FhsAfterSuccessPage extends React.Component {
  async componentDidMount() {
    // Generate and fetch free digital card
    await this.props.runSaga(getSubscriptions)
  }

  render() {
    const classes = resolveCss(css, grid, align)

    return (
      <TopMiddleBottomLayout
        adaptiveLayout={{
          stackMiddle: true
        }}
        css={{
          container: grid.container,
          bottom: css.bottom
        }}
        top={
          <MediaQuery minWidth={801}>
            <FrankLogoInSmallWhite />
          </MediaQuery>
        }
        middle={
          <div {...classes('columnLarge5', 'center')}>
            <img
              {...classes('check', 'column', 'center')}
              src={check}
              alt="nothing"
            />
            <H1 css={{container: align.largeCenterText}}>
              {this.props.t('profile.fhs_verification.verified.title')}
            </H1>
            <P
              style="small"
              css={{container: [css.message, align.largeCenterText]}}
            >
              {this.props.t('profile.fhs_verification.verified.body')}
            </P>
          </div>
        }
        bottom={
          <Link to={'/home'} {...classes('column', 'center')}>
            <GenericButton
              style="bold"
              css={{container: css.button}}
              isExpanding={true}
            >
              {this.props.t('profile.fhs_verification.verified.cta')}
            </GenericButton>
          </Link>
        }
      />
    )
  }
}

export default compose(
  withRouter,
  withRunSaga(),
  withTranslate()
)(FhsAfterSuccessPage)
