import * as React from 'react'
import {compose} from 'redux'
import css from './index.css'
import logoSrc from '$assets/icons/frankLogoSolidWhite.svg'
import SvgIcon from '$common/components/icons/SvgIcon'

// type Props = {}

const FrankLogoSmallWhite = () => {
  return (
    <SvgIcon css={{container: css.container}} sizing="none" src={logoSrc} />
  )
}

export default compose((a) => a)(FrankLogoSmallWhite)
