import {combineReducers} from 'redux'
import {reducer as formReducer} from 'redux-form'

import authNamespaceReducer from '$src/auth/reducer'
import commonReducer from '$common/reducer'
import i18nReducerNamespace from '$src/i18n/reducer'
import offersNamespaceReducer from '$src/offers/reducer'
import organizationsReducer from '$src/organizations/reducer'
import {asyncErrors, requests} from '$src/fetching/reducers'
import profileNamespaceReducer from '$src/profile/reducer'
import notificationNamespaceReducer from '$src/notification/reducer'
import uiNamespaceReducer from '$src/ui/reducer'
import articlesNamespaceReducer from '$src/articles/reducer'
import newsNamespaceReducer from '$src/news/reducer'
import subscriptionsReducer from '$src/subscriptions/reducer'
import pricingNamespaceReducer from '$src/pricing/reducer'
import {StoreState} from '$src/types'
import {
  mergeStateAction,
  setStateAction,
  resetStateAction
} from '$common/actions'
import {schoolsReducer, setTrialPath} from '../profile/reducer'
import {favouritesReducer} from '$src/offers/components/Favourites/reducer'
import {error404Reducer} from '$src/error404/reducers'
import {locationsReducer} from '$src/offers/components/ListPage/reducer'
import {randomOfferReducer} from '$src/home/components/HomePage/reducers'

const mainReducer = combineReducers<StoreState>({
  asyncErrors,
  requests,
  news: newsNamespaceReducer,
  articles: articlesNamespaceReducer,
  organizations: organizationsReducer,
  auth: authNamespaceReducer,
  notification: notificationNamespaceReducer,
  common: commonReducer,
  form: formReducer,
  locale: i18nReducerNamespace,
  offers: offersNamespaceReducer,
  profile: profileNamespaceReducer,
  subscriptions: subscriptionsReducer,
  ui: uiNamespaceReducer,
  pricing: pricingNamespaceReducer,
  schools: schoolsReducer,
  trialPath: setTrialPath,
  favourites: favouritesReducer,
  error404: error404Reducer,
  locations: locationsReducer,
  randomOffer: randomOfferReducer
})

export default function rootReducer(
  state: StoreState,
  action: {type: string; payload: Partial<StoreState>}
): StoreState {
  if (action && action.type === mergeStateAction.type) {
    return {...state, ...action.payload}
  } else if (action.type === setStateAction.type) {
    return action.payload as StoreState
  } else if (action.type === resetStateAction.type) {
    return mainReducer(undefined, action)
  } else {
    return mainReducer(state, action)
  }
}
