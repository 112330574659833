import * as React from 'react'
import {compose} from 'redux'
import Grid from 'react-virtualized/dist/commonjs/Grid'
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader'
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import range from 'lodash/range'
import deepEqual from 'deep-equal'
import {CommonProps} from './InfiniteLoadingResponsiveCollection'
import css from './InfiniteLoadingGrid.css'
import NoContent from '$src/offers/components/ListPage/NoContent'

type RenderCellBasicProps = {
  columnIndex: number // Horizontal (column) index of cell
  isScrolling: boolean // The Grid is currently being scrolled
  isVisible: boolean // This cell is visible within the grid (eg it is not an overscanned cell)
  // key: any, // Unique key within array of cells
  parent: any // Reference to the parent Grid (instance)
  rowIndex: number // Vertical (row) index of cell
  style: any
}
export type RenderCellProps = RenderCellBasicProps & {
  pageNumber: number
  offsetInPage: number
  index: number
}

export type SpecialProps = {
  renderCell: (props: RenderCellProps) => React.Node
  cellHeight: number
  minCellWidth: number
  cellMargin: number
}

type Props = CommonProps & SpecialProps

type State = {
  responsiveStateCalculated: boolean
  responsiveState: {
    numberOfColumns: number
    columnWidth: number
  }
}

class InfiniteLoadingGrid extends React.Component<Props, State> {
  _windowScrollerRef: any
  _onRowsRendered: Function
  _updatePositionTimeout: any

  constructor(props: Props) {
    super(props)
    this.state = {
      responsiveStateCalculated: false,
      responsiveState: {
        numberOfColumns: 0,
        columnWidth: 0
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._onRowsRendered = () => {}
    this._windowScrollerRef = null
    this._updatePositionTimeout = null
  }

  static indexToPageNumber = (index: number, itemsPerPage: number): number =>
    Math.floor(index / itemsPerPage) + 1

  static indexToOffsetInPage = (index: number, itemsPerPage: number): number =>
    index % itemsPerPage

  loadMoreRows = ({startIndex, stopIndex}, props: Props = this.props) => {
    const startPage = InfiniteLoadingGrid.indexToPageNumber(
      startIndex,
      props.itemsPerPage
    )
    const stopPage = InfiniteLoadingGrid.indexToPageNumber(
      stopIndex,
      props.itemsPerPage
    )

    const pageNumbers: Array<number> = range(startPage, stopPage + 1)
    return props.fetchPages(pageNumbers)
  }

  renderCell = (params: RenderCellBasicProps) => {
    const {numberOfColumns} = this.getResponsiveState()
    const index = params.rowIndex * numberOfColumns + params.columnIndex
    const pageNumber = InfiniteLoadingGrid.indexToPageNumber(
      index,
      this.props.itemsPerPage
    )
    const offsetInPage = InfiniteLoadingGrid.indexToOffsetInPage(
      index,
      this.props.itemsPerPage
    )
    const innerCell = this.props.renderCell({
      ...params,
      pageNumber,
      offsetInPage,
      index,
      numberOfColumns
    })

    const padding = Math.floor(this.props.cellMargin / 2)

    return this.props.autoWrapItems === false ? (
      innerCell
    ) : (
      <div
        className={css.cellOuterWrapper}
        key={`cellOuterWrapper-${index}`}
        style={{...params.style}}
      >
        <div
          className={css.cellInnerWrapper}
          key={`cellInnerWrapper-${index}`}
          style={{top: padding, right: padding, bottom: padding, left: padding}}
        >
          {innerCell}
        </div>
      </div>
    )
  }

  getResponsiveState() {
    return this.state.responsiveState
  }

  UNSAFE_componentWillMount() {
    this.update(null, this.props)
  }

  componentDidUpdate(prevProps) {
    this.update(prevProps, this.props)
  }

  update(oldProps?: Props, newProps: Props) {
    if (!oldProps || !deepEqual(oldProps.uniqueParams, newProps.uniqueParams)) {
      newProps.fetchPages([1])
    }
  }

  _onSectionRendered({
    columnStartIndex,
    columnStopIndex,
    rowStartIndex,
    rowStopIndex
  }) {
    const {numberOfColumns} = this.getResponsiveState()
    const startIndex = rowStartIndex * numberOfColumns + columnStartIndex
    const stopIndex = rowStopIndex * numberOfColumns + columnStopIndex

    this._onRowsRendered({startIndex, stopIndex})
  }

  calculateResponsiveState(containerWidth: number) {
    const numberOfColumns = Math.floor(
      containerWidth / (this.props.minCellWidth + this.props.cellMargin)
    )
    const columnWidth = Math.floor(containerWidth / numberOfColumns)
    return {numberOfColumns, columnWidth}
  }

  onResize = ({width}) => {
    const newResponsiveState = this.calculateResponsiveState(width)
    if (!deepEqual(newResponsiveState, this.state.responsiveState)) {
      this.setState({
        responsiveState: newResponsiveState,
        responsiveStateCalculated: true
      })
    }
  }

  isRowLoaded = ({index}: {index: number}): boolean => {
    const pageNumber = InfiniteLoadingGrid.indexToPageNumber(
      index,
      this.props.itemsPerPage
    )
    const offsetInPage = InfiniteLoadingGrid.indexToOffsetInPage(
      index,
      this.props.itemsPerPage
    )
    // sending the pageNumber to the parent component
    // to be used in scroll depth analytics
    this.props.currentPage.current = pageNumber
    return this.props.isItemLoaded({index, pageNumber, offsetInPage})
  }

  _setWindowScrollerRef = (windowScroller) => {
    this._windowScrollerRef = windowScroller

    this.clearUpdatePositionTimeout()
    this._updatePositionTimeout = setTimeout(() => {
      if (this._windowScrollerRef) {
        this._windowScrollerRef.updatePosition()
      }
    }, 1000)
  }

  clearUpdatePositionTimeout() {
    if (this._updatePositionTimeout !== null) {
      clearTimeout(this._updatePositionTimeout)
      this._updatePositionTimeout = null
    }
  }

  componentWillUnmount() {
    this.clearUpdatePositionTimeout()
  }

  render() {
    const responsiveState = this.getResponsiveState()

    return (
      <AutoSizer disableHeight onResize={this.onResize}>
        {({width}) => {
          if (this.state.responsiveStateCalculated === false) {
            return null
          }
          const numberOfColumns = responsiveState.numberOfColumns
          return (
            <InfiniteLoader
              isRowLoaded={this.isRowLoaded}
              loadMoreRows={this.loadMoreRows}
              rowCount={
                Math.ceil(this.props.itemCount / numberOfColumns) *
                numberOfColumns
              }
            >
              {({onRowsRendered, registerChild}) => {
                this._onRowsRendered = onRowsRendered
                return (
                  <WindowScroller
                    ref={this._setWindowScrollerRef}
                    disableHeight
                  >
                    {({height, isScrolling, scrollTop}) => {
                      return (
                        <Grid
                          noContentRenderer={NoContent}
                          autoHeight
                          height={height}
                          width={width}
                          columnCount={numberOfColumns}
                          columnWidth={responsiveState.columnWidth}
                          isScrolling={isScrolling}
                          scrollTop={scrollTop}
                          onSectionRendered={(...args) =>
                            this._onSectionRendered(...args)
                          }
                          ref={(ref) => {
                            registerChild(ref)
                          }}
                          rowCount={Math.ceil(
                            this.props.itemCount / numberOfColumns
                          )}
                          rowHeight={
                            this.props.cellHeight + this.props.cellMargin
                          }
                          cellRenderer={this.renderCell}
                        />
                      )
                    }}
                  </WindowScroller>
                )
              }}
            </InfiniteLoader>
          )
        }}
      </AutoSizer>
    )
  }
}

export default compose((a) => a)(InfiniteLoadingGrid)
