import * as React from 'react'

import css from './DiscountBoxDialog.css'
import {resolveCss, withTranslate} from '$common/utils'

const AffiliateLinkDialog = (props) => {
  const classes = resolveCss(css)

  return (
    <div {...classes('menuWrapper')}>
      <div {...classes('menu')}>{props.t('offer.affiliateDialog.body')}</div>
    </div>
  )
}

export default withTranslate()(AffiliateLinkDialog)
