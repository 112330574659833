import * as React from 'react'
import {compose} from 'redux'

import {Route, Switch, BrowserRouter as Router} from 'react-router-dom'

import NavigationEventLogger from '$src/analytics/NavigationEventLogger'
import HomePage from '$src/home/components/HomePage/HomePage-container'
import OrderCardPage from '$src/ordercard/components/OrderCardPage'
import userUnauthenticated from '$src/auth/components/userUnauthenticated'
import {userHasPhoto} from '$src/auth/components/userHasPhoto'
import NotFoundPage from '$common/components/NotFoundPage'
import OfflineStudentcard from '$src/offline-studentcard/components/StudentCard-container'
import GreetingPage from '$src/onboarding/components/GreetingPage'
import SplashUnlessBootstrapped from '$src/bootstrap/components/SplashUnlessBootstrapped'
import Main from '$src/bootstrap/components/Main-container'
import NavBar from '$src/common/components/NavBar/NavBar-container'
import offersRoutes from '$src/offers/routes'
import authRoutes from '$src/auth/routes'
import onboardingRoutes from '$src/onboarding/routes'
import profileRoutes from '$src/profile/routes'
import articleRoutes from '$src/articles/routes'
import subscriptionsRoutes from '$src/subscriptions/routes'
import Logout from '$common/components/Logout/Logout'
import BurgerMenu from '$common/components/BurgerMenu/BurgerMenu-container'
import UnbounceLauncher from '$src/unbounce/launcher'
import {jobRoutes} from '$src/job/routes'

let PlaygroundPage
if (process.env.NODE_ENV === 'development') {
  PlaygroundPage = require('$src/playground/components/PlaygroundPage').default
}

const conditionalStudentCardRoutes = window.cordova
  ? [
      <Route
        key="studentcard"
        path={`/studentcard`}
        component={userHasPhoto('/digital-card/photo')(OfflineStudentcard)}
      />
    ]
  : []

const routes = [
  ...jobRoutes,
  ...profileRoutes,
  ...offersRoutes,
  ...onboardingRoutes,
  ...subscriptionsRoutes,
  ...articleRoutes,
  ...authRoutes,
  ...conditionalStudentCardRoutes,
  process.env.NODE_ENV === 'development' ? (
    <Route
      key="playgroundRoute"
      path={`/playground`}
      component={PlaygroundPage}
    />
  ) : null,
  <Route key="home" path={`/home`} component={HomePage} />,
  <Route key="ordercard" path={`/ordercard`} component={OrderCardPage} />,
  <Route
    key="toIndex"
    path={`/`}
    exact
    component={userUnauthenticated(GreetingPage)}
  />,
  <Route key="logout" path={`/logout`} exact component={Logout} />,
  <Route key="menu" path={`/menu`} exact component={BurgerMenu} />,
  <Route key="unbounce" path={`/unbounce`} component={UnbounceLauncher} />,
  <Route key="404" component={NotFoundPage} />
].map(
  (el: React.Element<any>, i): React.Node => {
    return (
      el && (
        <el.type {...el.props} key={i}>
          {el.children && el.children}
        </el.type>
      )
    ) // eslint-disable-line
  }
)

/**
 * Root routes. Since we're using react-router@v4, we don't need to keep all of these
 * routes in this file. In fact, I think we should put the routes belonging to each namespace
 * inside that namespace.
 *
 * @note Make sure to read react-router@v4'd docs, otherwise things would look confusing.
 *
 * LoggedInOnlyRotue, userUnauthenticated, etc are routes that will redirect to a different
 * route if their pre-condition isn't met.
 */
const RootRoutes = () => {
  /**
   * Fix BrowserRouter for cordova https://github.com/ReactTraining/react-router/issues/2161
   * HashRouter breaks back button scroll positioning when coming back from a single offer.
   */
  const basename = window.cordova
    ? document.location.pathname.split('index.html')[0] + 'index.html#'
    : null
  const routerProps = {basename}
  return (
    <SplashUnlessBootstrapped>
      <Router {...routerProps}>
        <Route
          render={({location}) => {
            /**
             * Fix for Android.
             * On Android, the first lunch gets sutck in an infinite loader due to a path error
             */
            {
              {
                if (
                  window.cordova &&
                  window.cordova.platformId == 'android' &&
                  location.pathname == '/index.html'
                ) {
                  location.pathname = '/'
                }
              }
            }
            return (
              <NavigationEventLogger>
                <span>
                  <span id="#portal" />
                  <Main>
                    <Switch location={location}>{routes}</Switch>
                  </Main>
                  <NavBar />
                </span>
              </NavigationEventLogger>
            )
          }}
        />
      </Router>
    </SplashUnlessBootstrapped>
  )
}

export default compose((a) => a)(RootRoutes)
