import * as React from 'react'
import {compose} from 'redux'
import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect'
import {authenticatedWrapper} from '$src/auth/components/userAuthenticated'
import {verifiedWrapper} from '$src/auth/components/userVerified'
import {getProfilePhoto} from '$src/profile/selectors'
import {StoreState} from '$src/types'

const hasPhotoWrapper = (photoPageUrl) =>
  connectedRouterRedirect({
    redirectPath: `/no-picture/${encodeURIComponent(photoPageUrl)}`,
    allowRedirectBack: false,
    authenticatedSelector: (state: StoreState) => {
      const photo = getProfilePhoto(state)
      return !!photo
    },
    wrapperDisplayName: 'UserHasPhoto'
  })

export const userHasPhoto = (photoPageUrl: string) => (
  Component: React.ComponentType<any>
) =>
  compose(
    authenticatedWrapper,
    verifiedWrapper,
    hasPhotoWrapper(photoPageUrl)
  )(Component)

export default (Component: React.ComponentType<any>) =>
  compose(
    authenticatedWrapper,
    verifiedWrapper,
    hasPhotoWrapper('/profile/photo')
  )(Component)
