import * as React from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'

import {Modal} from 'react-overlays'
import NewsCarousel from '$src/news/components/NewsCarousel-container'
import SvgIcon from '$common/components/icons/SvgIcon'
import messageIcon from '$assets/icons/message.svg'

import P from '$common/components/P'
import css from './NewsTeaser.css'
import fetchNews from '$src/news/sagas/fetchNews'
import {getNewsItems} from '$src/news/selectors'
import {isFetching} from '$src/fetching/selectors'
import {
  resolveCss,
  withTranslate,
  isDeviceOnline,
  WithTranslateProps
} from '$common/utils'
import withRunSaga, {WithRunSagaProps} from '$common/utils/withRunSaga'
import {NewsItem} from '$src/news/types'
import {Lang} from '$src/i18n/types'
import {getPreferredLang} from '$src/i18n/selectors'

type Props = WithRunSagaProps &
  WithTranslateProps & {
    firstNews?: NewsItem
    languageCode: Lang
    isFetchingNews: boolean
    toggleCarousel: () => void
    isCarouselOpen: boolean
  }

class NewsTeaser extends React.Component<Props> {
  componentDidMount() {
    if (!isDeviceOnline()) {
      return
    }

    if (!this.props.firstNews) {
      this.props.runSaga(fetchNews)
    }
  }

  componentDidUpdate(previousProps) {
    if (
      previousProps.languageCode !== this.props.languageCode &&
      isDeviceOnline()
    ) {
      this.props.runSaga(fetchNews)
    }
  }

  toggleCarousel = () => {
    if (!this.props.isFetchingNews && this.props.firstNews) {
      this.props.toggleCarousel()
    }
  }

  render() {
    const classes = resolveCss(css)
    const firstNewsTitle = this.props.firstNews && this.props.firstNews.title
    const showNewsTeaser = firstNewsTitle ? true : false

    const isIOs = window.device?.platform === 'iOS'

    return (
      showNewsTeaser && (
        <div>
          <div {...classes('container')} onClick={this.toggleCarousel}>
            <div {...classes('teaserTextContainer')}>
              <P style="small" bold color="black">
                {firstNewsTitle}
              </P>
            </div>
            <div {...classes('teaserIconContainer')}>
              <SvgIcon
                css={{container: css.teaserIcon}}
                sizing="none"
                src={messageIcon}
                style={{
                  margin: '0',
                  padding: '0',
                  width: '35px',
                  height: '35px'
                }}
              />
            </div>
          </div>
          {this.props.isCarouselOpen && (
            <Modal
              backdropClassName={css.modal}
              container={document.body}
              onHide={this.toggleCarousel}
              show={this.props.isCarouselOpen}
            >
              <div {...classes('modalContent', isIOs && 'modalContentIos')}>
                <NewsCarousel handleClose={this.toggleCarousel} />
              </div>
            </Modal>
          )}
        </div>
      )
    )
  }
}

export default compose(
  withTranslate(),
  connect((state) => {
    const news = getNewsItems(state)
    return {
      isFetchingNews: isFetching(state, `news`),
      languageCode: getPreferredLang(state),
      firstNews: news[0]
    }
  }),
  withRunSaga()
)(NewsTeaser)
