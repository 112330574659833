import start from './StartTrialPage-container'
import success from './StartTrialSuccessPage'
import fail from './StartTrialFailPage'
import status from './TrialStatusPage'
import activateMembership from './ActivateMembershipPage'
import landing from './LandingPage'
import ending from './EndingPage'

export const TrialPages = {
  landing,
  ending,
  start,
  success,
  fail,
  status,
  activateMembership
}
