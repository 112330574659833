import * as React from 'react'
import i18next from 'i18next'
import {I18nextProvider} from 'react-i18next'
import {PropTypes} from 'prop-types'
import {connect} from '$common/utils'

import {compose} from 'redux'
import localesByName from '../locales'
import {getPreferredLang} from '$src/i18n/selectors'
import {setPreferredLang} from '$src/i18n/sagas'
import {StoreState} from '$src/types'
import {withRunSaga, WithRunSagaProps} from '$common/utils'

const defaultLang = 'fi'

i18next.init(
  {
    resources: localesByName,
    lng: defaultLang
  },
  (err, t) => {
    if (err) {
      // @todo handle this properly
      console.error(err, t) // eslint-disable-line
    }
  }
)

type Props = WithRunSagaProps & {
  children: any
  preferredLang: string
}

class TranslationProvider extends React.Component<Props> {
  UNSAFE_componentWillMount() {
    this.ensureCorrectLangIsSet(this.props)
    i18next.on('languageChanged', (lng) => {
      if (this.props.preferredLang !== lng) {
        this.props.runSaga(setPreferredLang, lng)
      }
    })
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.ensureCorrectLangIsSet(newProps)
  }

  ensureCorrectLangIsSet({preferredLang}) {
    if (localesByName[preferredLang] && i18next.language !== preferredLang) {
      i18next.changeLanguage(preferredLang)
    } else if (i18next.language !== defaultLang) {
      i18next.changeLanguage(defaultLang)
    }
  }

  render() {
    return (
      <I18nextProvider i18n={i18next}>{this.props.children}</I18nextProvider>
    )
  }

  getChildContext() {
    return {
      availableLangs: Object.keys(localesByName)
    }
  }

  static childContextTypes = {
    availableLangs: PropTypes.any
  }
}

export default compose(
  connect((state: StoreState) => ({preferredLang: getPreferredLang(state)})),
  withRunSaga()
)(TranslationProvider)
