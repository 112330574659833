import callEndpoint from '$src/api/sagas/callEndpoint'
import jolla from '$src/api/endpoints/auth/jolla'
import {setUserProfile} from '$common/utils'
import {getAppboy} from '$common/pgWebSeparatorUtils'
import {cloneDeep} from 'lodash'

export default function* verifyJolla(values) {
  // FIXME Changing studentid to ssn here for backwards compatibility with API.
  // Could be changed in the future so that backend differentiates between student id and SSN.
  const reqValues = cloneDeep(values)
  if (reqValues.studentId) {
    reqValues.ssn = reqValues.studentId
  }

  if (reqValues.jollaId) {
    reqValues.organizationId = reqValues.jollaId // Backend expects a field called organizationId
  }

  const response = yield callEndpoint(jolla, reqValues)

  if (response.id) {
    const appboy = getAppboy()
    appboy.changeUser(response.id)
    if (!window.cordova) {
      appboy.openSession()
    }
    appboy.logCustomEvent('Registered')
  }

  yield setUserProfile(response)
}
