import * as React from 'react'
import {compose} from 'redux'
import MediaQuery from 'react-responsive'
import {Interpolate} from 'react-i18next'
import {SubmissionError, FormProps} from 'redux-form'
import FinnishSSN from 'finnish-ssn'
import trim from 'lodash/trim'
import isEmpty from 'lodash/isEmpty'

import {
  createApiErrorMessage,
  asForm,
  resolveCss,
  withRunSaga,
  withRouter,
  WithRouterProps,
  WithRunSagaProps,
  withTranslate,
  WithTranslateProps
} from '$common/utils'
import Layout from '$common/components/layouts/TopMiddleBottomLayout'
import GenericButton from '$common/components/buttons/GenericButton'
import grid from '$common/grid/grid.css'
import align from '$common/grid/align.css'
import css from './KoskiFormPage.css'
import P from '$common/components/P'
import TextInput from '$common/components/forms/TextInput'
import ErrorCandy from '$common/components/candyBars/ErrorCandy'
import LoadingSpinnerModal from '$common/components/LoadingSpinnerModal'
import BackButton from '$common/components/buttons/BackButton'
import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import verifyKoski from '$src/profile/sagas/verifyKoski'
import koskiAuthorization from '$src/profile/components/verification/utils/koskiAuthorization'
import getSubscriptions from '$src/subscriptions/sagas/getSubscriptions'

const State = {
  INITIAL: 'INITIAL',
  AUTHORIZING: 'AUTHORIZING',
  REGISTERING: 'REGISTERING'
}

type Props = WithRouterProps & WithTranslateProps & FormProps & WithRunSagaProps
type StateDef = {state: string}

class KoskiFormPage extends React.Component<Props, StateDef> {
  state = {state: State.INITIAL}
  koskiWindow = null

  componentDidMount() {
    this.submit()
  }

  submit = () => {
    if (this.props.fieldValues.ssn) {
      this.registerViaKoski()
    } else {
      this.setState({state: State.INITIAL})
    }
  }

  componentWillUnmount() {
    if (this.koskiWindow && !this.koskiWindow.closed) this.koskiWindow.close()
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.fieldValues.ssn !== this.props.fieldValues.ssn) {
      this.setState({state: State.INITIAL})
    }
  }

  registerViaKoski = async () => {
    this.setState({state: State.REGISTERING})
    try {
      const values = {ssn: trim(this.props.fieldValues.ssn)}
      await this.props.runSaga(verifyKoski, values)
      this.props.runSaga(getSubscriptions)
      this.props.history.replace('/profile/verify/after-success')
    } catch (err) {
      this.setState({state: State.INITIAL})
      const errMessageKey = createApiErrorMessage(
        err,
        'error.profile.verification.koski'
      )
      throw new SubmissionError({
        _error: this.props.t(errMessageKey),
        general: true
      })
    }
  }

  onOpenKoskiAuthorization = async () => {
    if (!window.cordova) {
      // For some reason the in-app browser closes if this state is set.
      // AUTHORIZING state is not visible in cordova app, so it's okay to just not
      // set it, though it's a totally inelegant solution.
      this.setState({state: State.AUTHORIZING})
    }
    await koskiAuthorization()
    this.submit()
  }

  render() {
    const classes = resolveCss(css, grid, align)

    if (this.state.state === State.AUTHORIZING) {
      return (
        <LoadingSpinnerModal
          message={this.props.t('profile.verification.koski.authorizing')}
        />
      )
    } else if (this.state.state === State.REGISTERING) {
      return (
        <LoadingSpinnerModal
          message={this.props.t('profile.verification.koski.verifying')}
        />
      )
    }

    const registerButton = (
      <GenericButton style="bold" disabled={this.props.invalid}>
        {this.props.t('profile.verification.koski.register')}
      </GenericButton>
    )

    const backButton = (
      <GenericButton style="outline" onClick={this.props.history.goBack}>
        {this.props.t('authentication.register.secondaryCta')}
      </GenericButton>
    )

    const faq = (
      <a
        href={this.props.t('common.faqLink')}
        target="_blank"
        rel="noopener noreferrer"
      >
        {this.props.t('profile.verification.faq')}
      </a>
    )

    return (
      <form onSubmit={this.props.handleSubmit(this.registerViaKoski)}>
        <Layout
          adaptiveLayout={{stackMiddle: true}}
          css={{
            container: grid.container,
            bottom: css.bottom
          }}
          top={
            <MediaQuery maxWidth={800}>
              {(isMobile) => {
                if (isMobile) return <BackButton onClick="goBack" />
                else return <FrankLogoInSmallWhite />
              }}
            </MediaQuery>
          }
          middle={
            <div {...classes('columnLarge5', 'center')}>
              <P css={{container: css.p}} style="small">
                {this.props.t('profile.verification.koski.body')}
              </P>
              <P css={{container: css.p}}>
                <GenericButton
                  style="bold"
                  onClick={this.onOpenKoskiAuthorization}
                >
                  {this.props.t('profile.verification.koski.goToKoski')}
                </GenericButton>
              </P>

              <P css={{container: css.p}} style="small">
                {this.props.t('profile.verification.koski.fillSsn')}
              </P>
              <TextInput
                name="ssn"
                placeholder={this.props.t(
                  'profile.verification.koski.ssnPlaceholder'
                )}
                label={this.props.t('profile.verification.koski.ssn')}
              />

              <P css={{container: css.problems}} style="subtle">
                <Interpolate
                  i18nKey="profile.verification.problems"
                  faq={faq}
                />
              </P>

              {
                <ErrorCandy
                  show={
                    !isEmpty(this.props.submitErrors) &&
                    !isEmpty(this.props.error)
                  }
                  onHide={this.props.clearSubmitErrors}
                >
                  {this.props.error}
                </ErrorCandy>
              }
            </div>
          }
          bottom={
            <div {...classes('columnLarge5', 'center')}>
              <MediaQuery maxWidth={800}>
                {(isMobile) => {
                  if (isMobile) {
                    return registerButton
                  } else {
                    return (
                      <div className={grid.row}>
                        <div {...classes('column', 'right')}>
                          {backButton}
                          &nbsp;
                          {registerButton}
                        </div>
                      </div>
                    )
                  }
                }}
              </MediaQuery>
            </div>
          }
        />
      </form>
    )
  }
}

const validate = ({ssn}) => ({
  ssn: !FinnishSSN.validate(trim(ssn))
})

export default compose(
  withRouter,
  withRunSaga(),
  withTranslate(),
  asForm('auth', {
    validate,
    destroyOnUnmount: process.env.NODE_ENV !== 'development'
  })
)(KoskiFormPage)
