import {connect} from 'react-redux'
import {compose} from 'redux'
import {isLoggedIn} from '$src/auth/selectors'
import {withTranslate, withRouter, withRunSaga} from '$common/utils'
import BurgerMenu from './BurgerMenu'
import {
  isUserActive,
  getProfile,
  getStudentType,
  isUserAlumn
} from '$src/profile/selectors'

const mapStateToProps = (state) => {
  return {
    isLoggedIn: isLoggedIn(state),
    profile: getProfile(state),
    isUserActive: isUserActive(state),
    isSecondGradeStudentByStudentType:
      getStudentType(state) === 'upper_secondary',
    isAlumn: isUserAlumn(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withRouter,
  withTranslate(),
  withRunSaga()
)(BurgerMenu)
