import {
  default as findVoucher,
  Params as FindEndpointParams
} from '$src/api/endpoints/vouchers/find'
import {callEndpoint} from '$src/api/sagas/callEndpoint'
import {reduceState} from '$common/utils'
import {select} from 'redux-saga/effects'
import {StoreState} from '$src/types'
// import {Offer} from '$src/offers/types'
import {Voucher} from '$src/offers/types'
import {getPreferredLang} from '$src/i18n/selectors'

export type Params = FindEndpointParams

export default function* fetchVoucher({key}: Params): Generator<> {
  const state: StoreState = yield select()
  const languageCode = getPreferredLang(state)
  const response: Voucher = yield callEndpoint('singleVoucher')(findVoucher, {
    languageCode,
    key
  })

  yield reduceState(['offers', languageCode, 'byKey'], (state) => {
    return {...state, [response.key]: response}
  })
}
