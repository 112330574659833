import {
  withRunSaga,
  withRouter,
  WithRouterProps,
  WithRunSagaProps,
  withTranslate,
  WithTranslateProps
} from '$common/utils'
import {PlainTextInput} from '$common/components/forms/TextInput'
import SvgIcon from '$common/components/icons/SvgIcon'
import SearchIcon from '$common/components/icons/SearchIcon'
import {compose} from 'redux'
import css from './index.css'
import * as React from 'react'
import closeIcon from '$assets/icons/close.svg'
import frankSpinner from '$assets/icons/frankSpinner.svg'
import {resolveCss} from '$common/utils'
import queryString from 'query-string'

type Props = WithRouterProps &
  WithRunSagaProps &
  WithTranslateProps & {initialValue: string; isFetching?: boolean}
type State = {search: string; active: boolean; touched: boolean}

class SearchInput extends React.Component<Props, State> {
  static defaultProps = {initialValue: ''}
  state = {
    search: this.props.initialValue,
    active: false
  }
  input = undefined

  componentDidMount() {
    if (this.input) {
      this.input.addEventListener('keydown', this.listenToEnter)
    }
    this.props.termClick.current = this.handleSearchTerm
  }

  componentWillUnmount() {
    if (this.input) {
      this.input.removeEventListener('keydown', this.listenToEnter)
    }
  }
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.state.search && !nextState.search) {
      this.props.initiateSearch()
      this.setSearch()
    }
  }

  setSearch = (value?: string) => {
    const parsedURL = queryString.parse(this.props.location.search)
    if (value === '') {
      this.props.history.push({
        path: '/offers',
        search: '?' + queryString.stringify(omit(parsedURL, 'search'))
      })
    } else {
      parsedURL.search = value
      this.props.history.push({
        path: '/offers',
        search: '?' + queryString.stringify(parsedURL)
      })
      this.props.setSearchTermToStorage(value)
    }
    this.props.setShowSearchTermList(false)
    this.props.updateSearchToParentState(value)
  }

  listenToEnter = (e) => {
    if (e.keyCode === 13 && !!this.state.search) {
      this.setSearch(this.state.search)
      if (window.cordova) {
        window.Keyboard.hide()
      }
    }
  }

  handleSearchTerm = (term) => {
    this.setState({search: term})
  }

  clearSearch = () => this.setState({search: ''})

  onFocus = () => this.setState({active: true})
  onBlur = () => this.setState({active: false})

  onChange = (e: Event) => {
    e.target instanceof HTMLInputElement &&
      this.setState({search: e.target.value})
    this.props.updateSearchToParentState(e.target.value)
  }

  render() {
    const {active, search} = this.state
    const classes = resolveCss(css)
    return (
      <div {...classes('container')}>
        <PlainTextInput
          setShowSearchTermList={this.props.setShowSearchTermList}
          setShowFilterDropDown={this.props.setShowFilterDropDown}
          css={{
            input: css.search,
            container: css.inputContainer,
            addonAfter: css.addonAfter,
            addonActive: css.addonActive
          }}
          inputRef={(input: object) => {
            this.input = input
          }}
          placeholder={this.props.t('offers.search.placeholder')}
          input={{
            value: this.state.search,
            onChange: this.onChange,
            onFocus: this.onFocus,
            onBlur: this.onBlur
          }}
          meta={{active, autoFocus: false, searchBar: true}}
          addonAfter={
            this.props.isFetching ? (
              <SvgIcon css={{container: css.spinner}} src={frankSpinner} />
            ) : search !== '' && search !== undefined ? (
              <SvgIcon
                role="button"
                aria-label="Clear Search"
                css={{container: css.clearSearch}}
                onClick={this.clearSearch}
                src={closeIcon}
              />
            ) : window.screen.width > 801 ? (
              <SearchIcon css={{container: css.searchIcon}} />
            ) : null
          }
        />
      </div>
    )
  }
}

export default compose(withRunSaga(), withRouter, withTranslate())(SearchInput)
