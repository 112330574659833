import * as React from 'react'
import {compose} from 'redux'
import {connect} from '$common/utils'

import SelectBoxField from '$common/components/forms/SelectBoxField'
import config from '$src/config'
import {asForm, withRunSaga, WithRunSagaProps} from '$common/utils'
import {getPreferredLang} from '$src/i18n/selectors'
import {setPreferredLang} from '$src/i18n/sagas'
import {StoreState} from '$src/types'

type Props = WithRunSagaProps & {
  preferredLang: string
  compact?: boolean
}

const {languageOptions} = config

const LanguageSelector = ({
  runSaga,
  preferredLang,
  compact,
  ...rest
}: Props) => {
  const languages = languageOptions.map((language) => ({
    value: language.short,
    label: compact ? language.short : language.long
  }))

  const preferredValue = languages.find((language) => {
    return language.value === preferredLang
  })

  const setLanguage = (value) => runSaga(setPreferredLang, value)
  return (
    <SelectBoxField
      {...rest}
      name="languageSelector"
      options={languages}
      config={{
        isSearchable: false,
        value: preferredValue
      }}
      input={{
        onChange: setLanguage
      }}
    />
  )
}

export default compose(
  connect((state: StoreState) => ({preferredLang: getPreferredLang(state)})),
  withRunSaga(),
  asForm('verify/start', {})
)(LanguageSelector)
