import {withRouter} from '$common/utils'
import {useEffect} from 'react'
import {compose} from 'redux'

const launcher = ({history, location}) => {
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const url = queryParams.get('url')
    // in app browser doesn't work with urls that are without http(s)
    if (url.includes('https') || url.includes('http')) {
      const target = '_blank'
      const options = 'location=no,toolbar=yes,presentationstyle=fullscreen,'
      const inAppBrowser = window.open(url, target, options)
      inAppBrowser.addEventListener('message', function(e: any) {
        inAppBrowser.close()
        /* the message that comes from unbounce should 
        be either '/' or '/offer/offer_1234' */
        history.push(e.data.message)
      })
    }
    /* if inAppBrowser is closed without a 'message' event
    then we use the below push to navigate to the home page */
    history.push('/')
  }, [])

  return null
}

export default compose(withRouter)(launcher)
