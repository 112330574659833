import * as React from 'react'
import {hot} from 'react-hot-loader/root'
import {Provider as StoreProvider} from 'react-redux'

import I18NContextProvider from '$src/i18n/components/I18NContextProvider'
import RootRoutes from '$src/bootstrap/components/RootRoutes'
import amplitude from '$src/analytics/amplitude'
import configureCore from '$src/bootstrap/configureCore'
import {getAppboy} from '$common/pgWebSeparatorUtils'
import '!style-loader!css-loader!./App.nomod.css'
import Core from '$src/bootstrap/Core'
import initializeDeepLinks from '$src/bootstrap/components/initializeDeepLinks'
import deviceType from '$src/common/utils/agentDevice'

type Props = {}

type State = {
  core?: Core<any, any>
}

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      core: undefined
    }
  }

  async UNSAFE_componentWillMount() {
    amplitude.init(process.env.AMPLITUDE_API_KEY, null, {
      platform: window.cordova ? 'Cordova' : 'Web'
    })
    getAppboy()
    amplitude.addDeviceTypeToUser(deviceType())
    if (
      window.cordova &&
      window.device &&
      window.device.platform !== 'browser'
    ) {
      initializeDeepLinks()
    }
  }

  setLang(locale) {
    const html = document.getElementsByTagName('html')[0]
    html.setAttribute('lang', `${locale}-${locale}`)
  }

  componentDidMount() {
    document.body.classList.add('no-touch')
    const callback = () => {
      document.body.classList.remove('no-touch')
      document.removeEventListener('touchstart', callback)
    }

    document.addEventListener('touchstart', callback)
    configureCore().then((core) => {
      this.setLang(core.reduxStore.getState().locale)
      this.setState({core})
    })
  }

  render() {
    const {core} = this.state

    if (!core) {
      return null
    }

    if (window.device && window.device.platform === 'iOS') {
      window.addEventListener('keyboardWillShow', () =>
        window.StatusBar?.hide()
      )
      window.addEventListener('keyboardWillHide', () =>
        window.StatusBar?.show()
      )
    }

    return (
      <StoreProvider store={core.reduxStore}>
        <I18NContextProvider>
          <RootRoutes />
        </I18NContextProvider>
      </StoreProvider>
    )
  }
}

export default process.env.HMR ? hot(App) : App
