import * as React from 'react'
import {compose} from 'redux'

import GenericButton from '$common/components/buttons/GenericButton'
import {isUserVerified, isUserActive, isUserAlumn} from '$src/profile/selectors'
import {isLoggedIn} from '$src/auth/selectors'
import {resetState} from '$common/sagas'

import discountBoxCss from '$src/offers/components/DiscountBox/index.css'
import {
  connect,
  withRunSaga,
  WithRunSagaProps,
  withTranslate,
  WithTranslateProps,
  withRouter,
  WithRouterProps,
  urlWithRedirect
} from '$common/utils'

type Props = WithTranslateProps &
  WithRouterProps &
  WithRunSagaProps & {
    loggedIn: boolean
    verified: boolean
    active: boolean
    isUserAlumn: boolean
  }

const UnverifiedBox = ({
  t,
  loggedIn,
  verified,
  active,
  location,
  runSaga,
  history,
  isUserAlumn
}: Props) => {
  let redirectUrl = urlWithRedirect('/', location)
  let ctaDescKey = 'offer.loginPrompt.cta'
  let body = 'offer.loginPrompt.body'

  if ((active && verified) || isUserAlumn) {
    return null
  } else if (verified && !active) {
    redirectUrl = null
    ctaDescKey = null
    body = 'offer.inactivePrompt.body'
  } else if (loggedIn) {
    redirectUrl = '/profile/verify/start'
    ctaDescKey = 'offer.verifyPrompt.cta'
    body = 'offer.verifyPrompt.body'
  }

  const onClick = async () => {
    if (!loggedIn) {
      await runSaga(resetState)
    }
    history.push(redirectUrl)
  }

  return (
    <div className={discountBoxCss.discountBox}>
      <div
        style={{textAlign: 'center'}}
        className={discountBoxCss.discountBoxItem}
      >
        {t(body)}
      </div>
      {ctaDescKey && (
        <div className={discountBoxCss.discountBoxItem}>
          <GenericButton onClick={onClick} isFullWidth style="bold">
            {t(ctaDescKey)}
          </GenericButton>
        </div>
      )}
    </div>
  )
}

export default compose(
  withTranslate(),
  withRunSaga(),
  withRouter,
  connect((state) => ({
    verified: isUserVerified(state),
    active: isUserActive(state),
    loggedIn: isLoggedIn(state),
    isUserAlumn: isUserAlumn(state)
  }))
)(UnverifiedBox)
