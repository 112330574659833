import React, {Component} from 'react'
import {WithRouterProps} from 'react-router-dom'

import LoadingSpinnerModal from '$common/components/LoadingSpinnerModal'
import SvgIcon from '$common/components/icons/SvgIcon'
import frankLogoRed from '$assets/icons/frankLogoRedOutline.svg'
import LogoutHeader from '$common/components/Header/LogoutHeader-container'
import Layout from '$common/components/layouts/TopMiddleBottomLayout'
import css from './ProfileSettingsPage.css'
import layoutCss from '$common/grid/grid.css'
import alignCss from '$common/grid/align.css'
import P from '$common/components/P'
import GenericButton from '$common/components/buttons/GenericButton'
import syncProfile from '$src/profile/sagas/syncProfile'
import koskiAuthorization from '$src/profile/components/verification/utils/koskiAuthorization'
import {
  resolveCss,
  WithRunSagaProps,
  WithResponsiveInfoProps
} from '$common/utils'
import ProfileSettingsForm from './ProfileSettingsForm'
import ErrorCandy from '$common/components/candyBars/ErrorCandy'
import {Profile} from '$src/profile/types'
import {Link} from 'react-router-dom'


type Props = WithRouterProps &
  WithRunSagaProps &
  WithResponsiveInfoProps & {
    location: {pathname: string}
    isFetchingLanguage: boolean
    isFetchingProfile: boolean
    isFetchingSubscriptions: boolean
    subscriptionsLoaded: boolean
    isVerified: boolean
    initialValues: Profile & {
      studentEligibilityValue: string
      studentType: string
    }
  }

export default class ProfileSettingsPage extends Component<Props> {
  static rootPath = '/profile/settings'

  form = undefined

  backButtonHandler = () => {
    this.props.history.push('/home')
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    document.addEventListener('backbutton', this.backButtonHandler)
  }

  componentWillUnmount() {
    document.removeEventListener('backbutton', this.backButtonHandler)
  }

  onSync = () => this.props.runSaga(syncProfile)
  onVerifyStart = () => this.props.history.push('/profile/verify/start')
  openActivateMembershipPage = () => this.props.history.push('/profile/trial/activatemembership')
  openTrialStatusPage = () => this.props.history.push('/profile/trial/status')


  render() {
    const {firstName, lastName, photo, email} = this.props.initialValues
    const classes = resolveCss(css, layoutCss, alignCss, this.props.css)
    const isFetching =
      this.props.isFetchingLanguage ||
      this.props.isFetchingProfile ||
      this.props.isFetchingSubscriptions

    return (
      <Layout
        noPadding={true}
        topStyle="sticky"
        css={{top: css.top}}
        top={<LogoutHeader css={{wrapper: css.fixedHeader}} />}
        middle={
          <div>
            {(isFetching || this.props.submitting) && (
              <LoadingSpinnerModal message={this.props.t('profile.loading')} />
            )}

            <div className={css.formContainer}>
              <div {...classes('columnLarge9', 'center', 'content')}>
                <div {...classes('profileHeader')}>
                  <div {...classes('profilePicWrapper')}>
                    {!photo ? (
                      <div
                        {...classes(
                          'profilePicElement',
                          'profilePicPlaceholder'
                        )}
                      />
                    ) : (
                      <img
                        {...classes('profilePicElement')}
                        src={photo}
                        alt="Profile Picture"
                      />
                    )}
                    {this.props.isDesktop ? (
                      lastName ? (
                        <P>
                          {firstName} {lastName}
                        </P>
                      ) : (
                        <P>{email}</P>
                      )
                    ) : null}
                  </div>
                </div>
                <div className={css.verifyButtons}>
                  {this.props.isVerified && (
                    <GenericButton
                      onClick={this.onSync}
                      style="outline"
                      size="small"
                    >
                      {this.props.t('profile.sync')}
                    </GenericButton>
                  )}
                  {!this.props.isVerified && (
                    <GenericButton
                      onClick={this.onVerifyStart}
                      style="outline"
                      size="small"
                    >
                      {this.props.t('profile.verify')}
                    </GenericButton>
                  )}
                  {this.props.isKoskiEnabled && (
                    <GenericButton
                      onClick={koskiAuthorization}
                      style="outline"
                      size="small"
                    >
                      {this.props.t('profile.koskiPermission')}
                    </GenericButton>
                  )}
                  {this.props.trial.active && !this.props.trial.membershipActivated &&(
                    <GenericButton
                      onClick={this.openActivateMembershipPage}
                      style="outline"
                      size="small"
                    >
                      {this.props.t('profile.trial.openActivateMembershipPage')}
                    </GenericButton>
                  )}
                  {this.props.trial.active && (
                  
                      <GenericButton
                        style="outline"
                        size="small"
                        onClick={this.openTrialStatusPage}
                      >
                        {this.props.t('profile.trial.openTrialStatusPage')}
                      </GenericButton>

                    
                  )}                  
                </div>
                <ProfileSettingsForm {...this.props} />
              </div>
            </div>
          </div>
        }
        bottom={
          <div {...classes('columnLarge6', 'center', 'bottom')}>
            <SvgIcon css={{container: css.bigFooterLogo}} src={frankLogoRed} />
            <ErrorCandy
              show={this.props.submitFailed && !!this.props.error}
              onHide={this.props.initWithValues}
            >
              {this.props.error}
            </ErrorCandy>
            <ErrorCandy
              top
              white
              centerContent
              autoHide
              show={this.props.submitSucceeded}
              onHide={this.props.initWithValues}
            >
              {this.props.t('profile.updateSuccess')}
            </ErrorCandy>
          </div>
        }
      />
    )
  }
}
