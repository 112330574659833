import plainPlastic from '$assets/images/card/plainPlastic.png'
import plainPlasticPostgraduate from '$assets/images/card/plainPlasticPostgraduate.png'
import isic from '$assets/images/card/isic.png'

import {Feature} from '$src/ordercard/types'

const features: Array<Feature> = [
  {
    id: '2',
    name: 'plasticStudentCard',
    title: 'home.features.plainCard.title',
    mobileTitle: 'home.features.mobile.plainCard.title',
    body: 'home.features.plainCard.body',
    mobileBody: 'home.features.mobile.plainCard.body',
    price: 'home.features.plainCard.amount',
    details: [
      'home.features.plainCard.feature.discountsAndBenefits',
      'home.features.plainCard.feature.sameCard',
      'home.features.plainCard.feature.officialId',
      'home.features.plainCard.feature.noCardPayment',
      'home.features.plainCard.feature.addIsic'
    ],
    cta: 'home.features.plainCard.cta',
    mobileCta: 'home.features.mobile.plainCard.cta',
    link: '/plain-card',
    type: 'plastic',
    isDefaultFeature: true,
    path: 'plastic-student-card',
    graduateImage: {
      activeSrc: plainPlastic
    },
    postgraduateImage: {
      activeSrc: plainPlasticPostgraduate
    },
    external: false,
    priceId: 'cardProducts.graduate_card'
  },
  {
    id: '3',
    filter: 'desktop-only',
    name: 'digiIsicDesktop',
    title: 'home.features.digiIsicDesktop.title',
    mobileTitle: 'home.features.mobile.digiIsicMobile.title',
    body: 'home.features.digiIsicDesktop.body',
    mobileBody: 'home.features.digiIsicDesktop.body',
    price: 'home.features.digiIsicDesktop.amount',
    details: [
      'home.features.digiIsicDesktop.feature.130countries',
      'home.features.digiIsicDesktop.feature.discountsAndBenefits',
      'home.features.digiIsicDesktop.feature.trainAndBusTickets',
      'home.features.digiIsicDesktop.feature.planeTickets',
      'home.features.digiIsicDesktop.feature.alwaysWithYou',
      'home.features.digiIsicDesktop.feature.ecological'
    ],
    cta: 'home.features.digiIsicDesktop.cta',
    mobileCta: 'home.features.digiIsicDesktop.cta',
    link: '/digi-isic',
    type: 'digital',
    isDefaultFeature: false,
    path: 'digital-isic-card',
    image: {
      activeSrc: isic
    },
    external: false,
    priceId: 'cardProducts.digital_isic_card'
  },
  {
    id: '4',
    filter: 'mobile-only',
    name: 'digiIsicMobile',
    mobileTitle: 'home.features.mobile.digiIsicMobile.title',
    mobileBody: 'home.features.mobile.digiIsicMobile.body',
    price: 'home.features.digiIsicMobile.amount',
    details: [],
    cta: 'home.features.digiIsicMobile.cta',
    mobileCta: 'home.features.mobile.digiIsicMobile.cta',
    link: '/digi-isic',
    type: 'digital',
    isDefaultFeature: false,
    path: 'digital-isic-mobile',
    image: {
      activeSrc: isic
    },
    external: false,
    priceId: 'cardProducts.digital_isic_card'
  }
]

export default features
