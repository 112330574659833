import * as React from 'react'
import {compose} from 'redux'
import {WithRouterProps} from 'react-router-dom'

import GenericButton from '$common/components/buttons/GenericButton'
import GenericModal from './GenericModal'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import SvgIcon from '$common/components/icons/SvgIcon'
import align from '$common/grid/align.css'
import css from './VerifyStudentStatusPromptModal.css'
import frankLogoRedOutline from '$assets/icons/frankLogoRedOutline.svg'
import grid from '$common/grid/grid.css'
import {
  resolveCss,
  withRouter,
  withTranslate,
  WithTranslateProps
} from '$common/utils'
import {getHomePage} from '$src/common/pgWebSeparatorUtils'

type Props = WithRouterProps &
  WithTranslateProps & {
    onHide: Function
  }

const VerifyStudentStatusPromptModal = (props: Props) => {
  const classes = resolveCss(css, grid, align)
  return (
    <GenericModal
      onHide={() => props.history.replace(getHomePage())}
      middle={
        <div {...classes('columnLarge5', 'center')}>
          <div {...classes('column', 'center')}>
            <SvgIcon
              key="logo"
              css={{container: css.icon}}
              src={frankLogoRedOutline}
              sizing="none"
            />
          </div>

          <H1 css={{container: align.centerText}}>
            {props.t('profile.verification.promptStrongAuth.title')}
          </H1>
          <P css={{container: [css.message, align.centerText]}}>
            {props.t('profile.verification.promptStrongAuth.body')}
          </P>

          <div {...classes('column', 'center')}>
            <GenericButton
              css={{container: css.button}}
              style="bold"
              onClick={() => props.history.replace('/profile/verify/start')}
            >
              {props.t('profile.verification.promptStrongAuth.cta')}
            </GenericButton>
          </div>
        </div>
      }
    />
  )
}

export default compose(
  withRouter,
  withTranslate()
)(VerifyStudentStatusPromptModal)
