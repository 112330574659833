import FrankLogoInSmallBlack from '$common/components/logos/FrankLogoInSmallBlack'
import * as React from 'react'
import {compose} from 'redux'
import {resolveCss} from '$common/utils'
import css from './index.css'
import DesktopVerticalSplitLayout from '$common/components/layouts/DesktopVerticalSplitLayout'

type Props = {
  css?: any
  children: React.Node
}

const OnboardingLayout = (props: Props) => {
  const classes = resolveCss(css, props.css)

  return (
    <DesktopVerticalSplitLayout
      css={{
        left: css.left
      }}
      left={
        <div {...classes('logo')}>
          <FrankLogoInSmallBlack />
        </div>
      }
      right={props.children}
    />
  )
}

export default compose((a) => a)(OnboardingLayout)
