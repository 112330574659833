import * as React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'

class WithWindowDims extends React.Component {
  static defaultProps
  state

  constructor(props) {
    super(props)
    this.state = this.getDimsFromWindow()
  }

  UNSAFE_componentWillMount() {
    this.listenToChangeEvents()
  }

  componentWillUnmount() {
    this.stopListeningToChangeEvents()
  }

  listenToChangeEvents() {
    window.addEventListener('resize', this.reactToWindowResize)
  }

  stopListeningToChangeEvents() {
    window.removeEventListener('resize', this.reactToWindowResize)
  }

  reactToWindowResize = () => {
    this.setState(this.getDimsFromWindow())
  }

  getDimsFromWindow() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  render() {
    const {component: Comp, ...rest} = this.props

    return <Comp {...rest} windowDims={this.state} />
  }
}

export default function withWindowDims() {
  return function connectTiWindowDims(component) {
    const finalComponent = (props) => {
      return <WithWindowDims component={component} {...props} />
    }

    finalComponent.displayName = `withWindowDims(${component.displayName ||
      component.name ||
      'Component'})`

    hoistNonReactStatics(finalComponent, component)

    return finalComponent
  }
}
