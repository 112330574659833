import {connect} from 'react-redux'
import {compose} from 'redux'

import {withTranslate, withRouter} from '$common/utils'
import NavBar from './NavBar'
import {getOffer} from '$src/offers/selectors'

const mapStateToProps = (state, props) => {
  let offer
  const currentPathname = props.location.pathname
  if (
    currentPathname.indexOf('/voucher/') >= 0 ||
    currentPathname.indexOf('/offer/') >= 0
  ) {
    offer = getOffer(
      state,
      currentPathname.substring(currentPathname.lastIndexOf('/') + 1)
    )
  }

  return {
    offers: state.offers,
    profile: state.profile,
    offer
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps),
  withTranslate()
)(NavBar)
