import React from 'react'
import {compose} from 'redux'
import {isEmpty} from 'lodash'

import {
  asForm,
  connect,
  resolveCss,
  withRouter,
  withRunSaga,
  withTranslate
} from '$common/utils'
import Checkout from '$common/components/Payment/Checkout'
import ConversationLayout from '$common/components/layouts/ConversationLayout'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import LoadingSpinnerModal from '$src/common/components/LoadingSpinnerModal'
import TextInput from '$common/components/forms/TextInput'
import GenericButton from '$common/components/buttons/GenericButton'
import ErrorCandy from '$common/components/candyBars/ErrorCandy'
import HeaderTopFill from '$src/common/components/Header/HeaderTopFill'
import {validatePayment} from './validators'
import {DIGI_ISIC_ORDER, getOrderProduct} from '$src/ordercard/selectors'

import css from '$src/subscriptions/components/styles.css'
import conversationLayoutCss from '$src/common/components/layouts/ConversationLayout.css'
import customCss from './Payment.css'

class Payment extends React.Component {
  render() {
    const classes = resolveCss(css, customCss, conversationLayoutCss)

    const conversationLayoutProps = {
      content: (
        <div {...classes('content', 'stretch')}>
          {this.props.submitting && <LoadingSpinnerModal style="light" />}

          <H1 css={{container: css.heading}}>
            {this.props.t(
              'subscriptions.digiIsicPurchase.payment.title',
              this.props.pricing
            )}
          </H1>
          <P css={{container: css.paragraph}}>
            {this.props.t(
              'subscriptions.digiIsicPurchase.payment.body',
              this.props.pricing
            )}
          </P>
          <P style="subtle" css={{container: css.smallPrint}}>
            {this.props.t(
              'subscriptions.digiIsicPurchase.payment.smallPrint',
              this.props.pricing
            )}
          </P>

          <TextInput
            name="cardHolderName"
            placeholder={this.props.t(
              'subscriptions.digiIsicPurchase.payment.cardHolderNamePlaceholder'
            )}
            style="box"
          />

          <Checkout
            {...this.props}
            key="payment"
            acceptedCardsLabel={this.props.t(
              'subscriptions.digiIsicPurchase.payment.cardInfo'
            )}
            css={{container: customCss.checkoutForm}}
            submitButton={
              <div {...classes('item', 'button-container')}>
                <GenericButton
                  type="submit"
                  style="bold"
                  css={{container: css.button}}
                  loading={this.props.submitting}
                  disabled={this.props.invalid || this.props.submitting}
                  isExpanding={true}
                >
                  {this.props.t('subscriptions.digiIsicPurchase.payment.cta')}
                </GenericButton>
              </div>
            }
          />

          <ErrorCandy
            key="error"
            show={!isEmpty(this.props.submitErrors)}
            onHide={this.props.initWithValues}
          >
            {this.props.submitErrors && this.props.submitErrors.error}
          </ErrorCandy>
        </div>
      ),
      dialogContainerStyle: 'black'
    }

    return (
      <div>
        <HeaderTopFill
          background={'linear-gradient(#036F89,#000000)'}
          position={'fixed'}
        />
        <ConversationLayout {...conversationLayoutProps} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    pricing: state.pricing,
    productSku: getOrderProduct(state, DIGI_ISIC_ORDER, true),
    onSuccessRedirectUrl: '/digi-isic/order-completed'
  }
}

export default compose(
  connect(mapStateToProps),
  asForm('digiIsicWizard', {
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: validatePayment
  }),
  withRouter,
  withRunSaga(),
  withTranslate()
)(Payment)
