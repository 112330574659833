import {Voucher} from '$src/offers/types'

export type Params = {
  languageCode: 'en' | 'sv' | 'fi'
  key: string
}

export default function findVoucher(
  {get}: any,
  {languageCode, key}: Params
): Promise<{data?: Voucher}> {
  return get(
    `/vouchers/${key}/?languageCode=${encodeURIComponent(languageCode)}`,
    null,
    {numberOfRetries: 4}
  )
}
