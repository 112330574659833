import {StoreState, Selector} from '$src/types'
import {getPreferredLang} from '$src/i18n/selectors'

import {
  getEntitiesForPage,
  getTotalPageCount,
  getOddEntitiesCount,
  getEntity,
  getTotalEntityCount,
  getEntityIDsForPage
} from '$src/pagination/selectors'

import {Offer} from '$src/offers/types'

type Params = {page: number; size: number}

const entityKey = 'offers'

export const getSearch: Selector<string> = (state) => {
  return state.offers.search
}

export const getTotalOfferCount: Selector<number, Params> = (state, params) => {
  return getTotalEntityCount(state, entityKey, {
    ...params,
    languageCode: getPreferredLang(state)
  })
}

export const getOffersByParams: Selector<any, Params> = (state, params) => {
  return getEntitiesForPage(state, entityKey, {
    ...params,
    languageCode: getPreferredLang(state)
  })
}

export const getOfferIDsByParams: Selector<any, Params> = (state, params) => {
  return getEntityIDsForPage(state, entityKey, {
    ...params,
    languageCode: getPreferredLang(state)
  })
}

export const getTotalOfferPageCount: Selector<number, Params> = (
  state,
  params
) => {
  return getTotalPageCount(state, entityKey, {
    ...params,
    languageCode: getPreferredLang(state)
  })
}

export const getOddOfferAmount: Selector<number, Params> = (
  state: StoreState,
  params: Params
) => {
  return getOddEntitiesCount(state, entityKey, {
    ...params,
    languageCode: getPreferredLang(state)
  })
}

export const getOffer: Selector<object, string> = (state, id) => {
  return getEntity(state, entityKey, {
    id,
    languageCode: getPreferredLang(state)
  })
}

/*
  Get Offers for carousel
*/
export const getCarouselOffers: Selector<[Offer]> = (state) => {
  const lang = getPreferredLang(state)
  const offersByLanguage = state.offers[lang].byKey
  const response = []

  for (const offer in offersByLanguage) {
    if (
      offersByLanguage[offer].hasOwnProperty('displayInCarousel') &&
      offersByLanguage[offer].displayInCarousel === true &&
      offer.includes('offer_')
    ) {
      response.push(offersByLanguage[offer])
    }
  }

  return response
}

export const getIsFavourite = (state, key) => {
  if (state.favourites[key]?.favourite.isActive) {
    return true
  }
  return false
}

export const getFavourites = (state) => {
  return state.favourites
}

export const getActiveFavourites = (state) => {
  const activeFavourites = []
  for (const key in state.favourites) {
    state.favourites[key].favourite.isActive &&
      activeFavourites.push(state.favourites[key])
  }
  return activeFavourites
}
