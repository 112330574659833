import * as React from 'react'
import {Link} from 'react-router-dom'

import {resetState} from '$common/sagas'
import LanguageSelector from '$src/i18n/components/LanguageSelector'
import GenericButton from '$common/components/buttons/GenericButton'
import SvgIcon from '$common/components/icons/SvgIcon'
import logoSrc from '$assets/icons/frankLogoSolidWhite.svg'
import TopMiddleBottomLayout from '$common/components/layouts/TopMiddleBottomLayout'
import Header from '$common/components/Header/Header-container'
import {
  resolveCss,
  urlWithRedirect,
  WithTranslateProps,
  WithRouterProps,
  WithRunSagaProps
} from '$common/utils'
import socialInstagramIcon from '$assets/icons/icon-social-black_instagram.svg'
import socialFacebookIcon from '$assets/icons/icon-social-black_facebook.svg'
import socialTiktokIcon from '$assets/icons/icon-social-black_tiktok.svg'
import {Profile} from '$src/profile/types'
import css from './BurgerMenu.css'
import setNotification from '$src/notification/sagas/setNotification'
import {logEvent} from '$src/common/utils/logUtils'
import LogoutMenu from '$src/common/components/Header/LogoutMenu'

type Props = WithTranslateProps &
  WithRouterProps &
  WithRunSagaProps & {
    onClose?: Function
    isLoggedIn: boolean
    profile: Profile
    isUserActive: boolean
  }

class BurgerMenu extends React.Component<Props> {
  state = {showLogoutMenu: false}

  login = () => {
    this.props.runSaga(resetState)
    this.props.history.push(urlWithRedirect('/', this.props.location))
  }

  logout = (globalLogout = false) => {
    this.props.history.replace(`/logout?global=${globalLogout}`)
  }

  showMenuModal = () => this.setState({showLogoutMenu: true})
  hideMenuModal = () => this.setState({showLogoutMenu: false})

  render() {
    const classes = resolveCss(css)

    const isStudentCard = this.props.location.pathname === '/studentcard'

    // Possibly incomplete way of checking for 2nd degree student, see comment below
    const type =
      this.props.profile.organization && this.props.profile.organization.type

    const isSecondGradeStudent =
      type === 'vocational-school' || type === 'high-school'

    // This is the is the same check for 2nd degree student as in the Header Component
    // It checks the student type
    const {isSecondGradeStudentByStudentType, isAlumn} = this.props

    return (
      <TopMiddleBottomLayout
        adaptiveLayout={true}
        noPadding={true}
        css={{
          middle: css.middle
        }}
        topStyle="sticky"
        top={<Header />}
        middle={
          <div
            {...classes(
              'menuWrapper',
              window.cordova ? 'cordova' : '',
              isStudentCard ? 'studentcard' : '',
              this.props.isLoggedIn ? '' : 'notLogged'
            )}
          >
            {window.cordova ? (
              <div {...classes('header')}>
                <SvgIcon
                  css={{container: css.iconLogo}}
                  sizing="none"
                  src={logoSrc}
                />
                {!this.props.isLoggedIn && (
                  <div {...classes('rightContent')}>
                    <LanguageSelector
                      compact
                      css={{container: css.languageSelector}}
                    />
                  </div>
                )}
              </div>
            ) : null}

            <div {...classes('welcomePhrase')}>
              {this.props.t('common.welcome')}
            </div>
            <div {...classes('buttonsContainer')}>
              {!this.props.isLoggedIn && (
                <GenericButton
                  css={{container: css.menuButton}}
                  style="outline"
                  onClick={this.login}
                >
                  {this.props.t('common.logIn')}
                </GenericButton>
              )}
              {window.cordova ? null : (
                <span>
                  <Link to="/home">
                    <GenericButton
                      css={{container: css.menuButton}}
                      style="outline"
                    >
                      {this.props.t('common.home')}
                    </GenericButton>
                  </Link>
                  <Link to="/offers" onClick={() => window.scrollTo(0, 0)}>
                    <GenericButton
                      css={{container: css.menuButton}}
                      style="outline"
                    >
                      {this.props.t('common.discounts')}
                    </GenericButton>
                  </Link>
                </span>
              )}

              {!isSecondGradeStudent &&
                !isSecondGradeStudentByStudentType &&
                !isAlumn && (
                  <Link to="/ordercard">
                    <GenericButton
                      css={{container: css.menuButton}}
                      style="outline"
                      onClick={() => {
                        logEvent('Hamburger: Order Card')
                      }}
                    >
                      {this.props.t('common.ordercard')}
                    </GenericButton>
                  </Link>
                )}
              {window.cordova && this.props.isLoggedIn && (
                <>
                  <Link to="/profile/settings">
                    <GenericButton
                      css={{container: css.menuButton}}
                      style="outline"
                    >
                      {this.props.t('common.profile')}
                    </GenericButton>
                  </Link>
                  <GenericButton
                    onClick={this.showMenuModal}
                    css={{container: css.menuButton}}
                    style="outline"
                  >
                    {this.props.t('common.logout')}
                  </GenericButton>
                  {this.state.showLogoutMenu && (
                    <LogoutMenu
                      onLogout={this.logout}
                      onClose={this.hideMenuModal}
                    />
                  )}
                </>
              )}
            </div>
            <div {...classes('linksContainer')}>
              <a
                onClick={() =>
                  logEvent('Hamburger: Open contact link', null, true)
                }
                href={this.props.t('common.contactLink')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.props.t('common.contact')}
              </a>
              <a
                onClick={() => logEvent('Hamburger: Open FAQ link', null, true)}
                href={this.props.t('common.faqLink')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.props.t('common.faq')}
              </a>
              <a
                onClick={() =>
                  logEvent('Hamburger: Open terms of use link', null, true)
                }
                href={this.props.t('common.termsofuseLink')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.props.t('common.termsofuse')}
              </a>
            </div>
            <div {...classes('version')}>
              {this.props.t('common.version')} {process.env.APP_VERSION}
            </div>
            <div {...classes('socialIconsContainer')}>
              <a
                href="https://www.instagram.com/frankstudents/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvgIcon
                  css={{container: [css.icon, css.socialIcon]}}
                  sizing="none"
                  src={socialInstagramIcon}
                />
              </a>
              <a
                href="https://www.facebook.com/frankstudents/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvgIcon
                  css={{container: [css.icon, css.socialIcon]}}
                  sizing="none"
                  src={socialFacebookIcon}
                />
              </a>
              <a
                href="https://www.tiktok.com/@frankstudents"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvgIcon
                  css={{container: [css.icon, css.socialIcon]}}
                  sizing="none"
                  src={socialTiktokIcon}
                />
              </a>
            </div>
          </div>
        }
      />
    )
  }
}

export default BurgerMenu
