import * as React from 'react'
import {compose} from 'redux'
import {Elements, StripeProvider} from 'react-stripe-elements'

import {connect} from '$common/utils'
import Form from '$common/components/Payment/Form'
import {getPreferredLang} from '$src/i18n/selectors'
import {StoreState} from '$src/types'

type Props = {
  preferredLang: string
  acceptedCards?: Array<string>
  acceptedCardsLabel: string
  css?: any
  onSuccessRedirectUrl: string
}

type State = {
  stripe?: object
}

class Checkout extends React.Component<Props, State> {
  state = {
    stripe: null
  }

  componentDidMount() {
    // Stripe js is downloaded asynchronously. Check / wait until
    // library is loaded.
    if (window.Stripe) {
      this.setState({stripe: window.Stripe(process.env.STRIPE_API_KEY)})
    } else {
      document
        .querySelector('#stripe-js')
        .addEventListener('load', () =>
          this.setState({stripe: window.Stripe(process.env.STRIPE_API_KEY)})
        )
    }
  }

  render() {
    return (
      // Ensure children are updated by providing a dynamic key
      <StripeProvider
        stripe={this.state.stripe}
        key={`stripeProvider-${this.props.preferredLang}`}
      >
        <Elements locale={this.props.preferredLang}>
          <Form {...this.props} />
        </Elements>
      </StripeProvider>
    )
  }
}

export default compose(
  connect((state: StoreState) => ({preferredLang: getPreferredLang(state)}))
)(Checkout)
