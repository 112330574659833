import {StorageHelper} from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import {getStoredState, createPersistoid} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {setLoggedInToCognito} from '$src/auth/actions'
import rootReducer from './rootReducer'
import Core from './Core'
import {bootstrapAction} from '$common/actions'
import {getProfile as profileSelector} from '$src/profile/selectors'

export default async function configureCore() {
  const persistenceConfig = {
    key: 'root',
    keyPrefix: process.env.REDUX_PERSIST_KEY_PREFIX,
    storage,
    whitelist: [
      'auth',
      'locale',
      'notification',
      'organizations',
      'profile',
      'subscriptions',
      'schools'
    ]
  }

  const state = await getStoredState(persistenceConfig)
  const persistoid = createPersistoid(persistenceConfig)

  const reduxPersistV4State = persistenceConfig.whitelist.reduce(
    (migratedState, key) => {
      const oldValue = localStorage.getItem(
        `${process.env.REDUX_PERSIST_KEY_PREFIX}${key}`
      )
      if (!oldValue) {
        return migratedState
      }
      migratedState[key] = JSON.parse(oldValue || '{}')
      localStorage.removeItem(`${process.env.REDUX_PERSIST_KEY_PREFIX}${key}`)
      return migratedState
    },
    {}
  )

  const core = new Core({
    rootReducer,
    initialState: {...state, ...reduxPersistV4State}
  })

  core.reduxStore.subscribe(() => {
    persistoid.update(core.reduxStore.getState())
  })

  if (process.env.NODE_ENV === 'development') {
    /**
     * In development mode, the `$s` is available as a container for a bunch of helpful
     * development functions. All the actions in the common namespace (such as setStateAction)
     * are available in `$s.shortcuts`. `$s.runSaga()` is also available to run single orphaned
     * sagas.
     */
    window.$s = core.reduxStore
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const commonActions = require('$common/actions')
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const mapValues = require('lodash/mapValues')
    const shortcuts = mapValues(commonActions, (actionCreator) => (...args) =>
      core.reduxStore.dispatch(actionCreator(...args))
    )

    window.$s.shortcuts = shortcuts
    window.$s.runSaga = core.sagaMiddleware.run
  }

  core.reduxStore.sagaMiddleware = core.sagaMiddleware

  core.reduxStore.dispatch(bootstrapAction())

  Auth.configure({
    Auth: {
      region: process.env.COGNITO_REGION,
      userPoolId: process.env.COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.COGNITO_CLIENT_ID,
      mandatorySignIn: true
    }
  })

  await updateReduxStoreWithAmplifyAuthInfo(core)

  return core
}

async function updateReduxStoreWithAmplifyAuthInfo(core) {
  try {
    await Auth.currentSession() // Throws if not logged in with amplify
    core.reduxStore.dispatch(setLoggedInToCognito(true))
  } catch (err) {
    // Not logged in with amplify.
    await tryAmplifyLoginFromOldReduxStore(core)
  }
}

// Check if user has logged in using backend auth.
// If so, fill the information to localstorage for amplify.
//
// NOTE: This behavior is a temporary hack to allow users to take amplify login
// into use without logging out first. This logic can be removed when clients <= 3.4.4
// are note anymore in use.
async function tryAmplifyLoginFromOldReduxStore(core) {
  const reduxState = core.reduxStore.getState()
  const profile = profileSelector(reduxState)
  const tokens = reduxState.auth.tokensState // This is available only when users are moving from old auth system to the amplify auth system
  if (profile.userId && tokens.hasTokens) {
    const userId = profile.userId
    const {idToken, refreshToken, accessToken} = tokens
    const userPoolClientId = process.env.COGNITO_CLIENT_ID
    const frankStorage = new StorageHelper().getStorage()
    frankStorage.setItem(
      `CognitoIdentityServiceProvider.${userPoolClientId}.${userId}.idToken`,
      idToken
    )
    frankStorage.setItem(
      `CognitoIdentityServiceProvider.${userPoolClientId}.${userId}.accessToken`,
      accessToken
    )
    frankStorage.setItem(
      `CognitoIdentityServiceProvider.${userPoolClientId}.${userId}.refreshToken`,
      refreshToken
    )
    frankStorage.setItem(
      `CognitoIdentityServiceProvider.${userPoolClientId}.LastAuthUser`,
      userId
    )
    await Auth.currentUserInfo()
    core.reduxStore.dispatch(setLoggedInToCognito(true))
  }
}
