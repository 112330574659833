import * as React from 'react'
import {compose} from 'redux'

import {resetState} from '$common/sagas'
import GenericButton from '$common/components/buttons/GenericButton'
import TopMiddleBottomLayout from '$common/components/layouts/TopMiddleBottomLayout'
import SvgIcon from '$common/components/icons/SvgIcon'
import frankLogoSolidWhite from '$assets/icons/frankLogoSolidWhite.svg'
import P from '$common/components/P'
import H1 from '$common/components/headers/H1'
import gridCss from '$common/grid/grid.css'
import css from './LoginPromptModal.css'
import {
  withRouter,
  withRunSaga,
  withTranslate,
  WithTranslateProps,
  WithRunSagaProps,
  WithRouterProps
} from '$common/utils'

type Props = WithRouterProps & WithTranslateProps & WithRunSagaProps

const LoginPromptModal = (props: Props) => {
  const goToLogin = () => {
    props.runSaga(resetState)
    props.history.push(`/login${props.location.search}`)
  }

  return (
    <TopMiddleBottomLayout
      css={{middle: css.middle, container: [css.container, gridCss.container]}}
      topLeft={
        <SvgIcon
          onClick={props.history.goBack}
          css={{container: css.closeIcon}}
          src={frankLogoSolidWhite}
          sizing="em"
        />
      }
      middle={[
        <H1 css={{container: css.heading}} key="header">
          {props.t('common.loginRequired.heading')}
        </H1>,
        <P css={{container: css.paragraph}} key="paragraph">
          {props.t('common.loginRequired.body')}
        </P>,
        <div key="buttons" className={css.buttonsContainer}>
          <GenericButton onClick={goToLogin} style="bold">
            {props.t('common.logIn')}
          </GenericButton>
          <GenericButton onClick={props.history.goBack} style="outline">
            {props.t('common.back')}
          </GenericButton>
        </div>
      ]}
    />
  )
}

export default compose(
  withRouter,
  withRunSaga(),
  withTranslate()
)(LoginPromptModal)
