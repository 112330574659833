import React, {Component} from 'react'
import {compose} from 'redux'
import isEmpty from 'lodash/isEmpty'

import Checkout from '$common/components/Payment/Checkout'
import ConversationLayout from '$common/components/layouts/ConversationLayout'
import ErrorCandy from '$common/components/candyBars/ErrorCandy'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import LoadingSpinnerModal from '$src/common/components/LoadingSpinnerModal'
import TextInput from '$common/components/forms/TextInput'
import conversationLayoutCss from '$src/common/components/layouts/ConversationLayout.css'
import css from '$src/subscriptions/components/styles.css'
import customCss from './index.css'
import {validatePayment} from '../validators'
import {
  resolveCss,
  withRouter,
  withRunSaga,
  withTranslate,
  asForm,
  connect
} from '$common/utils'
import HeaderTopFill from '$src/common/components/Header/HeaderTopFill'
import {getOrderProduct, PLASTIC_CARD_ORDER} from '$src/ordercard/selectors'

class Payment extends Component {
  componentDidMount() {
    if (!this.props.fieldValues.streetAddress) {
      this.props.history.goBack()
    }
  }

  render() {
    let titleI18nKey,
      bodyI18nKey,
      smallPrintI18nKey,
      cardHolderNamePlaceholder18nKey,
      ctaI18nKey
    if (this.props.match.params.nationality === 'national') {
      titleI18nKey = 'subscriptions.plainCardPurchase.finnish.payment.title'
      bodyI18nKey = 'subscriptions.plainCardPurchase.finnish.payment.body'
      smallPrintI18nKey =
        'subscriptions.plainCardPurchase.finnish.payment.purchaseInformation'
      cardHolderNamePlaceholder18nKey =
        'subscriptions.plainCardPurchase.finnish.payment.cardHolderNamePlaceholder'
      ctaI18nKey = 'subscriptions.plainCardPurchase.finnish.payment.cta'
    } else {
      titleI18nKey =
        'subscriptions.plainCardPurchase.international.payment.title'
      bodyI18nKey = 'subscriptions.plainCardPurchase.international.payment.body'
      smallPrintI18nKey =
        'subscriptions.plainCardPurchase.international.payment.purchaselInformation'
      cardHolderNamePlaceholder18nKey =
        'subscriptions.plainCardPurchase.international.payment.cardHolderNamePlaceholder'
      ctaI18nKey = 'subscriptions.plainCardPurchase.international.payment.cta'
    }

    const classes = resolveCss(css, customCss, conversationLayoutCss)

    const conversationLayoutProps = {
      content: (
        <div {...classes('content', 'stretch')}>
          {this.props.submitting && <LoadingSpinnerModal style="light" />}

          <H1 css={{container: css.heading}}>
            {this.props.t(titleI18nKey, this.props.pricing)}
          </H1>
          <P css={{container: css.paragraph}}>
            {this.props.t(bodyI18nKey, this.props.pricing)}
          </P>
          <P style="subtle" css={{container: css.smallPrint}}>
            {this.props.t(smallPrintI18nKey, this.props.pricing)}
          </P>

          <TextInput
            name="cardHolderName"
            placeholder={this.props.t(cardHolderNamePlaceholder18nKey)}
            style="box"
          />

          <Checkout
            {...this.props}
            key="payment"
            acceptedCardsLabel={this.props.t(
              'subscriptions.plainCardPurchase.international.payment.cardInfo'
            )}
            css={{container: customCss.checkoutForm}}
            submitButton={
              <div {...classes('item', 'button-container')}>
                <GenericButton
                  type="submit"
                  style="bold"
                  css={{container: css.button}}
                  loading={this.props.submitting}
                  disabled={this.props.invalid || this.props.submitting}
                  isExpanding={true}
                >
                  {this.props.t(ctaI18nKey)}
                </GenericButton>
              </div>
            }
          />

          <ErrorCandy
            key="error"
            show={!isEmpty(this.props.submitErrors)}
            onHide={this.props.initWithValues}
          >
            {this.props.submitErrors && this.props.submitErrors.error}
          </ErrorCandy>
        </div>
      ),
      dialogContainerStyle: 'blue'
    }

    return (
      <div>
        <HeaderTopFill
          background={'linear-gradient(#036F89,#04A3CA)'}
          position={'fixed'}
        />
        <ConversationLayout {...conversationLayoutProps} />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const nationality = props.match.params.nationality
  const isIsic = nationality === 'international'

  return {
    pricing: state.pricing,
    productSku: getOrderProduct(state, PLASTIC_CARD_ORDER, isIsic),
    onSuccessRedirectUrl: `/plain-card/${nationality}/order-already-in-progress`
  }
}

export default compose(
  connect(mapStateToProps),
  asForm('plainCardWizard', {
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: validatePayment
  }),
  withRouter,
  withRunSaga(),
  withTranslate()
)(Payment)
