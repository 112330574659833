import React, {Component} from 'react'
import Cropper from 'react-cropper'
import {SubmissionError} from 'redux-form'
import postProfilePhoto from '$src/profile/sagas/postProfilePhoto'
import amplitude from '$src/analytics/amplitude'
import {createApiErrorMessage} from '$common/utils'

import {WithRouterProps} from 'react-router-dom'
import {WithRunSagaProps, WithTranslateProps} from '$common/utils'

type Props = WithRouterProps &
  WithRunSagaProps &
  WithTranslateProps & {
    photo?: string
  }

export default function webProfilePhotoPageHOC(WrappedComponent) {
  return class WebProfilePhotoPage extends Component<Props> {
    props: Props
    state = {loadedPhoto: undefined, croppedPhoto: undefined}

    static cropperOverlayStyles = {
      height: '65vh',
      width: '65vw'
    }

    cropperRef = undefined

    onImageDrop = (files, rejectedFiles) => {
      if (rejectedFiles && rejectedFiles.length) {
        alert(this.props.t('profile.profilePhotoPage.wrongFileType'))
        return
      }
      const reader = new FileReader()
      reader.readAsDataURL(files[0])
      reader.onload = () => {
        const photo = reader.result
        this.setState({loadedPhoto: photo})
      }
      reader.onerror = function(error) {
        alert(error)
      }
    }

    uploadCroppedImage = async () => {
      this.props.toggleChangingPhoto(true)
      try {
        const croppedPhoto =
          this.cropperRef &&
          this.cropperRef.getCroppedCanvas().toDataURL('image/jpeg')
        await this.props.runSaga(postProfilePhoto, croppedPhoto)
        this.setState({croppedPhoto, loadedPhoto: undefined})
        const isFirst = !this.props.photo
        amplitude.logEvent('uploadProfilePhoto', {isFirst})
        this.props.history.push('/profile/settings')
      } catch (e) {
        const errMsg = createApiErrorMessage(
          e,
          'error.profile.profilePhotoPage'
        )
        throw new SubmissionError({_error: this.props.t(errMsg)})
      } finally {
        this.props.toggleChangingPhoto(false)
      }
    }

    updatePhotos = (loadedPhoto) => {
      this.setState({loadedPhoto})
    }

    render() {
      const props = {
        ...this.props,
        loadedPhoto: this.state.loadedPhoto,
        croppedPhoto: this.state.croppedPhoto,
        uploadCroppedImage: this.uploadCroppedImage,
        onImageDrop: this.onImageDrop,
        cropper: (
          <Cropper
            ref={(cropper) => {
              this.cropperRef = cropper
            }}
            src={this.state.loadedPhoto}
            style={WebProfilePhotoPage.cropperOverlayStyles}
            viewMode={1}
            scaleX={1}
            scaleY={1}
            minContainerWidth={150}
            zoomable={false}
            aspectRatio={1}
            guides={false}
          />
        ),
        cropperRef: this.cropperRef,
        updatePhotos: this.updatePhotos
      }

      return <WrappedComponent {...props} />
    }
  }
}
