import {opintopolku} from '../../api/endpoints/auth/opintopolku'
import {setUserProfile} from '../../common/utils'
import callEndpoint from '$src/api/sagas/callEndpoint'
import {getAppboy} from '$common/pgWebSeparatorUtils'

export default function* verifyOpintopolku({url, schoolId}) {
  const splitUrl = url.trim().split('/')
  const secret = splitUrl[splitUrl.length - 1]
  const response = yield callEndpoint(opintopolku, {secret, schoolId})

  if (response.id) {
    const appboy = getAppboy()
    appboy.changeUser(response.id)
    if (!window.cordova) {
      appboy.openSession()
    }
    appboy.logCustomEvent('Registered')
  }

  yield setUserProfile(response)
}
