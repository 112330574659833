/* eslint-disable react/jsx-key */

import * as React from 'react'
import {Route} from 'react-router-dom'
import ForgotPasswordPage from '$src/onboarding/components/ForgotPasswordPage'
import ResetPasswordPage from '$src/onboarding/components/ResetPasswordPage'
import LoginPage from '$src/onboarding/components/LoginPage'
import SignUpPage from '$src/onboarding/components/SignUpPage/SignUpPage-container'
import userUnauthenticated from '$src/auth/components/userUnauthenticated'

export default [
  <Route
    path="/forgot-password"
    component={userUnauthenticated(ForgotPasswordPage)}
  />,
  <Route
    path="/reset-password"
    component={userUnauthenticated(ResetPasswordPage)}
  />,
  <Route path="/login" component={userUnauthenticated(LoginPage)} />,
  <Route path="/signup" component={userUnauthenticated(SignUpPage)} />
]
