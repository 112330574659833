import {Offer} from '$src/offers/types'

export type Params = {
  languageCode: 'en' | 'sv' | 'fi'
  key: string
}

export default function findOffer(
  {get},
  {languageCode, key}: Params
): Promise<{data?: Offer}> {
  return get(
    `/offers/${key}/`,
    {languageCode: encodeURIComponent(languageCode)},
    {numberOfRetries: 4}
  )
}
