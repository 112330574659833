import * as React from 'react'
import {compose} from 'redux'
import {Link} from 'react-router-dom'
import BackButton from '$common/components/buttons/BackButton'
import GenericButton from '$common/components/buttons/GenericButton'
import Layout from '$common/components/layouts/TopMiddleBottomLayout'
import P from '$common/components/P'
import {resolveCss, withTranslate, withRunSaga} from '$common/utils'
import css from './InitiateAccountDeletion.css'
import grid from '$common/grid/grid.css'
import align from '$common/grid/align.css'
import postAccountDeletion from '$src/profile/sagas/postAccountDeletion'

const InitiateAccountDeletion = (props: any) => {
  const classes = resolveCss(css, grid, align)
  const {t} = props

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onBackButtonClick = (e: any) => {
    e.preventDefault()
    props.history.push('/profile/settings')
  }

  const sendEmailAboutNextSteps = async () => {
    // make api call here to send email from backend
    await props.runSaga(postAccountDeletion, {
      subject: t('profile.accountDeletion.confirmation_email_subject'),
      message: t('profile.accountDeletion.confirmation_email_body')
    })
    alert(t('profile.accountDeletion.alert'))
  }

  return (
    <Layout
      adaptiveLayout={{
        stackMiddle: true
      }}
      css={{
        container: grid.container
      }}
      top={<div></div>}
      middle={
        <div {...classes('columnLarge5', 'center')}>
          <BackButton onClick={onBackButtonClick} />
          <div className={css.header}>
            {t('profile.accountDeletion.alumnReminderTitle')}
          </div>
          <P>{t('profile.accountDeletion.alumnReminderBody')}</P>
          <div className={css.header}>{t('profile.accountDeletion.title')}</div>
          <P>{t('profile.accountDeletion.body')}</P>
          <P style="subtle" css={{container: css.p}}>
            <a
              href={t('profile.accountDeletion.gdprLink')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('profile.accountDeletion.gdprLinkLabel')}
            </a>
          </P>
        </div>
      }
      bottom={
        <div {...classes('columnLarge5', 'center', 'marginBottom')}>
          <Link to={'/home'}>
            <GenericButton style="outline" size="small" hasTopMargin={true}>
              {t('profile.accountDeletion.cancelButton')}
            </GenericButton>
          </Link>
          <GenericButton
            onClick={sendEmailAboutNextSteps}
            style="outline"
            css={{container: css.menuButton}}
            size="small"
            hasTopMargin={true}
          >
            {t('profile.accountDeletion.confirmButton')}
          </GenericButton>
        </div>
      }
    />
  )
}

export default compose(withTranslate(), withRunSaga())(InitiateAccountDeletion)
