import React from 'react'
import moment from 'moment'

import ConversationLayout from '$common/components/layouts/ConversationLayout'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import Line from '$common/components/Divider'
import P from '$common/components/P'
import css from '$src/subscriptions/components/styles.css'
import {Profile} from '$src/profile/types'
import {
  WithRunSagaProps,
  WithRouterProps,
  WithTranslateProps
} from '$common/utils'
import HeaderTopFill from '$src/common/components/Header/HeaderTopFill'

type Props = WithRouterProps &
  WithTranslateProps &
  WithRunSagaProps & {
    profile: Profile
    fetching: boolean
    dialogContainerStyle?: string
    startLink?: string
    from?: string
  }

export const HeaderTopFillStyles = {
  blue: 'linear-gradient(#036F89,#04A3CA)',
  black: 'linear-gradient(#036F89,#000000)',
  red: 'linear-gradient(#F15B2A,#DB0C52)'
}

class ProfileSummary extends React.Component<Props> {
  render() {
    const {t, profile} = this.props
    const dialogContainerStyle =
      this.props && this.props.dialogContainerStyle
        ? this.props.dialogContainerStyle
        : 'red'
    const buttonStyles = {
      container: [css.button, css.push, css.horizontalButton]
    }

    const conversationLayoutProps = {
      content: (
        <div className={css.content}>
          <H1 css={{container: css.heading}}>
            {t('subscriptions.profileSummary.title')}
          </H1>
          <P css={{container: css.summaryParagraph}}>
            {t('subscriptions.profileSummary.body')}
          </P>

          <div className={css.summaryListItem}>
            <P css={{container: css.summaryListKey}} style="xSmall">
              {t('profile.name')}
            </P>
            <P css={{container: css.summaryListValue}} style="xSmall">
              {profile.firstName} {profile.lastName}
            </P>
          </div>
          <Line css={{line: css.summaryDivider}} />

          <div className={css.summaryListItem}>
            <P css={{container: css.summaryListKey}} style="xSmall">
              {t('profile.studentTypeLabel')}
            </P>
            <P css={{container: css.summaryListValue}} style="xSmall">
              {t(`profile.studentType.${profile.studentType}`)}
            </P>
          </div>
          <Line css={{line: css.summaryDivider}} />

          <div className={css.summaryListItem}>
            <P css={{container: css.summaryListKey}} style="xSmall">
              {t('profile.school')}
            </P>
            <P css={{container: css.summaryListValue}} style="xSmall">
              {getEducationName(profile)}
            </P>
          </div>
          <Line css={{line: css.summaryDivider}} />

          {profile.studentNumber && (
            <div className={css.summaryListItem}>
              <P css={{container: css.summaryListKey}} style="xSmall">
                {t('profile.studentId')}
              </P>
              <P css={{container: css.summaryListValue}} style="xSmall">
                {profile.studentNumber}
              </P>
            </div>
          )}
          {profile.studentNumber && <Line css={{line: css.summaryDivider}} />}

          {profile.jollaMembershipExtraInfo && (
            <div className={css.summaryListItem}>
              <P css={{container: css.summaryListKey}} style="xSmall">
                {t('profile.extraInfoLabel')}
              </P>
              <P css={{container: css.summaryListValue}} style="xSmall">
                {profile.jollaMembershipExtraInfo}
              </P>
            </div>
          )}
          {profile.jollaMembershipExtraInfo && (
            <Line css={{line: css.summaryDivider}} />
          )}

          <div className={css.summaryListItem}>
            <P css={{container: css.summaryListKey}} style="xSmall">
              {t('profile.dateOfBirth')}
            </P>
            <P css={{container: css.summaryListValue}} style="xSmall">
              {moment(profile.dob).format('DD.MM.YYYY')}
            </P>
          </div>
          <Line css={{line: css.summaryDivider}} />

          <div className={css.summaryListItem}>
            <P css={{container: css.summaryListKey}} style="xSmall">
              {t('profile.profilePictureLabel')}
            </P>
            <div className={css.summaryListValue}>
              <img src={profile.photo} />
            </div>
          </div>
          <Line css={{line: css.summaryDivider}} />
        </div>
      ),
      buttons: (
        <div className={css.horizontalButtons}>
          <div className={css.left}>
            <GenericButton
              onClick={() => this.props.history.push(this.props.startLink)}
              style="bold"
              isFullWidth
              css={buttonStyles}
            >
              {t('subscriptions.plainCardPurchase.profileSummary.confirmCta')}
            </GenericButton>
          </div>

          <div className={css.right}>
            <GenericButton
              onClick={() =>
                this.props.history.push('/profile/settings', {
                  from: this.props.from
                })
              }
              style="outline"
              isFullWidth
              css={buttonStyles}
            >
              {t('subscriptions.plainCardPurchase.profileSummary.editCta')}
            </GenericButton>
          </div>
        </div>
      ),
      hideBackButton: true,
      dialogContainerStyle: dialogContainerStyle
    }

    return (
      <div>
        <HeaderTopFill
          background={HeaderTopFillStyles[dialogContainerStyle]}
          position={'fixed'}
        />
        <ConversationLayout {...conversationLayoutProps} />
      </div>
    )
  }
}

function getEducationName(profile) {
  if (profile.organization) {
    return profile.organization.name
  }
  if (profile.school) {
    return profile.school.name
  }
  return ''
}

export default ProfileSummary
