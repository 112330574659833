import callEndpoint from '$src/api/sagas/callEndpoint'
import {startOrder as startOrderEndpoint} from '$src/api/endpoints/subscriptions/startOrder'

/**
 * @param {OrderParams} orderParams
 * @returns {Promise<any>}
 */
export function* startOrder(orderParams) {
  return yield callEndpoint(startOrderEndpoint, orderParams)
}
