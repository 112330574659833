import * as React from 'react'
import {compose} from 'redux'
import List from 'react-virtualized/dist/commonjs/List'
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader'
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller'
import range from 'lodash/range'
import deepEqual from 'deep-equal'
import {CommonProps} from './InfiniteLoadingResponsiveCollection'
import css from './InfiniteLoadingList.css'
import NoContent from '$src/offers/components/ListPage/NoContent'

type RenderRowBasicProps = {
  index: number
  style: object
  key: string
  isScrolling: boolean
  isVisible: boolean
}

export type RenderRowProps = RenderRowBasicProps & {
  pageNumber: number
  offsetInPage: number
}

export type SpecialProps = {
  renderRow: (props: RenderRowProps) => React.Node
}

type Props = CommonProps & SpecialProps

type State = {}

class InfiniteLoadingList extends React.Component<Props, State> {
  static defaultProps: Props
  props: Props
  state: State
  _windowScrollerRef: any
  _updatePositionTimeout: any

  constructor(props: Props) {
    super(props)
    this.state = {}
    this._windowScrollerRef = null
    this._updatePositionTimeout = null
  }

  static indexToPageNumber = (index: number, itemsPerPage: number): number =>
    Math.floor(index / itemsPerPage) + 1

  static indexToOffsetInPage = (index: number, itemsPerPage: number): number =>
    index % itemsPerPage

  loadMoreRows = ({startIndex, stopIndex}, props: Props = this.props) => {
    const startPage = InfiniteLoadingList.indexToPageNumber(
      startIndex,
      props.itemsPerPage
    )
    const stopPage = InfiniteLoadingList.indexToPageNumber(
      stopIndex,
      props.itemsPerPage
    )

    const pageNumbers: Array<number> = range(startPage, stopPage + 1)
    return props.fetchPages(pageNumbers)
  }

  renderRow(params: RenderRowBasicProps) {
    const pageNumber = InfiniteLoadingList.indexToPageNumber(
      params.index,
      this.props.itemsPerPage
    )
    const offsetInPage = InfiniteLoadingList.indexToOffsetInPage(
      params.index,
      this.props.itemsPerPage
    )
    const innerRow = this.props.renderRow({...params, pageNumber, offsetInPage})

    return this.props.autoWrapItems === false ? (
      innerRow
    ) : (
      <div
        className={css.cellOuterWrapper}
        key={`cellOuterWrapper-${params.index}`}
        style={{...params.style}}
      >
        {innerRow}
      </div>
    )
  }

  UNSAFE_componentWillMount() {
    this.update(null, this.props)
  }

  componentDidUpdate(prevProps) {
    this.update(prevProps, this.props)
  }

  update(oldProps?: Props, newProps: Props) {
    if (!oldProps || !deepEqual(oldProps.uniqueParams, newProps.uniqueParams)) {
      newProps.fetchPages([1])
    }
  }

  isRowLoaded = ({index}: {index: number}): boolean => {
    const pageNumber = InfiniteLoadingList.indexToPageNumber(
      index,
      this.props.itemsPerPage
    )
    const offsetInPage = InfiniteLoadingList.indexToOffsetInPage(
      index,
      this.props.itemsPerPage
    )
    // sending the pageNumber to the parent component
    // to be used in scroll depth analytics
    this.props.currentPage.current = pageNumber
    return this.props.isItemLoaded({index, pageNumber, offsetInPage})
  }

  _setWindowScrollerRef = (windowScroller) => {
    this._windowScrollerRef = windowScroller

    this.clearUpdatePositionTimeout()
    this._updatePositionTimeout = setTimeout(() => {
      if (this._windowScrollerRef) {
        this._windowScrollerRef.updatePosition()
      }
    }, 1000)
  }

  clearUpdatePositionTimeout() {
    if (this._updatePositionTimeout !== null) {
      clearTimeout(this._updatePositionTimeout)
      this._updatePositionTimeout = null
    }
  }

  componentWillUnmount() {
    this.clearUpdatePositionTimeout()
  }

  render() {
    return (
      <InfiniteLoader
        isRowLoaded={this.isRowLoaded}
        loadMoreRows={this.loadMoreRows}
        rowCount={this.props.itemCount}
      >
        {({onRowsRendered, registerChild}) => {
          return (
            <WindowScroller ref={this._setWindowScrollerRef}>
              {({height, width, isScrolling, scrollTop}) => {
                return (
                  <List
                    noRowsRenderer={NoContent}
                    autoHeight
                    height={height}
                    width={width}
                    isScrolling={isScrolling}
                    scrollTop={scrollTop}
                    onRowsRendered={onRowsRendered}
                    ref={registerChild}
                    rowCount={this.props.itemCount}
                    rowHeight={this.props.rowHeight}
                    rowRenderer={(...args) => this.renderRow(...args)}
                  />
                )
              }}
            </WindowScroller>
          )
        }}
      </InfiniteLoader>
    )
  }
}

export default compose((a) => a)(InfiniteLoadingList)
