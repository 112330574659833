import resolveCss from './resolveCss'
import isMobile from './isMobile'
import withRunSaga from './withRunSaga'
import reduceState from './reduceState'
import multiReduceState from './multiReduceState'
import asForm from './asForm'
import asField from './asField'
import connect from './connect'
import actionCreator from './actionCreator'
import setUserProfile from './setUserProfile'
import createApiErrorMessage from '$src/api/utils/createApiErrorMessage'
import urlWithRedirect from './urlWithRedirect'
import onlyUpdateForKeysDeep from './onlyUpdateForKeysDeep'
import {
  default as withRouter,
  WithRouterProps as _WithRouterProps
} from './withRouter'
import withErrorBoundary from './withErrorBoundary'
import {
  default as withTranslate,
  WithTranslateProps as _WithTranslateProps
} from '$src/i18n/utils/withTranslate'
import {
  default as withResponsiveInfo,
  WithResponsiveInfoProps as _WithResponsiveInfoProps
} from './withResponsiveInfo'
import isDeviceOnline from './isDeviceOnline'
import {trimmedValues} from './objectUtils'
import {launchRating} from './launchRating'

export {
  isMobile,
  resolveCss,
  withRunSaga,
  reduceState,
  multiReduceState,
  asForm,
  asField,
  connect,
  actionCreator,
  createApiErrorMessage,
  withErrorBoundary,
  withRouter,
  withTranslate,
  onlyUpdateForKeysDeep,
  setUserProfile,
  withResponsiveInfo,
  urlWithRedirect,
  isDeviceOnline,
  trimmedValues,
  launchRating
}

export {WithRunSagaProps} from './withRunSaga'
export type WithRouterProps = _WithRouterProps
export type WithTranslateProps = _WithTranslateProps
export type WithResponsiveInfoProps = _WithResponsiveInfoProps
