import { callEndpoint } from '$src/api2/sagas/callEndpoint'
import postCanRedeemEndpoint from '$src/api2/endpoints/redeemOnce/post'
import { reduceState } from '$common/utils'
import {
    ANALYTICS_EVENT as evt,
    ANALYTICS_PROVIDER as provider,
    logEvent2 as log
  } from '$common/utils/logUtils'
  

export default function* canRedeem(payload) {

    const response = yield callEndpoint('postCanRedeem')(
        postCanRedeemEndpoint,
        payload
    )
    return response
}
