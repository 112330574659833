import {applyMiddleware, createStore, compose, Reducer, Store} from 'redux'
import createSagaMiddleware from 'redux-saga'
import * as Sentry from '@sentry/browser'
import createSentryMiddleware from 'redux-sentry-middleware'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import pick from 'lodash/pick'

import defaultRootReducer from '../rootReducer'

type ConstructorProps = {
  initialState?: State
  rootReducer?: Reducer<State, Action>
}

/**
 * Core is basically redux and sagas. Maybe we can reduce this to just a simple function?
 */
export default class Core<State, Action> {
  sagaMiddleware: any
  _initialState?: State
  rootReducer: Reducer<State, Action>
  reduxStore: Store<State, Action>

  constructor({initialState, rootReducer}: ConstructorProps<State, Action>) {
    this._initialState = initialState
    this.sagaMiddleware = createSagaMiddleware({
      onError(error: Error): void {
        Sentry.captureException(error)
      }
    })

    this.rootReducer = rootReducer || defaultRootReducer

    this.reduxStore = this._createReduxStore()
  }

  _createReduxStore(): Store<State, Action> {
    const middlewares = []

    middlewares.push(this.sagaMiddleware)

    if (process.env.SENTRY_DSN && !process.env.DISABLE_SENTRY) {
      const sentryReduxMiddleware = createSentryMiddleware(Sentry, {
        stateTransformer: (state) => {
          const sanitized = cloneDeep(state)
          if (get(sanitized, 'auth.tokensState')) {
            sanitized.auth.tokensState = pick(sanitized.auth.tokensState, [
              'externalId',
              'hasTokens'
            ])
          }
          if (get(sanitized, 'profile')) {
            sanitized.profile = pick(sanitized.profile, [
              'loaded',
              'id',
              'photoLastModified',
              'photoLastModifiedDisabled',
              'organization',
              'jollaMembershipExtraInfo',
              'studentStatus',
              'studentType',
              'identityCode',
              'language',
              'verificationState',
              'state'
            ])
          }
          if (get(sanitized, 'form')) {
            Object.values(sanitized.form).forEach((form) => {
              form instanceof Object &&
                form.values instanceof Object &&
                delete form.values.password
            })
          }
          // Remove big objects / lists to not exceed sentry body size limit
          delete sanitized.articles
          delete sanitized.offers
          delete sanitized.news

          return sanitized
        }
      })
      middlewares.push(sentryReduxMiddleware)
    }

    const enhancer = compose(
      applyMiddleware(...middlewares),
      typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    )

    const store = createStore(this.rootReducer, this._initialState, enhancer)

    store.sagaMiddleware = this.sagaMiddleware

    return store
  }
}
