import React from 'react'
import StudentCardButton from './StudentCardButton'
import barcodeIcon from '$assets/icons/barcodeCard.png'
import checkMarkIcon from '$assets/icons/checkmark_black.png'
import resolveCss from '$src/common/utils/resolveCss'
import css from '$src/offline-studentcard/components/StudentCardView/StudentCardPhoto.css'
import {
  addToCache,
  loadFromCache
} from '$src/offline-studentcard/components/StudentCardView/PhotoCache'

const calculateImageDimension = () => window.screen.width * 0.7

export const calculateImageMargin = () =>
  (window.screen.width - calculateImageDimension()) / 2

type Props = {
  studentCardColor: 'white' | 'green' | 'blue'
  onBarcodeClick: Function
  onQrCodeClick: Function
  src?: string
}

const StudentCardPhoto = ({
  src: originalSrc,
  onQrCodeClick,
  onBarcodeClick,
  studentCardColor
}: Props) => {
  // Cache may empty occasionally, so always update cache.
  // Update is async operation - cache will be available for later renders.
  addToCache(originalSrc)
  const src = loadFromCache(originalSrc)

  const classes = resolveCss(css)
  const margin = calculateImageMargin()
  const imageDimension = calculateImageDimension()

  return (
    <div
      {...classes('studentCardPhotoContainer')}
      style={{minHeight: imageDimension}}
    >
      <img
        src={src}
        style={{
          height: imageDimension,
          width: imageDimension,
          marginLeft: margin
        }}
        crossOrigin="anonymous"
      />
      <StudentCardButton
        studentCardColor={studentCardColor}
        style={{bottom: -20, left: margin / 2}}
        src={barcodeIcon}
        onClick={onBarcodeClick}
      />
      <StudentCardButton
        studentCardColor={studentCardColor}
        style={{bottom: -20, right: margin / 2}}
        src={checkMarkIcon}
        onClick={onQrCodeClick}
        pulse
      />
    </div>
  )
}

export default StudentCardPhoto
