import React from 'react'
import {ProfileNamespaceState} from '$src/profile/types'
import resolveCss from '$src/common/utils/resolveCss'
import css from '$src/offline-studentcard/components/StudentCardView/Icons.css'
import sylIcon from '$assets/icons/SYL_white.png'
import ythsIcon from '$assets/icons/yths.png'
import sakkiIcon from '$assets/icons/Sakki_black.png'
import sllIcon from '$assets/icons/SLL_black.png'
import fssIcon from '$assets/icons/FSS_black.png'

type Props = {
  profile: ProfileNamespaceState
}

const Icons = (props: Props) => {
  const {profile} = props
  if (!profile.loaded) {
    return null
  }

  const classes = resolveCss(css)

  const organizationType = profile.organization && profile.organization.type
  const organizationId = profile.organization && profile.organization.id
  const studentType = profile.studentType

  const sylIconVisible = organizationType === 'university'
  const ythsIconVisible =
    organizationType === 'university' && studentType === 'graduate'
  const sakkiIconVisible = organizationType === 'vocational-school'
  const fssIconVisible =
    organizationType === 'high-school' && organizationId === 'FSS'
  const sllIconVisible =
    organizationType === 'high-school' && organizationId === 'SLL'

  return (
    <div {...classes('iconsContainer')}>
      {sylIconVisible && <img {...classes('schoolLogo')} src={sylIcon} />}
      {ythsIconVisible && <img {...classes('schoolLogo')} src={ythsIcon} />}
      {sakkiIconVisible && <img {...classes('schoolLogo')} src={sakkiIcon} />}
      {fssIconVisible && <img {...classes('schoolLogo')} src={fssIcon} />}
      {sllIconVisible && <img {...classes('schoolLogo')} src={sllIcon} />}
    </div>
  )
}

export default Icons
