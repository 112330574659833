import * as React from 'react'
import {Interpolate} from 'react-i18next'
import {Link, Route} from 'react-router-dom'

import GenericButton from '$common/components/buttons/GenericButton'

import {withRouter, WithRouterProps} from '$common/utils'

type Props = {
  canContinue: boolean
  linkTo?: string
  matchPath?: string
  from?: string
}

export const ProfileFlowButton = withRouter((props: WithRouterProps) => (
  <Route
    path="/profile/settings/photo"
    render={() => (
      <GenericButton
        onClick={() =>
          props.history.push('/profile/settings', {
            from: props.from || '/profile/settings/photo'
          })
        }
        style="bold"
      >
        <Interpolate i18nKey="common.close" />
      </GenericButton>
    )}
  />
))

export const RegisterFlowButton = ({canContinue}: Props) => (
  <Route
    path="/profile/verify/photo"
    render={() => (
      <Link to="/home">
        {canContinue ? (
          <GenericButton surroundMargin={true} style="bold">
            <Interpolate i18nKey="profile.profilePhotoPage.continue" />
          </GenericButton>
        ) : (
          <GenericButton surroundMargin={true} style="outline">
            <Interpolate i18nKey="profile.verification.continueLater" />
          </GenericButton>
        )}
      </Link>
    )}
  />
)

export const CardFlowButton = ({canContinue, linkTo, matchPath}: Props) => (
  <Route
    path={matchPath}
    render={() => (
      <Link to={linkTo} onClick={(e) => !canContinue && e.preventDefault()}>
        <GenericButton disabled={!canContinue} style="bold">
          <Interpolate i18nKey="profile.profilePhotoPage.continue" />
        </GenericButton>
      </Link>
    )}
  />
)
