// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'

import * as React from 'react'
import * as Sentry from '@sentry/browser'
import {render} from 'react-dom'
import App from '$src/bootstrap/components/App'

const runApp = () => {
  if (window.cordova) {
    document.addEventListener(
      'deviceready',
      () => {
        render(<App />, document.getElementById('root'))
        if (window.navigator.splashscreen) {
          window.navigator.splashscreen.hide()
          window.open = cordova.InAppBrowser.open
        }
      },
      false
    )
  } else {
    return render(<App />, document.getElementById('root'))
  }
}

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: `${process.env.SENTRY_PROJECT}@${process.env.APP_VERSION}`
  })
  Sentry.configureScope((scope) => {
    scope.setTag('git_commit', process.env.COMMIT_HASH)
  })
}
runApp()
