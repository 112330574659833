import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import amplitude from '$src/analytics/amplitude'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import SvgIcon from '$common/components/icons/SvgIcon'
import features from '$src/ordercard/features'
import {
  resolveCss,
  WithRouterProps,
  WithRunSagaProps,
  WithTranslateProps
} from '$common/utils'
import Layout from '$common/components/layouts/TopMiddleBottomLayout'

import align from '$common/grid/align.css'
import css from './FeaturesMobile.css'
import grid from '$common/grid/grid.css'

import wizardStepDark from '$assets/icons/wizardStepDark.svg'
import wizardStepRed from '$assets/icons/wizardStepRed.svg'
import Slider from 'react-slick/lib/index'

type Props = WithRouterProps &
  WithRunSagaProps &
  WithTranslateProps & {
    handleFeatureCTA: Function
    subs: any
    pricing: any
    language: string
  }

type State = {
  slideIndex: number
}

const logEvent = () => amplitude.logEvent('downloadFrankApp')

function getFeatures() {
  const toRemove = !window.cordova ? 'mobile-only' : 'desktop-only'
  return features.filter((feature) => {
    return feature.filter !== toRemove
  })
}

export default class FeaturesMobile extends Component<Props, State> {
  props: Props

  state: State = {
    slideIndex: 0,
    blockedModal: false
  }

  afterSlideChange = (currentSlideIdx: number) => {
    this.setState({
      slideIndex: currentSlideIdx
    })
  }

  render() {
    const classes = resolveCss(css, grid, align)

    const sliderSettings = {
      arrows: false,
      infinite: false,
      speed: 100,
      touchThreshold: 10
    }

    const renderFeatures = getFeatures()

    return (
      renderFeatures && (
        <div className={css.carousel}>
          <div {...classes('columnSlide', 'center')}>
            <div {...classes('verticalCenter', 'slideIndicatorContainer')}>
              <div>
                {renderFeatures.map((feature, index) => (
                  <SvgIcon
                    key={index}
                    src={
                      index === this.state.slideIndex
                        ? wizardStepRed
                        : wizardStepDark
                    }
                    css={{container: css.slideIndicator}}
                    sizing="none"
                  />
                ))}
              </div>
            </div>
          </div>

          <Slider {...sliderSettings} afterChange={this.afterSlideChange}>
            {renderFeatures.map((feature) => {
              return (
                <div key={feature.id}>
                  <Layout
                    css={{
                      container: css.slideContainer,
                      middle: align.verticalCenter
                    }}
                    containerStyle={'fitHeight'}
                    bottom={
                      <div>
                        <H1 css={{container: align.centerText}}>
                          {this.props.t(
                            feature.mobileTitle,
                            this.props.pricing
                          )}
                        </H1>
                        <P css={{container: align.centerText}} style="subtle">
                          {this.props.t(feature.mobileBody, this.props.pricing)}
                        </P>
                        {feature.external ? (
                          <Link
                            onClick={() => {
                              window.scrollTo(0, 0)
                              logEvent()
                            }}
                            to={this.props.t(feature.link)}
                            target="_blank"
                          >
                            <GenericButton
                              css={{container: css.button}}
                              style="bold"
                            >
                              {this.props.t(feature.mobileCta)}
                            </GenericButton>
                          </Link>
                        ) : (
                          <GenericButton
                            css={{container: css.button}}
                            onClick={this.props.handleFeatureCTA(feature)}
                            style="bold"
                          >
                            {this.props.t(feature.mobileCta)}
                          </GenericButton>
                        )}
                      </div>
                    }
                  />
                </div>
              )
            })}
          </Slider>
        </div>
      )
    )
  }
}
