import React, {Component} from 'react'
import {Route} from 'react-router-dom'
import _ from 'lodash'
import {withRouter, withRunSaga, RunSagaFn} from '$common/utils'

import {WithRouterProps} from '$common/utils'
import {Voucher} from '$src/offers/types'
import {StoreState} from '$src/types'
import {getOffer} from '$src/offers/selectors'
import {isFetching} from '$src/fetching/selectors'
import {isUserVerified, isUserActive} from '$src/profile/selectors'
import fetchVoucher from '$src/offers/sagas/fetchVoucher'

import LocationsModal from '$src/offers/components/LocationsModal'
import LoadingSpinnerModal from '$common/components/LoadingSpinnerModal'
import HeroLayout from '$common/components/layouts/HeroLayout'

import {compose} from 'redux'
import {connect} from '$common/utils'
import get from 'lodash/get'

import diamondIcon from '$assets/icons/diamond.svg'
import {getAppboy} from '$common/pgWebSeparatorUtils'
import amplitude from '$src/analytics/amplitude'

type Props = WithRouterProps & {
  css?: any
  runSaga: RunSagaFn
  isFetching: boolean
  voucherKey: string
  voucher?: Voucher
  voucherKey: string
  isUserVerified: boolean
  isUserActive: boolean
  isUserLoggedIn: boolean
  languageCode: 'en' | 'sv' | 'fi'
}

class VoucherPage extends Component<Props> {
  UNSAFE_componentWillMount() {
    this.loadVoucherIfNecessary(undefined, this.props)
    this.logCustomEvents()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.loadVoucherIfNecessary(this.props, nextProps)

    if (
      this.props.voucher &&
      (this.props.voucher && this.props.voucher.key) !==
        (nextProps.voucher && nextProps.voucher.key)
    ) {
      this.logCustomEvents()
    }
  }

  logCustomEvents = () => {
    if (this.props.isUserVerified) {
      const params = _.pick(this.props.voucher, [
        'key',
        'advertiser',
        'categories'
      ])
      if (!_.isEmpty(params.categories)) {
        params.categories = params.categories.join(',')
      }
      getAppboy().logCustomEvent('Open voucher', params)
      amplitude.logEvent('openVoucher', params)
    }
  }

  loadVoucherIfNecessary(oldProps?: Props, {voucherKey: newVoucherKey}: Props) {
    if (get(oldProps, ['voucherKey']) !== newVoucherKey) {
      this.loadVoucher(newVoucherKey)
    }
  }

  loadVoucher(voucherKey: string) {
    this.props.runSaga(fetchVoucher, {
      key: voucherKey,
      languageCode: 'en',
      dontOverrideCache: true
    })
  }

  render() {
    const {
      voucher,
      history,
      isUserVerified,
      isUserActive,
      isFetching,
      voucherKey
    } = this.props

    return isFetching ? (
      <LoadingSpinnerModal />
    ) : (
      <div>
        <Route
          path={`${this.props.match.path}/locations`}
          component={() => (
            <LocationsModal
              hide={() => history.push(`/voucher/${voucherKey}`)}
              item={voucher}
            />
          )}
        />
        <HeroLayout
          item={voucher}
          isUserVerified={isUserVerified}
          isUserActive={isUserActive}
          parentRoute="offers"
          extraIcons={[{src: diamondIcon, key: 'diamond'}]}
        />
      </div>
    )
  }
}

export default compose(
  connect((state: StoreState, props: WithRouterProps) => {
    const voucherKey = get(props, ['match', 'params', 'key']) || 'not_defined'
    return {
      voucherKey,
      isFetching: isFetching(state, 'singleVoucher'),
      voucher: getOffer(state, voucherKey),
      isUserVerified: isUserVerified(state),
      isUserActive: isUserActive(state)
    }
  }),
  withRunSaga(),
  withRouter
)(VoucherPage)
