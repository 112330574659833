import {fork, select} from 'redux-saga/effects'

import {callEndpoint} from '$src/api/sagas/callEndpoint'
import getProfileEndpoint from '$src/api/endpoints/profile/get'
import {setUserProfile} from '$common/utils'
import {Profile, ProfileVerificationState} from '$src/profile/types'
import {getSchools} from './getSchools'
import {getProfile as getProfileSelector} from '$src/profile/selectors'

export default function* getProfile(requestKey = 'getProfile') {
  const r: Profile & {state: ProfileVerificationState} = yield callEndpoint(
    requestKey
  )(getProfileEndpoint, {forceSync: false})
  yield fork(getSchools)
  yield setUserProfile(r)
  const profile = yield select(getProfileSelector)
  return profile
}
