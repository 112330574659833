import React, {Component} from 'react'
import ErrorCandy from '$common/components/candyBars/ErrorCandy'
import {FormProps} from 'redux-form'
import {
  WithRouterProps,
  WithRunSagaProps,
  WithTranslateProps
} from '$common/utils'
import {compose} from 'redux'
import {
  connect,
  withRouter,
  withRunSaga,
  withTranslate
} from '$src/common/utils'
import clearNotification from '$src/notification/sagas/clearNotification'

type Props = WithRouterProps & WithRunSagaProps & WithTranslateProps & FormProps

export const NOTIFICATION_TYPE = {
  NOTIFICATION: 'NOTIFICATION',
  ERROR: 'ERROR'
}

class Notification extends Component<Props> {
  render() {
    const {messageKey, message} = this.props.notification
    return (
      <ErrorCandy
        white={this.props.notification.type === NOTIFICATION_TYPE.NOTIFICATION}
        show={messageKey || message}
        onHide={() => this.props.runSaga(clearNotification)}
      >
        <div>{messageKey ? this.props.t(messageKey) : message}</div>
      </ErrorCandy>
    )
  }
}

export default compose(
  connect((state: any) => ({
    notification: state.notification
  })),
  withRunSaga(),
  withRouter,
  withTranslate()
)(Notification)
