import React, {useEffect, useRef} from 'react'
import {compose} from 'redux'
import {withResponsiveInfo, WithResponsiveInfoProps} from '$common/utils'
import {
  default as InfiniteLoadingGrid,
  SpecialProps as GridSpecialProps,
  RenderCellProps as _RenderCellProps
} from './InfiniteLoadingGrid'
import {
  default as InfiniteLoadingList,
  SpecialProps as ListSpecialProps,
  RenderRowProps as _RenderRowProps
} from './InfiniteLoadingList'
import {
  logEvent2 as log,
  ANALYTICS_EVENT,
  ANALYTICS_PROVIDER
} from '$src/common/utils/logUtils'

export type CommonProps = {
  /**
   * you can choose a very large number if you want infinite-loading
   */
  itemCount: number

  /**
   * Number of items that get loaded from the server in each batch
   */
  itemsPerPage: number

  /**
   * if the list's content is loaded from the server along with some query parameters,
   * you should provide those parameters here, so that when those params change, the
   * list will try to load the first page with the new query parameters.
   */
  uniqueParams: any

  /**
   * Takes a list of page numbers, and tries to load them. Must return a promise.
   * We don't care what's the resolve type of the promise.
   */
  fetchPages: (pages: Array<number>) => Promise<any>

  /**
   * Height of rows, both in the grid and in list view
   */
  rowHeight: number | ((index: number) => number)

  /**
   * Defaults to true. This will automatically apply styling to each cell/row so that they
   * appear in the correct location. If this is off, then it's your job to read `params.style`
   * and apply it manually to each element
   */
  autoWrapItems: boolean

  /**
   * Must return whether a particular index has been loaded or not
   */
  isItemLoaded: (param: {
    index: number
    offsetInPage: number
    pageNumber: number
  }) => boolean
}

/**
 * This component renders a grid when in desktop mode, and a list when in mobile mode.
 * Both are infinite lists, unless a small `itemCount` is supplied.
 *
 * @note make sure to read the type suffixed with 'SpecialProps' to learn what other props
 * are needed for this component.
 */
export type Props = WithResponsiveInfoProps &
  CommonProps &
  GridSpecialProps &
  ListSpecialProps

export type RenderCellProps = _RenderCellProps
export type RenderRowProps = _RenderRowProps

const InfiniteLoadingResponsiveCollection = (props: Props) => {
  const {isDesktop, ...rest} = props
  const currentPage = useRef(0)

  useEffect(() => {
    // we don't want to track scrolling on the homepage that contains vouchers
    if (props.uniqueParams.includeVouchers) return

    const logScrollEvent = () => {
      if (currentPage.current) {
        log(
          ANALYTICS_EVENT.OFFER_PAGE_SCROLLING_DEPTH,
          [ANALYTICS_PROVIDER.AMPLITUDE],
          {
            currentPage: currentPage.current
          }
        )
      }
    }

    // on cordova we also want to send a scrolling event if the user
    // has scrolled and then backgrounds the app
    if (window.cordova) {
      document.addEventListener('pause', logScrollEvent, false)
    }

    // this is run every time when the component unmounts.
    return () => {
      if (window.cordova) {
        document.removeEventListener('pause', logScrollEvent, false)
      }
      logScrollEvent()
    }
  }, [])

  if (isDesktop) {
    return <InfiniteLoadingGrid {...rest} currentPage={currentPage} />
  } else {
    return <InfiniteLoadingList {...rest} currentPage={currentPage} />
  }
}

export default compose(withResponsiveInfo())(
  InfiniteLoadingResponsiveCollection
)
