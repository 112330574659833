import {compose} from 'redux'

import {withRouter, withTranslate, asForm} from '$src/common/utils'
import {validatePassword, validatePasswordAgain} from '$common/validators'
import ChangePasswordPage from './ChangePasswordPage'

const validate = ({currentPassword, newPassword, newPasswordAgain}) => {
  return {
    currentPassword: validatePassword(currentPassword),
    newPassword: validatePassword(newPassword),
    newPasswordAgain: validatePasswordAgain(newPasswordAgain, newPassword)
  }
}

export default compose(
  asForm('changePassword', {validate}),
  withRouter,
  withTranslate()
)(ChangePasswordPage)
