import React from 'react'
import {compose} from 'redux'
import debounce from 'lodash/debounce'
import amplitude from '$src/analytics/amplitude'
import collectBreadCrumbs from '$src/analytics/sagas/collectBreadCrumbs'

import {
  withErrorBoundary,
  withRouter,
  WithRouterProps,
  withRunSaga,
  WithRunSagaProps
} from '$common/utils'

type Props = WithRouterProps & WithRunSagaProps & {children: object}

class NavigationEventLogger extends React.Component<Props> {
  previousPathname = undefined

  UNSAFE_componentWillMount() {
    this.props.runSaga(collectBreadCrumbs)
  }

  UNSAFE_componentWillReceiveProps() {
    const currentPathname = this.props.history.location.pathname

    if (!this.previousPathname) {
      this.previousPathname = this.props.location.pathname
    }
    this.collectUrls(currentPathname)
  }

  collectUrls = (currentPathname) => {
    if (currentPathname !== this.previousPathname) {
      this.previousPathname = undefined
    }
  }

  collectUrls = debounce(this.collectUrls, 200)

  render() {
    return this.props.children
  }
}

export default compose(
  withErrorBoundary,
  withRouter,
  withRunSaga()
)(NavigationEventLogger)
