export const categories = [
  {
    name: 'offers.categories.all',
    id: '0'
  },
  {
    name: 'offers.categories.electronics',
    id: '2'
  },
  {
    name: 'offers.categories.homeAndFamily',
    id: '3'
  },
  {
    name: 'offers.categories.transportation',
    id: '4'
  },
  {
    name: 'offers.categories.foodAndBeverages',
    id: '5'
  },
  {
    name: 'offers.categories.sports',
    id: '6'
  },
  {
    name: 'offers.categories.clothesAndAccessories',
    id: '7'
  },
  {
    name: 'offers.categories.goingOut',
    id: '8'
  },
  {
    name: 'offers.categories.healthAndWellbeing',
    id: '9'
  },
  {
    name: 'offers.categories.groceryStore',
    id: '18'
  },
  {
    name: 'offers.categories.jobs',
    id: '19'
  },
  {
    name: 'offers.categories.quizzes',
    id: '20'
  }
] as const

export const sort = [
  {
    name: 'offers.sort.mostRecent',
    id: 'date:desc'
  },
  {
    name: 'offers.sort.az',
    id: 'alphabetical:asc'
  }
]
