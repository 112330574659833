import {get} from 'lodash'
import {Profile} from '$src/profile/types'
import setBodyBackgroundColor from '$src/common/utils/setBodyBackgroundColor'

export const STUDENT_CARD_COLOR = {
  WHITE: 'white',
  BLUE: 'blue',
  GREEN: 'green'
}

export const getStudentCardColor = (profile: Profile) => {
  const educationType = profile.organization
    ? get(profile, 'organization.type')
    : get(profile, 'school.type')
  const studentType = get(profile, 'studentType')
  if (educationType === 'university' || educationType === 'polytechnic') {
    if (studentType === 'graduate') {
      return STUDENT_CARD_COLOR.BLUE
    } else if (studentType === 'postgraduate') {
      return STUDENT_CARD_COLOR.GREEN
    }
  }
  return STUDENT_CARD_COLOR.WHITE
}

export const setBodyBackgroundByStudentCardColor = (profile: Profile) => {
  if (getStudentCardColor(profile) === STUDENT_CARD_COLOR.WHITE) {
    setBodyBackgroundColor('white')
  } else if (getStudentCardColor(profile) === STUDENT_CARD_COLOR.BLUE) {
    setBodyBackgroundColor('#498EDF')
  } else if (getStudentCardColor(profile) === STUDENT_CARD_COLOR.GREEN) {
    setBodyBackgroundColor('#37bb9d')
  }
}
