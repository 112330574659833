import React, {Component} from 'react'
import {WithRouterProps} from 'react-router-dom'
import {Link} from 'react-router-dom'
import MediaQuery from 'react-responsive'
import {compose} from 'redux'

import {StoreState} from '$src/types'
import {WithRunSagaProps} from '$src/common/utils'
import {
  resolveCss,
  connect,
  withRouter,
  withRunSaga,
  withTranslate,
  WithTranslateProps
} from '$src/common/utils'
import {getProfile as profileSelector} from '$src/profile/selectors'
import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import TopMiddleBottomLayout from '$common/components/layouts/TopMiddleBottomLayout'
import align from '$common/grid/align.css'
import check from '$assets/icons/temporary/check.png'
import css from './index.css'
import grid from '$common/grid/grid.css'

type Props = WithRouterProps & WithRunSagaProps & WithTranslateProps & {}

class PhoneNumberVerification extends Component<Props> {
  onClickVerify() {
    this.props.history.push('/profile/verify/phone-number')
  }

  render() {
    const classes = resolveCss(css, grid, align)
    // unverified users should not be allowed to access the add phone number page.
    if (!this.props.isUserVerified) {
      this.props.history.push('/profile/settings')
    }
    return (
      <TopMiddleBottomLayout
        adaptiveLayout={{
          stackMiddle: true
        }}
        css={{
          container: grid.container,
          bottom: css.bottom
        }}
        top={
          <MediaQuery minWidth={801}>
            <FrankLogoInSmallWhite />
          </MediaQuery>
        }
        middle={
          <div {...classes('columnLarge5', 'center')}>
            <img
              {...classes('check', 'column', 'center')}
              src={check}
              alt="nothing"
            />
            <H1 css={{container: align.largeCenterText}}>
              {this.props.t('profile.verification.phoneNumber.title')}
            </H1>
            <P
              style="small"
              css={{container: [css.message, align.largeCenterText]}}
            >
              {this.props.t('profile.verification.phoneNumber.body')}
            </P>
          </div>
        }
        bottom={
          <div {...classes('columnLarge5', 'center')}>
            <GenericButton
              onClick={() => this.onClickVerify()}
              style="bold"
              css={{container: css.button}}
              isFullWidth
            >
              {this.props.t('profile.verification.phoneNumber.verify')}
            </GenericButton>
            <Link to="/profile/verify/photo">
              <GenericButton
                isFullWidth
                surroundMargin={true}
                css={{container: css.button}}
                style="outline"
              >
                {this.props.t('profile.verification.continueLater')}
              </GenericButton>
            </Link>
          </div>
        }
      />
    )
  }
}

export default compose(
  connect((state: StoreState) => {
    const profile = profileSelector(state)
    return {
      photo: profile.loaded && profile.photo
    }
  }),
  withRunSaga(),
  withRouter,
  withTranslate()
)(PhoneNumberVerification)
