export interface Subscription {
  id: string
  type: string
  name: string
  status: string
  createdAt: string
  nextAt?: string
  url: string
  validFrom?: string
  validTo?: string
  isicNumber?: string
  featureDisabled?: boolean
}

export interface SubscriptionsNamespaceState {
  existing: Subscription[]
  danskeDebitCard: {existingCustomer?: boolean}
}

export type NationalOrInternational = 'national' | 'international'

// Card types

export const GRADUATE_STUDENT_CARD = 'graduate_card'
export const POSTGRADUATE_STUDENT_CARD = 'postgraduate_card'
export const GRADUATE_ISIC_CARD = 'graduate_isic_card'
export const POSTGRADUATE_ISIC_CARD = 'postgraduate_isic_card'
export const DIGITAL_ISIC_CARD = 'digital_isic_card'
export const GRADUATE_DANSKEBANK_ISIC_CARD = 'graduate_danskebank_isic_card'
export const POSTGRADUATE_DANSKEBANK_ISIC_CARD =
  'postgraduate_danskebank_isic_card'
export const DANSKEBANK_GRADUATE_ISIC_CARD = 'danskebank_graduate_isic_card'
export const DANSKEBANK_POSTGRADUATE_ISIC_CARD =
  'danskebank_postgraduate_isic_card'
export const DANSKEBANK_GRADUATE_NATIONAL_CARD =
  'danskebank_graduate_national_card'

export type DebitCardProductSkus =
  | typeof DANSKEBANK_GRADUATE_NATIONAL_CARD
  | typeof DANSKEBANK_GRADUATE_ISIC_CARD
  | typeof DANSKEBANK_POSTGRADUATE_ISIC_CARD

export type IsicCardTypes =
  | typeof DIGITAL_ISIC_CARD
  | typeof GRADUATE_ISIC_CARD
  | typeof POSTGRADUATE_ISIC_CARD
  | typeof GRADUATE_DANSKEBANK_ISIC_CARD
  | typeof POSTGRADUATE_DANSKEBANK_ISIC_CARD

export type IsicProductSkus =
  | typeof DIGITAL_ISIC_CARD
  | typeof GRADUATE_ISIC_CARD
  | typeof POSTGRADUATE_ISIC_CARD
  | typeof GRADUATE_DANSKEBANK_ISIC_CARD
  | typeof POSTGRADUATE_DANSKEBANK_ISIC_CARD

export type StudentCardTypes =
  | typeof GRADUATE_STUDENT_CARD
  | typeof POSTGRADUATE_STUDENT_CARD
export type StudentProductSkus =
  | typeof GRADUATE_STUDENT_CARD
  | typeof POSTGRADUATE_STUDENT_CARD

export type ProductSkus =
  | DebitCardProductSkus
  | IsicProductSkus
  | StudentProductSkus
