import MediaQuery from 'react-responsive'
import * as React from 'react'
import {compose} from 'redux'
import {Link} from 'react-router-dom'

import BackButton from '$common/components/buttons/BackButton'
import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import css from './index.css'
import {resolveCss, withRouter, withTranslate} from '$common/utils'

const Header = ({pathBack, ...props}) => {
  const classes = resolveCss(css, props.css)

  return (
    <header {...classes('container')}>
      <div {...classes('left')}>
        <MediaQuery maxWidth={800}>
          {(isMobile) => {
            if (isMobile) {
              return (
                <Link to={pathBack}>
                  <BackButton />
                </Link>
              )
            } else {
              return <FrankLogoInSmallWhite />
            }
          }}
        </MediaQuery>
      </div>

      <div {...classes('middle')} />

      <div {...classes('right')}>
        <MediaQuery maxWidth={800}>
          <Link to="/login" className={css.loginLink}>
            {props.t('common.logIn')}
          </Link>
        </MediaQuery>
      </div>
    </header>
  )
}

Header.defaultProps = {pathBack: '/'}

export default compose(withRouter, withTranslate())(Header)
