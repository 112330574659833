/* global Branch */
import {isDeviceOnline} from '$common/utils'

type BranchData = {
  $canonical_url?: string
  '+non_branch_link'?: string
  '+clicked_branch_link'?: boolean
}

export default function initializeDeepLinks() {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  window.DeepLinkHandler = function() {}

  const app = {
    updateCount: 0,
    initialize: function() {
      app.bindEvents()
    },
    bindEvents: function() {
      document.addEventListener('deviceready', app.onDeviceReady, false)
      document.addEventListener('resume', app.onDeviceResume, false)
    },
    onDeviceReady: function() {
      app.branchInit()
      if (window.cordova && window.device.platform === 'Android') {
        window.plugins.intent.setNewIntentHandler(function(intent) {
          if (
            intent.data &&
            (intent.data.indexOf('frankapp://') === 0 ||
              intent.data.indexOf('frank://') === 0)
          ) {
            app.updateUrl({
              '+non_branch_link': intent.data
            })
          }
        })
      } else if (window.cordova && window.device.platform === 'iOS') {
        window.handleOpenURL = function(url) {
          app.updateUrl({
            '+non_branch_link': url
          })
        }
      }
    },
    onDeviceResume: function() {
      app.branchInit()
    },
    branchInit: function() {
      if (!isDeviceOnline()) {
        return
      }
      if (window.cordova && window.device.platform === 'Android') {
        Branch.setRequestMetadata('$braze_install_id', window.device.uuid)
      }
      Branch.initSession()
        .then(function(data) {
          app.updateUrl(data)
        })
        .catch((error) => {
          console.log('initializeDeepLinks.ts: ', error)
        })
    },
    updateUrl: function(data: BranchData) {
      const url = data['$canonical_url']
      if (data['+non_branch_link']) {
        const applink = data['+non_branch_link']
        const index = applink.indexOf('://') + 2
        window.location.replace(`index.html#${applink.substring(index)}`)
      } else if (data['+clicked_branch_link'] && url) {
        let index = 0
        if (url.indexOf('.com') >= 0) {
          index = url.indexOf('.com') + 4
        } else {
          index = url.indexOf('.fi') + 3
        }
        window.location.replace(`index.html#${url.substring(index)}`)
      }
    }
  }
  app.initialize()
}
