import React, {Component} from 'react'

import SelectBoxField from '$common/components/forms/SelectBoxField'

export default class EducationSelect extends Component {
  findSelectedEducationIndex = () => {
    const {
      school = {},
      organization = {},
      availableEducations = []
    } = this.props.profile
    if (school.id) {
      return availableEducations.findIndex(
        (education) => education.schoolId === school.id
      )
    } else {
      return availableEducations.findIndex(
        (education) => education.organizationId === organization.id
      )
    }
  }

  onEducationSelected = (index) => {
    if (index !== this.findSelectedEducationIndex()) {
      this.props.onEducationSelected(
        this.props.profile.availableEducations[index]
      )
    }
  }

  render() {
    const {profile, t} = this.props
    const {availableEducations = []} = profile

    const educationOptions = availableEducations.map((education, i) => ({
      value: i,
      label: education.schoolName || education.organizationName
    }))

    const selectedIndex = this.findSelectedEducationIndex()
    const selectedValue = educationOptions.find(
      (e) => e.value === selectedIndex
    )

    return (
      <SelectBoxField
        name="education"
        label={t('profile.selectSchool')}
        options={educationOptions}
        input={{
          onChange: this.onEducationSelected
        }}
        config={{
          isDisabled: this.props.isDisabled,
          isLoading: this.props.isLoading,
          value: selectedValue
        }}
      />
    )
  }
}
