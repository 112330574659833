import * as React from 'react'
import {Trans} from 'react-i18next'
import MediaQuery from 'react-responsive'
import {isEmpty} from 'lodash'
import Collapsible from 'react-collapsible'
import SvgIcon from '$common/components/icons/SvgIcon'
import BackButton from '$common/components/buttons/BackButton'
import ErrorCandy from '$common/components/candyBars/ErrorCandy'
import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import GenericButton from '$common/components/buttons/GenericButton'
import P from '$common/components/P'
import Layout from '$common/components/layouts/TopMiddleBottomLayout'
import grid from '$common/grid/grid.css'
import align from '$common/grid/align.css'
import {resolveCss} from '$common/utils'
import {
  logLinkClick,
  logEvent2,
  ANALYTICS_PROVIDER,
  ANALYTICS_EVENT
} from '$src/common/utils/logUtils'
import TextInput from '$common/components/forms/TextInput'
import {startOpintopolkuTrial} from '$src/profile/sagas/opintopolkuTrial'
import LoadingSpinnerModal from '$common/components/LoadingSpinnerModal'
import FaqLink from '$common/components/FaqLink/FaqLink'
import checkIcon from '$assets/icons/checkSingleGreen.svg'

import sharedCss from './../verification/JollaFormPage/JollaFormPage.css'
import css from './StartTrialPage.css'

export default class StartTrialPage extends React.Component {
  submitButtonRef = React.createRef()

  componentDidMount() {
    logEvent2(ANALYTICS_EVENT.START_TRIAL_PAGE_OPEN, [
      ANALYTICS_PROVIDER.AMPLITUDE,
      ANALYTICS_PROVIDER.BRANCH,
      ANALYTICS_PROVIDER.BRAZE
    ])
    const {degree} = this.props.fieldValues
    if (!degree) {
      // If user refreshes page, we may lose the values provided in previous form.
      // In that case, we need to redirect user to the school selection form.
      this.props.history.replace('/profile/trial/start')
    }
  }

  componentDidUpdate(prevProps) {
    // Detect if opintopolku url changed to valid state. Then automatically
    // switch focus to submit button. Timeouts are needed to give input element
    // chance to get the actual value and to overcome weird iOS safari scrolling problem.
    if (this.props.isValidOpintopolkuUrl && !prevProps.isValidOpintopolkuUrl) {
      setTimeout(() => {
        this.submitButtonRef.current.focus()
        setTimeout(() => window.scrollTo(0, document.body.scrollHeight))
      })
    }
  }

  submit = async (values) => {
    if (!values.opintopolkuUrl) return null
    try {
      logEvent2(ANALYTICS_EVENT.START_TRIAL_INITIATED, [
        ANALYTICS_PROVIDER.AMPLITUDE,
        ANALYTICS_PROVIDER.BRANCH,
        ANALYTICS_PROVIDER.BRAZE
      ])

      await this.props.runSaga(startOpintopolkuTrial, {
        url: values.opintopolkuUrl
      })
    } catch (e) {
      logEvent2(ANALYTICS_EVENT.START_TRIAL_FAIL, [
        ANALYTICS_PROVIDER.AMPLITUDE,
        ANALYTICS_PROVIDER.BRANCH,
        ANALYTICS_PROVIDER.BRAZE
      ])
      return this.props.history.replace(
        `/profile/trial/fail/${e.response.status}`
      )
    }

    logEvent2(ANALYTICS_EVENT.START_TRIAL_SUCCESS, [
      ANALYTICS_PROVIDER.AMPLITUDE,
      ANALYTICS_PROVIDER.BRANCH,
      ANALYTICS_PROVIDER.BRAZE
    ])

    this.props.history.replace('/profile/trial/success')
  }

  onBackButtonClick = (e) => {
    e.preventDefault()
    this.props.history.goBack()
  }

  onOpintopolkuInstructionLinkClick = (e) => {
    logLinkClick(e, 'start-trial: Opintopolku instructions link clicked ')
  }

  onOpintopolkuQuickInstructionLinkClick = (e) => {
    logLinkClick(e, 'start-trial: Opintopolku quick instructions link clicked')
  }

  onOpintopolkuLoginLinkClick = (e) => {
    logLinkClick(e, 'start-trial: Opintopolku login link clicked')
  }

  onFaqLinkClick = (e) => {
    logLinkClick(e, 'start-trial: FAQ link clicked')
  }

  render() {
    const classes = resolveCss(grid, align)
    const continueButton = (
      <GenericButton
        ref={this.submitButtonRef}
        style="bold"
        loading={this.props.submitting}
      >
        {this.props.t('common.continue')}
      </GenericButton>
    )

    const backButton = (
      <GenericButton style="outline" onClick={this.onBackButtonClick}>
        {this.props.t('common.back')}
      </GenericButton>
    )

    const body = (
      <P css={{container: sharedCss.p}} style="small">
        <Trans i18nKey="profile.verification.opintopolku.body">
          [0: text]
          <a
            href={this.props.t('profile.verification.opintopolku.link')}
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.onOpintopolkuInstructionLinkClick}
          >
            [1: linkText]
          </a>
          [2: text]
        </Trans>
      </P>
    )

    const quickInstructions = (
      <Collapsible
        trigger={this.props.t(
          'profile.verification.opintopolku.quickInstructionsTitle'
        )}
        accordionPosition="right"
        className={css.collapsibleContainer}
        openedClassName={css.collapsibleContainer}
        triggerClassName={css.collapsibleHeader}
        triggerOpenedClassName={css.collapsibleHeaderOpen}
        contentInnerClassName={css.collapsibleContent}
      >
        <P css={{container: sharedCss.p}} style="small">
          <Trans i18nKey="profile.verification.opintopolku.quickInstructionsBody">
            [0: text]
            <a
              href={this.props.t(
                'profile.verification.opintopolku.quickInstructionsLink'
              )}
              target="_blank"
              rel="noopener noreferrer"
              onClick={this.onOpintopolkuQuickInstructionLinkClick}
            >
              [1: linkText]
            </a>
            [2: text]
          </Trans>
        </P>
      </Collapsible>
    )

    const opintopolkulink = (
      <P css={{container: sharedCss.p}} style="small">
        <Trans i18nKey="profile.verification.opintopolku.opintopolkuLoginLinkBody">
          [0: text]
          <a
            href={this.props.t(
              'profile.verification.opintopolku.opintopolkuLoginLink'
            )}
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.onOpintopolkuLoginLinkClick}
          >
            [1: linkText]
          </a>
          [2: text]
        </Trans>
      </P>
    )

    const top = (
      <MediaQuery maxWidth={800}>
        {(isMobile) => {
          if (isMobile)
            return (
              <BackButton
                onClick={
                  this.props.trialPath
                    ? () => this.props.history.replace('/home')
                    : 'goBack'
                }
              />
            )
          else return <FrankLogoInSmallWhite />
        }}
      </MediaQuery>
    )

    const middle = (
      <div {...classes('columnLarge5', 'center')}>
        {body}
        {quickInstructions}
        {opintopolkulink}
        <TextInput
          name="opintopolkuUrl"
          placeholder={this.props.t(
            'profile.verification.opintopolku.urlPlaceholder'
          )}
          label={this.props.t('profile.verification.opintopolku.urlLabel')}
        />
        {this.props.isValidOpintopolkuUrl && (
          <div className={css.urlOk}>
            <SvgIcon css={{container: css.checkIcon}} src={checkIcon} />
            {this.props.t('profile.verification.opintopolku.urlOk')}
          </div>
        )}
        <FaqLink
          href="profile.verification.opintopolku.faqHref"
          text="profile.verification.opintopolku.faqLinkText"
          onClick={this.onFaqLinkClick}
        />
        {this.props.submitting && (
          <LoadingSpinnerModal
            message={this.props.t('profile.verification.submitting')}
          />
        )}
        <ErrorCandy
          show={!isEmpty(this.props.submitErrors) && !isEmpty(this.props.error)}
          onHide={this.props.clearSubmitErrors}
        >
          {this.props.error}
        </ErrorCandy>
      </div>
    )

    const bottom = (
      <div {...classes('columnLarge5', 'center')}>
        <MediaQuery maxWidth={800}>
          {(isMobile) => {
            if (isMobile) {
              return continueButton
            } else {
              return (
                <div className={grid.row}>
                  <div {...classes('column', 'right')}>
                    {backButton}
                    &nbsp;
                    {continueButton}
                  </div>
                </div>
              )
            }
          }}
        </MediaQuery>
      </div>
    )

    return (
      <form onSubmit={this.props.handleSubmit(this.submit)}>
        <Layout
          adaptiveLayout={{
            stackMiddle: true
          }}
          css={{
            container: grid.container
          }}
          top={top}
          middle={middle}
          bottom={bottom}
        />
      </form>
    )
  }
}
