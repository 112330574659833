import {select, put} from 'redux-saga/effects'

import {callEndpoint} from '$src/api/sagas/callEndpoint'
import {getPreferredLang} from '$src/i18n/selectors'
import fetch from '$src/api/endpoints/news/fetch'
import {Lang} from '$src/i18n/types'
import {PaginatedResponseType} from '$src/pagination/types'
import {NewsItem, SetNewsAction} from '$src/news/types'

export default function* fetchNews() {
  const languageCode: Lang = yield select(getPreferredLang)
  const fetchParams = {page: 1, size: 100, languageCode, type: 'news'}

  const response: PaginatedResponseType<NewsItem> = yield callEndpoint('news')(
    fetch,
    fetchParams
  )

  const news = response.data
  const action: SetNewsAction = {
    type: 'SET_NEWS',
    news,
    language: languageCode
  }
  yield put(action)
}
