import {Offer} from '$src/offers/types'

export type Params = {
  languageCode: 'en' | 'sv' | 'fi'
  key: string
}

export default function getUniqueCode(
  {get}: any,
  {languageCode, key}: Params
): Promise<{data: Offer}> {
  return get(`/offers/${key}/code`, {languageCode}, {numberOfRetries: 4})
}
