import MediaQuery from 'react-responsive'
import * as React from 'react'
import {Trans} from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import {SubmissionError} from 'redux-form'

import BackButton from '$common/components/buttons/BackButton'
import ErrorCandy from '$common/components/candyBars/ErrorCandy'
import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import LoadingSpinnerModal from '$common/components/LoadingSpinnerModal'
import GenericButton from '$common/components/buttons/GenericButton'
import Layout from '$common/components/layouts/TopMiddleBottomLayout'
import P from '$common/components/P'
import TextInput from '$common/components/forms/TextInput'
import align from '$common/grid/align.css'
import css from './JollaFormPage.css'
import grid from '$common/grid/grid.css'
import verifyJolla from '$src/profile/sagas/verifyJolla'
import {createApiErrorMessage, resolveCss, trimmedValues} from '$common/utils'
import {
  logLinkClick,
  logEvent2,
  ANALYTICS_EVENT,
  ANALYTICS_PROVIDER
} from '$src/common/utils/logUtils'
import FaqLink from '$common/components/FaqLink/FaqLink'
import {getSchools} from '$src/profile/sagas/getSchools'
import SelectBoxField from '$common/components/forms/SelectBoxField'

import {SECOND_DEGREE} from '$src/profile/components/verification/StartFormPage/StartFormPage'

export default class JollaFormPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ssnFormOpen: true,
      studentIdFormOpen: false
    }
  }

  componentDidMount = async () => {
    logEvent2(ANALYTICS_EVENT.JOLLA_VERIFICATION_PAGE_OPEN, [
      ANALYTICS_PROVIDER.AMPLITUDE,
      ANALYTICS_PROVIDER.BRAZE,
      ANALYTICS_PROVIDER.BRANCH,
      ANALYTICS_PROVIDER.FIREBASE
    ])

    if (!this.props.schools) {
      await this.props.runSaga(getSchools)
    }
    const {degree} = this.props.fieldValues

    if (!degree) {
      // If user refreshes page, we may lose the values provided in previous form.
      // In that case, we need to redirect user to the school selection form.
      this.props.history.replace('/profile/verify/start-form')
    }
  }

  submit = async (values) => {
    // Let's try always to register first through Jolla.

    logEvent2(ANALYTICS_EVENT.JOLLA_VERIFICATION_INITIATED, [
      ANALYTICS_PROVIDER.AMPLITUDE,
      ANALYTICS_PROVIDER.BRAZE,
      ANALYTICS_PROVIDER.BRANCH,
      ANALYTICS_PROVIDER.FIREBASE
    ])

    try {
      const verifyParams = trimmedValues(values)

      if (verifyParams.ssn) {
        verifyParams.ssn = verifyParams.ssn.toUpperCase()
      }

      await this.props.runSaga(verifyJolla, verifyParams)

      logEvent2(ANALYTICS_EVENT.JOLLA_VERIFICATION_SUCCESS, [
        ANALYTICS_PROVIDER.AMPLITUDE,
        ANALYTICS_PROVIDER.BRAZE,
        ANALYTICS_PROVIDER.BRANCH,
        ANALYTICS_PROVIDER.FIREBASE
      ])

      this.props.history.replace('/profile/verify/after-success')
    } catch (e) {
      logEvent2(ANALYTICS_EVENT.JOLLA_VERIFICATION_FAIL, [
        ANALYTICS_PROVIDER.AMPLITUDE,
        ANALYTICS_PROVIDER.BRAZE,
        ANALYTICS_PROVIDER.BRANCH,
        ANALYTICS_PROVIDER.FIREBASE
      ])

      if (this.props.isKoskiEnabled) {
        this.props.history.replace('/profile/verify/koski/form')
      } else {
        const errMsg = createApiErrorMessage(
          e,
          'error.profile.verification.jolla'
        )
        throw new SubmissionError({
          _error: this.props.t(errMsg),
          general: true
        })
      }
    }
  }

  handleSecondaryButtonClick = (e) => {
    e.preventDefault()
    this.props.history.goBack()
  }

  onFaqLinkClick = (e) => {
    logLinkClick(e, 'FAQ link from jolla verification')
  }

  getSecondDegreeOptions = () => {
    if (!this.props.schools) {
      return []
    }
    return this.props.schools[SECOND_DEGREE].map((school) => {
      return {value: school.jollaId, label: school.name}
    })
  }

  toggleVerificationType = () => {
    this.props.change('ssn', '')
    this.props.change('studentId', '')
    this.props.change('lastName', '')
    this.setState((state) => ({
      ssnFormOpen: !state.ssnFormOpen,
      studentIdFormOpen: !state.studentIdFormOpen
    }))
  }

  render() {
    const classes = resolveCss(css, grid, align)

    const ctaButton = (
      <GenericButton
        style="bold"
        loading={this.props.submitting}
        disabled={this.props.invalid}
      >
        {this.props.t('profile.verification.jolla.cta')}
      </GenericButton>
    )

    const secondaryCtaButton = (
      <GenericButton style="outline" onClick={this.handleSecondaryButtonClick}>
        {this.props.t('authentication.register.secondaryCta')}
      </GenericButton>
    )

    const secondDegreeDropdown = (
      <SelectBoxField
        key="jollaIdKey"
        name="jollaId"
        label={this.props.t(
          'profile.verification.jolla.secondDegreeDropdownLabel'
        )}
        options={this.getSecondDegreeOptions()}
      />
    )

    const ssnForm = (
      <div>
        <P css={{container: css.p}} style="small">
          {this.props.t('profile.verification.jolla.viaSsn')}
        </P>

        <div className={css.snnInput}>
          <TextInput
            key="ssnKey"
            name="ssn"
            placeholder={this.props.t(
              'profile.verification.jolla.ssnPlaceholder'
            )}
            label={this.props.t('profile.verification.jolla.ssn')}
          />
        </div>

        <P css={{container: css.p}} style="small">
          <Trans i18nKey="profile.verification.jolla.changeToViaOrganization">
            [0: text]
            <span className={css.link} onClick={this.toggleVerificationType}>
              [1: link text]
            </span>
            [2: text]
          </Trans>
        </P>
      </div>
    )

    const studentIdForm = (
      <div>
        <P css={{container: css.p}} style="small">
          {this.props.t('profile.verification.jolla.viaOrganization')}
        </P>
        {this.props.fieldValues.degree === SECOND_DEGREE &&
          secondDegreeDropdown}
        <TextInput
          key="lastNameKey"
          name="lastName"
          placeholder={this.props.t(
            'profile.verification.jolla.lastNamePlaceholder'
          )}
          label={this.props.t('profile.verification.jolla.lastName')}
        />
        <TextInput
          key="studentIdKey"
          name="studentId"
          placeholder={this.props.t(
            'profile.verification.jolla.studentIdPlaceholder'
          )}
          label={this.props.t('profile.verification.jolla.studentId')}
        />
        <P css={{container: css.p}} style="small">
          <Trans i18nKey="profile.verification.jolla.changeToViaSsn">
            [0: text]
            <span className={css.link} onClick={this.toggleVerificationType}>
              [1: link text]
            </span>
            [2: text]
          </Trans>
        </P>
      </div>
    )

    return (
      <form onSubmit={this.props.handleSubmit(this.submit)}>
        <Layout
          adaptiveLayout={{
            stackMiddle: true
          }}
          css={{
            container: grid.container,
            bottom: css.bottom
          }}
          top={
            <MediaQuery maxWidth={800}>
              {(isMobile) => {
                if (isMobile) return <BackButton onClick="goBack" />
                else return <FrankLogoInSmallWhite />
              }}
            </MediaQuery>
          }
          middle={
            <div {...classes('columnLarge5', 'center')}>
              {this.state.ssnFormOpen && ssnForm}
              {this.state.studentIdFormOpen && studentIdForm}

              <FaqLink
                href="profile.verification.jolla.faqHref"
                text="profile.verification.jolla.faqLinkText"
                onClick={this.onFaqLinkClick}
              />

              {this.props.submitting && (
                <LoadingSpinnerModal
                  message={this.props.t('profile.verification.submitting')}
                />
              )}
              <ErrorCandy
                show={
                  !isEmpty(this.props.submitErrors) &&
                  !isEmpty(this.props.error)
                }
                onHide={this.props.clearSubmitErrors}
              >
                {this.props.error}
              </ErrorCandy>
            </div>
          }
          bottom={
            <div {...classes('columnLarge5', 'center')}>
              <MediaQuery maxWidth={800}>
                {(isMobile) => {
                  if (isMobile) {
                    return ctaButton
                  } else {
                    return (
                      <div className={grid.row}>
                        <div {...classes('column', 'right')}>
                          {secondaryCtaButton}
                          &nbsp;
                          {ctaButton}
                        </div>
                      </div>
                    )
                  }
                }}
              </MediaQuery>
            </div>
          }
        />
      </form>
    )
  }
}
