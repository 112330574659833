/* eslint-disable react/jsx-key */
import * as React from 'react'
import {Route} from 'react-router-dom'

import PlainCardOrderPage from '$src/subscriptions/components/PlainCardOrderPage'
import DigiIsicCardOrderPage from '$src/subscriptions/components/DigiIsicCardOrderPage'
import DenyPlain from '$src/subscriptions/components/PlainCardOrderPage/Deny'
import {userHasPhoto} from '$src/auth/components/userHasPhoto'
import userVerified from '$src/auth/components/userVerified'
import userHasStudentType from '$src/auth/components/userHasStudentType'

const AuthWrapper = (noPhotoUrl, denyUrl) => (
  Component
): React.ComponentType<any> =>
  userHasPhoto(noPhotoUrl)(
    userHasStudentType(['graduate', 'postgraduate'], denyUrl)(Component)
  )

const DenyWrapper = (Component): React.ComponentType<any> =>
  userVerified(userHasStudentType('upper_secondary', '/home')(Component))

const DigiIsicAuthWrapper = (noPhotoUrl) => (Component) => {
  return userHasPhoto(noPhotoUrl)(Component)
}

const plainRoot = '/plain-card'
const digiIsicRoot = '/digi-isic'

export default [
  <Route path={`${plainRoot}/deny`} exact component={DenyWrapper(DenyPlain)} />,
  <Route
    path={plainRoot}
    component={AuthWrapper(
      `${plainRoot}/photo`,
      `${plainRoot}/deny`
    )(PlainCardOrderPage)}
  />,
  <Route
    path={digiIsicRoot}
    component={DigiIsicAuthWrapper(`${digiIsicRoot}/photo`)(
      DigiIsicCardOrderPage
    )}
  />
]
