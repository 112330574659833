import {compose} from 'redux'
import {
  asForm,
  withRunSaga,
  withTranslate,
  withRouter,
  connect
} from '$common/utils'
import StartFormPage, {SECOND_DEGREE} from './StartFormPage'
import {getSchools, shouldDisplayTrialStartButton} from '$src/profile/selectors'

/** @param {import('./StartFormPage').StartFormPageValues} values */
const validate = (values) => {
  if (values.degree === SECOND_DEGREE) {
    return {degree: false}
  }
  if (values.schoolId || values.jollaId) {
    return {
      degree: !values.degree
    }
  }

  return {degree: true}
}

const mapStateToProps = (state) => {
  return {
    schools: getSchools(state),
    displayTrialStartButton: shouldDisplayTrialStartButton(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withTranslate(),
  withRunSaga(),
  asForm('auth', {validate, destroyOnUnmount: false}),
  withRouter
)(StartFormPage)
