import * as React from 'react'
import {useEffect} from 'react'
import {compose} from 'redux'
import {Link} from 'react-router-dom'

import ConversationLayout from '$common/components/layouts/ConversationLayout'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import css from '$src/subscriptions/components/styles.css'
import {withRouter, withTranslate, connect} from '$common/utils'
import HeaderTopFill from '$src/common/components/Header/HeaderTopFill'
import {HeaderTopFillStyles} from '$src/subscriptions/components/ProfileSummary'
import {getAppboy} from '$common/pgWebSeparatorUtils'
import {
  logEvent2,
  ANALYTICS_EVENT,
  ANALYTICS_PROVIDER
} from '$src/common/utils/logUtils'

const OrderCompleted = (props) => {
  useEffect(() => {
    logEvent2(ANALYTICS_EVENT.ISIC_PURCHASED, [ANALYTICS_PROVIDER.BRAZE], {
      date: new Date().toISOString()
    })
  })

  const onClickIsicLink = () => {
    const url = process.env.ISIC_DEEP_LINK
    logEvent2(ANALYTICS_EVENT.ISIC_ACTIVATED, [ANALYTICS_PROVIDER.BRAZE], {
      date: new Date().toISOString()
    })
    getAppboy().requestImmediateDataFlush() // Flush before navigating outside the application
    if (window.cordova) {
      window.open(url, '_system')
    } else {
      window.open(url, '_blank', 'noopener,noreferrer')
    }
  }

  const conversationLayoutProps = {
    content: (
      <div className={css.content}>
        <H1 css={{container: css.heading}}>
          {props.t(
            'subscriptions.digiIsicPurchase.orderCompleted.title',
            props.pricing
          )}
        </H1>
        <P css={{container: css.paragraph}}>
          {props.t(
            'subscriptions.digiIsicPurchase.orderCompleted.body',
            props.pricing
          )}
        </P>
        <P css={{container: css.paragraph}} bolder={true}>
          {props.t(
            'subscriptions.plainCardPurchase.international.orderAlreadyInProgress.isic_activation_reminder'
          )}
        </P>
      </div>
    ),
    buttons: (
      <div className={css.singleButton}>
        <GenericButton
          externalLink
          css={{container: css.button}}
          isFullWidth
          style="bold"
          onClick={onClickIsicLink}
          isExpanding={true}
        >
          {props.t('common.download')}
        </GenericButton>

        <Link to="/offer/offer_2585">
          <GenericButton
            css={{container: css.button}}
            style="bold"
            isFullWidth
            isExpanding={true}
          >
            {props.t(
              'subscriptions.plainCardPurchase.international.orderAlreadyInProgress.isic_activation_button'
            )}
          </GenericButton>
        </Link>
        <Link to="/">
          <GenericButton
            css={{container: css.button}}
            style="bold"
            isFullWidth
            isExpanding={true}
          >
            {props.t('subscriptions.digiIsicPurchase.orderCompleted.cta')}
          </GenericButton>
        </Link>
      </div>
    ),
    hideBackButton: true,
    dialogContainerStyle: 'black'
  }

  return (
    <div>
      <HeaderTopFill
        background={HeaderTopFillStyles['black']}
        position={'fixed'}
      />
      <ConversationLayout {...conversationLayoutProps} />
    </div>
  )
}

export default compose(
  connect((state) => ({pricing: state.pricing})),
  withRouter,
  withTranslate()
)(OrderCompleted)
