import {Offer} from '$src/offers/types'

export type Params = {
  languageCode: 'en' | 'sv' | 'fi'
}

export default function getRandomOffer(
  {get}: any,
  {languageCode}: Params
): Promise<{data: Offer}> {
  return get(
    `/offers/random`, 
    {languageCode: encodeURIComponent(languageCode)}, 
    {numberOfRetries: 4}
  )
}