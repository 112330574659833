/* eslint-disable react/jsx-key */
import * as React from 'react'
import {Route} from 'react-router-dom'
import OfferPage from '$src/offers/components/OfferPage'
import VoucherPage from '$src/offers/components/VoucherPage/VoucherPage'
import ListPage from '$src/offers/components/ListPage/ListPage-container'
import FavouritesList from '$src/offers/components/Favourites/List'

export default [
  <Route path="/offers" exact component={ListPage} />,
  <Route path="/offer/:key" component={OfferPage} />,
  <Route path="/voucher/:key" exact component={VoucherPage} />,
  <Route path="/favourites" component={FavouritesList} />
]
