import {compose} from 'redux'
import {connect} from 'react-redux'
import {withRunSaga, withRouter, withTranslate} from '$common/utils'
import {selectAsyncErrors, isFetching} from '$src/fetching/selectors'
import {isUserVerified} from '$src/profile/selectors'
import {isLoggedIn} from '$src/auth/selectors'
import Main from '$src/bootstrap/components/Main'
import {remove404ErrorFromStore} from '$src/error404/actions'

const mapStateToProps = (state) => {
  return {
    asyncErrors: selectAsyncErrors(state),
    loggedIn: isLoggedIn(state),
    isUserVerified: isUserVerified(state),
    isFetchingProfile: isFetching(state, 'getProfile'),
    isFetchingSubscriptions: isFetching(state, 'subscriptions'),
    isFetchingPricing: isFetching(state, 'getPricing'),
    isFetchingFavourites: isFetching(state, 'getFavourites'),
    error404: state.error404.error404
  }
}

const mapDispatchToProps = {
  remove404ErrorFromStore
}

export default compose(
  withRunSaga(),
  withTranslate(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Main)
