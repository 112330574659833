import MediaQuery from 'react-responsive'
import * as React from 'react'
import {compose} from 'redux'
import {Link} from 'react-router-dom'

import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import TextInput from '$common/components/forms/TextInput'
import TopMiddleBottomLayout from '$common/components/layouts/TopMiddleBottomLayout'
import align from '$common/grid/align.css'
import css from './StartTrialPage.css'
import grid from '$common/grid/grid.css'
import {asForm, withRunSaga, withTranslate, withRouter, resolveCss, createApiErrorMessage} from '$common/utils'

import {trimmedValues} from '$common/utils'
import FinnishSSN from 'finnish-ssn'
import {  activateMembership } from '$src/profile/sagas/opintopolkuTrial'
import {ApiErrorCode} from '$src/api/error-codes'
import {logEvent, logLinkClick, logCustomEventToFirebase, events as analyticsEvents} from '$src/common/utils/logUtils'
import {SubmissionError} from 'redux-form'
import ErrorCandy from '$common/components/candyBars/ErrorCandy'
import {isEmpty} from 'lodash'




class ActivateMembershipPage extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      ssnVal: null
    }
  }

  ssnOnChange = (e, ssnVal) => {
    this.setState({ssnVal})
  }

  submit = async (values) => {
    const trimmed = trimmedValues(values)
    if (trimmed.ssn) {
      trimmed.ssn = trimmed.ssn.toUpperCase()
    }

    try {
      await this.props.runSaga(
        activateMembership, 
        {
          ssn: trimmed.ssn
        }
      )
    }
    catch(e) {
      if (e.errorCode === ApiErrorCode.TRIAL_ACTIVATE_MEMBERSHIP_FAIL) {
        logEvent(analyticsEvents.TRIAL_ACTIVATE_MEMBERSHIP_FAIL)
      }

      const errMsg = createApiErrorMessage(
        e,
        'error.profile.trial.activateMembershipFailed'
      )

      throw new SubmissionError({
        _error: this.props.t(errMsg),
        general: true
      })
    }

    this.props.history.replace('/profile/trial/status')





  }

  render() {
    const classes = resolveCss(css, grid, align)

    return (
      <form onSubmit={this.props.handleSubmit(this.submit)}>

        <TopMiddleBottomLayout
          adaptiveLayout={{
            stackMiddle: true
          }}
          css={{
            container: grid.container,
            bottom: css.bottom
          }}
          top={
            <MediaQuery minWidth={801}>
              <FrankLogoInSmallWhite />
            </MediaQuery>
          }
          middle={
            <div {...classes('columnLarge5', 'center')}>
              <H1 css={{container: align.largeCenterText}}>
                {this.props.t('profile.trial.activatemembership.title')}
              </H1>
              <P
                style="small"
                css={{container: [css.message, align.largeCenterText]}}
              >
                {this.props.t('profile.trial.activatemembership.body')}
              </P>

              <div className={css.snnInput}>
            <TextInput
              key="ssnKey"
              name="ssn"
              placeholder={this.props.t(
                'profile.verification.jolla.ssnPlaceholder'
              )}
              label={this.props.t('profile.verification.jolla.ssn')}
              onChange={this.ssnOnChange}
            />

            <GenericButton
              style="bold"
              css={{container: css.button}}
              isExpanding={true}
            >
              {this.props.t('profile.trial.activatemembership.button')}
            </GenericButton>
          </div>

            </div>
          }
          bottom={
            <div>
              <Link to="/profile/settings" {...classes('column', 'center')}>
              <GenericButton
                style="outline"
                css={{container: css.button}}
                isExpanding={true}
              >
                {this.props.t('profile.trial.activatemembership.backButton')}
              </GenericButton>
            </Link>
            <ErrorCandy
              show={!isEmpty(this.props.submitErrors) && !isEmpty(this.props.error)}
              onHide={this.props.clearSubmitErrors}
            >
              {this.props.error}
            </ErrorCandy>
          </div>
          }
        />
      </form>
    )
  }
}

const validate = (values) => {
  const {ssn} = trimmedValues(values)

  if (ssn) {
    const validSsn = FinnishSSN.validate(ssn.toUpperCase())
    return {
      ssn: !validSsn && 'profile.verification.jolla.invalidSsn'
    }
  }
}

export default compose(
  asForm('auth', {validate, destroyOnUnmount: false}), //validates and paints ivalid form input onChange
  withTranslate(),
  withRunSaga()
  )(ActivateMembershipPage)
