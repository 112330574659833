import * as React from 'react'
import {compose} from 'redux'
import css from './TextInput.css'
import {resolveCss, asField, withTranslate} from '$common/utils'
import {get} from 'lodash'

type FieldProps = {
  input: {
    value: any
    onChange: Function
  }
  meta: {
    active: boolean
    invalid?: boolean
    autoFocus: boolean
    searchBar: boolean
  }
  setShowSearchTermList: Function
  setShowFilterDropDown: Function
  onClick: Function
}

type CommonProps = {
  css?: any
  label?: React.Node
  disabled?: boolean
  placeholder?: string
  addonAfter?: React.Node
  inputRef?: Function
  style?: 'box'
  type?: 'number'
  topRowExtraContent?: React.Node
  autocomplete?: string
}

type Props = CommonProps & FieldProps

export class PlainTextInput extends React.Component<Props> {
  handleOnClick = () => {
    if (typeof this.props.setShowSearchTermList === 'function') {
      this.props.setShowSearchTermList(true)
    }
    if (typeof this.props.setShowFilterDropDown === 'function') {
      this.props.setShowFilterDropDown(false)
    }
  }

  render() {
    const classes = resolveCss(css, this.props.css)
    const {
      active,
      invalid,
      error,
      touched,
      autoFocus = true,
      searchBar = false
    } = this.props.meta
    const {value, ...props} = {...this.props.input}
    const onChange = (e) => {
      const onChangeInput = get(this.props, 'input.onChange')
      onChangeInput(e)
      if (typeof this.props.setShowSearchTermList === 'function') {
        this.props.setShowSearchTermList(false)
      }
    }

    return (
      <div {...classes('container', this.props.style)}>
        {this.props.label && (
          <div {...classes('topRow')}>
            <label {...classes('label')}>{this.props.label}</label>
            &nbsp;
            {this.props.topRowExtraContent}
          </div>
        )}
        <div
          {...classes(
            'inputWrapper',
            active && 'inFocus',
            searchBar ? 'search' : ''
          )}
        >
          <input
            {...props}
            {...{value}}
            {...classes('input')}
            ref={this.props.inputRef}
            disabled={this.props.disabled}
            placeholder={this.props.placeholder}
            type={this.props.type || 'text'}
            onChange={onChange}
            autoComplete={this.props.autocomplete}
            autoFocus={autoFocus}
            onClick={this.handleOnClick}
          />
          {this.props.addonAfter && (
            <div
              {...classes(
                'addonAfter',
                invalid && 'invalid',
                active && 'addonActive'
              )}
            >
              {this.props.addonAfter}
            </div>
          )}
        </div>
        {touched && !active && typeof error === 'string' && (
          <div {...classes('errorMessage')}>{this.props.t(error)}</div>
        )}
      </div>
    )
  }
}

export default compose(asField(), withTranslate())(PlainTextInput)
