import * as React from 'react'
import {compose} from 'redux'
import {connect} from '$common/utils'
import {getIsBootstrapped} from '$common/selectors'
import SplashScreen from '$common/components/SplashScreen'

import {StoreState} from '$src/types'

type Props = {
  isBootstrapped: boolean
  children?: any
}

const SplashUnlessBootstrapped = (props: Props) => {
  return !props.isBootstrapped ? <SplashScreen /> : props.children
}

/**
 * Shows a splash screen, unless we're bootstrapped (see bootstrapped in $common/reducer)
 */
export default compose(
  connect((state: StoreState) => ({
    isBootstrapped: getIsBootstrapped(state)
  }))
)(SplashUnlessBootstrapped)
