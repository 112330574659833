import {compose} from 'redux'
import {connect} from 'react-redux'
import {getProfile as selectProfile} from '$src/profile/selectors'
import {isFetching} from '$src/fetching/selectors'
import {withRouter, withTranslate, withRunSaga} from '$common/utils'
import ProfileSummary from '$src/subscriptions/components/ProfileSummary'

const mapStateToProps = (state) => {
  return {
    fetching: isFetching(state),
    profile: selectProfile(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withRouter,
  withTranslate(),
  withRunSaga()
)(ProfileSummary)
