import * as React from 'react'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'
import {Link} from 'react-router-dom'

import {resolveCss, WithTranslateProps} from '$common/utils'

import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import carouselCss from '$src/articles/components/Carousel/Carousel.css'
import css from './NewsCard.css'

import closeIcon from '$assets/icons/close.svg'
import SvgIcon from '$common/components/icons/SvgIcon'
import {withTranslate} from '$common/utils'
import {NewsItem} from '$src/news/types'
import SlideLoading from '$common/components/loadings/CarouselSlideLoading'

interface Props extends WithTranslateProps {
  item: NewsCardItem
  handleClose?: () => void
  nextSlide?: () => void
  prevSlide?: () => void
}

interface Placeholder {
  placeholder: true
}

export type NewsCardItem = NewsItem | Placeholder

function isPlaceholder(item: NewsCardItem): item is Placeholder {
  return (item as Placeholder).placeholder
}

const NewsCard: React.FC<Props> = ({
  item,
  nextSlide,
  prevSlide,
  handleClose,
  t
}) => {
  const classes = resolveCss(css)

  return isPlaceholder(item) ? (
    <SlideLoading
      overlayColor={!handleClose && 'blue'}
      css={{
        container: [css.placeholder, handleClose && css.mobile],
        animatedBarContainer: carouselCss.cell
      }}
    />
  ) : (
    <div {...classes('container', handleClose && 'mobile')}>
      <div {...classes('headingContainer')}>
        <H1 css={{container: css.heading}}>
          {item.title || t('home.news.newscard_desktop_placeholder_title_text')}
        </H1>
        {handleClose && (
          <SvgIcon
            src={closeIcon}
            css={{container: css.closeIcon}}
            onClick={handleClose}
          />
        )}
      </div>
      <P css={{container: [css.timestamp, !handleClose && css.withUnderline]}}>
        {moment(item.publishedAt).format('DD.MM.YYYY')}
      </P>
      <ReactMarkdown
        renderers={{link: LinkRenderer}}
        source={
          item.body || t('home.news.newscard_desktop_placeholder_body_text')
        }
        className={css.content}
      />
      <div {...classes('slideChangerContainer')}>
        {prevSlide !== undefined && (
          <P
            onClick={prevSlide}
            style="small"
            bold
            css={{
              container: [
                css.slideChanger,
                css.prevLink,
                prevSlide && css.active
              ]
            }}
          >
            {t('common.previous')}
          </P>
        )}
        {nextSlide !== undefined && (
          <P
            onClick={nextSlide}
            style="small"
            bold
            css={{
              container: [
                css.slideChanger,
                css.nextLink,
                nextSlide && css.active
              ]
            }}
          >
            {t('common.next')}
          </P>
        )}
      </div>
    </div>
  )
}

const LinkRenderer = (props) => {
  const isExternalLink =
    props.href.startsWith('https://') || props.href.startsWith('http://')
  return isExternalLink ? (
    <a href={props.href} rel="noopener noreferrer" target="_blank">
      {props.children}
    </a>
  ) : (
    <Link to={props.href}>{props.children}</Link>
  )
}

export default withTranslate()(NewsCard)
