import {connect} from 'react-redux'
import {compose} from 'redux'
import {
  getProfile,
  isUserActive,
  isUserFhsStudent
} from '$src/profile/selectors'
import {isFetching} from '$src/fetching/selectors'
import {withTranslate, withRouter, withRunSaga} from '$common/utils/index'
import StudentCard from './StudentCard'
import {hasActiveDigitalSubscription} from '$src/subscriptions/selectors'

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state),
    subscriptions: state.subscriptions,
    isUserActive: isUserActive(state),
    isUserFhsStudent: isUserFhsStudent(state),
    shouldShowDigicard:
      hasActiveDigitalSubscription(state) && isUserActive(state),
    isFetchingProfile: isFetching(state, 'getProfile'),
    isFetchingSubscriptions: isFetching(state, 'subscriptions'),
    isProfileLoaded: Boolean(getProfile(state).loaded),
    isSubscriptionsLoaded: state.subscriptions !== null
  }
}

export default compose(
  connect(mapStateToProps),
  withRouter,
  withTranslate(),
  withRunSaga()
)(StudentCard)
