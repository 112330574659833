import {getProfile} from '$src/profile/selectors'
import {Selector} from '$src/types'

/**
 * Returns true if user is both logged in to cognito and user profile info
 * is loaded. Use this in components to decide whether to show logged-in content.
 */
export const isLoggedIn: Selector<boolean> = (state) => {
  return isLoggedInToCognito(state) && Boolean(getProfile(state).loaded)
}

/**
 * Returns true if user is logged in to cognito. Use this when
 * you want just to ensure that user tokens are loaded.
 */
export const isLoggedInToCognito: Selector<boolean> = (state) => {
  return state.auth.loggedIn
}
