import * as React from 'react'
import {withTranslate, WithTranslateProps} from '$common/utils'
import {resolveCss} from '$common/utils'
import css from './LoadingItem.css'

type Props = WithTranslateProps & {
  style: object
  mode: 'cell' | 'row'
  css?: any
}

const LoadingItem = (props: Props) => {
  const classes = resolveCss(css, props.css)
  return (
    <div {...classes('container', props.mode)}>{props.t('common.loading')}</div>
  )
}

export default withTranslate()(LoadingItem)
