import MediaQuery from 'react-responsive'
import * as React from 'react'
import {compose} from 'redux'
import {Interpolate} from 'react-i18next'
import {Link} from 'react-router-dom'

import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import LanguageSelector from '$src/i18n/components/LanguageSelector'
import Layout from '$common/components/layouts/TopMiddleBottomLayout'
import OnboardingLayout from '$src/onboarding/components/OnboardingLayout'
import P from '$common/components/P'
import align from '$common/grid/align.css'
import css from './index.css'
import grid from '$common/grid/grid.css'
import {
  resolveCss,
  withRunSaga,
  withRouter,
  WithRunSagaProps,
  WithRouterProps,
  withTranslate,
  WithTranslateProps
} from '$common/utils'
import {isCurrentPage} from '$src/common/pgWebSeparatorUtils'

type Props = WithRunSagaProps & WithRouterProps & WithTranslateProps

class GreetingPage extends React.Component<Props> {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    if (!isCurrentPage(this.props)) {
      return null
    }
    const classes = resolveCss(css, grid, align)

    const faq = (
      <a
        href={this.props.t('common.faqLink')}
        target="_blank"
        rel="noopener noreferrer"
      >
        {this.props.t('authentication.signUp.faq')}
      </a>
    )

    return (
      <OnboardingLayout>
        <Layout
          adaptiveLayout={{
            stackMiddle: true
          }}
          top={
            <div {...classes('columnLarge11')}>
              <div {...classes('right', 'onboardingHeader')}>
                <div className={css.selectorContainer}>
                  <LanguageSelector />
                </div>
              </div>
            </div>
          }
          middle={
            <div {...classes('columnLarge8', 'offsetLarge2')}>
              <MediaQuery maxWidth={800}>
                <FrankLogoInSmallWhite />
              </MediaQuery>
              <H1 css={{container: css.h1}}>
                {this.props.t('authentication.signUp.title')}
              </H1>
              <P>{this.props.t('authentication.signUp.body')}</P>
            </div>
          }
          bottom={
            <div {...classes('columnLarge8', 'offsetLarge2')}>
              <div className={css.buttonContainer}>
                <Link to={`/login${this.props.location.search}`}>
                  <GenericButton
                    surroundMargin
                    css={{container: css.button}}
                    style="bold"
                    isExpanding={true}
                  >
                    {this.props.t('authentication.logIn.cta')}
                  </GenericButton>
                </Link>
                <Link to="/signup">
                  <GenericButton
                    surroundMargin
                    css={{container: css.button}}
                    style="outline"
                    isExpanding={true}
                  >
                    {this.props.t('authentication.signUp.signUp')}
                  </GenericButton>
                </Link>
                <Link to="/home">
                  <GenericButton
                    surroundMargin
                    css={{container: css.button}}
                    style="outline"
                    isExpanding={true}
                  >
                    {this.props.t('authentication.browseNow.cta')}
                  </GenericButton>
                </Link>
              </div>
              <P css={{container: css.terms}} style="subtle">
                <Interpolate
                  i18nKey="authentication.signUp.instructions"
                  faq={faq}
                />
              </P>
            </div>
          }
        />
      </OnboardingLayout>
    )
  }
}

export default compose(withRunSaga(), withRouter, withTranslate())(GreetingPage)
