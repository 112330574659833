import MediaQuery from 'react-responsive'
import * as React from 'react'
import {compose} from 'redux'
import {Link} from 'react-router-dom'

import ContinueButton from '$common/components/buttons/ContinueButton'
import BackButton from '$common/components/buttons/BackButton'
import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import TopMiddleBottomLayout from '$common/components/layouts/TopMiddleBottomLayout'
import align from '$common/grid/align.css'
import css from './index.css'
import grid from '$common/grid/grid.css'
import {
  resolveCss,
  withRouter,
  WithRouterProps,
  withTranslate,
  WithTranslateProps
} from '$common/utils'
import {withRunSaga} from '$src/common/utils'

type PropTypes = {
  runSaga: any
}
type Props = WithRouterProps & WithTranslateProps & PropTypes

const StartPage = (props: Props) => {
  const classes = resolveCss(css, grid, align)
  const continuePath = '/profile/verify/start-form'

  return (
    <TopMiddleBottomLayout
      adaptiveLayout={{
        stackMiddle: true
      }}
      css={{
        container: grid.container,
        middle: css.middle,
        bottom: css.bottom
      }}
      top={
        <div>
          <MediaQuery minWidth={801}>
            <FrankLogoInSmallWhite />
          </MediaQuery>
        </div>
      }
      middle={
        <div {...classes('columnLarge6', 'center')}>
          <H1>{props.t('profile.verification.title')}</H1>
          <P>{props.t('profile.verification.body')}</P>
        </div>
      }
      bottom={
        <div {...classes('columnLarge6', 'center')}>
          <MediaQuery maxWidth={800}>
            {(isMobile) => {
              if (isMobile) {
                return (
                  <div>
                    <div {...classes('column', 'space-between')}>
                      <div {...classes('align-self-center')}>
                        <BackButton
                          {...classes('align-self-center')}
                          onClick={() => {
                            props.history.push('/home')
                          }}
                        />
                      </div>
                      <ContinueButton
                        onClick={() => props.history.push(continuePath)}
                      />
                    </div>
                  </div>
                )
              } else {
                return (
                  <div className={grid.row}>
                    <div {...classes('column', 'right')}>
                      <Link to="/">
                        <GenericButton style="outline">
                          {props.t('profile.verification.continueLater')}
                        </GenericButton>
                      </Link>
                      &nbsp;
                      <Link to={continuePath}>
                        <GenericButton style="bold">
                          {props.t('common.continue')}
                        </GenericButton>
                      </Link>
                    </div>
                  </div>
                )
              }
            }}
          </MediaQuery>
        </div>
      }
    />
  )
}

export default compose(withRouter, withRunSaga(), withTranslate())(StartPage)
