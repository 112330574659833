import {connect} from 'react-redux'
import {compose} from 'redux'

import {isLoggedIn} from '$src/auth/selectors'
import {
  connect as connectCustom,
  withTranslate,
  withRouter,
  withRunSaga,
  withResponsiveInfo
} from '$common/utils'
import Header from './Header'
import {getStudentType, isUserVerified} from '$src/profile/selectors'

const mapStateToProps = (state, ownProps) => {
  return {
    isUserVerified: isUserVerified(state),
    isSecondGradeStudent: getStudentType(state) === 'upper_secondary',
    isMenuPage: ownProps.history.location.pathname === '/menu',
    loggedIn: isLoggedIn(state)
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps),
  connectCustom((state) => ({isLoggedIn: isLoggedIn(state)})),
  withRunSaga(),
  withTranslate(),
  withResponsiveInfo()
)(Header)
