import {select, call} from 'redux-saga/effects'

import {Lang} from './types'
import {reduceState} from '$common/utils'
import {getProfile} from '$src/profile/selectors'
import setUserLanguage from '$src/profile/sagas/setUserLanguage'

export function* setPreferredLang(lng: Lang): Generator<> {
  const profile = yield select(getProfile)
  let result = Promise.resolve()

  if (profile.loaded && profile.language !== lng) {
    result = yield call(setUserLanguage, {language: lng})
  }

  yield reduceState(['locale'], () => lng)
  return result
}

export function* unsetPreferredLang(): Generator<> {
  yield reduceState(['locale'], () => undefined)
}
