import React from 'react'
import css from './index.css'

import {resolveCss} from '$common/utils'

type Props = {
  css: {}
  overlayColor?: false | 'red' | 'blue'
}

const SlideLoading = ({css: propCss, overlayColor}: Props) => {
  const classes = resolveCss(css, propCss)
  return (
    <div {...classes('container', 'placeHolder')}>
      <div {...classes('animatedBarContainer', overlayColor)}>
        <div {...classes('placeHolderBar', 'top')} />
        <div {...classes('placeHolderBar', 'bottom')} />
      </div>
    </div>
  )
}

export default SlideLoading
