import * as React from 'react'
import ArticleCard from './ArticleCard'
import {Article} from '$src/articles/types'
import {resolveCss} from '$common/utils'
import css from '$src/articles/components/Carousel/Carousel.css'

type Props = {items: Array<Article | {placeholder: true}>}

const BoxStackSlide = ({items}: Props) => {
  const classes = resolveCss(css)
  return (
    <div {...classes('boxStack')}>
      {items.map((article, i) => (
        <ArticleCard key={i} item={article} />
      ))}
    </div>
  )
}

export default BoxStackSlide
