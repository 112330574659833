import * as React from 'react'
import {compose} from 'redux'
import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect'

import userAuthenticated from '$src/auth/components/userAuthenticated'
import {isUserUnverified} from '$src/profile/selectors'
import {getHomePage} from '$src/common/pgWebSeparatorUtils'

export const unverifiedWrapper = connectedRouterRedirect({
  redirectPath: getHomePage(),
  allowRedirectBack: false,
  authenticatedSelector: isUserUnverified,
  wrapperDisplayName: 'UserUnverified'
})

export default (Component: React.ComponentType<any>) =>
  compose(userAuthenticated, unverifiedWrapper)(Component)
