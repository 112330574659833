import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {destroy} from 'redux-form'
import {get} from 'lodash'

import amplitude from '$src/analytics/amplitude'
import ProfileSummary from '$src/subscriptions/components/ProfileSummary-container'
import DigiIsicTermsAndConditions from './DigiIsicTermsAndConditions'
import Payment from './Payment'
import OrderCompleted from './OrderCompleted'
import ExistingOrder from './ExistingOrder'
import {getLatestActiveIsicSubscription} from '../../selectors'

const basePath = '/digi-isic'
const termsPath = basePath + '/terms-and-conditions'
const summaryPath = basePath + '/summary'
const existingOrderPath = basePath + '/existing-order'

const Summary = (routeProps) => (
  <ProfileSummary
    startLink={termsPath}
    from="digi-isic/profile-summary"
    dialogContainerStyle="black"
    {...routeProps}
  />
)

class DigiIsicCardOrderPage extends React.Component {
  componentDidUpdate() {
    window.scrollTo(0, 0)
  }

  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0)
    amplitude.logEvent('beginDigiIsicPurchase', {
      hasValidIsic: !!this.props.currentActiveIsic,
      currentValidTo: get(this.props, 'currentActiveIsic.validTo')
    })
  }

  componentWillUnmount() {
    this.props.destroy('digiIsicWizard')
  }

  selectStartRoute = () => {
    if (this.props.currentActiveIsic) {
      return <Redirect to={{pathname: existingOrderPath}} />
    }
    return <Redirect to={{pathname: summaryPath}} />
  }

  render() {
    return (
      <Switch>
        <Route path={basePath} exact render={this.selectStartRoute} />
        <Route path={summaryPath} exact component={Summary} />
        <Route path={existingOrderPath} exact component={ExistingOrder} />
        <Route path={termsPath} exact component={DigiIsicTermsAndConditions} />
        <Route path={basePath + '/payment'} exact component={Payment} />
        <Route
          path={basePath + '/order-completed'}
          exact
          component={OrderCompleted}
        />
      </Switch>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentActiveIsic: getLatestActiveIsicSubscription(state)
  }
}

export default compose(connect(mapStateToProps, {destroy}))(
  DigiIsicCardOrderPage
)
