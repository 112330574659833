import React from 'react'
import SvgIcon from '$common/components/icons/SvgIcon'
import {resolveCss} from '$common/utils'
import css from './ChecklistBox.css'
import checkIcon from '$assets/icons/checkSingleGreen.svg'

export type ChecklistProps = {
  position?: 'right-of-lead' | 'left-of-lead' | 'right-of-body' | 'left-of-body'
  title?: string
  items: Array<string>
}

const ChecklistBox = ({
  position = 'right-of-lead',
  title,
  items = []
}: ChecklistProps) => {
  const classes = resolveCss(css)

  return (
    <div
      {...classes(
        'checklist',
        position === 'left-of-lead' || position === 'left-of-body'
          ? 'onLeft'
          : 'onRight'
      )}
    >
      <h3 {...classes('title')}>{title}</h3>
      <ul {...classes('list')}>
        {items.map((item, i) => (
          <li key={i} {...classes('listItem')}>
            <SvgIcon css={{container: css.checkIcon}} src={checkIcon} />
            {item}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ChecklistBox
