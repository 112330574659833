import * as React from 'react'
import {compose} from 'redux'
import {FormProps} from 'redux-form'
import {connect} from '$common/utils'

import ConversationLayout from '$common/components/layouts/ConversationLayout'
import GenericButton from '$common/components/buttons/GenericButton'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import TextInput from '$common/components/forms/TextInput'
import css from '$src/subscriptions/components/styles.css'
import {NationalOrInternational} from '$src/subscriptions/types'
import {StoreState} from '$src/types'
import {getProfile as profileSelector} from '$src/profile/selectors'
import {Profile} from '$src/profile/types'
import {validateShippingAddress} from './validators'
import {
  WithRouterProps,
  WithTranslateProps,
  withRouter,
  withTranslate,
  asForm
} from '$common/utils'
import HeaderTopFill from '$src/common/components/Header/HeaderTopFill'

type Props = WithRouterProps &
  WithTranslateProps &
  FormProps & {
    match: {params: {nationality: NationalOrInternational}}
    profile: Profile
  }

const ShippingAddress = (props: Props) => {
  let titleI18nKey,
    bodyI18nKey,
    nameLabelI18nKey,
    streetAddressLabelI18nKey,
    postalCodeLabelI18nKey,
    cityLabelI18nKey,
    ctaI18nKey,
    ctaPath
  if (props.match.params.nationality === 'national') {
    titleI18nKey =
      'subscriptions.plainCardPurchase.finnish.shippingAddress.title'
    bodyI18nKey = 'subscriptions.plainCardPurchase.finnish.shippingAddress.body'
    nameLabelI18nKey =
      'subscriptions.plainCardPurchase.finnish.shippingAddress.name'
    streetAddressLabelI18nKey =
      'subscriptions.plainCardPurchase.finnish.shippingAddress.streetAddress'
    postalCodeLabelI18nKey =
      'subscriptions.plainCardPurchase.finnish.shippingAddress.postalCode'
    cityLabelI18nKey =
      'subscriptions.plainCardPurchase.finnish.shippingAddress.city'
    ctaI18nKey = 'subscriptions.plainCardPurchase.finnish.shippingAddress.cta'
    ctaPath = '/plain-card/national/payment'
  } else {
    titleI18nKey =
      'subscriptions.plainCardPurchase.international.shippingAddress.title'
    bodyI18nKey =
      'subscriptions.plainCardPurchase.international.shippingAddress.body'
    nameLabelI18nKey =
      'subscriptions.plainCardPurchase.international.shippingAddress.name'
    streetAddressLabelI18nKey =
      'subscriptions.plainCardPurchase.international.shippingAddress.streetAddress'
    postalCodeLabelI18nKey =
      'subscriptions.plainCardPurchase.international.shippingAddress.postalCode'
    cityLabelI18nKey =
      'subscriptions.plainCardPurchase.international.shippingAddress.city'
    ctaI18nKey =
      'subscriptions.plainCardPurchase.international.shippingAddress.cta'
    ctaPath = '/plain-card/international/payment'
  }

  const conversationLayoutProps = {
    content: (
      <div className={css.content}>
        <H1 css={{container: css.heading}}>{props.t(titleI18nKey)}</H1>
        <P css={{container: css.paragraph}}>{props.t(bodyI18nKey)}</P>

        <TextInput
          input={{
            value: `${props.profile.firstName} ${props.profile.lastName}`
          }}
          name="name"
          disabled
          label={props.t(nameLabelI18nKey)}
          css={{container: css.textInputField, label: css.textInputLabel}}
        />
        <TextInput
          name="streetAddress"
          label={props.t(streetAddressLabelI18nKey)}
          css={{container: css.textInputField, label: css.textInputLabel}}
        />
        <TextInput
          name="postalCode"
          label={props.t(postalCodeLabelI18nKey)}
          css={{container: css.textInputField, label: css.textInputLabel}}
          type="number"
        />
        <TextInput
          name="city"
          label={props.t(cityLabelI18nKey)}
          css={{container: css.textInputField, label: css.textInputLabel}}
        />
      </div>
    ),
    buttons: (
      <div className={css.singleButton}>
        <GenericButton
          type="submit"
          css={{container: css.button}}
          style="bold"
          isExpanding={true}
          disabled={props.invalid}
        >
          {props.t(ctaI18nKey)}
        </GenericButton>
      </div>
    ),
    dialogContainerStyle: 'blue'
  }

  return (
    <form
      onSubmit={props.handleSubmit(() => {
        props.history.push(ctaPath)
        window.scrollTo(0, 0)
      })}
    >
      <HeaderTopFill
        background={'linear-gradient(#036F89,#04A3CA)'}
        position={'fixed'}
      />
      <ConversationLayout {...conversationLayoutProps} />
    </form>
  )
}

export default compose(
  asForm('plainCardWizard', {
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: validateShippingAddress
  }),
  connect((state: StoreState) => ({
    profile: profileSelector(state)
  })),
  withRouter,
  withTranslate()
)(ShippingAddress)
