import * as React from 'react'
import MediaQuery from 'react-responsive'
import {SubmissionError} from 'redux-form'
import {Trans} from 'react-i18next'
import {isEmpty} from 'lodash'

import FaqLink from '$common/components/FaqLink/FaqLink'
import BackButton from '$common/components/buttons/BackButton'
import FrankLogoInSmallWhite from '$common/components/logos/FrankLogoInSmallWhite'
import GenericButton from '$common/components/buttons/GenericButton'
import Layout from '$common/components/layouts/TopMiddleBottomLayout'
import SelectBoxField from '$common/components/forms/SelectBoxField'
import P from '$common/components/P'
import ErrorCandy from '$common/components/candyBars/ErrorCandy'
import {resolveCss} from '$common/utils'
import {
  logEvent2,
  logLinkClick,
  ANALYTICS_EVENT,
  ANALYTICS_PROVIDER
} from '$src/common/utils/logUtils'
import {getSchools} from '$src/profile/sagas/getSchools'
import createApiErrorMessage from '$src/api/utils/createApiErrorMessage'
import css from './StartFormPage.css'
import grid from '$common/grid/grid.css'
import align from '$common/grid/align.css'
import {TrialButton} from '$src/profile/components/verification/StartFormPage/trialButton'

export const SECOND_DEGREE = 'second_degree'
const POLYTECHNIC = 'polytechnic'
const UNIVERSITY = 'university'
const FOLK_HIGH_SCHOOL = 'folk_high_school'

/**
 * redux-form values (this.props.fieldValues)
 * @typedef {Object} StartFormPageValues
 * @property {string} degree
 * @property {string} jollaId
 * @property {string} schoolId
 * @property {number} polytechnic
 * @property {number} university
 */

export default class StartFormPage extends React.Component {
  async componentDidMount() {
    logEvent2(ANALYTICS_EVENT.OPEN_VERIFICATION_START_PAGE, [
      ANALYTICS_PROVIDER.AMPLITUDE,
      ANALYTICS_PROVIDER.BRAZE,
      ANALYTICS_PROVIDER.BRANCH,
      ANALYTICS_PROVIDER.FIREBASE
    ])

    // FIXME If we return to this page via the back button, react-select doesn't show any values in drop-downs,
    // even though they exist in redux-form state. As a quick fix, clear selections when this page is loaded. A better-
    // looking but more complex solution would be to set drop-down values manually to redux-form stored values.
    this.clearInitialValues()

    try {
      await this.props.runSaga(getSchools)
    } catch (e) {
      const errMsg = createApiErrorMessage(
        e,
        'error.profile.verification.startForm'
      )
      // Not really a submission error, but use the same logic as all other verification pages, for consistency.
      throw new SubmissionError({
        _error: this.props.t(errMsg),
        general: true
      })
    }
  }

  degreeDropdownOptions = [
    {
      value: SECOND_DEGREE,
      label: this.props.t('profile.verification.startForm.secondDegree')
    },
    {
      value: POLYTECHNIC,
      label: this.props.t('profile.verification.startForm.polytechnic')
    },
    {
      value: UNIVERSITY,
      label: this.props.t('profile.verification.startForm.university')
    },
    {
      value: FOLK_HIGH_SCHOOL,
      label: this.props.t('profile.verification.startForm.folkHighSchool')
    }
  ]

  clearInitialValues = () => {
    this.props.change('degree', '')
    this.clearEducationSelection()
  }

  clearEducationSelection = () => {
    this.props.change('schoolId', '')
    this.props.change('jollaId', '')
    this.props.change('polytechnic', '')
    this.props.change('university', '')
  }

  onDegreeSelectionChange = (event, newValue, previousValue) => {
    if (newValue !== previousValue) {
      //this.clearEducationSelection()
      if (newValue === FOLK_HIGH_SCHOOL) this.props.change('schoolId', '000')
    }
  }

  onEducationSelectionChange = (event, newValue, previousValue) => {
    if (newValue === previousValue) {
      return
    }

    const schoolInfo = this.props.schools[this.props.fieldValues.degree][
      newValue
    ]
    if (schoolInfo && schoolInfo.jollaId) {
      this.props.change('jollaId', schoolInfo.jollaId)
      this.props.change('schoolId', '')
    } else if (schoolInfo && schoolInfo.schoolId) {
      this.props.change('schoolId', schoolInfo.schoolId)
      this.props.change('jollaId', '')
    } else {
      this.clearEducationSelection()
    }
  }

  /** @param {string} degree */
  getSchoolOptions = (degree) => {
    if (!this.props.schools) {
      return []
    }

    return this.props.schools[degree].map((school, index) => {
      return {value: index, label: school.name}
    })
  }

  onBackButtonClick = (e) => {
    e.preventDefault()
    this.props.history.push('/profile/settings')
  }

  onAdditionalDegreeTextLinkClick = (e) => {
    logLinkClick(e, 'Outbound link from start verification')
  }

  onFaqClick = (e) => {
    logLinkClick(e, 'FAQ link from start verification')
  }

  /** @param {StartFormPageValues} values */
  submit = (values) => {
    if (values.degree === SECOND_DEGREE || values.jollaId) {
      this.props.history.push('/profile/verify/jolla/form')
    } else if (values.degree === FOLK_HIGH_SCHOOL) {
      this.props.history.push('/profile/verify/fhs/form')
    } else {
      this.props.history.push('/profile/verify/opintopolku/form')
    }
  }

  additionalDegreeText = (textKey, hrefKey) => {
    return (
      <P>
        <Trans i18nKey={textKey}>
          [0: text]
          <a
            href={this.props.t(hrefKey)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.onAdditionalDegreeTextLinkClick}
          >
            [1: link text]
          </a>
          [2: text]
        </Trans>
      </P>
    )
  }

  render() {
    const classes = resolveCss(css, grid, align)
    const {degree} = this.props.fieldValues

    const degreeSelectionDropdown = (
      <SelectBoxField
        key="degreeKey"
        name="degree"
        label={this.props.t('profile.verification.startForm.selectDegree')}
        options={this.degreeDropdownOptions}
        onChange={this.onDegreeSelectionChange}
      />
    )

    const polytechnicDropdown = (
      <SelectBoxField
        key="polytechnicKey"
        name="polytechnic"
        label={this.props.t('profile.verification.startForm.selectPolytechnic')}
        options={this.getSchoolOptions(POLYTECHNIC)}
        onChange={this.onEducationSelectionChange}
      />
    )

    const universityDropdown = (
      <SelectBoxField
        key="universityKey"
        name="university"
        label={this.props.t('profile.verification.startForm.selectUniversity')}
        options={this.getSchoolOptions(UNIVERSITY)}
        onChange={this.onEducationSelectionChange}
      />
    )

    /** @param {StartFormPageValues} values */
    const getEducationDropdown = (values) => {
      switch (values.degree) {
        case POLYTECHNIC:
          return polytechnicDropdown
        case UNIVERSITY:
          return universityDropdown
        default:
          null
      }
    }

    const jollaAdditionalText = this.additionalDegreeText(
      'profile.verification.startForm.jollaAdditionalText',
      'profile.verification.startForm.jollaAdditionalHref'
    )
    const opintopolkuAdditionalText = this.additionalDegreeText(
      'profile.verification.startForm.opintopolkuAdditionalText',
      'profile.verification.startForm.opintopolkuAdditionalHref'
    )
    const SecondDegreeTextAndButtons = () => (
      <>
        <P bolder={true} css={{container: [css.smallMarginTop]}}>
          {this.props.t(
            'profile.verification.startForm.secondDegree_upperTitle'
          )}
        </P>
        <P style="small" css={{container: [css.smallMarginTop]}}>
          {this.props.t(
            'profile.verification.startForm.secondDegree_upperText'
          )}
        </P>
        <div {...classes('smallMarginTop')}>{continueButton}</div>
        {this.props.displayTrialStartButton && (
          <>
            <P bolder={true} css={{container: [css.marginTop]}}>
              {this.props.t(
                'profile.verification.startForm.secondDegree_lowerTitle'
              )}
            </P>
            <P style="small" css={{container: [css.smallMarginTop]}}>
              {this.props.t(
                'profile.verification.startForm.secondDegree_lowerText'
              )}
            </P>
            <div {...classes('smallMarginTop')}>
              <TrialButton {...this.props} />
            </div>
          </>
        )}
      </>
    )

    /** @param {StartFormPageValues} values */
    const getAdditionalText = (values) => {
      switch (values.degree) {
        case POLYTECHNIC: // Fallthrough
        case UNIVERSITY: {
          if (values.jollaId) {
            return jollaAdditionalText
          }
          if (values.schoolId) {
            return opintopolkuAdditionalText
          }
          return null
        }
        case FOLK_HIGH_SCHOOL: {
          return opintopolkuAdditionalText
        }
        case SECOND_DEGREE:
          return <SecondDegreeTextAndButtons />
        default:
          return null
      }
    }

    const continueButton = (
      <GenericButton
        style="bold"
        loading={this.props.submitting}
        disabled={this.props.invalid}
        isFullWidth={degree === SECOND_DEGREE ? true : false}
      >
        {this.props.t('common.continue')}
      </GenericButton>
    )

    const backButton = (
      <GenericButton style="outline" onClick={this.onBackButtonClick}>
        {this.props.t('common.back')}
      </GenericButton>
    )

    const educationDropdown = getEducationDropdown(this.props.fieldValues)
    const additionalText = getAdditionalText(this.props.fieldValues)

    return (
      <form onSubmit={this.props.handleSubmit(this.submit)}>
        <Layout
          adaptiveLayout={{
            stackMiddle: true
          }}
          css={{
            container: grid.container
          }}
          top={
            <MediaQuery maxWidth={800}>
              {(isMobile) => {
                if (isMobile)
                  return <BackButton onClick={this.onBackButtonClick} />
                else return <FrankLogoInSmallWhite />
              }}
            </MediaQuery>
          }
          middle={
            <div {...classes('columnLarge5', 'center')}>
              {degreeSelectionDropdown}
              {educationDropdown}
              {additionalText}

              <FaqLink
                href="profile.verification.startForm.faqHref"
                text="profile.verification.startForm.faqLinkText"
                onClick={this.onFaqClick}
              />
              {
                <ErrorCandy
                  show={
                    !isEmpty(this.props.submitErrors) &&
                    !isEmpty(this.props.error)
                  }
                  onHide={this.props.clearSubmitErrors}
                >
                  {this.props.error}
                </ErrorCandy>
              }
            </div>
          }
          bottom={
            <div {...classes('columnLarge5', 'center')}>
              <MediaQuery maxWidth={800}>
                {(isMobile) => {
                  if (isMobile) {
                    return degree !== SECOND_DEGREE ? continueButton : null
                  } else {
                    return (
                      <div className={grid.row}>
                        <div {...classes('column', 'right')}>
                          {backButton}
                          &nbsp;
                          {degree !== SECOND_DEGREE && continueButton}
                        </div>
                      </div>
                    )
                  }
                }}
              </MediaQuery>
            </div>
          }
        />
      </form>
    )
  }
}
