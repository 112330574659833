import React, {useState} from 'react'
import moment from 'moment'
import layoutCss from '$common/grid/grid.css'
import alignCss from '$common/grid/align.css'
import {resolveCss} from '$common/utils'
import TextInput from '$common/components/forms/TextInput'
import GenericButton from '$src/common/components/buttons/GenericButton'
import css from '$src/profile/components/ChangeGraduationYear/ChangeGraduationYear.css'
import LogoutHeader from '$common/components/Header/LogoutHeader-container'
import Layout from '$common/components/layouts/TopMiddleBottomLayout'
import getProfile from '$src/profile/sagas/getProfile'
import {Link} from 'react-router-dom'
import {compose} from 'redux'
import {connect} from 'react-redux'
import get from 'lodash/get'
import {withRouter, withRunSaga, withTranslate, asForm} from '$src/common/utils'
import setGraduationYear from '$src/profile/sagas/setGraduationYear'
import {getTranslation} from '$src/profile/components/ChangeGraduationYear/translationHelper'

export const MODE = {
  REGISTRATION: 'registration',
  LANDER: 'lander',
  DEFAULT: null //never read, this is the default display mode
}

const ChangeGraduationYearComponent = ({
  location,
  t,
  runSaga,
  history,
  ...props
}) => {
  const classes = resolveCss(css, layoutCss, alignCss, css)

  const {
    copyParam,
    header,
    fieldLabel,
    saveButton,
    backButton,
    laterButton
  } = getTranslation(location, t)

  const [graduationYear, setGraduationYear] = useState('')

  return (
    <Layout
      noPadding={true}
      topStyle="sticky"
      css={{top: css.top}}
      top={<LogoutHeader css={{wrapper: css.fixedHeader}} />}
      middle={
        <div {...classes('editPhoneNumberContainer', 'columnLarge6', 'center')}>
          <h1>{header}</h1>
          <form {...classes('editPhoneNumberForm')}>
            <TextInput
              placeholder={`${t('common.eg')} ${moment().year() + 5}`}
              //inputRef={(node) => (graduationYear = node)}
              name="graduationYear"
              label={fieldLabel}
              onChange={(e, val) => {
                setGraduationYear(val)
              }}
            />

            <GenericButton
              isFullWidth
              css={{container: css.button}}
              style="bold"
              disabled={!validate(graduationYear)}
              onClick={(e) => {
                onSubmitHandler(e, graduationYear, runSaga, history, copyParam)
              }}
            >
              {saveButton}
            </GenericButton>

            {copyParam === MODE.REGISTRATION && (
              <Link to="/profile/verify/start">
                <GenericButton
                  isFullWidth
                  surroundMargin={true}
                  css={{container: css.button}}
                  style="outline"
                >
                  {laterButton}
                </GenericButton>
              </Link>
            )}

            {copyParam !== MODE.REGISTRATION && (
              <Link to="/profile/settings">
                <GenericButton
                  isFullWidth
                  surroundMargin={true}
                  css={{container: css.button}}
                  style="outline"
                >
                  {backButton}
                </GenericButton>
              </Link>
            )}
          </form>
        </div>
      }
    />
  )
}

const validate = (graduationYear) => {
  const maxGraduationYearOffset = 20
  return (
    graduationYear >= moment().year() &&
    graduationYear < moment().year() + maxGraduationYearOffset
  )
}

async function onSubmitHandler(e, graduationYear, runSaga, history, copyParam) {
  e.preventDefault() // default is "submit" which reloads the page
  runSaga(setGraduationYear, {graduationYear})

  if (copyParam === MODE.REGISTRATION) {
    history.replace('/profile/verify/start')
  } else {
    history.push('/profile/settings')
  }
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state),
    fieldErrors: get(state, 'form.editPhoneNumberPage.syncErrors', {})
  }
}

export default compose(
  asForm('ChangeGraduationYearComponent', {}),
  connect(mapStateToProps),
  withRunSaga(),
  withRouter,
  withTranslate()
)(ChangeGraduationYearComponent)
