import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect'
import {isLoggedIn} from '$src/auth/selectors'
import {StoreState} from '$src/types'
import {getHomePage} from '$common/pgWebSeparatorUtils'

const locationHelper = locationHelperBuilder({})

const unAuthenticatedWrapper = connectedRouterRedirect({
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || getHomePage(),
  allowRedirectBack: false,
  authenticatedSelector: (state: StoreState) => !isLoggedIn(state),
  wrapperDisplayName: 'UserUnauthenticated'
})

export default unAuthenticatedWrapper
