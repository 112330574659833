import * as React from 'react'
import {compose} from 'redux'

import css from './index.css'
import {resolveCss} from '$common/utils'

type Props = {
  vertical?: boolean
  css?: any
}

const Divider = (props: Props) => {
  const classes = resolveCss(css, props.css)

  return <div {...classes('line', props.vertical && 'vertical')} />
}

export default compose((a) => a)(Divider)
