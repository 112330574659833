import {OffersNamespaceState} from './types'

const defaultState = {
  fi: {
    byKey: {},
    queries: {},
    pagination: {}
  },
  en: {
    byKey: {},
    queries: {},
    pagination: {}
  },
  sv: {
    byKey: {},
    queries: {},
    pagination: {}
  },
  search: ''
}

export default (
  state: OffersNamespaceState = defaultState
): OffersNamespaceState => state
