import * as React from 'react'
import css from './FadeToDirection.css'
import resolveCss from '$src/common/utils/resolveCss'

type Props = {
  children: React.Node
  isMounted: boolean
  delayTime: number
  direction: 'left' | 'right' | ''
}

type State = {
  shouldRender: boolean
}

export default class FadeToDirection extends React.Component<Props, State> {
  state = {
    shouldRender: this.props.isMounted
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isMounted && !this.props.isMounted) {
      setTimeout(
        () => this.setState({shouldRender: false}),
        this.props.delayTime
      )
    } else if (!prevProps.isMounted && this.props.isMounted) {
      this.setState({shouldRender: true})
    }
  }

  render() {
    if (!this.state.shouldRender) {
      return null
    }

    const {isMounted, direction} = this.props
    const classes = resolveCss(css)
    return (
      <div
        {...classes(
          'fadeToDirection',
          isMounted ? `fade-in-${direction}` : `fade-out-${direction}`
        )}
      >
        {this.props.children}
      </div>
    )
  }
}
