// @todo this bundles all of moment.js's locales. we should only include the ones we actually use.
// Unfortunately, moment.js isn't really split up into modules, so the bundle size is quite big.
// Consider using https://date-fns.org instead
import moment from 'moment'
import amplitude from '$src/analytics/amplitude'
import * as Sentry from '@sentry/browser'
import {getAppboy} from '$common/pgWebSeparatorUtils'

import {reduceState} from '$common/utils'
import {Profile, ProfileVerificationState} from '$src/profile/types'

export default function setUserProfile(
  response: Profile & {state: ProfileVerificationState}
) {
  Sentry.configureScope((scope) => {
    scope.setUser({id: response.id})
    scope.setTag('language', response.language)
  })
  amplitude.setUserId(response.id)
  getAppboy().changeUser(response.id)
  if (window.cordova) {
    window.cordova.plugins.firebase.analytics.setUserId(response.id)
  } else {
    window.firebase.analytics().setUserId(response.id)
  }

  const profile = {
    ...response,
    loaded: moment().toISOString(),
    verificationState: response.state
  }
  delete profile.state

  return reduceState(['profile'], (prevState: Profile) => {
    return profile
  })
}
