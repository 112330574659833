import * as React from 'react'
import Scroll from 'react-scroll'
import {NavLink, Route, Switch} from 'react-router-dom'

import {Feature} from '$src/ordercard/types'

import FeatureContent from '$src/ordercard/components/FeatureContent/FeatureContent-container'
import H1 from '$common/components/headers/H1'
import P from '$common/components/P'
import align from '$common/grid/align.css'
import css from './Features.css'
import grid from '$common/grid/grid.css'
import {StudentTypes} from '$src/profile/types'
import features from '$src/ordercard/features'
import {resolveCss, WithRouterProps, WithTranslateProps} from '$common/utils'

type Props = WithRouterProps &
  WithTranslateProps & {
    studentType: StudentTypes
    handleFeatureCTA: (feature: Feature) => () => {}
    pricing: object
  }

const Features = (props: Props) => {
  const {match, studentType} = props
  const classes = resolveCss(css, grid, align)

  const plasticStudentCardPath = '/plastic-student-card'
  const digiIsicPath = '/digital-isic-card'

  const plasticStudentCardContent = features.find(
    (feature) => feature.name === 'plasticStudentCard'
  )
  const digiIsicContent = features.find(
    (feature) => feature.name === 'digiIsicDesktop'
  )

  const isFeatureActive = (match, location, isDefaultFeature) => {
    return match || (isDefaultFeature && location.pathname === '/ordercard')
  }

  const content = (
    <div className={css.featuresWrapper}>
      <Switch>
        <Route
          exact
          path={match.url}
          render={() => {
            return (
              <FeatureContent
                handleFeatureCTA={props.handleFeatureCTA}
                content={plasticStudentCardContent}
              />
            )
          }}
        />

        <Route
          exact
          path={match.url + plasticStudentCardPath}
          render={() => {
            return (
              <FeatureContent
                handleFeatureCTA={props.handleFeatureCTA}
                content={plasticStudentCardContent}
              />
            )
          }}
        />

        <Route
          exact
          path={match.url + digiIsicPath}
          render={() => {
            return (
              <FeatureContent
                handleFeatureCTA={props.handleFeatureCTA}
                content={digiIsicContent}
              />
            )
          }}
        />
      </Switch>

      <div {...classes('row', 'featuresListWrapper')}>
        {features
          .filter((f) => f.filter !== 'mobile-only')
          .map((feature: Feature) => {
            let activeImageSrc
            if (feature.image) {
              activeImageSrc = feature.image.activeSrc
            }

            if (!activeImageSrc) {
              if (studentType === 'graduate') {
                activeImageSrc =
                  feature.graduateImage && feature.graduateImage.activeSrc
              } else if (studentType === 'postgraduate') {
                activeImageSrc =
                  feature.postgraduateImage &&
                  feature.postgraduateImage.activeSrc
              }
            }

            if (!activeImageSrc) {
              activeImageSrc = ''
            }

            const featureHeadlineWrapperBackgroundActive = {
              backgroundImage: `url(${activeImageSrc})`
            }

            return (
              <NavLink
                {...classes(
                  'columnLarge4',
                  'featureHeadlineWrapper',
                  feature.type === 'digital' && 'blue',
                  feature.type === 'plastic' && 'red'
                )}
                isActive={(match, location) =>
                  isFeatureActive(match, location, feature.isDefaultFeature)
                }
                activeClassName={css.active}
                onClick={() =>
                  Scroll.animateScroll.scrollToTop({duration: 100})
                }
                to={`${match.url}/${feature.path}`}
                key={feature.id}
              >
                <div
                  style={featureHeadlineWrapperBackgroundActive}
                  {...classes('featureHeadlineWrapperBackground')}
                />

                <div className={css.featureHeadlineContainer}>
                  <div {...classes('column', 'center')}>
                    <div className={css.arrowDown} />
                  </div>

                  <div
                    {...classes(
                      'verticalCenter',
                      'centerText',
                      'featureHeadlineContent'
                    )}
                  >
                    <H1 css={{container: css.featureHeadlineTitle}}>
                      {props.t(feature.title)}
                    </H1>
                    <P css={{container: css.featureHeadlinePrice}}>
                      {props.t(feature.price, props.pricing)}
                    </P>
                  </div>
                </div>
              </NavLink>
            )
          })}
      </div>
    </div>
  )

  return content
}

export default Features
