/* eslint-disable react/jsx-key */

import * as React from 'react'
import {Route} from 'react-router-dom'
import VerifyStudentStatusPromptModal from '$common/components/modals/VerifyStudentStatusPromptModal'
import LoginPromptModal from '$common/components/modals/LoginPromptModal'
import ProfilePhotoRequiredPromptModal from '$common/components/modals/ProfilePhotoRequiredPromptModal'
import userUnverified from '$src/auth/components/userUnverified'
import userAuthenticated from '$src/auth/components/userAuthenticated'
import userUnauthenticated from '$src/auth/components/userUnauthenticated'

export default [
  <Route
    path={`/verify`}
    component={userUnverified(VerifyStudentStatusPromptModal)}
  />,
  <Route
    path={`/suggest-login`}
    component={userUnauthenticated(LoginPromptModal)}
  />,
  <Route
    path={`/no-picture/:photoPageLink`}
    component={userAuthenticated(ProfilePhotoRequiredPromptModal)}
  />
]
