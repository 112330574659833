import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {call} from 'redux-saga/effects'

function preventToThrow(fn) {
  return function* callAndCatch(...args) {
    try {
      // `call as any` because typescript, variadic functions and redux saga do not work well together
      return yield (call as any)(fn, ...args)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

export default function withRunSaga() {
  return (WrappedComponent) => {
    return class WithRunSaga extends Component {
      static contextTypes = {
        store: PropTypes.any
      }

      render() {
        const props = {
          ...this.props,
          runSaga: (fn, ...args) =>
            this.context.store.sagaMiddleware
              .run(preventToThrow(fn), ...args)
              .toPromise()
        }
        return <WrappedComponent {...props} />
      }
    }
  }
}

export interface WithRunSagaProps {
  runSaga: (saga: () => Generator, ...params: any) => any
}
