import * as React from 'react'
import {Link} from 'react-router-dom'
import Auth from '@aws-amplify/auth'

import TextInput from '$common/components/forms/TextInput'
import LogoutHeader from '$common/components/Header/LogoutHeader-container'
import Layout from '$common/components/layouts/TopMiddleBottomLayout'
import GenericButton from '$src/common/components/buttons/GenericButton'
import LoadingSpinnerModal from '$common/components/LoadingSpinnerModal'
import putProfile from '$src/profile/sagas/putProfile'
import setNotification from '$src/notification/sagas/setNotification'
import {resolveCss} from '$common/utils'
import css from './VerifyEmailPage.css'
import layoutCss from '$common/grid/grid.css'
import alignCss from '$common/grid/align.css'
import {ContainerProps} from './VerifyEmailPage-container'

interface Props extends ContainerProps {
  fieldValues: {
    newEmail: string
    verifyCode: string
  }
  runSaga: Function // TODO: proper types
  setNotification: typeof setNotification
}

interface State {
  isSubmitting: boolean
}

class VerifyEmailPage extends React.Component<Props, State> {
  state = {
    isSubmitting: false
  }

  verifyCodeField: HTMLElement | undefined = undefined

  onSetNewEmail = async (evt: React.SyntheticEvent) => {
    evt.preventDefault()

    this.setState({isSubmitting: true})

    const currentEmail = this.props.profile.email
    const newEmail = this.props.fieldValues.newEmail.toLowerCase()

    try {
      const cogitoUser = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(cogitoUser, {
        email: newEmail,
        'custom:validated_email': currentEmail
      })
      setTimeout(
        () => this.verifyCodeField && this.verifyCodeField.focus(),
        100
      )
    } catch (err) {
      this.props.runSaga(setNotification, {
        message: this.props.t('error.500')
      })
    } finally {
      this.setState({isSubmitting: false})
    }
  }

  onVerifyNewEmail = async (evt) => {
    evt.preventDefault()

    this.setState({isSubmitting: true})

    try {
      await Auth.verifyCurrentUserAttributeSubmit(
        'email',
        this.props.fieldValues.verifyCode
      )
    } catch (err) {
      if (
        ['CodeMismatchException', 'ExpiredCodeException'].includes(err.code)
      ) {
        this.props.runSaga(setNotification, {
          message: this.props.t(`error.cognito.${err.code}`)
        })
      }
      this.setState({isSubmitting: false})
      return
    }

    const cogitoUser = await Auth.currentAuthenticatedUser()
    const newEmail = this.props.fieldValues.newEmail.toLowerCase()
    try {
      await Auth.updateUserAttributes(cogitoUser, {
        email: newEmail,
        'custom:validated_email': newEmail
      })
    } catch (err) {
      /* Expected error. See backend triggers for more info. */
    }

    await this.props.runSaga(
      putProfile,
      {
        email: newEmail
      },
      'profile.email'
    )

    this.props.history.push('/profile/settings')
  }

  render() {
    const classes = resolveCss(css, layoutCss, alignCss, this.props.css)

    return (
      <Layout
        noPadding={true}
        topStyle="sticky"
        top={<LogoutHeader />}
        middle={
          <div {...classes('editEmailContainer', 'columnLarge9', 'center')}>
            {this.state.isSubmitting && <LoadingSpinnerModal />}

            <h1>{this.props.t('validate.email.caption')}</h1>
            <form onSubmit={this.onSetNewEmail} className={css.editEmailForm}>
              <TextInput
                name="newEmail"
                type="email"
                autocomplete="username"
                placeholder={this.props.t('common.emailPlaceholder')}
                label={this.props.t('validate.email.newEmail.label')}
              />
              <GenericButton
                disabled={
                  this.props.fieldErrors.newEmail || this.state.isSubmitting
                }
                style="bold"
              >
                {this.props.t('validate.email.sendConfirmationMessage')}
              </GenericButton>
            </form>
            <form
              onSubmit={this.onVerifyNewEmail}
              className={css.editEmailForm}
            >
              <TextInput
                inputRef={(ref) => (this.verifyCodeField = ref)}
                name="verifyCode"
                placeholder={this.props.t(
                  'validate.email.verifyCode.placeholder'
                )}
                label={this.props.t('validate.email.verifyCode.label')}
              />
              <GenericButton
                disabled={
                  this.props.fieldErrors.verifyCode || this.state.isSubmitting
                }
                style="bold"
              >
                {this.props.t('validate.email.confirm')}
              </GenericButton>
            </form>
            <Link to="/profile/settings">
              <GenericButton css={{container: css.button}} style="outline">
                {this.props.t('common.back')}
              </GenericButton>
            </Link>
          </div>
        }
      />
    )
  }
}

export default VerifyEmailPage
