import {put} from 'redux-saga/effects'
import {reduceState} from '$common/utils'
import {resetStateAction} from '$common/actions'
import {bootstrapAction} from '$common/actions'

export function* setArticleCarouselPage(page? = 1) {
  yield reduceState(['common', 'ui', 'articleCarouselPage'], () => page)
}

export function* setMobileArticleCarouselPage(page? = 1) {
  yield reduceState(['common', 'ui', 'mobileArticleCarouselPage'], () => page)
}

export function* setNewsCarouselPage(page? = 1) {
  yield reduceState(['common', 'ui', 'newsCarouselPage'], () => page)
}

export function* resetState() {
  yield put(resetStateAction())
  yield put(bootstrapAction())
}
