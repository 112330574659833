import React, {Component} from 'react'
import {Interpolate} from 'react-i18next'
import moment from 'moment'
import {WithRouterProps, Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {SubmissionError} from 'redux-form'

import TextInput from '$common/components/forms/TextInput'
import LoadingSpinnerModal from '$common/components/LoadingSpinnerModal'
import LanguageSelector from '$src/i18n/components/LanguageSelector'
import css from './ProfileSettingsPage.css'
import {
  resolveCss,
  createApiErrorMessage,
  WithRunSagaProps
} from '$common/utils'
import changeEducation from '$src/profile/sagas/changeEducation'
import {compose} from 'redux'
import {withRouter, withRunSaga, withTranslate} from '$src/common/utils'
import {
  getProfile as profileSelector,
  shouldShowEducationSelect
} from '$src/profile/selectors'
import {StoreState} from '$src/types'
import EducationSelect from '$src/profile/components/EducationSelect/EducationSelect'
import updateOpintopolkuSecret from '$src/profile/sagas/updateOpintopolkuSecret'
import GenericButton from '../../../common/components/buttons/GenericButton'
import {Portal} from 'react-portal'
import IsicLinkDialog from './IsicLinkDialog'
import {getAppboy} from '$src/common/pgWebSeparatorUtils'
import {
  logEvent2,
  ANALYTICS_EVENT,
  ANALYTICS_PROVIDER
} from '$src/common/utils/logUtils'
import isMobile from '$common/utils/isMobile'

import delay from '$src/common/utils/delay'

type Props = WithRouterProps &
  WithRunSagaProps & {
    initialValues: {}
  }

const ProfilePhotoUnchangeable = ({
  classes,
  language,
  t,
  photoIsModifiableDate
}) => {
  let link
  if (language === 'fi') {
    link = 'https://www.frank.fi/faq/#lp-pom-box-424'
  }
  if (language === 'sv') {
    link = 'https://www.frank.fi/sv/faq/#lp-pom-box-364'
  }
  if (language === 'en') {
    link = 'https://www.frank.fi/en/faq/#lp-pom-box-372'
  }

  return (
    <div {...classes('textSection')}>
      <p {...classes('textSectionHeader')}>
        {t('profile.profilePictureLabel')}
      </p>
      <p {...classes('textSectionParagraph')}>
        <p>
          {t('profile.profilePicture', {
            date: moment(photoIsModifiableDate).format('DD.MM.YYYY')
          })}
        </p>
        <br />
        <p>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {t('profile.profilePictureContactHere')}
          </a>
        </p>
      </p>
    </div>
  )
}
class ProfileSettingsForm extends Component<Props> {
  delayTimer = null

  state = {
    cordovaPhoneNumberPopupOpen: false,
    opintopolkuUrlFieldDisabled: true,
    shouldShowIsicLinkDialog: false
  }

  enableField = (field) => (event) => {
    const fieldState = field + 'FieldDisabled'
    if (this.state[fieldState]) {
      event.preventDefault()
      this.setState({[fieldState]: false})
    }
  }

  disableField = (field) => {
    this.setState({[field + 'FieldDisabled']: true})
  }

  onEducationSelected = (education) => {
    this.props.runSaga(changeEducation, education)
  }

  onSubmitOpintopolkuUrl = async ({opintopolkuUrl}) => {
    if (!this.props.submitting) {
      try {
        await this.props.runSaga(updateOpintopolkuSecret, opintopolkuUrl)
        this.disableField('opintopolkuUrl')
      } catch (e) {
        const errMsg = createApiErrorMessage(e)
        throw new SubmissionError({_error: this.props.t(errMsg), general: true})
      }
    }
  }

  isPictureChangeable = () =>
    this.props.photoLastModifiedDisabled === 'true' ||
    moment().isSameOrAfter(this.props.photoIsModifiableDate)

  render() {
    if (this.state.cordovaPhoneNumberPopupOpen) {
      return <LoadingSpinnerModal />
    }

    const rose = '#FD1E6E'
    const classes = resolveCss(css, this.props.css)
    const {handleSubmit} = this.props
    const opintopolkuUrlAddonAfterColor = this.state.opintopolkuUrlFieldDisabled
      ? 'inherit'
      : rose

    const LinkToPhotoPage = (
      <Link
        to="/profile/settings/photo"
        onClick={(e) => {
          e.preventDefault()
          this.props.history.push('/profile/settings/photo', {
            from:
              this.props.history.location.state &&
              this.props.history.location.state.from
          })
        }}
      >
        {this.props.t('profile.profilePictureAddonAfter')}
      </Link>
    )

    const OpintopolkuUrlAddonAfter = (
      <button
        style={{font: 'inherit', color: opintopolkuUrlAddonAfterColor}}
        type="submit"
        onClick={this.enableField('opintopolkuUrl')}
      >
        {this.state.opintopolkuUrlFieldDisabled ? (
          <Interpolate i18nKey="common.edit" />
        ) : (
          <Interpolate i18nKey="common.save" />
        )}
      </button>
    )

    const FormSectionCaption = ({caption}) => (
      <div className={css.formSectionCaption}>{caption}</div>
    )

    const showIsicLinkDialog = () => {
      this.setState({shouldShowIsicLinkDialog: true})
      document.body.classList.add('scroll-lock')
    }

    const hideIsicLinkDialog = () => {
      this.setState({shouldShowIsicLinkDialog: false})
      document.body.classList.remove('scroll-lock')
      if (this.delayTimer) this.delayTimer.cancel()
    }

    const onClickIsicLink = async () => {
      copyIsicNumber()
      showIsicLinkDialog()
      this.delayTimer = delay(3000)
      await this.delayTimer.promise
      logEvent2(ANALYTICS_EVENT.ISIC_PURCHASED, [ANALYTICS_PROVIDER.BRAZE], {
        date: new Date().toISOString()
      })
      getAppboy().requestImmediateDataFlush() // Flush before navigating outside the application
      if (window.cordova) {
        window.open(process.env.ISIC_DEEP_LINK, '_system')
      } else {
        window.open(process.env.ISIC_DEEP_LINK, '_blank', 'noopener,noreferrer')
      }
      setTimeout(hideIsicLinkDialog, 1000)
    }

    const copyIsicNumber = async () => {
      const isicNumber = this.props.initialValues.isicNumber
      if (isicNumber) {
        if (window.cordova) {
          try {
            window.cordova.plugins.clipboard.copy(isicNumber)
          } catch (error) {
            // Display an error message to the user
            alert('Oops, unable to copy. Please try again.')
          }
        } else {
          try {
            await navigator.clipboard.writeText(isicNumber)
          } catch (error) {
            console.error('Async: Could not copy text: ', error)
          }
        }
      }
    }

    return (
      <div {...classes('profileSettingsForm')}>
        <FormSectionCaption caption={this.props.t('profile.basicInfo')} />

        <TextInput
          disabled
          name="email"
          type="email"
          addonAfter={
            <Link to="/profile/settings/email">
              {this.props.t('common.edit')}
            </Link>
          }
          label={this.props.t('profile.emailAddressLabel')}
        />

        <TextInput
          disabled
          input={{
            value: '******'
          }}
          addonAfter={
            <Link to="/profile/settings/password">
              {this.props.t('common.edit')}
            </Link>
          }
          label={this.props.t('profile.passwordLabel')}
        />

        {this.props.isVerified && (
          <TextInput
            disabled
            name="phoneNumber"
            placeholder="-"
            input={{
              value: this.props.phoneNumber
            }}
            addonAfter={
              <Link to="/profile/settings/phone-number">
                {this.props.t('common.edit')}
              </Link>
            }
            label={this.props.t('profile.phoneNumber')}
          />
        )}
        {this.isPictureChangeable() ? (
          <TextInput
            disabled
            name="profilePicture"
            input={{
              value: this.props.fieldValues.photoLastModified
                ? this.props.t('profile.profilePictureUpdated', {
                    date: moment(
                      this.props.fieldValues.photoLastModified
                    ).format('DD.MM.YYYY')
                  })
                : '-'
            }}
            addonAfter={LinkToPhotoPage}
            label={this.props.t('profile.profilePictureLabel')}
          />
        ) : (
          <ProfilePhotoUnchangeable
            classes={classes}
            language={this.props.lng}
            t={this.props.t}
            photoIsModifiableDate={this.props.photoIsModifiableDate}
          />
        )}

        <form onSubmit={handleSubmit(this.onSubmitOpintopolkuUrl)}>
          {this.props.shouldShowOpintopolkuLink && (
            <TextInput
              disabled={this.state.opintopolkuUrlFieldDisabled}
              name="opintopolkuUrl"
              addonAfter={OpintopolkuUrlAddonAfter}
              label={this.props.t('profile.opintopolkuUrlLabel')}
            />
          )}
        </form>
        <LanguageSelector
          label={this.props.t('profile.languageLabel')}
          css={{container: css.languageSelect}}
        />

        <FormSectionCaption caption={this.props.t('profile.educationInfo')} />

        {this.props.isVerified && this.props.shouldShowEducationSelect && (
          <EducationSelect
            profile={this.props.initialValues}
            t={this.props.t}
            onEducationSelected={this.onEducationSelected}
          />
        )}

        {this.props.isVerified && (
          <div className={css.addEducationButtonContainer}>
            <Link to={'/profile/verify/start-form'}>
              <GenericButton style="outline" size="small">
                {this.props.t('profile.addMissingEducation')}
              </GenericButton>
            </Link>
          </div>
        )}

        {this.props.hasSchool && (
          <TextInput
            disabled
            name="school.name"
            placeholder="-"
            label={this.props.t('profile.school')}
          />
        )}

        <TextInput
          disabled
          name="organization.name"
          placeholder="-"
          label={this.props.t('profile.organizationLabel')}
        />

        <TextInput
          disabled
          name="studentEligibilityValue"
          label={this.props.t('profile.studentEligibilityLabel')}
        />

        <TextInput
          disabled
          name="studentType"
          input={{value: this.props.initialValues.studentType}}
          placeholder="-"
          label={this.props.t('profile.studentTypeLabel')}
        />

        <TextInput
          disabled
          name="jollaMembershipExtraInfo"
          placeholder="-"
          label={this.props.t('profile.extraInfoLabel')}
        />

        <TextInput
          disabled
          name="graduationYear"
          placeholder=""
          label={this.props.t('profile.graduationYear')}
          addonAfter={
            <Link to="/profile/settings/graduation-year">
              {this.props.t('common.edit')}
            </Link>
          }
        />

        {this.props.initialValues.digiIsicValidityValue && (
          <TextInput
            disabled
            name="digiIsicValidityValue"
            label={this.props.t('profile.digiIsicValidityLabel')}
          />
        )}

        {this.props.initialValues.isicNumber && (
          <TextInput
            disabled
            name="isicNumber"
            label={this.props.t('profile.isicNumberLabel')}
            addonAfter={
              <span onClick={onClickIsicLink}>
                {this.props.t('common.download')}
              </span>
            }
          />
        )}
        {this.state.shouldShowIsicLinkDialog && (
          <Portal node={document && document.getElementById('#portal')}>
            <IsicLinkDialog />
          </Portal>
        )}
        {isMobile() && (
          <Link to={'/profile/delete-account-permanently'}>
            <GenericButton style="outline" size="small" hasTopMargin={true}>
              {this.props.t('profile.accountDeletion.profilePageButton')}
            </GenericButton>
          </Link>
        )}
      </div>
    )
  }
}

export default compose(
  withRouter,
  withTranslate(),
  connect((state: StoreState) => {
    const profile = profileSelector(state)
    return {
      photo: profile.loaded && profile.photo,
      photoIsModifiableDate: profile.loaded && profile.photoIsModifiableDate,
      photoLastModifiedDisabled:
        profile.loaded && profile.photoLastModifiedDisabled,
      phoneNumber: profile.loaded && profile.phoneNumber,
      jollaMembershipExtraInfo:
        profile.loaded && profile.jollaMembershipExtraInfo,
      shouldShowEducationSelect: shouldShowEducationSelect(state),
      hasSchool: profile.loaded && profile.school,
      locale: profile.language
    }
  }),
  withRunSaga()
)(ProfileSettingsForm)
